// Copyright (C) 2022 by Posit Software, PBC.

/**
 * copyToClipboard copies a value to the clipboard in a backwards-compatible manner.
 * @param {{ value: String }} args string value to copy to clipboard
 */
export async function copyToClipboard({ value }) {
  try {
    return await navigator.clipboard.writeText(value);
  } catch (_) {}
}
