// Copyright (C) 2022 by Posit Software, PBC.

// ContentType is used to apply a filter for fetching the list of content for a user,
// i.e. shiny, document, plot, etc. For the purpose of fetching content on the dashboard
// if an empty content type filter is specified, then all content types are returned.
export class ContentType {
  constructor(value) {
    this._value = value;
  }

  isAll() {
    return this === ContentTypes.All;
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  toString() {
    const contentTypeDescription =
      this === ContentTypes.All ? 'all' : this._value;
    return `ContentType(${contentTypeDescription})`;
  }
}

// ContentTypes holds singleton values and a construction function for raw content type
// values.
const ContentTypes = {
  Application: new ContentType('application'),
  Document: new ContentType('document'),
  Plot: new ContentType('plot'),
  Pin: new ContentType('pin'),
  Api: new ContentType('api'),
  All: new ContentType('all'),

  of(value) {
    switch (value) {
      case 'application':
        return ContentTypes.Application;
      case 'document':
        return ContentTypes.Document;
      case 'plot':
        return ContentTypes.Plot;
      case 'pin':
        return ContentTypes.Pin;
      case 'api':
        return ContentTypes.Api;
      case 'all':
        return ContentTypes.All;
      default:
        // all other values are defaulted to all content types
        return ContentTypes.All;
    }
  },
};

export default ContentTypes;
