<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <span class="interval-schedule-input">
    {{ $t('appSettings.schedule.inputLabels.every') }}
    <input
      :value="value"
      type="number"
      class="schedule-n-input"
      min="1"
      name="minute"
      v-bind="$attrs"
      data-automation="schedule-by-interval"
      :disabled="disabled"
      :aria-label="ariaLabel"
      @input="onInput"
      @blur="emptyHandler"
    >
    {{ term }}.
  </span>
</template>

<script>
export default {
  name: 'IntervalScheduleInput',
  props: {
    value: {
      type: Number,
      required: true,
    },
    term: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  date() {
    return {
      isEmpty: false,
    };
  },
  computed: {
    ariaLabel() {
      return `${this.$t('appSettings.schedule.inputLabels.every')} ${this.value} ${this.term}`;
    },
  },
  methods: {
    onInput(ev) {
      const newValue = Number(ev.target.value);
      if (newValue) {
        // Only emit the new value if it is a non-empty (zero) value
        // this to prevent the weird jumpy auto-zero experience.
        // And we don't allow schedule intervals of 0.
        this.$emit('input', newValue);
      } else {
        this.isEmpty = true;
      }
    },
    emptyHandler() {
      // On blur, if the input is left empty...
      // We are forcing re-rendering the input with the latest valid value.
      if (this.isEmpty) {
        this.$forceUpdate();
        this.isEmpty = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.interval-schedule-input {
  display: inline-block;
  font-size: 0.85rem;
  line-height: 2.2rem;
  vertical-align: middle;
}

.schedule-n-input {
  padding-right: 0.2rem;
  width: 3.6rem;
}
</style>
