<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="time-input rsc-time-input">
    <div class="time-input__hour">
      <TimePicker
        :value="hour"
        :focus="isFocused('hour')"
        :disabled="disabled"
        type="hour"
        @change="updateHour"
        @focus="setFocus('hour')"
      />
    </div>
    <span class="time-input__separator" />
    <div class="time-input__minute">
      <TimePicker
        :value="minute"
        :focus="isFocused('minute')"
        :disabled="disabled"
        type="minute"
        @change="updateMinute"
        @focus="setFocus('minute')"
      />
    </div>
    <div class="time-input__ampm">
      <button
        v-focus-if="isFocused('ampm')"
        :class="{ selected: ampm === 'am' }"
        :disabled="disabled"
        @click="updateAmPm('am')"
      >
        am
      </button>
      <button
        :class="{ selected: ampm === 'pm' }"
        :disabled="disabled"
        @click="updateAmPm('pm')"
      >
        pm
      </button>
    </div>
  </div>
</template>

<script>
import TimePicker from './TimePicker';
import { FocusIf } from '@/utils/focus';
import moment from 'moment-mini';

export default {
  name: 'TimeInput',
  components: {
    TimePicker
  },
  directives: {
    'focus-if': FocusIf
  },
  props: {
    date: {
      // momentjs date object
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: String,
      required: false,
      default: null,
      validator: function(value) {
        return [null, 'date', 'hour', 'minute', 'ampm'].indexOf(value) !== -1;
      }
    }
  },
  computed: {
    hour() {
      if (!this.date) {
        return null;
      }

      const normalizedHour = this.date.hour() % 12;
      return normalizedHour === 0 ? 12 : normalizedHour;
    },
    minute() {
      return this.date ? this.date.minute() : null;
    },
    ampm() {
      if (!this.date) {
        return null;
      }

      return this.date.hour() < 12 ? 'am' : 'pm';
    }
  },
  methods: {
    isFocused(attr) {
      return this.focus === attr;
    },
    setFocus(attr) {
      this.$emit('focus', attr);
    },
    updateHour(hour) {
      const updatedDate = this.date ? this.date.clone() : moment();
      const normalizedHour = hour % 12;

      switch (this.ampm) {
        case 'pm':
          updatedDate.set({ h: normalizedHour + 12 });
          break;
        default:
          updatedDate.set({ h: normalizedHour });
      }

      this.update(updatedDate, 'hour');
    },
    updateMinute(minute) {
      const updatedDate = this.date ? this.date.clone() : moment();

      updatedDate.set({ m: minute });

      this.update(updatedDate, 'minute');
    },
    updateAmPm(ampm) {
      const updatedDate = this.date ? this.date.clone() : moment();
      const normalizedHour = updatedDate.hour() % 12;

      switch (ampm) {
        case 'pm':
          updatedDate.set({ h: normalizedHour + 12 });
          break;
        default:
          updatedDate.set({ h: normalizedHour });
      }

      this.update(updatedDate, 'ampm');
    },
    update(newDate, attr) {
      this.$emit('change', newDate, attr);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
.rsc-time-input {
  /* This is needed so a input[type=number]'s spin buttons do not bleed through the calendar */
  z-index: 1;

  button {
    text-transform: none;
  }
}

.time-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-input__separator {
  padding: 0 0.2em;
  text-align: center;

  &:after {
    content: ':';
  }
}

.time-input__ampm {
  display: flex;
  margin-left: 0.4em;

  button {
    padding: 5px 10px;

    &:first-child {
      margin-right: 0.2em;
    }

    &.selected {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.time-input__hour, .time-input__minute {
  width: 40px;
}
</style>
