// Copyright (C) 2023 by Posit Software, PBC.

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

export const reduxStore = createStore(
  rootReducer,
  {},
  applyMiddleware(thunk, sagaMiddleware),
);
