// Copyright (C) 2022 by Posit Software, PBC.

import keyMirror from '@/store/redux/utils/keyMirror';

export const ActionTypes = keyMirror({
  SHOW_NAV_PANEL: true,
  SHOW_USER_PANEL: true,
  SHRINK_HEADER: true,
  SHOW_REPORT_PROGRESS: true,
  SHOW_CONTENT_OPTIONS_PANEL: true,
  SET_INIT_WINDOW_SIZE: true,
  SCROLL_POS: true,
  CLEAR_STATUS_MESSAGE: true,
  SET_STATUS_MESSAGE: true,
  SAGA_STATUS_ACTIVITY_MESSAGE: true,
  SAGA_CLEAR_STATUS_ACTIVITY_MESSAGE: true,
  SET_STATUS_ACTIVITY_MESSAGE: true,
  CLEAR_STATUS_ACTIVITY_MESSAGE: true,
  TRACK_LAST_LOGIN: true,
  TOGGLE_SHOW_SETTINGS_PANEL: true,
  TOGGLE_PARAMS_PANEL: true,
  SAGA_SET_PANEL_VISIBILITY: true,
  SAGA_TOGGLE_PANEL_VISIBILITY: true,
  SHOW_LOGS: true,
  HIDE_LOGS: true,
  SHOW_SUBSCRIPTIONS: true,
  HIDE_SUBSCRIPTIONS: true,
  RELOADING_CONTENT_FRAME: true,
});

export const PanelTypes = keyMirror({
  INPUT_PANEL: true,
  HISTORY_PANEL: true,
  APPSETTINGS_PANEL: true,
  COMMENTS_PANEL: true,
});
