<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="currentStatus.activity.showActivity && !currentStatus.activity.contextualized"
      class="statusMessage activity showing"
    >
      <div class="message">
        <div class="messageSpinnerContainer">
          <Spinner />
        </div>
        {{ currentStatus.activity.message }}
      </div>
    </div>

    <div
      v-if="currentStatus.status.showErrorOrInfo"
      role="button"
      tabindex="-1"
      :class="currentStatus.status.cssClass"
      @click="closeMessage"
      @keyup.enter="closeMessage"
      @keyup.space="closeMessage"
    >
      <div
        class="message"
        data-automation="status-message-info-text"
        role="alert"
        aria-live="assertive"
        v-html="currentStatus.status.message"
      />
      <button
        id="messageClose"
        aria-label="Close"
        data-automation="status-message-info-close"
      />
    </div>

    <div
      v-if="currentStatus.status.showWarning"
      role="button"
      tabindex="-1"
      :class="currentStatus.status.cssClass"
      @click="closeMessage"
      @keyup.enter="closeMessage"
      @keyup.space="closeMessage"
    >
      <div
        class="message"
        data-automation="status-message-warning-text"
        role="alert"
        aria-live="assertive"
      >
        {{ currentStatus.status.message }}
      </div>
      <button
        id="messageClose"
        aria-label="Close"
        data-automation="status-message-warning-close"
      />
    </div>
  </div>
</template>

<script>
import { latestActivity } from '@/store/redux/app-state/app-state.selectors';
import { clearStatusMessage } from '@/store/redux/app-state/app-state.actions';
import { StatusTypes } from '@/store/redux/status/message-types';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'StatusMessage',
  components: {
    Spinner
  },
  data() {
    const reduxState = this.$redux.getState();

    return {
      reduxState,
    };
  },
  computed: {
    currentStatus() {
      // First calculate the activity message
      const pageId = this.$route.path;
      const activityInfo = latestActivity(this.reduxState, pageId);
      const activity = {
        showActivity: !!activityInfo.message,
        message: activityInfo.message,
        contextualized: !activityInfo.opts
          ? false
          : activityInfo.opts.contextualized
      };
      // then get the other status messages
      const {
        statusMessage: { message, statusType }
      } = this.reduxState.appState;
      let statusOptions = {
        showErrorOrInfo: false,
        showWarning: false
      };
      // Define response based on message type
      switch (statusType) {
        case StatusTypes.ERROR_MESSAGE:
          statusOptions = {
            cssClass: 'statusMessage error showing',
            message,
            showErrorOrInfo: true
          };
          break;
        case StatusTypes.INFO_MESSAGE:
          statusOptions = {
            cssClass: 'statusMessage info showing',
            message,
            showErrorOrInfo: true
          };
          break;
        case StatusTypes.HTTP_WARNING:
          statusOptions = {
            cssClass: 'statusMessage error showing', // TODO should this be warning?
            message,
            showWarning: true
          };
          break;
      }

      return {
        activity,
        status: statusOptions
      };
    },
  },
  created() {
    this.unsubscribe = this.$redux.subscribe(() => {
      this.reduxState = this.$redux.getState();
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    closeMessage() {
      this.$redux.dispatch(clearStatusMessage());
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';
@import 'connect-elements/src/styles/shared/_mixins';

.statusMessage {
  position: relative;
  max-height: 0px;
  overflow: hidden;

  @include normal-transition-duration();
  @include transition-property(max-height, margin);

  .message {
    text-align: center;
    border-top: 1px dotted $color-light-grey-4;
    line-height: 26px;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    padding: 4px 45px;

    a {
      color: #fff;
    }
  }

  &.showing {
    max-height: 100px;
  }

  &.activity {
    @include transition-delay(0.5s);
    .message {
      background-color: $color-light-grey;
      color: $color-dark-grey-3;

      .messageSpinnerContainer {
        display: inline-block;
      }
    }
  }

  &.warning {
    .message {
      background-color: #f9f5ed;
      color: #93691a;
    }
  }

  &.error {
    .message {
      background-color: #ffeded;
      color: $color-error;
    }
  }

  &.info {
    .message {
      background-color: lighten($color-alert-info, 15%);
      color: darken($color-posit-green, 15%);
    }
  }

  #messageClose {
    position: absolute;
    right: .5em;
    top: 2px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  .messageSpinnerContainer {
    display: none;
    width: 25px;
    height: 25px;
    margin: -5px 5px -5px 0;
  }
}

</style>
