<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioHeader"
    class="connect-header contentHeader"
    :class="{ logsActive, historyActive }"
  >
    <div class="band contentHeader">
      <div class="innards">
        <div id="leftSide">
          <template v-if="!isAuthenticated">
            <div>
              <router-link
                :to="{ name: 'welcome' }"
                data-automation="content-header-logo-link"
              >
                <img
                  class="connect-header__logo"
                  :src="'__logo__'"
                  alt="Return to Welcome"
                >
              </router-link>
            </div>
          </template>
          <template v-else>
            <button
              id="menuToggler"
              aria-label="Menu Toggle"
              class="connect-header__menu-button"
              @click="showNavPanel"
            />

            <button
              id="overlayNavToggler"
              aria-label="Overlay Toggle"
              @click="showOverlayNav = !showOverlayNav"
            />
          </template>

          <ContentPath />
        </div>

        <div id="rightSide">
          <ContentActions
            v-if="app && currentUser"
          />
          <CurrentUser id="currentUser" />
        </div>
      </div>
    </div>

    <BundleHistory v-if="isAppHistoryVisible" />
    <RenderingHistory v-if="isVariantHistoryVisible" />

    <div
      v-if="showOverlayNav"
      class="overlayNav"
    >
      <ContentPath
        :is-authenticated="isAuthenticated"
      />
    </div>

    <StatusMessage />
  </div>
</template>

<script>
import ContentActions from './ContentActions';
import ContentPath from './ContentPath';
import CurrentUser from '@/components/CurrentUser';
import StatusMessage from '@/components/StatusMessage';
import { BundleHistory, RenderingHistory } from '@/components/History';
import { showNavPanel } from '@/store/redux/app-state/app-state.actions';
import { mapState } from 'vuex';

export default {
  name: 'ContentHeader',
  components: {
    BundleHistory,
    ContentActions,
    ContentPath,
    CurrentUser,
    RenderingHistory,
    StatusMessage,
  },
  // TODO: This remaining redux dispatch can be migrated to Vuex state
  // when the <app-layout> (app-layout.component.js) gets migrated to Vue.
  mapDispatchToMethods(dispatch) {
    return {
      showNavPanel: () => dispatch(showNavPanel(true)),
    };
  },
  data() {
    return {
      showOverlayNav: false,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      isAppHistoryVisible: state => state.bundles.showHistoryPane,
      isVariantHistoryVisible: state => state.contentView.renderingHistory.showHistoryPane,
      showLogs: state => state.contentView.showLogs,
    }),
    logsActive() {
      return this.showLogs;
    },
    historyActive() {
      return this.isAppHistoryVisible || this.isVariantHistoryVisible;
    },
  },
};
</script>
