<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      :show-close="false"
      :message="$t('groups.status.loadingMembers')"
      type="activity"
    />

    <RSTable
      v-if="hasMembers"
      :columns="tableHeaders"
      data-automation="group-members__list"
    >
      <RSTableRow
        v-for="(member, index) in members"
        :key="member.guid"
        :row-id="member.guid"
        :deletable="canRemoveGroupMember(member)"
        :delete-button-label="formatRemoveGroupMember(member, group)"
        :row-label="formatNavigateUser(member)"
        :clickable="true"
        @click="navigateUser(member)"
        @delete="$emit('remove-member', { guid: member.guid, index })"
      >
        <RSTableCell
          :has-icon="true"
          :fill="true"
        >
          <RSPrincipalInfo
            :initials="member.displayInitials"
            :name="member.displayName"
            :status="member.displayStatuses"
          />
        </RSTableCell>
        <RSTableCell>{{ member.userRole.displayName }}</RSTableCell>
        <RSTableCell>
          {{ member.activeTime | activeTime }}
        </RSTableCell>
      </RSTableRow>
    </RSTable>

    <div
      v-else
      class="emptyListMessage"
      data-automation="members__list--empty"
    >
      {{ $t('groups.titles.noMembers') }}
    </div>

    <RSPager
      v-show="showPager"
      :disable-left-actions="disablePreviousPagination"
      :disable-right-actions="disableNextPagination"
      :labels="$t('navigation.pagination.fixed')"
      @first-page="$emit('goto-page', 'first')"
      @previous-page="$emit('goto-page', 'previous')"
      @next-page="$emit('goto-page', 'next')"
      @last-page="$emit('goto-page', 'last')"
    />
  </div>
</template>

<script>
import RSPager from 'Shared/components/RSPager';
import RSTable from 'Shared/components/RSTable';
import RSTableRow from 'Shared/components/RSTableRow';
import RSTableCell from 'Shared/components/RSTableCell';
import RSPrincipalInfo from 'Shared/components/RSPrincipalInfo';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { activeTime } from '@/utils/activeTime.filter';

export default {
  name: 'GroupMemberList',
  components: {
    RSPager,
    RSTable,
    RSTableCell,
    RSTableRow,
    RSPrincipalInfo,
    EmbeddedStatusMessage,
  },
  filters: {
    activeTime,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasMembers() {
      return this.members.length > 0;
    },
    tableHeaders() {
      const hasRemovableMembers = this.members.some(
        this.canRemoveGroupMember
      );
      return [
        { label: this.$t('users.title.user'), width: '100%' },
        { label: this.$t('users.title.role') },
        {
          label: this.$t('users.title.lastActive'),
          // 2 to account for delete icon, 1 otherwise
          size: hasRemovableMembers ? 2 : 1,
        },
      ];
    },
    showPager() {
      return this.totalPages > 1;
    },
    disablePreviousPagination() {
      return this.currentPage === 1;
    },
    disableNextPagination() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    canRemoveGroupMember(user) {
      const { currentUser, group, serverSettings } = this;
      return currentUser.canDeleteGroupMember(group, serverSettings, user);
    },
    formatRemoveGroupMember(user, group) {
      return this.$t('groups.actions.removeGroupMember', {
        user: user.displayName,
        group: group.name,
      });
    },
    formatNavigateUser(user) {
      return this.$t('users.action.navigateUser', {
        user: user.displayName,
      });
    },
    navigateUser(user) {
      this.$router.push({ name: 'people.users.profile', params: { guid: user.guid } });
    },
  },
};
</script>
