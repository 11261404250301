// Copyright (C) 2022 by Posit Software, PBC.

const auditLogs = {
  title: 'Rastro de Auditoría',
  columnHeaders: {
    time: 'Hora',
    user: 'Usuario',
    event: 'Evento',
  },
};

export { auditLogs };
