// Copyright (C) 2022 by Posit Software, PBC.

// AppRole reflects the backend `AppRole` type.  It is used to specify the
// relationship of the accessing user to this app. A value of `owner` is
// returned for the content owner. `editor` indicates a collaborator. The
// `viewer` value is given to users who are permitted to view the content. A
// `none` role is returned for administrators who cannot view the content but
// are permitted to view its configuration.
export class AppRole {
  constructor(value) {
    this._value = value;
  }

  // Whether owners or editors have access.
  isCollaborator() {
    return this === AppRoles.Owner || this === AppRoles.Editor;
  }

  isOwner() {
    return this === AppRoles.Owner;
  }

  isEditor() {
    return this === AppRoles.Editor;
  }

  isViewer() {
    return this === AppRoles.Viewer;
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  // Allows comparison operators (>, >=, ==, <=, <) to work with this object.
  valueOf() {
    switch (this) {
      case AppRoles.Owner:
        return 3;
      case AppRoles.Editor:
        return 2;
      case AppRoles.Viewer:
        return 1;
      case AppRoles.None:
        return 0;
      default:
        return 0;
    }
  }

  toString() {
    return `AppRole(${this._value})`;
  }
}

// AppRoles holds singleton values and a construction function for raw app role
// values.
const AppRoles = {
  Owner: new AppRole('owner'),
  Editor: new AppRole('editor'),
  Viewer: new AppRole('viewer'),
  None: new AppRole('none'),

  of(value) {
    switch (value) {
      case 'owner':
        return AppRoles.Owner;
      case 'editor':
        return AppRoles.Editor;
      case 'viewer':
        return AppRoles.Viewer;
      case 'none':
        return AppRoles.None;
      default:
        return AppRoles.None;
    }
  },
};

export default AppRoles;
