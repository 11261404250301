<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Simple Selector for changing active locale within $i18n
-->
<template>
  <div class="locale-changer">
    <label for="language">{{ $t('language.select.label') }}</label>
    <select
      id="language"
      v-model="$i18n.locale"
    >
      <option
        v-for="(locale, i) in locales"
        :key="`Locale${i}`"
        :value="locale"
      >
        {{ longLocales[locale] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'LocaleChanger',
  computed: {
    locales() {
      return Object.keys(this.$i18n.messages);
    },
    longLocales() {
      const longLocaleList = {};
      this.locales.forEach(locale => {
        longLocaleList[locale] = this.$i18n.messages[this.$i18n.locale].language[locale];
      });
      return longLocaleList;
    },
  },
};
</script>

<style scoped>
</style>
