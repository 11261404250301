// Copyright (C) 2022 by Posit Software, PBC.

const connect = {
  longDescription: `
    Posit Connect es una plataforma de publicación para el trabajo que sus equipos
    crean en R. Comparta las aplicaciones Shiny, los informes de Markdown R,
    las API de Plumber, los tableros de control, las parcelas y más en un lugar
    conveniente. Utilice la publicación de botón pulsado del RStudio IDE, la ejecución
    programada de informes y las políticas de seguridad flexibles para llevar
    el poder de la ciencia de datos a toda su empresa.`,
};

export { connect };
