<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="app-settings__tags">
    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="hasChanges"
      @save="save"
      @discard="resetFromFrozenSelection"
    />

    <EmbeddedStatusMessage
      v-if="showLoading"
      :show-close="false"
      :message="loadingMessage"
      type="activity"
    />

    <div v-if="!loading">
      <EmptyTagsMessage
        v-if="noCategories"
        :is-admin="userIsAdmin"
      />

      <div v-else>
        <p class="formSection infoSection">
          {{ $t('appSettings.tags.message') }}
        </p>
        <TagsCatalogSelector
          ref="tagsCatalog"
          :selected="selected"
          :read-only="readOnly"
          @empty="noCategories = true"
          @change="selectionChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getApp, getAppTags, addTagToApp, removeTagFromApp } from '@/api/app';
import { setErrorMessageFromAPI } from '@/utils/status';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import TagsCatalogSelector from '@/components/TagsCatalogSelector';
import ConfirmationPanel from './ConfirmationPanel';
import EmptyTagsMessage from './EmptyTagsMessage';
import { mapState } from 'vuex';

export default {
  name: 'AppTags',
  components: {
    ConfirmationPanel,
    TagsCatalogSelector,
    EmptyTagsMessage,
    EmbeddedStatusMessage,
  },
  data() {
    return {
      app: null,
      loading: true,
      selected: new Set(),
      noCategories: false,
      userIsAdmin: false,
      userCanEdit: false,
      isSavingTags: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    showLoading() {
      return this.loading || this.isSavingTags;
    },
    loadingMessage() {
      const locale = this.isSavingTags ? 'common.saving' : 'common.loading';
      return this.$t(locale);
    },
    readOnly() {
      return !this.userCanEdit || this.isSavingTags;
    },
    enableConfirmation() {
      return this.hasChanges && !this.isSavingTags;
    },
    hasChanges() {
      const { frozenSelection, selected } = this;
      if (frozenSelection.size !== selected.size) {
        return true;
      }
      for (const tag of frozenSelection) {
        if (!selected.has(tag)) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    // frozenSelection will contain a frozen state of the selected tags after fetched
    this.frozenSelection = new Set();
    this.init();
  },
  methods: {
    async init() {
      let app, assignedTags, error;
      try {
        // location.hash #/apps/{id}/tags/...
        const appId = location.hash.split('/')[2];
        [app, assignedTags] = await Promise.all([
          getApp(appId),
          getAppTags(appId),
        ]);
      } catch (err) {
        error = err;
        setErrorMessageFromAPI(err);
      }

      if (!error) {
        const assignedTagsIds = assignedTags.map(tag => tag.id);
        this.app = app;
        this.userIsAdmin = this.currentUser?.isAdmin();
        this.userCanEdit = this.currentUser?.canEditAppSettings(app);
        this.selected = new Set(assignedTagsIds);
        this.frozenSelection = new Set(assignedTagsIds);
      }
      this.loading = false;
    },
    selectionChanged({ target, checked, deselectedChildren }) {
      if (checked) {
        this.selected.add(target);
      } else {
        this.selected.delete(target);
        deselectedChildren.forEach(tag => this.selected.delete(tag));
      }
      // Re-assignment: Vue doesn't track deep changes on Sets.
      this.selected = new Set(this.selected);
    },
    async save() {
      const savingTimeout = setTimeout(() => {
        this.isSavingTags = true;
      }, 300);
      const apiRequests = [];
      const appId = this.app.id;
      const deletedRegistry = new Set(this.frozenSelection);

      // Save added tags
      this.selected.forEach(item => {
        if (!this.frozenSelection.has(item)) {
          apiRequests.push(addTagToApp(appId, item));
        }
        deletedRegistry.delete(item);
      });
      // Remove tags that are not selected anymore
      deletedRegistry.forEach(item => {
        apiRequests.push(removeTagFromApp(appId, item));
      });

      // On requests submitted, sync frozen selection and reset state
      try {
        await Promise.all(apiRequests);
      } catch (err) {
        setErrorMessageFromAPI(err);
      }
      this.frozenSelection = new Set(this.selected);
      this.resetFromFrozenSelection();
      clearTimeout(savingTimeout);
      this.isSavingTags = false;
    },
    resetFromFrozenSelection() {
      this.selected = new Set(this.frozenSelection);
      this.$refs.tagsCatalog.updateCheckedRegistry(this.selected);
    },
  },
};
</script>
