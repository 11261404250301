<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Option panel for the groups view. It has a stateful search string. It dispatches
  actions into the Groups Vuex module store, which then updates the group list
  which is displayed by the Group View.
-->
<template>
  <OptionsPanel>
    <div class="rsc-options-panel__header">
      <h2 class="rsc-options-panel__header-title">
        {{ $t('groups.options.title') }}
      </h2>
      <button
        :aria-label="$t('groups.options.actions.close')"
        class="paneCloser"
        @click.prevent="$emit('close')"
      />
    </div>

    <div class="rsc-options-panel__input-group">
      <RSInputSearch
        ref="searchInput"
        v-model.trim="searchString"
        :label="$t('groups.options.search.filter')"
        :placeholder="$t('groups.options.search.description')"
        :show-label="false"
        :small="true"
        :show-clear="Boolean(searchString)"
        icon="search"
        name="gop-search"
        data-automation="gop-search-input"
        @input="filterChanged"
        @clear="clearSearch"
      />

      <div class="rs-field">
        <i18n
          v-for="(paragraph, index) in searchDescription"
          :key="index"
          tag="p"
          :path="paragraph"
        >
          <strong>{{ $t('groups.options.info.exactTerm') }}</strong>
        </i18n>
      </div>
    </div>
  </OptionsPanel>
</template>

<script>
import RSInputSearch from 'Shared/components/RSInputSearch';
import OptionsPanel from '@/components/OptionsPanel';
import { debounce } from '@/utils/debounce';
import Focus from '@/utils/focus';

export default {
  name: 'GroupsOptionsPanel',
  components: { OptionsPanel, RSInputSearch },
  directives: { Focus },
  props: {
    canAddGroups: {
      type: Boolean,
      required: true,
    },
    canSearchById: {
      type: Boolean,
      required: true,
    },
    isRemote: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchString: '',
    };
  },
  computed: {
    searchDescription() {
      const description = ['groups.options.info.search'];
      if (this.canSearchById) {
        description[0] = 'groups.options.info.searchWithUniqueID';
      }
      if (this.canAddGroups) {
        if (this.isRemote) {
          description.push('groups.options.info.importGroup');
        } else {
          description.push('groups.options.info.addGroup');
        }
      }
      return description;
    },
  },
  methods: {
    focus() {
      this.$nextTick(() => this.$refs.searchInput.focusElement());
    },
    clearSearch() {
      this.searchString = '';
      this.onChange();
    },
    onChange() {
      this.$emit('change', {
        prefix: this.searchString,
      });
    },
    filterChanged: debounce(300, function() {
      this.onChange();
    }),
  },
};
</script>
