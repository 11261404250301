<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="rs-authbox">
        <header class="rs-authbox__header">
          <div
            class="rs-authbox__title"
            data-automation="set-password-choose-password"
          >
            {{ $t('authentication.setPassword.title') }}
          </div>
        </header>
        <form class="rs-authbox__form">
          <RSInputPassword
            ref="password1"
            v-model="form.password"
            :label="$t('authentication.label.newPassword')"
            :disabled="!canSetPassword"
            :message="errorMessage('password')"
            data-automation="set-password-1"
            name="password"
            message-type="error"
            @input="formChangeHandler"
          >
            <template #help>
              <ul>
                <li
                  v-for="item in $t('authentication.setPassword.help')"
                  :key="item"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </RSInputPassword>
          <RSInputPassword
            v-model="form.repeatNewPassword"
            :label="$t('authentication.label.repeatNewPassword')"
            :disabled="!canSetPassword"
            :message="errorMessage('repeatNewPassword')"
            data-automation="set-password-2"
            name="repeatNewPassword"
            message-type="error"
            @input="formChangeHandler"
          />
          <RSButton
            ref="button"
            :data-automation="button.dataAutomation"
            :label="button.label"
            :disabled="!token"
            type="primary"
            @click.prevent="button.handler"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import RSInputPassword from 'Shared/components/RSInputPassword';
import RSButton from 'Shared/components/RSButton';
import { getHashQueryParameter } from '@/utils/paths';
import { setPassword } from '@/api/authentication';
import {
  setErrorMessage,
  setErrorMessageFromAPI,
  setInfoMessage,
  clearStatusMessage,
} from '@/utils/status';
import * as Validators from '@/utils/validators';
import { mapActions } from 'vuex';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';

const formValidations = {
  form: {
    password: Validators.password(),
    repeatNewPassword: Validators.repeatPassword('password'),
  },
};

export default {
  name: 'SetPasswordView',
  components: { RSButton, RSInputPassword },
  data() {
    return {
      isPasswordSet: false,
      hasApiError: false,
      form: {
        password: '',
        repeatNewPassword: '',
      },
    };
  },
  computed: {
    token() {
      const [token] = getHashQueryParameter('resettoken') || [];
      return token;
    },
    canSetPassword() {
      return this.token && !this.isPasswordSet;
    },
    button() {
      const submitButton = {
        label: this.$t('authentication.label.setPassword'),
        handler: this.setNewPassword,
        dataAutomation: 'set-password-submit',
      };
      const navigateButton = {
        label: this.$t('navigation.proceedToDashboard'),
        handler: this.redirect,
        dataAutomation: 'set-password-goto-connect',
      };
      return this.isPasswordSet ? navigateButton : submitButton;
    },
  },
  mounted() {
    clearStatusMessage();
    if (!this.token) {
      setErrorMessage(
        this.$t('authentication.setPassword.status.missingToken')
      );
    }
    this.$refs.password1.focusElement();
  },
  methods: {
    ...mapActions({
      reloadCurrentUser: CURRENT_USER_LOAD,
    }),
    errorMessage(field) {
      const fieldValidators = this.$v.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // find all failing field validators
      const failedValidators = Object.keys(formValidations.form[field]).filter(
        validatorName => fieldValidators[validatorName] === false
      );

      // return the first failed validator
      return this.$t(
        `authentication.validation.${field}.${failedValidators[0]}`
      );
    },
    formChangeHandler() {
      if (this.hasApiError) {
        clearStatusMessage();
        this.hasApiError = false;
      }
    },
    setNewPassword() {
      // trigger validation before API submit and bail if form is not valid
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return Promise.resolve();
      }
      clearStatusMessage();

      return setPassword(this.token, this.form.password)
        .then(() => {
          this.isPasswordSet = true;
          setInfoMessage(
            this.$t('authentication.setPassword.status.success'),
            true
          );
          this.$refs.button.focusElement();
        })
        .catch(e => {
          this.hasApiError = true;
          setErrorMessageFromAPI(e);
        });
    },
    async redirect() {
      await this.reloadCurrentUser();
      this.$router.push({ name: 'root' });
    },
  },
  validations: formValidations,
};
</script>
