<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    :aria-label="$t('contentHeader.actions.sourceVersions.label')"
    :title="$t('contentHeader.actions.sourceVersions.label')"
    class="action sourceVersions content-actions__source-versions-button"
    data-automation="menuitem-source-versions--dropdown"
    @click="$emit('source-versions')"
  >
    <span class="actionTitle">{{ $t('contentHeader.actions.sourceVersions.label') }}</span>
  </button>
</template>

<script>

export default {
  name: 'SourceVersionsButton',
};
</script>
