<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="!!errorMessage"
      :message="errorMessage"
      :show-close="true"
      type="error"
      data-automation="imageError"
      @close="clearError"
    />
    <div
      v-if="!readOnly"
      :class="{ imageFileInputContainer: true, noImageYet: !thumbnail }"
    >
      <input
        id="image-file-input"
        ref="image"
        type="file"
        aria-label="Upload a display image for this content"
        data-automation="settings-info-image-input"
        class="fileInput itemImage"
        accept=".gif, .jpg, .jpeg, .png, .svg"
        @change="imageAdded"
      >
      <div
        class="imageDisplay itemImage"
        :style="{ backgroundImage: `url(${thumbnail})` }"
        data-automation="settings-info-image-display"
      >
        <div class="prompt">
          {{ imageUploadUserActionProse }}
        </div>
      </div>
    </div>
    <div
      v-if="readOnly"
      class="imageFileInputContainer"
    >
      <div
        class="imageDisplay itemImage"
        :style="{ backgroundImage: `url(${thumbnail})` }"
      >
        <div class="prompt">
          {{ $t('appSettings.info.imageFileInput.accessDenied') }}
        </div>
      </div>
    </div>
    <div
      v-if="showClear"
      class="actions alignRight"
    >
      <button
        type="button"
        data-automation="settings-info-image-clear"
        @click="onClear"
        @keydown.space.prevent="onClear"
      >
        {{ $t('appSettings.info.imageFileInput.clearImage') }}
      </button>
    </div>
  </div>
</template>

<script>

import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { humanizeBytesDecimal } from '@/utils/bytes.filter';
import { mapState } from 'vuex';

const ACCEPTABLE_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
];

export default {
  name: 'ImageFileInput',
  components: {
    EmbeddedStatusMessage,
  },
  props: {
    thumbnail: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  computed: {
    showClear() {
      return this.thumbnail !== '' && !this.readOnly;
    },
    imageUploadUserActionProse() {
      // https://stackoverflow.com/questions/17907445/how-to-detect-ie11/19868056#19868056
      const isIe = !window.ActiveXObject && 'ActiveXObject' in window;
      const isEdge = !isIe && !!window.StyleMedia;
      // https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
      if (isIe || isEdge) {
        return this.$t('appSettings.info.imageFileInput.actionProseMS');
      }
      return this.$t('appSettings.info.imageFileInput.actionProse');
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    }),
  },
  watch: {
    thumbnail() {
      if (!this.readOnly) {
        this.$refs.image.value = this.$refs.image.defaultValue;
        this.clearError();
      }
    }
  },
  methods: {
    imageAdded() {
      const {
        maximumAppImageSize: maxSize
      } = this.serverSettings;
      if (this.$refs.image.files.length === 0) {
        // User cancelled
        return;
      }
      const file = this.$refs.image.files[0];
      if (ACCEPTABLE_FILE_TYPES.indexOf(file.type) === -1) {
        this.errorMessage = this.$t('appSettings.info.imageFileInput.notSupportedError');
        this.$refs.image.value = this.$refs.image.defaultValue;
        return;
      } else if (file.size > maxSize) {
        this.errorMessage = this.$t('appSettings.info.imageFileInput.maxSizeError', { size: humanizeBytesDecimal(maxSize) });
        this.$refs.image.value = this.$refs.image.defaultValue;
        return;
      }
      this.$emit('input', { file });
    },
    onClear() {
      if (!this.readOnly) {
        this.$refs.image.value = this.$refs.image.defaultValue;
        this.$emit('input', { file: null });
      }
    },
    clearError() {
      this.errorMessage = '';
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.imageFileInputContainer {
  position: relative;
  z-index: 0;
  &:focus-within {
    @include control-focus;
  }

  .fileInput {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: 1;
  }

  .imageDisplay {
    position: relative;
    z-index: -1;
    background-color: $color-white;
    @include flex-center();
  }

  .prompt {
    opacity: 0;
    position: relative;
    color: $color-heading;
    text-align: center;
    font-size: 15px;
    padding: 10%;
    background: rgba(255, 255, 255, .9);

    @include transition-property(opacity);
    @include normal-transition-duration();
  }

  &:hover, &.noImageYet {
    .prompt {
      opacity: 1;
    }
  }
}
</style>
