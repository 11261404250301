<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :subject="$t('content.jumpstart.dontShowExamples.purpose')"
    :close-button-label="$t('common.actions.close')"
    :active="active"
    class="dont-show"
    @close="close"
  >
    <template #content>
      <p>{{ $t('content.jumpstart.dontShowExamples.noExamplesNextLogin') }}</p>

      <p>{{ $t('content.jumpstart.dontShowExamples.viewAnyTime') }}</p>

      <p class="dont-show__screenshot">
        <img
          alt=""
          class="dont-show__screenshot-img"
          src="/images/jumpstart/newContentMenu.png"
        >
      </p>
    </template>
    <template #controls>
      <RSButton
        id="okButton"
        :label="$t('common.buttons.ok')"
        @click="close"
      />
    </template>
  </RSModal>
</template>

<script>
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';

export default {
  name: 'DontShowDialog',
  components: {
    RSModal,
    RSButton,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    const okButton = document.getElementById('okButton');
    okButton && okButton.focus();
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.dont-show {
  /* The DontShowDialog is a modal on top of another modal, so
     we need a higher z-index than the base rs-modal class (which is 1000).  */
  z-index: 1001 !important;

  &__screenshot {
    text-align: right;
    padding: 10px;
    margin-bottom: 0;
    background-color: $color-light-blue;
    border-radius: 10px;
    line-height: 0; /* so no extra space appears below the screen shot */
  }

  &__screenshot-img {
    width: 273px;
  }
}
</style>
