<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <!-- Creating a user is a restricted action - it may require reauthentication -->
  <!-- Show the create user dialog only after re-authentication -->
  <RestrictedAccessWrapper
    v-slot="{ executeRestrictedApi }"
    :eager="true"
  >
    <template>
      <RSModalForm
        :active="true"
        :subject="$t('users.title.createUser')"
        @close="$emit('close')"
        @submit="onSubmit(executeRestrictedApi)"
      >
        <template #content>
          <fieldset :disabled="processing">
            <div
              v-if="status.show"
              class="rs-field"
            >
              <EmbeddedStatusMessage
                :message="status.message"
                :type="status.type"
                data-automation="ucd-status-message"
                @close="hideStatusMessage"
              />
            </div>
            <RSInputText
              v-model.trim="form.username"
              :message="errorMessage('username')"
              :label="$t('authentication.label.username')"
              name="ucd-username"
              data-automation="ucd-username"
              autocomplete="off"
            />
            <RSInputText
              v-model.trim="form.firstName"
              :label="$t('users.title.firstName')"
              data-automation="ucd-first-name"
              name="ucd-first-name"
            />
            <RSInputText
              v-model.trim="form.lastName"
              :label="$t('users.title.lastName')"
              data-automation="ucd-last-name"
              name="ucd-last-name"
            />
            <RSInputText
              v-model.trim="form.email"
              :message="errorMessage('email')"
              :label="$t('authentication.label.email')"
              data-automation="ucd-email"
              name="ucd-email"
              type="email"
            />
            <RSInputSelect
              v-model="form.userRole"
              :options="roleOptions"
              :label="$t('users.title.role')"
              data-automation="ucd-role"
              name="ucd-role"
            />
          </fieldset>
        </template>
        <template #controls>
          <RSButton
            id="ucd-submit"
            :label="$t('users.title.createUser')"
            data-automation="ucd-submit"
          />
        </template>
      </RSModalForm>
    </template>
  </RestrictedAccessWrapper>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSInputSelect from 'Shared/components/RSInputSelect';
import RSModalForm from 'Shared/components/RSModalForm';

import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import { addNewLocalUser } from '@/api/users';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import { setInfoMessage } from '@/utils/status';
import { emailValidator, usernameValidator } from '@/utils/validators';
import UserRoles from '../../api/dto/userRole';

export default {
  name: 'UserCreateDialog',
  components: {
    RestrictedAccessWrapper,
    EmbeddedStatusMessage,
    RSButton,
    RSInputText,
    RSInputSelect,
    RSModalForm,
  },
  props: {
    serverSettings: {
      type: Object,
      required: true,
    },
    userRole: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      activityTimeoutId: null,
      form: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        userRole: 'viewer',
      },
      status: {
        show: false,
        message: null,
        type: null,
      },
    };
  },
  computed: {
    roleOptions() {
      const userRole = this.userRole;
      const roles = [
        { value: 'viewer', label: this.$t('users.label.role.viewer') },
        { value: 'publisher', label: this.$t('users.label.role.publisher') },
        {
          value: 'administrator',
          label: this.$t('users.label.role.administrator'),
        },
      ];
      const allowedRoles = [];
      roles.forEach(role => {
        if (userRole >= UserRoles.of(role.value)) {
          allowedRoles.push(role);
        }
      });
      return allowedRoles;
    },
  },
  methods: {
    async onSubmit(executeRestrictedApi) {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        // invalid form, bail
        return;
      }

      this.processing = true;
      this.activityTimeoutId = setTimeout(
        () => this.showStatusMessage('activity'),
        300
      );

      return executeRestrictedApi(addNewLocalUser(this.form))
        .then(this.onSuccess)
        .catch(this.onError);
    },

    async onSuccess(user) {
      try {
        if (this.serverSettings.mailConfigured) {
          // email configured
          setInfoMessage(
            this.$t('users.confirmation.sent', { email: user.email }),
            true
          );
        } else {
          // email not configured
          const { url } = await this.getConfirmationLink(user);
          this.$emit('confirmation-link', url);
        }
        this.$emit('user-created', user);
      } catch (err) {
        this.showStatusMessage('error', err.message);
      } finally {
        clearTimeout(this.activityTimeoutId);
        this.processing = false;
      }
    },

    onError(err) {
      this.processing = false;
      clearTimeout(this.activityTimeoutId);

      if (!(err instanceof ReauthenticationInProgressError)) {
        this.showStatusMessage('error', safeAPIErrorMessage(err));
      }
    },

    getConfirmationLink(user) {
      // workaround for https://github.com/kazupon/vue-i18n/issues/184
      const _this = this;

      return sendOrGetAccountConfirmationLink(user.guid).catch(err => {
        if (!err.response && !err.request) {
          // not API error
          console && console.error && console.error(err);
        }
        // not really an error, just failed to get confirmation link
        setInfoMessage(_this.$t('users.confirmation.getLinkFailed'), true);
        return { url: null };
      });
    },

    errorMessage(field) {
      const fieldValidators = this.$v.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // find all failing field validators
      const failedValidators = Object.keys(
        formValidations.call(this).form[field]
      ).filter(validatorName => fieldValidators[validatorName] === false);

      // return the first failed validator
      return this.$t(
        `authentication.validation.${field}.${failedValidators[0]}`
      );
    },

    showStatusMessage(type, message) {
      this.status.show = true;
      this.status.type = type;
      this.status.message = message;
    },

    hideStatusMessage() {
      this.status.show = false;
    },
  },
  validations() {
    return formValidations.call(this);
  },
};

// returns validators for fields in the form.
// Note: building the validators is extracted outside the vue component so the
// set of validators is accessible from within the `errorMessage` component
// method and the `validations` vuelidate mixin-method - component methods
// cannot call the `validations()` mixin-method.
function formValidations() {
  const validations = {
    form: {
      username: usernameValidator(this.serverSettings),
      email: emailValidator(),
    },
  };
  return validations;
}
</script>
