<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rsc-metrics__graph-body">
    <div
      v-show="isLoading"
      class="rsc-metrics__graph-loading"
    >
      <h4>{{ $t('admin.metrics.charts.loading') }}</h4>
    </div>
    <div
      v-show="!isLoading"
      class="rsc-metrics__graph-chart"
    >
      <canvas
        ref="canvas"
        role="presentation"
      />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'MetricsChart',
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    isLoading() {
      return this.data.datasets.length === 0;
    },
  },
  watch: {
    options(newOptions) {
      this.chart.options = newOptions;
      this.chart.update();
    },
    data(newData) {
      this.chart.data.datasets = newData.datasets;
      this.chart.update();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    this.chart.destroy();
  },
  methods: {
    renderChart() {
      this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'line',
        data: this.data,
        options: this.options,
      });
    },
  },
};
</script>
