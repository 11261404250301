// Copyright (C) 2022 by Posit Software, PBC.

const common = {
  json: 'JSON',
  yaml: 'YAML',
  or: 'o',
  and: 'y',
  footer: 'Derechos de autor © {year} Posit Software, PBC.',
  logs: 'Logs',
  input: 'Campo',
  schedule: 'Programar',
  publish: 'Publicar',
};

export { common };
