// Copyright (C) 2022 by Posit Software, PBC.

import Vue from 'vue';

/**
 * Factory that returns a scoped state ready to be provided in a view and injected on child components.
 * @param {Object} initialState The initial(default) state.
 * @param {Object} methods The methods to be mapped with the scoped state in the format: methodName(state) { ... }. Resulting methods don't need parameters.
 * @returns {Object} The resulting scoped state contains the {state} and {...methods}
 */
export const observableStore = (initialState = {}, methods = {}) => {
  /**
   * Scoped state, the source of truth for the view consuming the scoped store/state
   */
  const state = Vue.observable(initialState);

  /**
   * Mapping the state to the provided methods
   */
  const statefulMethods = {};
  for (const key of Object.keys(methods)) {
    statefulMethods[key] = (...args) => methods[key](state, ...args);
  }

  return {
    state,
    ...statefulMethods,
  };
};
