<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="app-settings__info">
    <ConfirmationPanel
      :enabled="confirmationEnabled"
      :visible="confirmationVisible"
      @save="save"
      @discard="discard"
    />
    <EmbeddedStatusMessage
      v-if="loading"
      :message="$t('appSettings.info.status.loading')"
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>{{ $t('appSettings.info.status.error', { error: loadingError }) }}</p>
    </div>
    <div
      v-if="!loading"
    >
      <ItemTitle
        :read-only="readOnly"
        :title="workingTitle"
        @input="updateTitle"
      />
      <div class="author">
        <UserBadge
          :username="ownerUsername"
          :first-name="ownerFirstName"
          :last-name="ownerLastName"
          :clickable="true"
          @clicked="onUserClicked"
        />
      </div>
      <UsagePanel v-if="canViewUsage" />
      <ItemDescription
        :read-only="readOnly"
        :description="workingDescription"
        @input="updateDescription"
      />
      <ImageFileInput
        :thumbnail="workingThumbnail"
        :read-only="imageReadOnly"
        @input="updateImage"
      />
      <GitInfo
        v-if="gitDeployment && app"
        :app-guid="app.guid"
        :repository-enabled="workingRepositoryEnabled"
        :user-can-make-changes="!readOnly"
        @input="updateRepositoryEnabled"
      />
      <ContentInfo />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { INFO_SETTINGS_DISCARD, INFO_SETTINGS_INIT, SAVE_INFO_SETTINGS } from '@/store/modules/infoSettings';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel.vue';
import ContentInfo from '@/views/content/settings/ContentInfo';
import ItemDescription from '@/views/content/settings/ItemDescription';
import ItemTitle from '@/views/content/settings/ItemTitle';
import GitInfo from '@/views/content/settings/GitInfo';
import UsagePanel from '@/views/content/settings/UsagePanel';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ImageFileInput from '@/components/ImageFileInput';
import UserBadge from '@/components/UserBadge';

export default {
  name: 'InfoSettings',
  components: {
    UserBadge,
    ContentInfo,
    ImageFileInput,
    ItemDescription,
    UsagePanel,
    ItemTitle,
    EmbeddedStatusMessage,
    ConfirmationPanel,
    GitInfo
  },
  data() {
    return {
      workingTitle: '',
      dirtyTitle: false,
      workingDescription: '',
      dirtyDescription: false,
      workingThumbnail: '',
      workingImage: null,
      dirtyImage: false,
      workingRepositoryEnabled: false,
      dirtyRepositoryEnabled: false,
      imageCleared: false,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      loadingError: state => state.infoSettings.error,
      loading: state => state.infoSettings.app.name === null,
      title: state => state.infoSettings.app.title || '',
      description: state => state.infoSettings.app.description || '',
      readOnly: state => !state.infoSettings.canEdit,
      imageReadOnly: state => !state.infoSettings.canEditImage,
      canViewUsage: state => state.infoSettings.canViewUsage,
      thumbnailURL: state => state.infoSettings.thumbnailURL,
      ownerUsername: state => state.infoSettings.app.ownerUsername,
      ownerFirstName: state => state.infoSettings.app.ownerFirstName,
      ownerLastName: state => state.infoSettings.app.ownerLastName,
      ownerGuid: state => state.infoSettings.app.ownerGuid,
      repositoryEnabled: state => state.infoSettings.app.git.enabled || false,
      gitDeployment: state => state.infoSettings.gitDeployment,
    }),
    dirty() {
      return this.dirtyTitle ||
        this.dirtyDescription ||
        this.dirtyImage ||
        this.dirtyRepositoryEnabled;
    },
    confirmationVisible() {
      return this.dirty;
    },
    confirmationEnabled() {
      const validDescription = ((!this.dirtyDescription) ||
                                this.workingDescription.length <= 4096);
      const validTitle = ((!this.dirtyTitle) ||
                          (this.workingTitle.length >= 3 && this.workingTitle.length <= 1024));
      return validDescription && validTitle;
    }
  },
  watch: {
    title(newValue) {
      this.workingTitle = newValue;
    },
    description(newValue) {
      this.workingDescription = newValue;
    },
    thumbnailURL(newValue) {
      this.workingThumbnail = newValue;
    },
    repositoryEnabled(newValue) {
      this.workingRepositoryEnabled = newValue;
    }
  },
  mounted() {
    // These are set when they first get initialized by vuex- but they need to be set on mount too
    // just in case they're already there and don't change such that `watch()` is never triggered
    this.workingTitle = this.title;
    this.workingDescription = this.description;
    // We don't set the thumbnail URL because it has its own super special lifecycle.
    this.workingRepositoryEnabled = this.repositoryEnabled;
    if (this.app) {
      this.initSettings(this.app.guid);
    }
  },
  beforeDestroy() {
    this.discardInfoSettings();
    window.URL.revokeObjectURL(this.thumbnailURL);
  },
  methods: {
    ...mapActions({
      initSettings: INFO_SETTINGS_INIT,
    }),
    ...mapMutations({
      discardInfoSettings: INFO_SETTINGS_DISCARD,
    }),
    onUserClicked() {
      this.$router.push({
        name: 'people.users.profile',
        params: { guid: this.ownerGuid },
      });
    },
    updateTitle(input) {
      this.dirtyTitle = true;
      this.workingTitle = input;
    },
    updateDescription(input) {
      this.dirtyDescription = true;
      this.workingDescription = input;
    },
    updateImage(input) {
      const { file } = input;
      this.dirtyImage = true;
      this.workingImage = file;
      // Do not revoke the state thumbnail image: let vuex do that
      if (this.workingThumbnail !== this.thumbnailURL) {
        window.URL.revokeObjectURL(this.workingThumbnail);
      }
      if (file !== null) {
        this.workingThumbnail = window.URL.createObjectURL(file);
      } else {
        this.imageCleared = true;
        this.workingThumbnail = '';
      }
    },
    updateRepositoryEnabled(input) {
      this.dirtyRepositoryEnabled = true;
      this.workingRepositoryEnabled = input;
    },
    discard() {
      this.dirtyTitle = false;
      this.dirtyDescription = false;
      this.dirtyImage = false;
      this.dirtyRepositoryEnabled = false;
      this.imageCleared = false;
      this.workingTitle = this.title;
      this.workingDescription = this.description;
      this.workingThumbnail = this.thumbnailURL;
      this.workingFile = null;
      this.workingRepositoryEnabled = this.repositoryEnabled;
      this.$emit('discard');
    },
    save() {
      const {
        workingTitle: title,
        workingDescription: description,
        workingImage: image,
        workingRepositoryEnabled: repositoryEnabled,
        imageCleared,
      } = this;
      this.$store.dispatch(SAVE_INFO_SETTINGS, {
        title,
        description,
        image,
        repositoryEnabled,
        imageCleared
      });
      // There is no routing change when changing the name of a content, so
      // Vue Router wouldn't be able to change it.
      document.title = `${title} - Posit Connect`;
      this.dirtyTitle = false;
      this.dirtyDescription = false;
      this.dirtyImage = false;
      this.dirtyRepositoryEnabled = false;
      this.imageCleared = false;
    }
  }
};
</script>
