// Copyright (C) 2022 by Posit Software, PBC.

const admin = {
  timeInfo: 'All times are displayed in local time ({offset})',
  auditLogs: {
    title: 'Audit Logs',
    columnHeaders: {
      time: 'Time',
      user: 'User',
      event: 'Event',
    },
  },
  queue: {
    id: 'Id',
    queue: 'Queue',
    priority: 'Priority',
    type: 'Type',
    active: 'Active',
    payload: 'Payload',
    permit: 'Permit',
    heartbeat: 'Permit Expiration',
    hostname: 'Hostname',
    createdTime: 'Created Time',
  },
  scheduledContent: {
    title: 'Scheduled Content',
    fetching: 'Getting schedules...',
    calendar: 'Calendar',
    list: 'List',
    frequency: {
      all: 'All Scheduled Content',
      minute: 'Content by the Minute',
      hour: 'Hourly Content',
      day: 'Daily Content',
      week: 'Weekly Content',
      semiMonth: 'Semi-monthly Content',
      month: 'Monthly Content',
      year: 'Yearly Content',
    },
    noContent: 'No scheduled content found',
    noContent7Days: 'No scheduled content found for the next 7 days',
    noContentForType: 'No scheduled content of this type found',
    headers: {
      name: 'Name',
      frequency: 'Frequency',
      nextScheduledRun: 'Next scheduled run',
      documentType: 'Document type',
      sendsEmail: 'Sends email',
      author: 'Author',
      viewContent: 'View Content Schedule',
    },
  },
  unpublishedContent: {
    title: 'Unpublished Content',
    description: `Unpublished content has encountered errors while deploying
     and is not available for viewing. It may need to be redeployed.`,
    fetching: 'Getting unpublished content...',
    noContent: 'All content has been published.',
  },
  tags: {
    title: 'Tags Schema',
    newCategory: 'New Category',
    newTag: 'New Tag',
    newTagIn: 'New tag in {category}',
    addSubtag: 'Add subtag to {tag}',
    deleteTag: 'Delete tag "{tag}"',
    deleteCategory: 'Delete {category}',
    tags: 'tags',
    categories: 'categories',
    processing: {
      sync: 'Syncing tags...',
      fetch: 'Loading tags...',
      create: 'Creating tag...',
      delete: 'Deleting tag...',
      update: 'Updating tag...',
    },
    gettingStarted: {
      createYourSchema: 'Create Your Schema',
      overview: [
        `Use tags to organize your content to make it easy for your users to find
        the content they're interested in.`,
        `As an administrator, you define the tagging schema that your data
        scientists then use to categorize the content they publish.`,
        `To get started, press the 'Create Your Schema' button above and to the right.`,
      ],
      shortOverview: [
        `Tags are used to organize your content to make it easy for your users to
         find the content they're interested in.`,
        `As an administrator, you define the tagging schema that your data scientists
         then use to categorize the content they publish.`,
      ],
      how: {
        title: 'Defining Your Schema',
        description: `To create a tagging schema for the content in your organization, you
         define one or more tag categories. Within each category, you define tags,
         which can be nested any number of levels deep. Here's an example schema
         for a marine mammal research organization that has four tag categories:
         Research Area, Project Status, Grant and Species.`,
        shortDescription: `The definition of the tagging schema for the content in your
         organization starts with categories. Within each category, you define tags,
         which can be nested any number of levels deep.`,
      },
      categoryExample:
        'The only category in your organization at this moment is "{category}".',
      catgoriesExamples:
        'Some current categories in your organization are "{one}" and "{two}".',
    },
    deletingTag: {
      confirmMessage: `Are you sure that you want to delete {tag}? You can't undo this action.`,
      cancel: 'No, do not delete tag {tag}',
      confirm: 'Yes, delete tag {tag}',
    },
  },
  metrics: {
    title: 'Metrics',
    noMetrics: 'Metrics are disabled',
    loading: 'Please wait, loading metrics...',
    showHistoryFor: 'Show history for',
    unitLabel: 'Unit',
    rangeLabel: 'Range',
    range: {
      hours: 'Hours',
      days: 'Days',
      weeks: 'Weeks',
      months: 'Months',
      years: 'Years',
    },
    server: 'Server: {name}',
    processes: {
      title: 'Processes',
      contentName: 'Content Name',
      appType: 'Type',
      // TODO: Remove PID when ProcessList is migrated
      pid: 'PID',
      jobKey: 'Job Key',
      hostname: 'Hostname',
      user: 'User',
      cpu: 'CPU',
      ram: 'RAM',
      startTime: 'Started',
      heartbeat: 'Updated',
      noProcesses: 'No processes running',
      navigateToApp: 'Go to content: {name}',
      actions: 'Actions',
      logAction: 'View Log',
      killAction: 'Kill Process',
      killConfirm: {
        warning: 'Killing a process can cause data loss and negatively impact users that are viewing related content.',
        details: 'You are about to kill the process {0} for the content {1}. Are you sure that you want to proceed?',
        confirmation: 'Yes, I want to kill the process'
      },
      killSuccess: 'The process "{type}" has been queued to be killed.',
    },
    errors: {
      timeframe: {
        required: 'A time period must be specified',
        minimum: 'A time period greater than 0 must be specified',
        tooEarly: 'Specify a time period that does not extend beyond 1980',
      },
    },
    reconnecting: 'Connection to server lost. Trying to reconnect...',
    charts: {
      cpu: 'CPU',
      ram: 'RAM',
      namedUsers: 'Named Users',
      shinyConnections: 'Shiny Connections',
      loading: 'Please wait, loading data...',
      labels: {
        sys: 'System',
        user: 'User',
        idle: 'Misc',
        actualused: 'Used',
        used: 'Cached',
        active1day: 'Past Day',
        active30day: 'Past Month',
        active365day: 'Past Year',
        shinyusers: 'Active Connections',
      },
      usage: {
        limited: {
          cpu: 'using {value} of {max} cores',
          ram: 'using {value} of {max}',
          namedUsers: '{value} of {max} allowed',
          shinyConnections: '{value} of {max} allowed',
        },
        unlimited: {
          namedUsers: '{n} users | {n} user | {n} users',
          shinyConnections: '{n} users | {n} user | {n} users',
        },
      },
      maxNamedUsersWarning: `Oops, it looks like you have more than your licensed number of active users!
        Please contact {sales}`,
      help: {
        namedUsers: `This graph tracks the number of accounts with recent activity. 
          Accounts are not counted when locked.`,
        shinyConnections: {
          limited: `Connect restricts new anonymous Shiny users when the number of total 
            concurrent Shiny users is in excess of this limit. The graph displays average values.`,
          unlimited: `Tracks the number of concurrent Shiny users. 
            The graph displays average values.`,
        },
      },
    },
  },
  serverStatus: {
    title: 'Server Status Reports',
    downloadReport: 'Download Selected Report',
    runReport: 'Run New Report',
    allCurrentlyActiveHosts: 'All Currently Active Hosts',
    currentlyActiveHosts: 'Currently Active Hosts',
    hostsActiveInTheLastDay: 'Recently Active Hosts (Unavailable)',
    reportStillRunning: 'The selected report will display when all associated tests have finished running.',
    selectReport: 'Please select a report.',
    deleteReport: 'Delete Selected Report',
    cancelReport: 'Cancel Selected Report',
    reportTitle: 'Posit Connect Server Status Report for',
    backToTop: 'Back to Top',
    summary: 'Summary',
    passedStatus: 'passed',
    failedStatus: 'warning | warnings',
    reportWasCanceled: 'This report was canceled. Results may be incomplete.',
    test: 'Test',
    group: 'Group',
    stdOut: 'Output',
    stdErr: 'Error',
    statusRequested: 'Waiting to start…',
    statusCanceled: 'Canceled',
    statusRunning: 'Running tests… ',
    table: {
      colNames: {
        startTime: 'Start Time',
        hostname: 'Hostname',
        status: 'Status',
      },
      testStatus: {
        runningTests: 'Running tests…',
      },
    },
    groupNames: {
      baseSandbox: 'Process Sandbox',
      packratRestoreSandbox: 'R Environment Restore',
      pythonRestoreSandbox: 'Python Environment Restore',
      server: 'Connect Server',
      rmarkdownSandbox: 'RMarkdown Sandbox',
    },
    tests: {
      connectVersions: {
        name: 'Posit Connect Versions',
        description: 'Prints the versions of Posit Connect on active hosts.',
        error: 'Some hosts are running inconsistent versions of Posit Connect. Please ensure that all hosts are running the same version.',
      },
      connectNotificationsConfig: {
        name: 'Posit Connect Notifications Settings',
        description: 'Checks if the Connect configuration contains valid Notification settings.',
        error: 'Your configuration contains Notification settings that may be invalid.',
      },
      connectDeprecatedSettings: {
        name: 'Posit Connect Deprecated Settings',
        description: 'Checks if the Connect configuration uses any deprecated settings.',
        error: 'Your configuration is using deprecated settings. Please update your configuration.',
      },
      connectLdapGroupsSources: {
        name: 'LDAP Group Source Consistency',
        description: 'Checks Posit Connect\'s LDAP groups for inconsistent sources.',
        error: 'Posit Connect is currently using a mixture of local and remote LDAP groups. Please see the Admin Guide\'s section on Group Management, and the appendix on the User Management CLI, for more information.',
      },
      connectLicense: {
        name: 'Posit Connect License',
        description: 'Checks the status of your Posit Connect license.',
        error: 'Your license may be expired or close to expiration.',
      },
      cranConnectivity: {
        name: 'CRAN Connectivity',
        description: 'Verifies that Posit Connect can reach CRAN.',
        error: 'Posit Connect could not reach CRAN. If this server has restricted internet access and uses a local R package repository, you can safely ignore this failure.',
      },
      databaseLatency: {
        name: 'Database Latency',
        description: 'Checks the latency of a simple database query.',
        error: 'Database requests look longer than expected.',
      },
      disk: {
        name: 'Disk',
        description: 'Checks for sufficient free disk space.',
        error: 'There was less than 10% free disk space in one of the following locations: `/`, `/tmp`, or the Posit Connect data directory.',
      },
      echo: {
        name: 'Echo',
        description: 'Runs a trivial command in Posit Connect\'s process sandbox.',
        error: 'Posit Connect was unable to run a trivial command in its process sandbox.',
      },
      environment: {
        name: 'Environment',
        description: 'Prints the environment variables that are visible the Posit Connect server.',
        error: '',
      },
      installedPackages: {
        name: 'Installed R Packages',
        description: 'Lists the packages available to this version of R.',
        error: '',
      },
      linuxVersion: {
        name: 'Linux Version',
        description: 'Prints information about the server\'s Linux distribution.',
        error: '',
      },
      memory: {
        name: 'Memory',
        description: 'Checks for sufficient free memory.',
        error: 'Less than 20% of RAM or swap space was free when this test ran.',
      },
      mounts: {
        name: 'Mounts',
        description: 'Lists filesystem mounts that are configured in the sandbox.',
        error: '',
      },
      options: {
        name: 'Options',
        description: 'Prints this R installation\'s options. This includes any options set in R configuration files, such as .Rprofile.',
        error: '',
      },
      pip: {
        name: 'Pip',
        description: 'Creates a virtual environment, prints information about the resulting environment\'s pip configuration, and verifies that pip can download a package from the configured repository.',
        error: 'There was a problem verifying pip functionality in a virtual environment.',
      },
      pypiConnectivity: {
        name: 'PyPI Connectivity',
        description: 'Verifies that the default PyPI repositories are reachable from within Posit Connect\'s sandbox.',
        error: 'Posit Connect was unable to reach the default PyPI repositories. If this server has restricted internet access and uses a local Python package repository, you can safely ignore this failure.',
      },
      pythonPackages: {
        name: 'Installed Python Packages',
        description: 'Lists the packages available to this Python installation.',
        error: '',
      },
      pythonVersion: {
        name: 'Python Version',
        description: 'Prints the version number reported by this Python installation.',
        error: '',
      },
      queueDepth: {
        name: 'Queue',
        description: 'Checks the number of scheduled reports queued.',
        error: 'A large number of queued reports may indicate that jobs are not completing as expected.',
      },
      rPackageRepository: {
        name: 'R Package Repository',
        description: 'Verifies that R package repositories can be reached from within Posit Connect\'s sandbox.',
        error: 'There was a problem trying to reach this R package repository.',
      },
      rVersion: {
        name: 'R Version',
        description: 'Prints the version number reported by this R installation.',
        error: '',
      },
      selfConnectivity: {
        name: 'Self Connectivity',
        description: 'Checks whether Posit Connect can reach its configured server addresses.',
        error: 'There was a problem trying to reach this configured server address. This might be expected, depending on your network configuration and security policies.',
      },
      virtualenv: {
        name: 'Virtual Environment',
        description: 'Verifies that this Python installation can create a virtual environment in Posit Connect\'s sandbox.',
        error: 'Unable to create a virtual environment using this Python installation.',
      },
    },
  },
};

export { admin };
