// Copyright (C) 2022 by Posit Software, PBC.

import keyMirror from '@/store/redux/utils/keyMirror';

export const StatusTypes = keyMirror({
  ACTIVITY_MESSAGE: true,
  ERROR_MESSAGE: true,
  INFO_MESSAGE: true,
  HTTP_WARNING: true /* TODO: oddly specific. Should this be WARNING_MESSAGE? */,
});
