// Copyright (C) 2022 by Posit Software, PBC.

const navPanel = {
  content: 'Content',
  people: {
    title: 'People',
    users: 'Users',
    groups: 'Groups',
  },
  environments: 'Environments',
  admin: {
    title: 'Admin',
    metrics: 'Metrics',
    mailSettings: 'Mail Settings',
    tags: 'Tags',
    auditLogs: 'Audit Logs',
    unpublishedContent: 'Unpublished Content',
    scheduledContent: 'Scheduled Content',
    serverStatusReports: 'Server Status Reports',
    queue: 'Queue',
    serverSettingsToggler: 'Server Settings Toggler',
  },
  documentation: {
    title: 'Documentation',
    userGuide: 'User Guide',
    adminGuide: 'Admin Guide',
    faq: 'FAQ',
    articles: 'Articles',
    releaseNotes: 'Release Notes',
    serverApiReference: 'Server API Reference',
    serverApiCookbook: 'Server API Cookbook',
  },
  build: 'Build',
};

export { navPanel };
