// Copyright (C) 2023 by Posit Software, PBC.

const sourceVersions = {
  title: 'Source Versions',
  active: 'active',
  unknownSize: 'Unable to determine size',
  published: 'Published',
  download: 'Download',
  view: 'View Log',
  delete: 'Delete',
  activate: 'Activate',
  reactivate: 'Reactivate',
  deleteTitle: 'Delete Bundle',
  deleteDetails: 'Permanently delete this bundle?',
  deleteButton: 'Yes',
  bundleLog: {
    title: {
      fetching: 'Loading...',
      noLog: 'No logs found.',
      completed: 'Activation complete.',
      notFound: 'Log not found.',
      deploying: 'Activating',
      deployed: 'Activation log for version #{bundleId}: {jobKey}'
    },
    previous: 'Previous Activation',
    next: 'Next',
    download: 'Download Log'
  }
};

export { sourceVersions };
