<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    v-if="showModal"
    data-automation="reqperm-dialog"
    :subject="locals.modalSubject"
    :close-button-label="locals.close"
    :active="showModal"
    @close="$emit('close')"
  >
    <template #content>
      <p>
        {{ locals.modalMessage }}
      </p>
    </template>
    <template #controls>
      <RSButton
        data-automation="reqperm-cancel-btn"
        :label="locals.cancel"
        type="secondary"
        @click="$emit('close')"
      />
      <RSButton
        data-automation="reqperm-submit-btn"
        :label="locals.request"
        @click="requestPrivilege"
      />
    </template>
  </RSModal>
</template>

<script>
import { requestPrivilege } from '@/api/privileges';
import { setInfoMessage, setErrorMessage } from '@/utils/status';
import { safeAPIErrorMessage } from '@/api/error';
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';

export default {
  name: 'RequestPermissionsModal',
  components: {
    RSModal,
    RSButton,
  },
  props: {
    privilege: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.locals = {
      modalSubject: this.$t('content.requestPermissions.modal.subject', { privilege: this.privilege }),
      modalMessage: this.$t('content.requestPermissions.modal.message', { privilege: this.privilege }),
      successMessage: this.$t('content.requestPermissions.success'),
      cancel: this.$t('common.buttons.cancel'),
      request: this.$t('common.buttons.request'),
      close: this.$t('common.actions.close'),
    };
  },
  methods: {
    async requestPrivilege() {
      try {
        await requestPrivilege(this.privilege);
        setInfoMessage(this.locals.successMessage);
      } catch (err) {
        setErrorMessage(safeAPIErrorMessage(err));
      }
      this.$emit('close');
    },
  },
};
</script>
