// Copyright (C) 2022 by Posit Software, PBC.

export const appSettings = {
  loginToView: 'You must log in to view and edit content settings.',
  missingApp: [
    'This application is missing or not visible to you.',
    'The application may have been deleted, or you may have followed an incorrect link.',
  ],
  backToList: 'Back to content list',
  tabs: {
    info: 'Info',
    access: 'Access',
    runtime: 'Runtime',
    schedule: 'Schedule',
    tags: 'Tags',
    vars: 'Vars',
  },
  runtime: {
    noSettingsMsgWithConstraints:
      `This content is not executed or rendered by the server. There are no 
      runtime settings to configure.`,
    noSettingsMsgWithoutConstraints:
      `This content is not executed by the server. There are no 
      runtime settings to configure.`,
    label: {
      worker: 'Runtime Settings',
      static: 'Runtime Settings',
      ramSettings: 'RAM Settings',
      cpuAndRamSettings: 'CPU & RAM Settings',
      processSettings: 'Process Settings',
      timeoutSettings: 'Timeout Settings',
      gpuSettings: 'GPU Settings',
    },
    readOnly: 'You do not have permissions to change the settings for this content.',
    docHelp: {
      generalLink: {
        worker: `Learn more about these settings in the Posit Connect {adminDoc} and User Guide ({userDocProcess} and {userDocTimeout}).`,
        renderer: `Learn more about these settings in the Posit Connect {userDoc}.`,
      },
      settingLink: `Learn more about this setting in the Posit Connect {docName}.`,
      adminGuide: 'Admin Guide',
      userGuide: 'User Guide',
      userGuideProcess: 'processes',
      userGuideTimeouts: 'timeouts',
    },
    units: {
      cpus: 'CPUs',
      gpus: 'GPUs',
      processes: 'processes',
      gibs: 'GiBs',
      connections: 'connections',
      seconds: 'seconds',
    },
    settingsHelp: {
      info: {
        cpuRam: {
          worker: `Changes to these runtime settings for executable 
          content will not be applied to running instances of this 
          content. New connections use updated values. Existing 
          connections are not modified or disconnected.`,
          rendered: `Changes to runtime settings for rendered content
          will be used the next time this content is built.`,
        },
        process: {
          worker: `Changes to runtime settings for executable content 
          are applied immediately to running instances of this content. 
          New connections use updated values. Existing connections 
          are not modified or disconnected.`,
        }
      },
    },
    cpuLimit: {
      label: 'Max Number of CPUs',
      info: [
        `Maximum limit of CPUs to be allocated to the process at runtime.`,
        `A value of 0 indicates no limit will be specified at runtime.`,
        `Value must be >= Initial Number of CPUs and the system limit set 
        in the server config file.`,
      ],
      zero: 'no limit',
    },
    cpuRequest: {
      label: 'Initial Number of CPUs',
      info: [
        `Initial numbers of CPUs to be requested when the process is launched.`,
        `A value of 0 indicates that no initial number of CPUs is to be requested.`,
        `Value must be <= Max Number of CPUs as well as the system limit set
        in the server config file.`,
      ],
      zero: 'none requested',
    },
    memoryLimit: {
      label: 'Max RAM (GiB)',
      info: [
        `Maximum limit of RAM (in GiB) to be allocated to the process at runtime.`,
        `A value of 0 indicates no limit.`,
        `Value must be >= the system limit set in the server config file.`,
      ],
      zero: 'no limit',
    },
    memoryRequest: {
      label: 'Initial RAM Requested (GiB)',
      info: [
        `Initial amount of RAM in GiB to be requested when the process is launched.`,
        `A value of 0 indicates that no initial amount of RAM is to be requested.`,
        `Value must be <= Max RAM as well as the system limit set in the server 
        config file.`,
      ],
      zero: 'none requested',
    },
    gpuLimit: {
      amd: {
        label: 'AMD GPUs Requested',
      },
      nvidia: {
        label: 'NVIDIA GPUs Requested',
      },
      info: [
        `The number of GPUs to be requested when the process is launched.`,
        `A value of 0 indicates that no GPUs are to be requested.`,
        `Value must be <= Max Number of GPUs as defined by the system limit set
        in the server config file.`,
      ],
      zero: 'none requested',
    },
    maxProcesses: {
      label: 'Max processes',
      info: [
        `The maximum number of processes that will be simultaneously 
        running for this content per Posit Connect node, regardless of
        load.`,
        `A value of 0 will prevent the content from running at all.`,
        `Value must be >= Min Processes as well as the system limit set in the 
        server config file.`,
      ],
    },
    minProcesses: {
      label: 'Min processes',
      info: [
        `The minimum number of processes that will be kept running
        for this content per Posit Connect node, regardless of load.`,
        `A value of 0 indicates that no minimum is to be imposed.`,
        `Value must be <= Max Processes as well as the system limit set in 
        the server config file.`,
      ],
      zero: 'no minimum',
    },
    maxConnsPerProcess: {
      label: 'Max connections per process',
      info: [
        `The maximum number of client connections allowed to an
        individual process. Incoming connections which will exceed
        this limit are routed to a new process or rejected.`,
      ],
    },
    loadFactor: {
      label: 'Load factor: {factor}',
      info: [
        `A value between 0 and 1 which determines how lazily additional
        processes will be spawned to handle incoming load for this
        process.`,
        `At the highest setting, Connect will only spawn
        additional processes when existing processes are not allowed
        to accept an additional connection. At the lowest setting,
        Connect will create many new processes as new users arrive to
        handle the load.`,
      ],
    },
    idleTimeout: {
      label: 'Idle Timeout per process (seconds)',
      info: [
        `Minimum number of seconds a worker process will remain alive
        after it becomes idle - i.e. no active connections.`,
      ],
    },
    initTimeout: {
      label: 'Initial timeout (seconds)',
      info: 'Maximum number of seconds to wait for an app to start.',
    },
    connectionTimeout: {
      label: 'Connection timeout (seconds)',
      info: [
        `Maximum number of seconds allowed without data sent or
        received across a client connection. Connections reaching this
        idle threshold are closed.`,
        `A value of 0 means connections will never time-out 
        (not recommended).`,
      ],
      zero: 'no timeout',
    },
    readTimeout: {
      label: 'Read timeout (seconds)',
      info: [
        `Maximum number of seconds allowed without data received from a
        client connection. Connections reaching this idle read
        threshold are closed.`,
        `A value of 0 means a lack of browser interaction will never 
        cause the connection to close. This is useful when deploying 
        dashboard applications which send regular updates but have 
        no need for interactivity.`,
      ],
      zero: 'no timeout',
    },
    errors: {
      internalError: 'Internal error: {desc}',
      invalidNumeric: 'Invalid non-numeric input: {desc}',
      rangeError: 'Must be between {lowValue} and {highValue}',
      cpuLimit: {
        systemLimit: 'Must be less then or equal to system limit of {limit}',
      },
      maxSystemLimit: 'Cannot be greater than the system limit of {maxLimit}.',
      minSystemLimit: 'Cannot be less than the system limit of {minLimit}',
      maxLimit: {
        cpuRequest: 'Initial Number of CPUs <= Max Number of CPUs',
        memoryRequest: 'Initial RAM requested <= Max RAM',
        minProcesses: 'Min Processes must be <= Max Processes.',
        gpuLimit: 'Number of GPUs must be <= Max Number of GPUs',
      },
      minLimit: {
        maxProcesses: 'Max Processes must be >= Min Processes.',
        cpuLimit: 'Max Number of CPUs >= Initial Number of CPUs',
        memoryLimit: 'Max RAM >= Initial RAM requested',
      },
    },
    warnings: {
      minProcesses:
        'Warning: Large values significantly increase memory usage.',
    },
    status: {
      saved: 'Runtime settings saved successfully.',
      loading: 'Loading runtime settings...',
      error: 'An error occurred while loading the runtime settings.',
    },
    environmentManagement: {
      python: {
        name: 'Python',
        packageManager: 'pip/venv',
        adminGuide: 'admin/python/package-management/#environment-management',
        userGuideCli: 'user/publishing-cli/#environment-management',
        userGuideUi: 'user/content-settings/#environment-management',
      },
      r: {
        name: 'R',
        packageManager: 'packrat',
        adminGuide: 'admin/r/package-management/#environment-management',
        userGuideCli: 'user/publishing-r/#environment-management',
        userGuideUi: 'user/content-settings/#environment-management',
      },
      title: '{name} Environment Management',
      help: {
        p1: {
          t: `When enabled, Posit Connect uses {packageManager} to install {name} packages required by the content.
          When environment management is disabled, Connect does not perform any package installation
          and Administrators must ensure that the required packages are available in the runtime environment.
          See the {adminGuide} for further details.`,
          guide: `Admin Guide`,
        },
        p2: `The environment management strategy can be selected by Publishers in 2 ways:`,
        p3: {
          t: `1) A bundle can select the {name} environment management strategy in the manifest. 
          This applies only to the bundle. See the {userGuideCli} for further details.`,
          guide: `User Guide`,
        },
        p4: {
          t: `2) Choose a default {name} environment management strategy for this application
        through the dashboard. This applies to all bundles for this content.
        See the {userGuideUi} for further details.`,
          guide: `User Guide`,
        }
      },
      labels: {
        nextTime: 'Next time',
        lastRestore: 'Last time',
      },
      lastRestoreUnknown: 'Unknown',
      options: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        unset: 'Use server default ({serverDefault})'
      },
    },
    executionEnvironment: {
      title: 'Execution Environment',
      moved: 'These settings have moved to the Runtime tab',
      help: {
        p1:
          `When first deployed, Posit Connect uses an image (from the configured
            execution environments) to create a build container to install R and Python 
            packages required by your content. Subsequently, each time your content 
            needs to run, that same image is used to create a container for content execution.`,
        p2: `This image can be selected in three ways.`,
        p3: {
          t: `1) A manifest can specify an image by setting the environment.image field. 
            Requires Application.ManifestImageSelectionEnabled to be enabled. See the {adminGuide}
            for further details.`,
          guide: `Admin Guide`,
        },
        p4: `2) Choose a default image here.`,
        p5: `3) Let Posit Connect determine which execution environment is most appropriate 
          for your content.`,
      },
    },
    serviceAccount: {
      dropdownLabel: 'Service account used to execute this content',
      intro: 'The name of the Kubernetes service account used to run this piece of content.',
      defaultAccountDetails: `
        If no service account is explicitly set, the configured global default service account will be used to execute this content.
        If a global default service account is not configured, the Kubernetes namespace default service account is used.`,
      infoConfDisabled: 'The system is configured to disable content service account selection.',
      notRecognized: '{account} (not recognized)',
      notRecognizedWarningAdmin: `
      Kubernetes service account {0} is not recognized as one of the service accounts found at system startup.
      Content execution problems might occur. See the {1} for more details.`,
      notRecognizedWarningPublisher: `
      Kubernetes service account {0} is not recognized as one of the service accounts found at system startup.
      Content execution problems might occur. Contact your Administrator for more details.`,
      offHostDocsLinkLabel: 'off-host execution documentation',
      troubleshootLinkLabel: 'troubleshooting documentation',
    },
  },
  logs: {
    label: 'Jobs',
    logLabel: 'Log',
    downloadButtonTitle: 'Download Log',
    runningLogTitle: 'Current: {jobDescription} began {startTime}',
    runningLogSubtitle: '{jobKey} on {hostname}',
    completedLogTitle: '{jobDescription} ran {startTime}',
    completedLogSubtitle: '{jobKey} on {hostname} for {duration}',
    goneLogSubtitle: '{jobKey} on {hostname} (gone)',
    status: {
      loading: 'Loading logs...',
      error: 'An error occured while loading the list of logs.',
    },
    noLogs: {
      unknown: 'No logs found for this item.',
      application: 'No logs found for this application.',
      document: 'No logs found for this document.',
      site: 'No logs found for this site.',
      plot: 'No logs found for this plot.',
      pin: 'No logs found for this pin.',
      api: 'No logs found for this API.',
      tensorFlowApi: 'No logs found for this TensorFlow Model API.',
    },
    notExecutable:
      'The source code for this content was not published. ' +
      'It is not executable and has no logs.',
    visitToRun: {
      // only content satisfying App.isWorker should appear here
      application: 'Visit this application to run a process for it.',
      document: 'Visit this document to run a process for it.',
      site: 'Visit this site to run a process for it.',
      api: 'Visit this API to run a process for it.',
      tensorFlowApi: 'Visit this TensorFlow Model API to run a process for it.',
    },
    renderToRun: {
      // only content satisfying App.isRenderable should appear here
      document: 'Regenerate this document to run a process for it.',
      site: 'Regenerate this site to run a process for it.',
    },
    showAll: 'Show All',
    showingLimit: 'Displaying  1-{maxJobCount} of {jobCount}.',
    showingAll: '{jobCount} jobs.',
    jobRunTime: {
      running: '(running)',
      gone: 'ran {when} (gone)',
      complete: 'ran {when} in {duration}',
    },
    exitStatus: {
      unknownError: 'This process ended with an error.',
      knownError: 'This process ended with an error: {error}.',
      gone: 'This process did not generate an error but ended unexpectedly.',
    },
    wrapLongLines: 'Wrap long lines',
  },
  logErrorHelp: {
    errorLabel: 'An error occurred:',
    helpURLLabel: 'For more info about this error:',
    helpURLText: '"{error}" in the User Guide',
  },
  tags: {
    message: 'Tagging content makes it easier for others to find it later.',
    cantAssign: {
      message: 'No tags can be assigned because none have been created yet.',
      notAdmin: 'Contact an administrator for more information.',
      whenAdmin: {
        create: 'To create some tags, please use the ',
        onlyAdmins: 'Only an administrator can create tags.',
      },
      tagEditor: 'Tag Editor',
    },
  },
  access: {
    sections: {
      sharing: {
        title: 'Sharing',
        help: {
          lead: `Who can view or change this content.`,
          license: {
            anonymousServers: `The product license prohibits access to interactive content without login. {adminGuide}.`,
            anonymousBranding: `The product license displays a "Powered by Posit Connect" badge on content that permits access without login. {adminGuide}.`,
            learnMore: `Learn more`,
          },
          specificUsers: `Give specific people and groups access to your content by adding them as
          viewers or collaborators. Search by their name and use the drop-down
          to specify what privileges they should receive. You can also adjust
          viewer and collaborator permissions after adding people by using the
          drop-down next to their name.`,
        },
        license: {
          allNotAvailable: 'Not available for interactive content.',
          allViolation: 'Not available for interactive content. Access to this content requires login.',
          allBranded: 'Adds a "Powered by Posit Connect" badge to the content.'
        },
      },
      contentUrl: {
        title: 'Content URL',
        help: {
          intro: 'A custom URL can be created to access this content.',
          details: `Your custom path will be appended to the URL of your Posit Connect server 
            to form a complete URL for this content. The {userGuide} explains what custom URL 
            paths are permitted.`,
          admin: `Note that Posit Connect may be configured to restrict this option only to 
            Administrators.`,
          userGuide: 'User Guide',
        }
      },
      processExecution: {
        title: 'Process Execution',
        help: [
          `Content is initially configured to run on the server as a Unix user 
          configured by your Posit Connect administrator`,
          `These settings allow your content to run using different credentials
          if needed.`,
        ],
        moved: 'These settings have moved to the Runtime tab',
      },
    },
    customUrl: {
      label: 'Path',
      placeholder: 'Customize',
      effectiveURL: 'URL',
    },
    principalInfo: [
      'A viewer will be able to access and view the content. Any type of account can be made a viewer for a given piece of content.',
      'A collaborator will be able to publish content updates and manage the settings for a given deployment. Collaborators must have either "publisher" or "administrator" accounts.',
    ],
    principalPrivacy: 'Some information about who can view or change this {type} is not shown due to privacy concerns.',
    runAsInfo: {
      admin: 'By default, this content will run on the server as the user {username}. You may need to use an alternate user depending on resource permissions and constraints. If you specify a user, they must be a member of the Unix group that is the primary group of {username}.',
      nonAdmin: 'By default, this content will run on the server as the default server {username} user. Administrators can specify an alternate user depending on resource permissions and constraints. Please contact an administrator if you need to change this setting.',
    },
    labels: {
      shareWith: 'Share with',
      modeSelector: 'Select access mode',
      viewer: 'Viewer',
      collaborator: 'Collaborator',
      principalList: 'Who can view or change this {type}',
      removePrincipal: 'Remove principal',
      runAs: 'Who runs this content on the server',
      secondaryRunAs: 'The content will execute as the current user when viewed by a logged in user. When scheduled or when viewed by someone who is not logged in, it will run as:',
      runAsUsername: 'RunAs',
      runAsAlternateLabel: 'Unix username to run content as',
      runAsAlternatePlaceholder: 'Enter the Unix username',
      runAsDefault: 'The default user {username}',
      runAsAlternate: 'An alternate user {username}',
      runAsCurrent: 'The Unix account of the current user',
      runAsWarning: 'User Changes',
      becomeViewerWarning: 'Become viewer',
      removePrincipalWarning: 'Remove viewer or collaborator',
      undisclosedOwner: '[Undisclosed owner]',
    },
    types: {
      all: 'Anyone - no login required',
      loggedIn: 'All users - login required',
      acl: 'Specific users or groups',
    },
    messages: {
      runAsWarning: 'Changing the user responsible for executing this {type} will terminate active processes associated with this {type}. Are you sure you want to continue?',
      becomeViewerWarning: {
        admin: 'Are you sure you want to make yourself a viewer? You will be removed as a collaborator.',
        nonAdmin: 'Are you sure you want to make yourself a viewer? You won\'t be able to change the settings afterwards.',
      },
      removePrincipalWarning: {
        admin: 'Are you sure you want to remove yourself? You may no longer be able to access the content, but will still be able to change application settings.',
        nonAdmin: 'Are you sure you want to remove yourself? You may no longer be able to access the content.',
      },
      customUrlConflict: {
        visible: 'Vanity path conflicts with: ',
        notVisible: 'Vanity path conflicts with {notShown} other vanity paths that you cannot view due to access restrictions.',
        mixed: 'Vanity path conflicts with {total} other vanity paths. You cannot view {notShown} of these paths due to access restrictions. You have permission to view the following conflicting paths: ',
      },
      cantFindSomething: {
        label: 'Can’t find something?',
        forAdmin: 'Users will show up here as they login. You can also add {0} and {1} yourself.',
        forUsersPermissions: 'Users will show up here as they login. Otherwise users or groups need to be added to Connect first. You can also add {0} yourself.',
        forGroupsPermissions: 'Users will show up here as they login. Otherwise users or groups need to be added to Connect first. You can also add {1} yourself.',
        forNoPermissions: 'Users will show up here as they login. Otherwise users or groups need to be added to Connect by an administrator first.',
      },
      principalAlreadyAdded: '{0} was already added as a {1}',
      addPrincipalByToken: '{0} has been added as a {1}. You must Save for the changes to take effect.',
      environmentMissing: [
        `The environment which specified this image is no longer available.`,
        `Content will fail to build until a compatible environment is selected or the option 
        is switched to automatic.`,
      ],
      environmentMissingAndEmpty: [
        `The environment which specified this image is no longer available and no
        alternative execution environments are configured on this system.`,
        `Content will fail to build until execution environments have been added and either a 
        valid environment is selected or the option is switched to automatic.`,
      ],
      environmentEmpty: [
        `No execution environments are configured on this system.
        Content will fail to build until execution environments have been added.`,
      ],
    },
    status: {
      loading: 'Loading access settings...',
      error: 'An error occurred while loading the access settings.',
    },
    executionEnvironment: {
      labels: {
        lastTime: 'Last time',
        nextTime: 'Next time',
        selectButton: 'Click to select environment',
        editButton: 'Edit',
      },
      options: {
        system: 'Automatic (default)',
        specify: 'Use a specific environment',
      },
      selectDialog: {
        title: 'Select a Default Execution Environment',
        help: [
          `Your administrator has made the following execution environments
          available for use as a default execution environment for your content.`,
          `Use the search field and column sorting to narrow down your choices.
          Choose one and then click Select or hit ESC to cancel.`,
        ],
        submitLabel: 'Select',
      },
      errors: {
        noSelection: `Click the button to specify an environment or change the option
          to automatic.`
      },
      noPreviousImage: 'Unknown',
    },
  },
  vars: {
    title: 'Environment Variables',
    buttons: {
      add: 'Add Variable',
    },
    labels: {
      name: 'Name',
      value: 'Value',
      newValue: 'New value',
      edit: 'Edit variable value',
      delete: 'Delete variable',
      undo: 'Undo change',
      empty: 'empty',
      deleted: 'deleted',
      conflict: 'Environment Variable Conflict',
      warningHeader: 'Environment Variable Changes',
    },
    note: 'Note: Do not wrap your text in quotation marks; all symbols become part of the value available to your code.',
    messages: {
      restricted:
        'You do not have permissions to change the environment variables for this content.',
      conflict: [
        'Unable to update environment variables because they were changed while you were editing them.',
        'Please try again.',
        'The environment variable(s) you attempted to change were:',
      ],
      notExecutable:
        'The source code for this content was not published. It is not executable and does not accept environment variables.',
      warning:
        'Changing the environment variables for processes related to this {type} will result in the termination of any currently running processes for this {type}. Are you sure you want to continue?',
      changes: 'The environment variable(s) that will be changed are:',
    },
    newValuePlaceholder: 'Enter new value...',
    errors: {
      required: 'Name is required',
      duplicated: 'A variable with this name already exists',
    },
    variableInfo: [
      'Environment variables defined here are exposed to the processes executing your content. This is one way to pass configuration options (e.g. database passwords, shared secrets, etc.).',
      'Once you add a variable, the value will be obscured. Environment variables are encrypted on-disk and in-memory. They are decrypted only when a process is about to be started.',
    ],
    status: {
      loading: 'Loading variables...',
      error: 'An error occurred while loading the environment variables.',
      saved: 'Updated environment variables successfully',
    },
  },
  schedule: {
    inputLabels: {
      singular: {
        minute: 'minute',
        hour: 'hour',
        day: 'day',
        week: 'week',
        dayofmonth: 'month',
        year: 'year',
      },
      plural: {
        minute: 'minutes',
        hour: 'hours',
        day: 'days',
        week: 'weeks',
        dayofmonth: 'months',
        year: 'years',
      },
      every: 'Run every',
      byMinute: 'By Minute',
      hourly: 'Hourly',
      daily: 'Daily',
      weekdays: 'Run every weekday (Monday to Friday).',
      weekly: 'Weekly',
      monthly: 'Monthly',
      onMonthDay: 'on the {input} day of the month.',
      onNthMonthDay: 'on the {input} of the month.',
      semimonthly: 'Semimonthly',
      semimonthlyFirst: 'Run on the 1st and 15th days of each month.',
      semimonthlyLast: 'Run on the 14th and last days of each month.',
      weekPosition: 'Week position',
      weekDay: 'Week day',
      yearly: 'Yearly',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      first: 'first',
      second: 'second',
      third: 'third',
      fourth: 'fourth',
      fifth: 'fifth',
    },
    timezone: {
      label: 'Timezone',
      title: 'Select a timezone',
      start: 'Start date & time',
      reset: 'Reset to local time',
    },
    contentDesc: {
      unknown: 'incomplete content',
      shiny: 'an interactive Shiny application',
      staticRmd: 'an R Markdown document',
      interactiveShiny: 'an interactive Shiny document', // shiny quarto too
      static: 'a document',
      api: 'an API-hosting application',
      dash: 'an interactive Dash application',
      streamlit: 'an interactive Streamlit application',
      bokeh: 'an interactive Bokeh application',
      fastapi: 'a FastAPI application',
      shinyPython: 'a Python Shiny application',
      voila: 'a Voila interactive notebook',
      tensorflow: 'a TensorFlow Model API',
      jupyter: 'a Jupyter notebook',
      staticQuarto: 'Quarto content',
    },
    loading: 'Loading...',
    scheduleFor: 'Schedule output for {name}',
    scheduleType: 'Schedule type',
    unsavedReports: 'Unsaved reports cannot be scheduled. Please save this report before attempting to schedule it.',
    notOfType: 'This is {mode} so there is no need to render on a schedule - it will present the latest data whenever it is run.',
    notPublished: 'The source code for this content was not published. The output cannot be refreshed.',
    cannotRefresh: 'This content cannot be refreshed.',
    notDeployed: 'This has not been fully deployed. Please try publishing again.',
    publishOutput: 'Publish output after it is generated',
    saved: 'Schedule saved successfully.',
    email: {
      checkboxLabel: 'Send email after update',
      ownersNotified: 'Owners are always notified',
      unlessOptout: 'unless they {optoutLink}',
      optout: 'opt-out',
      sendCollab: 'Send to all collaborators',
      sendViewers: 'Send to all viewers',
      viewersAcl: 'listed in the Access panel',
      usersInSystem: 'all users in the system (Server Broadcast)',
      additional: 'Additional Recipients',
      removeAdditional: 'Remove {name} from additional recipients',
      additionalPrivacy: 'Information about additional recipients is not shown due to privacy concerns.',
      accessRequired: 'User must have permission to view the document in order to be subscribed to it.',
      noNeedOwner: 'The content owner does not need to be added as a recipient.',
    },
  },
  info: {
    status: {
      loading: 'Loading content information settings...',
      error: 'Error saving application settings: %{error}'
    },
    title: {
      title: 'Content Title',
      placeholder: 'Click here to edit title',
      error: 'Title is required and must be between 3 and 1024 characters long.',
    },
    description: {
      placeholder: 'Click here to edit description',
      error: 'Description is limited to 4096 characters long.',
    },
    usage: {
      loading: 'Loading content usage...',
      thirtyDaysUsage: 'Past 30 days usage',
      help: 'Usage data for the last 30 days is summarized across all versions and variations of this content.',
      shinyHelp: 'Total number of visits plus the total time users spent interacting with the content along with a plot of the daily visit count are displayed.',
      generalHelp: 'Total number of visits along with a plot of the daily visit count are displayed.',
      cookbookRef: 'Use the Instrumentation Usage APIs to perform your own analysis. Start with the recipes in the',
      apiCookbook: 'API Cookbook',
      partial: 'Due to high request volume for this content item, partial data is displayed.',
    },
    imageFileInput: {
      accessDenied: 'Only Content Owners and Collaborators may change the image for content.',
      clearImage: 'Clear Image',
      actionProse: 'Drag and drop, or click to select, an image for this content',
      actionProseMS: 'Click to select an image for this content',
      notSupportedError: 'That kind of content is not supported.',
      maxSizeError: 'That image is larger than the maximum size of %{size}.'
    },
    contentInfo: {
      created: 'Created:',
      lastDeployed: 'Last Deployed:',
      contentType: 'Content Type:',
      GUID: 'GUID:',
      copyGUID: 'Copy GUID',
      contentID: 'Content ID:',
      rVersion: 'R Version:',
      pythonVersion: 'Python Version:',
      quartoVersion: 'Quarto Version:',
      lastExecution: 'Last Execution:',
      usedImageWithinCluster: 'Used the %{imageName} image within the %{clusterName} cluster'
    }
  }
};
