<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div>
    <table
      class="vertical-space local-about-table"
    >
      <tr>
        <td class="emphasize-no-wrap right-space">
          Product:
        </td>
        <td>
          {{ $t('common.product') }}
        </td>
      </tr>
      <tr v-if="version">
        <td class="emphasize-no-wrap right-space">
          Version:
        </td>
        <td>
          {{ version }}
        </td>
      </tr>
      <tr v-if="build">
        <td class="emphasize-no-wrap right-space">
          Build:
        </td>
        <td>
          {{ build }}
        </td>
      </tr>
      <tr v-if="showImageInfo">
        <td class="emphasize right-space">
          Execution Images:
        </td>
        <td>
          &nbsp;
        </td>
      </tr>
    </table>
    <!-- Handle display of R, Python, and Quarto versions for k8s execution -->
    <table
      v-if="showImageInfo"
      class="execution-image-table"
    >
      <thead>
        <tr>
          <th class="do-not-wrap-text">
            Cluster Name
          </th>
          <th>Image Name</th>
          <th>R Versions</th>
          <th>Python Versions</th>
          <th>Quarto Versions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(environment, index) in environments"
          :key="index"
          data-automation="runtime"
        >
          <td>{{ environment.clusterName }}</td>
          <td class="do-not-wrap-text">
            {{ environment.imageName }}
          </td>
          <td>{{ buildVersionString(environment.rVersions) }}</td>
          <td>{{ buildVersionString(environment.pythonVersions) }}</td>
          <td>{{ buildVersionString(environment.quartoVersions) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AboutOffHost',
  props: {
    about: {
      type: String,
      required: true,
    },
    build: {
      type: String,
      required: true,
    },
    version: {
      type: String,
      required: true,
    },
    environments: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showImageInfo() {
      return Object.keys(this.environments).length !== 0;
    },
  },
  methods: {
    buildVersionString(versions) {
      if (versions === undefined) {
        // Likely to be unreachable with real data.
        return this.$t('documentation.versions.unknown');
      }

      if (versions.length === 0) {
        return this.$t('documentation.versions.none');
      }

      return versions.join(', ');
    }
  }
};
</script>
