<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="errorMessages">
    <p>
      <i18n
        path="content.deploymentDialog.directoryScanningFailed.description"
        tag="span"
      >
        <template #manifestJson>
          <code>
            {{ $t('content.deploymentDialog.common.manifestJson') }}
          </code>
        </template>
      </i18n>
    </p>
    <p>
      <i18n
        path="content.deploymentDialog.directoryScanningFailed.help"
        tag="span"
      >
        <template #manifestJson>
          <code>
            {{ $t('content.deploymentDialog.common.manifestJson') }}
          </code>
        </template>
        <template #writeManifestR>
          <code>
            {{ $t('content.deploymentDialog.directoryScanningFailed.writeManifestR') }}
          </code>
        </template>
        <template #writeManifestPython>
          <code>
            {{ $t('content.deploymentDialog.directoryScanningFailed.writeManifestPython') }}
          </code>
        </template>
        <template #userGuideLink>
          <a
            :href="userGuideLocation"
            target="_blank"
          >
            {{ $t('content.deploymentDialog.directoryScanningFailed.userGuide') }}
          </a>
        </template>
      </i18n>
    </p>
    <p v-if="!hasDirectoryScanningError">
      {{ $t('content.deploymentDialog.directoryScanningFailed.scanningTimeout') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DirectoryScanFailure',
  props: {
    hasDirectoryScanningError: {
      type: Boolean,
      required: true,
    },
    userGuideLocation: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.errorMessages {
  p {
    margin-bottom: .75rem;
  }
}
</style>
