<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rs-action">
    <RSButton
      ref="openModalRef"
      :label="$t('authentication.label.changePassword')"
      data-automation="cp-trigger"
      @click="reset(true)"
    />
    <RSModalForm
      v-if="form.show"
      :active="true"
      :subject="$t('authentication.label.changePassword')"
      autocomplete="off"
      @close="reset(false)"
      @submit="changePassword"
    >
      <template #content>
        <EmbeddedStatusMessage
          v-if="apiError"
          :message="apiError"
          :show-close="true"
          class="rsc-status"
          type="error"
          @close="apiError = ''"
        />

        <fieldset :disabled="processing">
          <RSInputPassword
            ref="currentPassword"
            v-model="form.currentPassword"
            :message="errorMessage('currentPassword')"
            :label="$t('authentication.label.currentPassword')"
            autocomplete="off"
            data-automation="cp-current"
            name="cp-current"
          />
          <RSInputPassword
            v-model="form.newPassword"
            :message="errorMessage('newPassword')"
            :label="$t('authentication.label.newPassword')"
            autocomplete="off"
            data-automation="cp-new"
            name="cp-new"
          >
            <template #help>
              <ul>
                <li
                  v-for="item in $t('authentication.setPassword.help')"
                  :key="item"
                >
                  {{ item }}
                </li>
              </ul>
            </template>
          </RSInputPassword>
          <RSInputPassword
            v-model="form.repeatNewPassword"
            :message="errorMessage('repeatNewPassword')"
            :label="$t('authentication.label.repeatNewPassword')"
            autocomplete="off"
            data-automation="cp-repeat-new"
            name="cp-repeat-new"
          />
        </fieldset>
      </template>
      <template #controls>
        <RSButton
          :disabled="processing"
          :label="$t('authentication.label.changePassword')"
        />
      </template>
    </RSModalForm>
  </div>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputPassword from 'Shared/components/RSInputPassword';
import RSModalForm from 'Shared/components/RSModalForm';

import { changePassword } from '@/api/authentication';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { setInfoMessage } from '@/utils/status';
import * as Validators from '@/utils/validators';

const formValidations = {
  form: {
    currentPassword: Validators.password(),
    newPassword: Validators.password(),
    repeatNewPassword: Validators.repeatPassword('newPassword'),
  },
};

export default {
  name: 'ChangePassword',
  components: {
    EmbeddedStatusMessage,
    RSButton,
    RSInputPassword,
    RSModalForm,
  },
  data() {
    return {
      processing: false,
      apiError: '',
      form: {
        show: false,
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      },
    };
  },
  methods: {
    focusCurrentPassword() {
      this.$refs.currentPassword.$el.querySelector('input').focus();
    },
    changePassword() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      this.processing = true;
      return changePassword(this.form.currentPassword, this.form.newPassword)
        .then(() => {
          this.reset(false);
          setInfoMessage(this.$t('authentication.setPassword.status.changed'));
          this.$emit('changed-password');
        })
        .catch(e => {
          this.reset(true);
          this.apiError = safeAPIErrorMessage(e);
          this.focusCurrentPassword();
        });
    },
    errorMessage(field) {
      const fieldValidators = this.$v.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // find all failing field validators
      const failedValidators = Object.keys(formValidations.form[field]).filter(
        validatorName => fieldValidators[validatorName] === false
      );

      // return the first failed validator
      return this.$t(
        `authentication.validation.${field}.${failedValidators[0]}`
      );
    },
    reset(show) {
      this.processing = false;
      this.apiError = '';
      this.form.currentPassword = '';
      this.form.newPassword = '';
      this.form.repeatNewPassword = '';
      // clear validation errors
      this.$v.$reset();
      this.form.show = show;

      if (show) {
        // invoke after next tick (waits for the modal and its contents to render)
        setTimeout(() => this.focusCurrentPassword(), 1);
      } else {
        this.$nextTick(() => this.$refs.openModalRef.focusElement());
      }
    },
  },
  validations: formValidations,
};
</script>

<style lang="scss" scoped>
.rsc-status {
  margin-bottom: 0.9rem;
}
</style>
