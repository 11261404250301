// Copyright (C) 2022 by Posit Software, PBC.

const documentation = {
  title: 'Documentación',
  user: {
    title: 'Guía del usuario',
    description:
      'Esta guía ayudará al usuario a configurar e implementar contenido en Posit Connect.',
  },
  admin: {
    title: 'Guía de administración',
    description: `
      Esta guía ayudará a un administrador a instalar y configurar Posit Connect en un
      servidor administrado. Aprenderá cómo instalar el producto en diferentes sistemas
      operativos, configurar la autenticación y monitorear los recursos del sistema.`,
  },
  faq: {
    title: 'Preguntas más frecuentes',
    description: 'Obtenga respuestas a las preguntas frecuentes.',
  },
  articles: {
    title: 'Artículos',
    description:
      'Artículos y documentación adicional sobre el uso y administración de Posit Connect.',
  },
  releaseNotes: {
    title: 'Notas de lanzamiento',
    description: `
      Obtenga toda la información sobre las versiones más recientes y anteriores de Posit
      Connect.`,
  },
  apiReference: {
    title: 'Referencia de la API del servidor',
    description: `
      La referencia de la API del servidor Connect lo ayudará a acceder a la funcionalidad
      Posit Connect mediante scripts o herramientas externas. También puede descargar
      la especificación como una definición compatible con Swagger en. ({yaml} {json})`,
  },
};

export { documentation };
