// Copyright (C) 2022 by Posit Software, PBC.

// WeekView provides a view-model for displaying a week's worth of scheduled
// content in a calendar format
import moment from 'moment-mini';
import { filterSort } from './filter';

export class WeekView {
  // used to render column headers in the calendar
  hourLabels = [
    '12AM',
    '1AM',
    '2AM',
    '3AM',
    '4AM',
    '5AM',
    '6AM',
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM',
    '6PM',
    '7PM',
    '8PM',
    '9PM',
    '10PM',
    '11PM',
    '12AM',
  ];

  // start is a moment-wrapped date-time object
  // items are [ScheduledContent]
  constructor(start, items) {
    // sort items by descending frequency
    this.items = filterSort(items, 'all', 'frequency', true);

    // only use the date to calculate the start Day
    const startDay = moment([start.year(), start.month(), start.date()]);
    // list of date-time objects, one for each day in the view (excluding time)
    this.days = [];
    // list of formatted labels for days
    this.dayLabels = [];
    // list of scheduled content (indexed by day)
    this.scheduledContentByDay = [];

    // keeps track of expanded view per day+content
    // [{[id]: boolean}]
    this.expanded = [];

    // construct 7 days worth of information
    for (let k = 0; k < 7; k++) {
      const day = moment(startDay).add(k, 'day');

      // items that will run on this day
      const itemsThisDay = this.items.filter(i => i.runsOn(day));
      if (itemsThisDay.length === 0) {
        // no items for this day so skip it
        // eslint-disable-next-line no-continue
        continue;
      }

      this.scheduledContentByDay.push(itemsThisDay);
      this.days.push(day);
      this.dayLabels.push(day.format('MMMM D, YYYY'));

      this.expanded.push(
        itemsThisDay
          .map(i => i.id)
          .reduce((acc, id) => {
            acc[id] = false;
            return acc;
          }, {})
      );
    }
  }

  rowStyle(r, day) {
    if (r.multiple) {
      // the maxWidthPct is a percentage of the entire row, excluding the
      // right-side "12 AM" cell.
      const maxWidthPct = 96;

      if (moment(r.start).dayOfYear() < moment(day).dayOfYear()) {
        // in the case of the day we're rendering being after the start day of
        // the item, set the style to the full row width
        return { left: 0, width: `${maxWidthPct }%` };
      }

      // when the day we're rendering is the same day as the start time of the
      // item, set the left side of the "bar" to the current time and fill in
      // the rest of the row to the right
      const leftBound = this.startTimePercent(new Date().toISOString());
      return {
        left: `${leftBound }%`,
        width: `${(maxWidthPct - parseFloat(leftBound, 10)).toFixed(1) }%`,
      };
    }

    // in the case of any other type of item, the expected value is a timestamp
    // ISO string which is used to calculate the left-side position
    return { left: `${this.startTimePercent(r) }%`, width: '.75%' };
  }

  rowClass(r) {
    const classes = ['scheduledRun', 'projected'];
    if (r.multiple) {
      classes.push('multipleRuns');
    }
    return classes;
  }

  // provides an offset used by the `left` CSS property to determine where to
  // show the start-time in the timeline
  startTimePercent(time) {
    const t = moment(time);
    return (((t.hours() * 60 + t.minutes()) * 100) / (25 * 60)).toFixed(1);
  }

  toggleExpanded(dayIndex, contentId) {
    this.expanded[dayIndex][contentId] = !this.expanded[dayIndex][contentId];
  }

  isExpanded(dayIndex, contentId) {
    return this.expanded[dayIndex][contentId];
  }
}
