// Copyright (C) 2023 by Posit Software, PBC.

const ideToken = {
  title: 'Connect to R',
  cannotPublish: {
    title: 'Insufficient Privileges',
    details: `Your user account ({username}) has insufficient
      privileges to publish content to Connect. {permissionsLink}`,
    linkLabel: 'Request permission to publish your own content',
  },
  canPublish: {
    title: 'Confirm Package Connection',
    details: [
      `You're almost done configuring R to publish content to Posit
      Connect. Click the Connect button below to link your Posit
      Connect account ({username}) to the {package} R package.`,
      `If you didn't reach this screen by initiating an account link from the
      RStudio IDE or in R via {code}, click Cancel.`,
    ],
  },
  connection: {
    failed: {
      title: 'Connection Failed',
      details: `We couldn't connect your account. Try connecting again, and contact
        your administrator if the problem persists.`,
    },
    success: {
      title: 'Connection Succeeded',
      details: [
        `You've successfully connected the {package} package
        to Posit Connect! If you're using the RStudio IDE, click the
        "Connect Account" button to finish adding this account.`,
        `Now you are ready to publish a {docslink} created with R.`,
      ],
      linkLabel: 'Shiny application, R Markdown documents or any plot',
    },
    cancelled: {
      title: 'Connection Cancelled',
      details: `If this was in error, please read the {docslink}
        for help getting the RStudio IDE connected to your account.`,
      linkLabel: 'documentation',
    },
  },
  actions: {
    cancel: 'Cancel',
    connect: 'Connect',
  },
};

export { ideToken };
