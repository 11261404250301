// Copyright (C) 2022 by Posit Software, PBC.

const confirmUser = {
  awaitingConfirmationTitle: 'Awaiting Confirmation',
  leadMessage: 'You need to confirm your account before proceeding.',
  leadMessageInstructions: 'You should have received an email with a link that will allow you to confirm your account.',
  timeEmailArrivalMessage: 'It may take a few minutes for the email to arrive; you might also need to check your Spam or Junk folder.',
  resendConfirmationEmail: 'Resend Confirmation Email',
  requiresConfirmationTitle: 'Requires Confirmation',
  askYourAdminMessagePart1: `Ask your {systemDisplayName} administrator to visit your user profile and obtain an <strong>account confirmation link</strong> for you. You can`,
  askYourAdminMessagePart2: `to your user profile and pass it to an administrator.`,
  copyLink: 'copy this link',
  copyLinkMessage: 'A link to your user profile has been copied to the clipboard. Be sure to pass it along to an administrator without visiting it.',
  confirmationEmailSent: 'Confirmation email resent.'
};

export { confirmUser };
