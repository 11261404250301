// Copyright (C) 2023 by Posit Software, PBC.

//  functions are designed to return true when invalid, false when valid.
export function isEmptyValue(valueStr) {
  return Boolean((valueStr === '' || valueStr === null || valueStr === undefined));
}

export function containsControlWhitespace(valueStr, allowNewLines) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  if (allowNewLines) {
    return /[\t\b\f\r]/.test(valueStr);
  }
  return /[\t\n\b\f\r]/.test(valueStr);
}

export function containsSpaceCharacter(valueStr) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  return / /.test(valueStr);
}

export function invalidAbsoluteUnixFilepath(valueStr) {
  if (isEmptyValue(valueStr)) {
    return false;
  }
  return (
    valueStr.charAt(0) !== '/' ||
    valueStr.charAt(valueStr.length - 1) === '/'
  );
}

export function invalidSemanticVersionString(valueStr) {
  const re = /^(\d+)\.(\d+)\.(\d+)$/.exec(valueStr);
  if (re === null || re.length !== 4) {
    return true; // invalid
  }
  return false;
}
