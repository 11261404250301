// Copyright (C) 2022 by Posit Software, PBC.

import { delay } from '@/store/redux/utils/delay';

// eslint doesn't like something about this jsdoc and I don't know why. I'm trying to be good! (SJ)
// eslint-disable-next-line valid-jsdoc
/**
 * cancelableInterval executes a promise creator on an interval and cancels it using the
 * [AbortController API]{@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController}.
 * @example
 * const abortMe = new AbortController;
 * cancelableInterval(() => Promise.resolve(1), abortMe.signal, 5000);
 * await delay(25000); // wait 25 seconds
 * // 1 ----- 1 ----- 1 ----- 1 ----- 1
 * abortMe.abort();
 * // stops the promise from resolving 1 over and over.
 * @param {() => Promise} promiseCreator Any function that's a promise returner
 * @param {AbortSignal} signal AbortController signal member
 * @param {Number} interval interval in milliseconds
 */
export async function cancelableInterval(promiseCreator, signal, interval) {
  for (;;) {
    if (signal.aborted) {
      return;
    }
    await promiseCreator().then(() => delay(interval));
  }
}
