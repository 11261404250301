<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="tags-help">
    <ul class="tags-help__overview">
      <li
        v-for="(bullet, index) in locals.gettingStarted.overview"
        :key="'help-bullet-' + index"
      >
        {{ bullet }}
      </li>
    </ul>

    <h1 class="tags-help__subtitle sectionSubtitle">
      {{ locals.gettingStarted.how.title }}
    </h1>
    <p class="tags-help__description">
      {{ locals.gettingStarted.how.description }}
    </p>

    <div class="tags-help__schema">
      <div
        v-for="category in sampleSchema"
        :key="category.id"
        class="tags-category"
      >
        <div class="tags-category__name">
          <h2 class="tags-category__name-label">
            {{ category.name }}
          </h2>
        </div>
        <div
          v-for="tag in category.children"
          :key="tag.id"
          class="tags-category__child"
        >
          <div
            v-if="tag.children.length"
            class="tags-group"
          >
            <div class="tags-group__main-tag">
              <div class="tags-group__main-tag-icon" />
              <span class="tags-group__main-tag-label">
                {{ tag.name }}
              </span>
            </div>
            <div class="tags-group__children">
              <div
                v-for="groupTag in tag.children"
                :key="`tag-${groupTag.id}`"
                class="tags-group__sub-tag"
              >
                <div class="tag-record">
                  <div class="tag-record__label">
                    <div class="tag-record__icon" />
                    <span class="tag-record__label-text">
                      {{ groupTag.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="tag-record"
          >
            <div class="tag-record__label">
              <div class="tag-record__icon" />
              <span class="tag-record__label-text">
                {{ tag.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tags-help__schema-stamp" />
    </div>
  </div>
</template>

<script>
import { sampleSchema } from './sampleSchema';

export default {
  name: 'TagsGettingStarted',
  created() {
    this.locals = {
      gettingStarted: this.$t('admin.tags.gettingStarted'),
    };
    this.sampleSchema = sampleSchema;
  },
};
</script>

<style scoped lang="scss">

.sectionSubtitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  margin: 4px 0 10px 0;
}

</style>
