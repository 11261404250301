<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  This component contains the base methods and lifecycle hooks needed for managing tag components focus/selection behavior
-->

<script>
export default {
  name: 'TagSelectableBase',
  data() {
    return {
      isSelected: false,
      isEditing: false,
    };
  },
  created() {
    this.focusRefs = [];
  },
  beforeDestroy() {
    if (this.isSelected) {
      document.removeEventListener('click', this.handleOutsideClick);
    }
  },
  methods: {
    handleSelection(clicked) {
      if (!this.isSelected) {
        this.isSelected = true;
        if (clicked) {
          this.addUnfocusListeners();
        }
      }
      // Focused by key navigation
      if (!clicked) {
        this.isEditing = true;
      }
    },
    focusOnEditAttempt() {
      if (this.$refs.mainInput) {
        this.$refs.mainInput.focus();
      }
    },
    handleOutsideClick(ev) {
      if (this.$el !== ev.target) {
        this.isSelected = false;
        this.removeUnfocusListeners();
      }
    },
    eachTreeSiblingsDo(cb) {
      ['.tag-record', '.tags-group', '.tags-category', '.add-item-input__input'].forEach(
        selector => {
          const stack = document.querySelectorAll(selector);
          Array.prototype.forEach.call(stack, sibling => {
            if (this.$el !== sibling) {
              cb(sibling);
            }
          });
        }
      );
    },
    addUnfocusListeners() {
      document.addEventListener('click', this.handleOutsideClick);
      this.eachTreeSiblingsDo(sb => sb.addEventListener('click', this.handleOutsideClick));
    },
    removeUnfocusListeners() {
      document.removeEventListener('click', this.handleOutsideClick);
      this.eachTreeSiblingsDo(sb => sb.addEventListener('click', this.handleOutsideClick));
    },
    handleBlur(ev, cb) {
      let keepSelected = false;

      // IE11 doesn't feature relatedTarget, use document.activeElement in that case
      // relatedTarget is prefered over document.activeElement, most modern browsers rely on relatedTarget
      const target = ev.relatedTarget || document.activeElement;
      this.focusRefs.forEach(ref => {
        if (target === this.$refs[ref]) {
          keepSelected = true;
        }
      });
      this.isSelected = keepSelected;
      this.isEditing = false;

      // Callback used to trigger auto-save methods declared on tag extended components
      if (cb) {
        cb();
      }
    },
  },
};
</script>
