// Copyright (C) 2022 by Posit Software, PBC.

import { combineReducers } from 'redux';

import appState from '@/store/redux/app-state/app-state.reducers';

export const appReducers = {
  appState,
};

export default combineReducers(appReducers);
