// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Utility functions for content listings.
 */

import { vueI18n } from '@/i18n/index';
import { User } from '@/api/dto/user';
import { appPath, userPath } from '@/utils/paths';

/**
 * @param {Object} app - an App DTO
 * @returns {Object} - an href and title for a link to a specific piece of content
 */
export function contentLink(app) {
  return {
    href: appPath(app.guid),
    title: vueI18n.t(`content.list.tooltip.${app.contentType()}`),
  };
}

/**
 * @param {string} userId - a user GUID
 * @returns {Object} - an href and title for a link to the owner of a piece of content
 */
export function authorLink(userId) {
  return {
    href: userPath(userId),
    title: vueI18n.t('users.action.navigateOwner'),
  };
}

/**
 * @param {Object} app - an App DTO
 * @returns {boolean} - true if a piece of content has an identifiable owner
 */
export function hasOwner(app) {
  return Boolean(app.ownerUsername && app.ownerGuid);
}

// Helper to construct a (partial) User DTO so we can access computed fields (displayInitials, displayName).
// The User DTO is missing most fields and so is not suitable for general use.
function appOwner(app) {
  return new User({
    firstName: app.ownerFirstName || '',
    lastName: app.ownerLastName || '',
    username: app.ownerUsername,
  });
}

/**
 * @param {Object} app - an App DTO
 * @returns {string} - the app owner's initials
 */
export function displayOwnerInitials(app) {
  return appOwner(app).displayInitials;
}

/**
 * @param {Object} app - an App DTO
 * @returns {string} - the app owner's display name
 */
export function displayOwnerName(app) {
  return appOwner(app).displayName;
}

/**
 * @param {Object} git - the `git` field of an App DTO
 * @returns {string} - an empty string if `git` is falsey, else a translated "from Git" string
 */
export function fromGit(git) {
  return git ? ` ${vueI18n.t('content.list.labels.fromGit')}` : '';
}
