<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <p
      v-if="loading"
      data-automation="challenge-loading"
    >
      {{ $t("authentication.label.challengeLoading") }}
    </p>

    <div
      v-else
      class="challenge-container"
      data-automation="challenge-container"
    >
      <img
        v-if="challengeImg"
        id="captcha-image"
        data-automation="challenge-image"
        :src="`data:image/png;base64,${challengeImg}`"
        :alt="$t('authentication.label.challengeImage')"
      >

      <!-- eslint-disable vuejs-accessibility/media-has-caption -->
      <audio
        v-if="challengeAudio"
        data-automation="challenge-audio"
        controls
      >
        {{ $t('authentication.label.audioUnsupported') }}
        <source
          type="audio/wav"
          :src="`data:audio/wav;base64,${challengeAudio}`"
        >
      </audio>

      <RSInputText
        v-model="challengeValue"
        name="challenge-response"
        class="challenge-input"
        data-automation="challenge-response"
        autocomplete="off"
        :label="$t('authentication.label.challengeVerification')"
        @input="newVal => onChange(newVal)"
      />
    </div>
  </div>
</template>

<script>
import { getCaptcha } from '@/api/authentication';
import { setErrorMessageFromAPI } from '@/utils/status';
import RSInputText from 'Shared/components/RSInputText';

export default {
  name: 'LoginCaptcha',
  components: { RSInputText },
  props: {
    value: {
      type: String,
      required: true,
    },
    shouldReload: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      challenge: {},
      challengeValue: this.value,
      challengeId: null,
      challengeImg: null,
      challengeAudio: null,
    };
  },
  watch: {
    shouldReload(reload) {
      if (reload) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      return getCaptcha()
        .then(({ challengeId, challengeData = [] }) => {
          this.challengeId = challengeId;
          challengeData.forEach(({ mimeType, payload }) => {
            switch (mimeType) {
              case 'image/png':
                this.challengeImg = payload;
                break;
              case 'audio/wav':
                this.challengeAudio = payload;
                break;
              default:
            }
          });
        })
        .catch(setErrorMessageFromAPI)
        .finally(() => {
          this.loading = false;
          this.challengeValue = '';
          this.$emit('ready', this.challengeId);
        });
    },
    onChange(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>

<style scoped>
.challenge-input {
  margin-top: 1.5rem;
}
</style>
