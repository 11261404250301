<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <AppContainer data-automation="not-found">
    <h1
      class="sectionTitle"
      data-automation="not-found__title"
    >
      {{ $t('common.notFound') }}
    </h1>

    <p data-automation="not-found__message">
      {{ $t('common.notFoundMsg') }}
    </p>
    <router-link :to="{ name: returnLink }">
      <RSButton
        class="go_back"
        type="primary"
        label="Back"
        data-automation="not-found__back-button"
      >
        {{ $t('common.buttons.back') }}
      </RSButton>
    </router-link>
  </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import RSButton from 'Shared/components/RSButton';
import { mapState } from 'vuex';

export default {
  name: 'NotFoundView',
  components: {
    AppContainer,
    RSButton
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.currentUser.isAuthenticated,
    }),
    returnLink() {
      if (this.isAuthenticated) {
        return 'contentList';
      }
      return 'welcome';
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';

.go_back {
  margin-top: 2em;
}
</style>
