<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    :aria-label="$t('contentHeader.actions.logs.label')"
    :title="$t('contentHeader.actions.logs.label')"
    class="action logs content-actions__logs-button"
    :class="{ current: isOpen, narrow: dropdown, wide: !dropdown }"
    :data-automation="dataAutomation"
    @click="runAction"
  >
    <span
      v-if="dropdown"
      class="actionTitle"
    >
      {{ $t('contentHeader.actions.logs.label') }}
    </span>
  </button>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState, mapActions } from 'vuex';
import {
  OPEN_PANEL,
  CLOSE_PANEL,
  LOGS_PANEL,
  TOGGLE_PANELS,
} from '@/store/modules/contentView';

export default {
  name: 'LogsButton',
  props: {
    dropdown: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState({
      isOpen: state => state.contentView.showLogs,
      currentVariant: state => state.parameterization.currentVariant,
    }),
    dataAutomation() {
      return this.dropdown ? 'menuitem-logs--dropdown' : 'menuitem-logs';
    }
  },
  methods: {
    ...mapActions({
      togglePanels: TOGGLE_PANELS,
    }),
    runAction() {
      const params = {};
      let logsFlag = CLOSE_PANEL;
      let routeName = 'apps.access';

      if (!this.isOpen) {
        routeName = 'apps.logs';
        logsFlag = OPEN_PANEL;
      }

      if (!isEmpty(this.currentVariant)) {
        params.id = this.currentVariant.id;
        routeName += '.variant';
      }

      this.$router.push({
        name: routeName,
        replace: true,
        params,
      });
      this.togglePanels({ panel: LOGS_PANEL, action: logsFlag });
    }
  }
};
</script>
