<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="metrics-feature-usage">
    <RSInformationToggle>
      <template #title>
        <div class="sectionTitle small">
          {{ $t('metrics.featureUsage.title') }}
        </div>
      </template>
      <template #rightSideControls>
        <button
          v-show="hasData"
          v-clipboard:copy="usageMetricsReport"
          v-clipboard:success="openNotifyCopyDialog"
          class="smallTextButton"
        >
          {{ $t('common.buttons.copy') }}
        </button>
      </template>
      <template #help>
        <p
          v-for="(line, i) in $t('metrics.featureUsage.usageInfo')"
          :key="i"
        >
          {{ line }}
        </p>
      </template>
    </RSInformationToggle>
    <pre
      class="rsc-metrics__feature-usage-output"
      tabindex="0"
    ><code>{{ hasData ? usageMetricsReport : $t('metrics.featureUsage.dataLoadError') }}</code></pre>

    <RSModal
      v-if="showNotifyCopyDialog"
      :subject="$t('metrics.featureUsage.notifyCopy.purpose')"
      :close-button-label="$t('common.actions.close')"
      :active="showNotifyCopyDialog"
      @close="closeNotifyCopyDialog"
    >
      <template #content>
        <div>
          <i18n
            path="metrics.featureUsage.notifyCopy.message.email"
            tag="p"
          >
            <template #mailto>
              <a
                href="mailto:usage@rstudioconnect.com"
                target="_blank"
              >usage@rstudioconnect.com</a>
            </template>
          </i18n>
          <i18n
            path="metrics.featureUsage.notifyCopy.message.thanks"
            tag="p"
          />
          <i18n
            path="metrics.featureUsage.notifyCopy.message.privacy"
            tag="p"
          >
            <template #policy>
              <a
                href="https://posit.co/about/privacy-policy/"
                target="_blank"
              >https://posit.co/about/privacy-policy/</a>
            </template>
          </i18n>
        </div>
      </template>
      <template #controls>
        <RSButton
          :label="$t('common.buttons.ok')"
          @click="closeNotifyCopyDialog"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInformationToggle from 'Shared/components/RSInformationToggle';
import RSModal from 'Shared/components/RSModal';
import { getFeatureUsage } from '@/api/featureUsage';
import { setErrorMessageFromAPI } from '@/utils/status';
import moment from 'moment-mini';
import { keysToSnake } from '@/api/transform';

export default {
  name: 'FeatureUsage',
  components: {
    RSButton,
    RSModal,
    RSInformationToggle,
  },
  props: {
    version: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNotifyCopyDialog: false,
      usageMetrics: {},
    };
  },
  computed: {
    // Converts {foo: 'bar', baz: 'xyzzy'} into 'foo: bar\nbaz: xyzzy'
    usageMetricsReport() {
      return Object.entries(this.usageMetrics)
        .map(([k, v]) => {
          return `${k}: ${v}`;
        })
        .join('\n');
    },
    hasData() {
      return Object.entries(this.usageMetrics).length !== 0;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getFeatureUsage()
        .then(data => {
          const features = data.reduce((map, obj) => {
            map[obj.name] = obj.used;
            return map;
          }, {});

          const metadata = keysToSnake({
            connectVersion: this.version,
            dateTime: moment().format('YYYY/MM/DD HH:mm:ss.SSS'),
          });

          this.usageMetrics = { ...metadata, ...features };
        })
        .catch(setErrorMessageFromAPI);
    },
    openNotifyCopyDialog() {
      this.showNotifyCopyDialog = true;
    },
    closeNotifyCopyDialog() {
      this.showNotifyCopyDialog = false;
    },
  },
};
</script>
