<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__plumber-intro wizard-panel--blue">
    <div class="wizard-panel__content wizard-panel__content--no-title">
      <p>{{ $t('publish.plumberAPIIntro.body') }}</p>
      <p>{{ $t('publish.plumberAPIIntro.howToPublish') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlumberAPIIntro',
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__plumber-intro {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>
