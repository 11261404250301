<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    v-if="showComponent"
    class="band"
  >
    <div
      class="bandContent mainPage requires-auth environment-list"
    >
      <div
        class="environment-list__main environment-list__main--fill"
      >
        <RestrictedAccessWrapper
          v-slot="{ executeRestrictedApi }"
          :eager="false"
        >
          <EnvironmentsList
            ref="environmentsList"
            :execute-restricted-api="executeRestrictedApi"
            :readonly="readonly"
            :dialog-mode="false"
          />
        </RestrictedAccessWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { ExecutionTypeK8S } from '@/api/serverSettings';
import { mapState } from 'vuex';
import UserRoles from '@/api/dto/userRole';

import EnvironmentsList from './EnvironmentsList';

import RestrictedAccessWrapper from '@/components/RestrictedAccessWrapper';

export default {
  name: 'ExecutionEnvironments',
  components: {
    EnvironmentsList,
    RestrictedAccessWrapper,
  },
  data() {
    return {
      readonly: true,
      showComponent: false, // hide component until we decide it can be seen
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let redirect = false;
      if (this.currentUser.userRole === UserRoles.Admin) {
        this.readonly = false;
      } else if (this.currentUser.userRole === UserRoles.Publisher) {
        this.readonly = true;
      } else {
        // viewers cannot view this page.
        redirect = true;
      }

      if (!this.serverSettings.enableImageManagement ||
          this.serverSettings.executionType !== ExecutionTypeK8S) {
        // do not allow access if feature flag is disabled or if
        // we're not operating in off-host mode
        redirect = true;
      }

      if (redirect) {
        // Normally unable to navigate here, but router does setup static route,
        // so it is possible to access via direct URL.
        // In that case, just reroute to content view, but execute this in the
        // next pass through the event loop.
        window.setTimeout(() => {
          window.location.href = '#/content';
        }, 0);
      } else {
        // now show it!
        this.showComponent = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.pb-0 {
  padding-bottom: 0;
}
.environment-list {
  display: flex;
  height: 100vh;

  &__main {
    width: 73%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding-right: 2%;
    overflow-y: unset;

    &--fill {
      width: 100%;
      padding-right: 0;
    }
  }
}

@media screen and (max-width : 1024px) {
  .environment-list__main {
    width: 100%;
    padding-right: 0;
  }
}
</style>
