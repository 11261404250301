// Copyright (C) 2023 by Posit Software, PBC.

const ideToken = {
  title: 'Conectar a R',
  cannotPublish: {
    title: 'No tiene permisos suficientes',
    details: `Tu cuenta ({username}) no tiene los permisos suficientes
      para publicar contenido en Connect. {permissionsLink}`,
    linkLabel: 'Solicita permisos para publicar tu contenido',
  },
  canPublish: {
    title: 'Confirma la conexión',
    details: [
      `Estas muy cerca de configurar R para publicar contenido a Posit
      Connect. Da click en el botón de Conectar para enlazar tu cuenta
      de Posit Connect ({username}) a el paquete {package} R.`,
      `En caso de llegar a esta pantalla sin haber iniciado el enlace desde el IDE de
      RStudio o desde R con el paquete {code}, da click en Cancelar.`,
    ],
  },
  connection: {
    failed: {
      title: 'Conexión fallida',
      details: `No fue posible conectar tu cuenta. Intenta nuevamente, contacta a tu
      administrador si el problema continua.`,
    },
    success: {
      title: 'Conexión exitosa',
      details: [
        `Haz conectado exitosamente el paquete {package}
        a Posit Connect! En caso de usar el IDE de RStudio, da click
        en "Conectar Cuenta" para terminar de agregar la cuenta.`,
        `Todo esta listo para que publiques {docslink} creadas con R.`,
      ],
      linkLabel: 'aplicaciones de Shiny, documentos R Markdown o gráficas',
    },
    cancelled: {
      title: 'Conexión cancelada',
      details: `En caso de haber sido un error, por favor visita {docslink}
        como guía para conectar el IDE de RStudio a tu cuenta.`,
      linkLabel: 'la documentación',
    },
  },
  actions: {
    cancel: 'Cancelar',
    connect: 'Conectar',
  },
};

export { ideToken };
