<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="group-members-actions actionBar inline showTitles"
  >
    <button
      v-if="canEdit"
      ref="renameButton"
      :aria-label="
        $t('groups.actions.rename.label', {
          name: groupName
        })
      "
      data-automation="rename-group"
      type="button"
      class="action edit"
      @click="onClick('rename')"
    >
      <span class="actionTitle">{{
        $t('groups.actions.rename.title')
      }}</span>
    </button>
    <button
      v-if="canRemove"
      ref="deleteButton"
      :aria-label="
        $t('groups.actions.delete.label', {
          name: groupName
        })
      "
      data-automation="delete-group"
      type="button"
      class="action delete"
      @click="onClick('remove')"
    >
      <span class="actionTitle">{{
        $t('groups.actions.delete.title')
      }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'GroupMembersActions',
  props: {
    groupName: {
      type: String,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { lastClicked: null };
  },
  methods: {
    onClick(buttonName) {
      this.lastClicked = buttonName === 'rename' ? this.$refs.renameButton : this.$refs.deleteButton;
      this.$emit(buttonName);
    },
    focusLast() {
      this.$nextTick(() => this.lastClicked && this.lastClicked.focus());
    },
  }
};
</script>
