<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__open-example wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.openExample.title') }}
      </StepHeading>
      <div class="wizard-panel__open-example-content">
        <h3 class="wizard-panel__subtitle">
          {{ $t('publish.openExample.subtitle.server') }}
        </h3>
        <div class="wizard-panel__open-example-description">
          <i18n
            path="publish.openExample.body.server"
            tag="p"
          >
            <template #upload>
              <span class="emphasize">{{ $t('publish.openExample.upload') }}</span>
            </template>
            <template #zip>
              <code>{{ zip }}</code>
            </template>
            <template #rproj>
              <code>{{ rprojLegend }}</code>
            </template>
          </i18n>
          <img
            :alt="$t('publish.openExample.thumbAlt')"
            class="wizard-panel__open-example-description-thumb"
            src="./thumbnail.png"
          >
        </div>

        <hr class="wizard-panel__separator">

        <h3 class="wizard-panel__subtitle">
          {{ $t('publish.openExample.subtitle.desktop') }}
        </h3>
        <div class="wizard-panel__open-example-description">
          <i18n
            path="publish.openExample.body.desktop"
            tag="p"
          >
            <template #upload>
              <span class="emphasize">{{ $t('publish.openExample.upload') }}</span>
            </template>
            <template #zip>
              <code>{{ zip }}</code>
            </template>
            <template #rproj>
              <code>{{ rprojLegend }}</code>
            </template>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'OpenExample',
  components: { StepHeading },
  mixins: [PanelMixin],
  computed: {
    rprojLegend() {
      return this.rproj || this.$t('publish.openExample.aProject');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.wizard-panel {
  &__open-example {
    background-position: right bottom;
    background-repeat: no-repeat;

    &-content {
      margin-top: 1.5rem;
    }

    &-description {
      margin-left: 1rem;
      font-size: 0.9rem;

      &-thumb {
        display: block;
        margin: 1rem 0;
      }
    }
  }

  &__separator {
    height: 1px;
    color: $color-divider;
    background-color: $color-divider;
    border: none;
    margin: 1.5rem 0;
  }
}
</style>
