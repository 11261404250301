// Copyright (C) 2022 by Posit Software, PBC.

import { ActionTypes } from './variant.constants';

export function addVariant(variant) {
  return {
    type: ActionTypes.ADD_VARIANT,
    payload: { variant },
  };
}
