// Copyright (C) 2022 by Posit Software, PBC.

const documentation = {
  title: 'Documentation',
  user: {
    title: 'User Guide',
    description:
      'This guide will help a user configure and deploy content to Posit Connect.',
  },
  admin: {
    title: 'Admin Guide',
    description: `
      This guide will help an administrator install and configure Posit Connect on a managed
      server. You will learn how to install the product on different operating systems,
      configure authentication, and monitor system resources.`,
  },
  faq: {
    title: 'FAQ',
    description: 'Get answers to frequently asked questions.',
  },
  articles: {
    title: 'Articles',
    description:
      'Articles and additional documentation on the use and administration of Posit Connect.',
  },
  releaseNotes: {
    title: 'Release Notes',
    description:
      'Get all the information on the latest, and previous, releases of Posit Connect.',
  },
  api: {
    title: 'Server API',
    cookbook: 'Server API Cookbook',
    reference: 'Server API Reference',
    description: `
      The Server API lets you interact with Posit Connect from scripts,
      code, and external tools. The {cookbook} has code examples to help you
      get started using the API. The {reference} describes the available
      endpoints. A Swagger-compliant specification of the API is available in
      {yaml} and {json}.`,
  },
  versions: {
    label: 'Version:',
    unknown: '(unknown)',
    none: '(none)',
  },
  build: {
    label: 'Build:',
  },
  rVersions: {
    label: 'R Versions:',
  },
  pythonVersions: {
    label: 'Python Versions:',
  },
  quartoVersions: {
    label: 'Quarto Versions:',
  }
};

export { documentation };
