// Copyright (C) 2022 by Posit Software, PBC.

const resetPassword = {
  titles: {
    resetPassword: 'Reset Password',
    resetPasswordEmailSent: 'Password Reset Email Sent',
  },
  instructions: {
    checkEmail: 'Check your e-mail for instructions on resetting your password. Please contact your {systemDisplayName} administrator for assistance if your username is correct but you have not received an email after a few minutes.',
    enterUsername: 'Enter your username and select Reset Password. An email will be sent to the address registered to your account with further instructions.',
    askYourAdmin: 'Ask your {systemDisplayName} administrator to obtain a {resetLink} from your user profile for you. By visiting this link you will be able to set a new password.',
    resetLink: 'password reset link',
  },
  validationMessage: 'This field cannot be blank.',
};

export { resetPassword };
