// Copyright (C) 2022 by Posit Software, PBC.

const email = {
  settings: {
    readOnly: {
      intro: 'These settings are controlled by your configuration file.',
      docLink:
        'Please visit the {emailSetup} section on the Admin Guide for details.',
      linkLabel: 'Email Setup',
    },
    form: {
      title: 'Email Settings',
      sendTestEmail: {
        label: 'Send Test Email',
        eventMsg: {
          success: 'Test email sent to {address}',
          activity: 'Sending test email.',
        },
      },
    },
    sendType: {
      label: 'Email Delivery',
      option: {
        none: 'None',
        sendmail: 'Sendmail',
        smtp: 'SMTP',
      },
    },
    smtp: {
      address: {
        label: 'Server Address',
      },
      port: {
        label: 'Port',
        info: [
          `
              Mail servers will expose one set of ports for
              SSL/TLS connections and other ports for unencrypted
              connections.
            `,
          `
              If connecting to the mail server port 465, you
              probably need to use SSL/TLS connections. Port 587
              generally means you should configure StartTLS to be
              "always" used. Port 25 is the traditional,
              unencrypted SMTP port and may not permit StartTLS;
              ask your systems administrator what StartTLS option
              is appropriate.
            `,
        ],
        error: {
          required: 'A port number is required for SMTP configuration.',
          validation: 'Port must be a number greater than zero.',
        },
      },
      startTLS: {
        options: {
          whenAvailable: 'When available',
          always: 'always',
          never: 'never',
        },
      },
      auth: {
        label: 'My mail server requires authentication',
        userName: {
          label: 'Username',
        },
        password: {
          label: 'Password not shown for security',
        },
      },
    },
  },
};

export { email };
