// Copyright (C) 2022 by Posit Software, PBC.

import { observableStore } from '@/store/observableStore';
import { vueI18n } from '@/i18n/index';
import {
  getTagsTree,
  createTag,
  deleteTag,
  createTagVersion,
} from '@/api/tags';

/**
 * Tags State network activity process options
 */
export const networkProcess = {
  SYNC: 'sync',
  FETCH: 'fetch',
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
};

/**
 * Admin Tags state source of truth
 */
export const $tags = observableStore(
  {
    tagsTree: [],
    deletingTag: {
      name: '',
      payload: {},
    },
    currentRequest: {
      active: false,
      message: '',
    },
    focusOnNewGroup: false,
  },
  {
    /**
     * Meant to be used internally, within state/store methods. Sets the XHR activity status involved with tags.
     * @param {Object} state This param contains the state provided by the factory
     * @param {string} type Required. Type of network activity
     * @returns {Function} The clear activity function resolver
     */
    setNetworkActivity(state, type) {
      const activityTimeout = setTimeout(() => {
        state.currentRequest.message = vueI18n.t(
          `admin.tags.processing.${type}`
        );
        state.currentRequest.active = true;
      }, 300);
      return () => {
        if (state.currentRequest.active) {
          $tags.resetNetworkActivity();
        } else {
          clearTimeout(activityTimeout);
        }
      };
    },

    /**
     * Reset network activity. Delays the reset by 500ms, for UX purposes, preventing the undesirable/flashy feedback
     * when the API responds almost instantly.
     * @param {Object} state This param contains the state provided by the factory
     */
    resetNetworkActivity(state) {
      setTimeout(() => {
        state.currentRequest.active = false;
        state.currentRequest.message = '';
      }, 500);
    },

    /**
     * Fetch admin tags tree and save in state
     * @param {Object} state This param contains the state provided by the factory
     * @returns {Promise} A promise that resolves with the tags tree
     */
    fetchTree(state) {
      const activity = state.tagsTree.length
        ? networkProcess.SYNC
        : networkProcess.FETCH;
      const clearActivity = $tags.setNetworkActivity(activity);
      return getTagsTree()
        .then(tree => {
          state.tagsTree = tree;
        })
        .finally(clearActivity);
    },

    /**
     * Submit the request for a new tag or category and persist in state
     * @param {Object} state This param contains the state provided by the factory
     * @param {Object} payload Required. The new tag info
     * @param {string} payload.name Required. Tag's name
     * @param {Object} payload.stateParent Tag's parent observable object from state. Not required for new categories
     * @param {boolean} payload.discardFocus Discard focus on new category or group. Used when tag is created on blur.
     * @returns {Promise} A promise that resolves with the new tag data
     */
    // eslint-disable-next-line no-shadow
    create(state, { name, stateParent = null, discardFocus = false }) {
      if (!name) {
        return Promise.resolve();
      }
      const reqPayload = { name };
      if (stateParent) {
        reqPayload.parentId = stateParent.id;
      }
      const clearActivity = $tags.setNetworkActivity(networkProcess.CREATE);
      return createTag(reqPayload)
        .then(newTag => {
          const newTagNormalized = { ...newTag, children: [] };
          if (!stateParent) {
            // New category, set flag to focus on add children input
            state.focusOnNewGroup = !discardFocus;
            state.tagsTree.push(newTagNormalized);
          } else {
            // Created new group?, set flag to focus on add children input
            if (stateParent.children.length === 0) {
              state.focusOnNewGroup = !discardFocus;
            }
            stateParent.children.push(newTagNormalized);
          }
        })
        .finally(clearActivity);
    },

    /**
     * Sets the state to confirm a delete request, submission of delete via this.confirmDelete
     * @param {Object} state This param contains the state provided by the factory
     * @param {Object} payload Required. Tag to delete info
     * @param {string} payload.id Required. Tag's id
     * @param {string} payload.name Required. Tag's name
     * @param {string|number} payload.version Required. Tag's version
     * @param {Object} payload.stateParent Tag's parent observable object from state. Not required when deleting a category
     */
    requestDelete(state, payload) {
      state.deletingTag.name = payload.name;
      state.deletingTag.payload = payload;
    },

    /**
     * Submits the delete request for a tag or category and update state tree
     * @param {Object} state This param contains the state provided by the factory
     * @returns {Promise} A promise that resolves on delete tag success
     */
    confirmDelete(state) {
      const { id, version, stateParent } = state.deletingTag.payload;
      const clearActivity = $tags.setNetworkActivity(networkProcess.DELETE);
      return deleteTag({ id, version })
        .then(() => {
          const deletedTagHolder = stateParent
            ? stateParent.children
            : state.tagsTree;
          for (let i = 0; i < deletedTagHolder.length; i++) {
            if (deletedTagHolder[i].id === id) {
              // Using splice to mutate the actual observable object
              deletedTagHolder.splice(i, 1);
              break;
            }
          }
          state.deletingTag.name = '';
          state.deletingTag.payload = {};
        })
        .finally(clearActivity);
    },

    /**
     * Cancels and resets the delete request payload
     * @param {Object} state This param contains the state provided by the factory
     */
    cancelDelete(state) {
      state.deletingTag.name = '';
      state.deletingTag.payload = {};
    },

    /**
     * Submit the request to create a new version for a tag or category and update state
     * @param {Object} state This param contains the state provided by the factory
     * @param {Object} payload Required. Tag update info
     * @param {string} payload.name Required. Tag's new name
     * @param {Object} payload.stateTag Required. Tag to be updated observable object from state
     * @returns {Promise} A promise that resolves with the new tag version
     */
    // eslint-disable-next-line no-shadow
    update(state, { name, stateTag }) {
      // Only save when there are changes in the tag's name
      if (name === stateTag.name) {
        return Promise.resolve();
      }
      const clearActivity = $tags.setNetworkActivity(networkProcess.UPDATE);
      return createTagVersion({
        name,
        id: stateTag.id,
        version: stateTag.version,
      })
        .then(updatedTag => {
          stateTag.name = updatedTag.name;
          stateTag.version = updatedTag.version;
          stateTag.updatedTime = updatedTag.updatedTime;
        })
        .finally(clearActivity);
    },

    /**
     * Reset focus flag
     * @param {Object} state This param contains the state provided by the factory
     */
    resetFocusFlag(state) {
      state.focusOnNewGroup = false;
    },
  }
);
