<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--

  DatePicker provides a date input along with a calendar control (on focus) for visually
  selecting a date.

-->

<template>
  <div
    :class="{ active }"
    class="date-input rsc-date-input"
  >
    <input
      ref="input"
      v-model="text"
      type="text"
      class="date-input__input"
      aria-label="date"
      :disabled="disabled"
      @input="setDirty"
      @focus="activate"
      @blur="deactivate"
    >
    <div class="date-input__picker rsc-date-input__picker">
      <DatePicker
        :value="pickerDate"
        :inline="true"
        @input="picked"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import moment from 'moment-mini';

const ValidDateFormats = [
  'MMM D, YYYY',
  'MM-DD-YYYY',
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY/MM/DD',
];

const DateFormat = 'MMM D, YYYY';

export default {
  name: 'DateInput',
  components: {
    DatePicker,
  },
  props: {
    // date is expected to be a `moment` wrapped Date object if provided
    date: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      dirty: false,
      text: '', // See watch on `date` for initial value
    };
  },
  computed: {
    pickerDate() {
      if (!this.date) {
        // Default to today if no date is provided
        return moment().toDate();
      }

      return this.date.toDate();
    },
  },
  watch: {
    date: {
      immediate: true,
      handler: function(newDate) {
        this.text = this.formatDate(newDate);
      },
    }
  },
  methods: {
    activate() {
      this.$refs.input.select();
      this.active = true;
      this.$emit('focus', 'date');
    },
    deactivate() {
      if (this.dirty) {
        const parsedDate = moment(this.text, ValidDateFormats, true);

        if (parsedDate.isValid()) {
          this.update(parsedDate);
        } else {
          // reset formatting
          this.text = this.formatDate(this.date);
        }
      }

      this.dirty = false;
      this.active = false;
    },
    setDirty() {
      this.dirty = true;
    },
    picked(date) {
      this.dirty = false;
      this.update(moment(date));
    },
    update(date) {
      this.$emit('change', date);
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      return moment(date).format(DateFormat);
    },
  }
};
</script>

<style lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.date-input {
  position: relative;
  display: inline-block;

  &:not(.active) {
    .date-input__picker {
      display: none;
    }
  }

  &.active {
    .date-input__picker {
      display: block;
    }
  }

  .date-input__input {
    /* rsc-row input has so much specificity... */
    width: 120px!important;
  }
}

.date-input__picker {
  position: absolute;
  @include small-drop-shadow();

  /* <vuejs-datepicker> : Note that the below styles are specific to augment vuejs-datepicker
   * and that you may need to provide your own styles for any other datepicker component */
  .cell {
    &.day {
      border-radius: 50%;
    }

    &:not(.blank):not(.disabled):not(.selected).day:hover {
      border-color: $color-white;
      background-color: $color-light-grey-2;
    }

    &.selected, &.selected:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
  /* </vuejs-datepicker> */
}
.rsc-date-input__picker {
  /* This is needed to override rsc-row */
  white-space: normal;
}
.rsc-date-input {
  /* This is needed so a input[type=number]'s spin buttons do not bleed through the calendar */
  z-index: 1;
}
</style>
