// Copyright (C) 2022 by Posit Software, PBC.

import { vueI18n } from '@/i18n';
import { GiB as gibibyte } from '@/utils/bytes.filter';

const maxSystemLimitError = (maxLimit, unit) => {
  const labeledMax = (unit === undefined) ? maxLimit : `${maxLimit} ${unit}`;
  return vueI18n.t('appSettings.runtime.errors.maxSystemLimit', { maxLimit: labeledMax });
};
const minSystemLimitError = minLimit => vueI18n.t('appSettings.runtime.errors.minSystemLimit', { minLimit });
const invalidNumericError = desc => vueI18n.t('appSettings.runtime.errors.invalidNumeric', { desc });
const internalError = desc => vueI18n.t('appSettings.runtime.errors.internalError', { desc });

// 30 days in seconds
const MaxTimeout = 60 * 60 * 24 * 30;
const MaxTimeoutStr = MaxTimeout.toString();

// when we convert to bytes to make the API call, we must not exceed
// MAX_SAFE_INTEGER
const maxGibibytes = Math.trunc(Number.MAX_SAFE_INTEGER / gibibyte);

// keys in app obj used by the runtime settings UX
// must be updated to handle new attributes
const appObjKeys = {
  integerKeys: [
    'minProcesses',
    'maxProcesses',
    'maxConnsPerProcess',
    'amdGpuLimit',
    'nvidiaGpuLimit',
  ],
  timeoutIntegerKeys: [
    'idleTimeout',
    'initTimeout',
    'connectionTimeout',
    'readTimeout',
  ],
  floatingKeys: [
    'loadFactor',
    'cpuLimit',
    'cpuRequest',
  ],
  byteKeys: [
    'memoryLimit',
    'memoryRequest',
    'maxMemoryLimit',
    'maxMemoryRequest',
  ],
};

// Control the conversion between the app object and the values used within the ux
// returns object
// {
//   data: app data object or null in case of error
//   error: null if no error or error string
// }
const exportAppData = (obj, appId) => {
  // cherry pick the attributes we want
  let newObj = {};
  for (const [key, objValue] of Object.entries(obj)) {
    if (appObjKeys.integerKeys.includes(key)
      || appObjKeys.timeoutIntegerKeys.includes(key)
      || appObjKeys.floatingKeys.includes(key)
      || appObjKeys.byteKeys.includes(key)
    ) {
      const { value, error } = parseNumberOrNull(objValue);
      if (error !== null) {
        return {
          data: null,
          error: internalError(`exportAppData: conversion error of ${key}=${value}: ${error}`),
        };
      }
      newObj[key] = value;
    }
  }
  newObj.appId = appId;

  newObj = transformSecToNS(newObj);
  newObj = transformGiBToByte(newObj);

  return {
    data: newObj,
    error: null,
  };
};

// Converts numeric values received from server API into strings
// (timeouts are also converted into seconds as strings)
const importAppData = app => {
  let obj = { ...app };
  obj = transformNSToSec(obj);
  obj = transformByteToGiB(obj);

  // cherry pick the attributes we need
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (appObjKeys.integerKeys.includes(key)
      || appObjKeys.timeoutIntegerKeys.includes(key)
      || appObjKeys.floatingKeys.includes(key)
      || appObjKeys.byteKeys.includes(key)
    ) {
      newObj[key] = convertToNullOrString(value);
    }
  }
  newObj.appMode = obj.appMode;

  return newObj;
};

// Convert numeric values received from server settings api into strings
// (timeouts are also converted into seconds as strings)
function importDefaultsAndLimits(obj) {
  // create a new copy and convert timeouts to seconds and bytes to gibibytes
  let defaultsAndLimits = { ...obj };
  defaultsAndLimits = transformNSToSec(
    defaultsAndLimits,
  );
  defaultsAndLimits = transformByteToGiB(
    defaultsAndLimits,
  );

  // convert all of the attributes within the object
  for (const [key, value] of Object.entries(defaultsAndLimits)) {
    defaultsAndLimits[key] = convertToNullOrString(value);
  }
  return defaultsAndLimits;
}

// Utility functions to transform timeout values to/from seconds/nanoseconds.
function transformNSToSec(
  obj = {}
) {
  appObjKeys.timeoutIntegerKeys.forEach(propertyName => {
    if (!(propertyName in obj && Number.isFinite(obj[propertyName]))) {
      // if property doesn't exist or is not a number
      return;
    }

    // convert from nanoseconds to seconds
    obj[propertyName] = +(obj[propertyName] / 1e9).toFixed(0);
  });

  return obj;
}

// see transformNSToSec
function transformSecToNS(
  obj = {}
) {
  appObjKeys.timeoutIntegerKeys.forEach(propertyName => {
    if (!(propertyName in obj && Number.isFinite(obj[propertyName]))) {
      // if property doesn't exist or is not a number
      return;
    }

    // convert from seconds to nanoseconds
    obj[propertyName] = +(obj[propertyName] * 1e9).toFixed(0);
  });

  return obj;
}

// Utility functions to transform byte values to/from GiBs.
//
// See: http://en.wikipedia.org/wiki/Binary_prefix
//
// There's some potential loss of precision here as the API expects byte values
// as an integer (and I'm rounding GiB to 2 digits; otherwise, you could enter
// "1.1", hit save, and you'd get "1.099999999627471" back in the display.) As
// the UX is dealing in GiB I think plus/minus a byte won't really matter.
function transformByteToGiB(
  obj = {}
) {
  appObjKeys.byteKeys.forEach(propertyName => {
    if (!(propertyName in obj && Number.isFinite(obj[propertyName]))) {
      // if property doesn't exist or is not a number
      return;
    }

    // convert from bytes to gibibytes (limit to 2 decimal places for nicer display)
    obj[propertyName] = +(obj[propertyName] / gibibyte).toFixed(2);
  });
  return obj;
}

// see transformByteToGiB
function transformGiBToByte(
  obj = {}
) {
  appObjKeys.byteKeys.forEach(propertyName => {
    if (!(propertyName in obj && Number.isFinite(obj[propertyName]))) {
      // if property doesn't exist or is not a number
      return;
    }

    // convert from gibibytes to (integer) bytes
    obj[propertyName] = +(obj[propertyName] * gibibyte).toFixed(0);
  });
  return obj;
}

function errorForMinValueZero(valueStr) {
  // if 0 or Null, then no limit, always valid
  if (valueStr === '0' || valueStr === null) {
    return null;
  }
  if (typeof valueStr !== 'string') {
    return invalidNumericError(`errorForMinValueZero: valueStr=${valueStr}`);
  }
  const inputValue = Number(valueStr);
  if (isNaN(inputValue)) {
    return internalError(`errorForMinValueZero: valueStr=${valueStr} isNaN`);
  }
  if (inputValue < 0) {
    return minSystemLimitError('0');
  }
  return null;
}

function errorForTimeoutSettings(valueStr) {
  // if 0, then no limit, always valid
  if (valueStr === '0' || valueStr === null) {
    return null;
  }
  if (typeof valueStr !== 'string') {
    return invalidNumericError(`errorForTimeoutSettings: valueStr=${valueStr}`);
  }
  const inputValue = Number(valueStr);
  if (isNaN(inputValue)) {
    return internalError(`errorForTimeoutSettings: valueStr=${valueStr} isNaN`);
  }
  if (inputValue < 0) {
    return minSystemLimitError('0');
  }
  if (inputValue > MaxTimeout) {
    return maxSystemLimitError(MaxTimeoutStr);
  }
  return null;
}

const convertToNullOrString = value => {
  return value === null ? null : value.toString();
};

// returns object
// {
//   value = number or null
//   error = null (if no error) or error string
// }
const parseNumberOrNull = valueStr => {
  if (valueStr === null || valueStr === '') {
    return { value: null, error: null };
  }
  if (typeof valueStr !== 'string') {
    return {
      value: null,
      error: invalidNumericError(`parseNumberOrNull: valueStr is not string ${typeof valueStr}`),
    };
  }
  const num = Number(valueStr);
  if (isNaN(num)) {
    return {
      value: null,
      error: internalError(`parseNumberOrNull: valueStr=${valueStr} isNaN`),
    };
  }
  return {
    value: num,
    error: null,
  };
};

// Validates the entry in the Minimum Processes field.
//
// Returns an error message or null.
const validateMinProcesses = (minString, limitString, maxString, defaultMax) => {
  // if empty, then using defaults, always valid
  if (isEmptyValue(minString)) {
    return null;
  }

  // input value must be a number
  const minValue = Number(minString);
  if (isNaN(minValue)) {
    return internalError(`minProcesses isNaN`);
  }

  // input value must be >= 0
  if (minValue < 0) {
    return minSystemLimitError('0');
  }

  const limitValue = Number(limitString);
  if (isNaN(limitValue)) {
    return internalError(`minProcessesLimit isNaN`);
  }
  // input value must <= associated system limit
  if (minValue > limitValue) {
    return maxSystemLimitError(limitString);
  }

  // Determine if we have a max input value or need to use default
  let maxValue = null;
  if (!isEmptyValue(maxString)) {
    // use input form value
    maxValue = Number(maxString);
    if (isNaN(maxValue)) {
      return internalError(`maxProcesses isNaN`);
    }
  } else {
    // use default value instead
    maxValue = Number(defaultMax);
    if (isNaN(maxValue)) {
      return internalError(`default maxProcesses isNaN`);
    }
  }

  // input value must be <= max
  if (minValue > maxValue) {
    return vueI18n.t('appSettings.runtime.errors.maxLimit.minProcesses');
  }

  // all good!
  return null;
};

// Validates the entry in the Max Processes field.
//
// Returns an error message or null.
const validateMaxProcesses = (maxString, limitString, minString, defaultMin) => {
  // if empty, then using defaults, always valid
  if (isEmptyValue(maxString)) {
    return null;
  }

  // input value must be a number
  const maxValue = Number(maxString);
  if (isNaN(maxValue)) {
    return internalError(`maxProcesses isNaN`);
  }

  // input value must be >= 0
  if (maxValue < 0) {
    return minSystemLimitError('0');
  }

  const limitValue = Number(limitString);
  if (isNaN(limitValue)) {
    return internalError(`maxProcessesLimit isNaN`);
  }
  // input value must <= associated system limit
  if (maxValue > limitValue) {
    return maxSystemLimitError(limitString);
  }

  // Determine if we have a min input value or need to use default
  let minValue = null;
  if (!isEmptyValue(minString)) {
    // use input form value
    minValue = Number(minString);
    if (isNaN(minValue)) {
      return internalError(`minProcesses isNaN`);
    }
  } else {
    // use default value instead
    minValue = Number(defaultMin);
    if (isNaN(minValue)) {
      return internalError(`default minProcesses isNaN`);
    }
  }

  // input value must be >= min
  if (maxValue < minValue) {
    return vueI18n.t('appSettings.runtime.errors.minLimit.maxProcesses');
  }

  // all good!
  return null;
};

// Validate RAM/CPU limits.
//
// Compares user-entered value against system-wide max, if any, and against the
// effective minimum ("request"), if any.
//
// 0, meaning no limit, is only allowed if there is not a corresponding
// system-wide max limit. This is because allowing 0 would let a user
// circumvent the max imposed by their admin.
//
// A system-wide max of 0 means there is no uppper limit to what users can request.
//
// input.unit (optional) is used in error messages to label limits (e.g. 'GiB').
//
// In addition to the (admin-configured) system limit, for RAM constraints we
// have an extra always-applied safeLimit.  Because we end up converting GiB to
// bytes, we need to make sure the converted values don't exceed
// MAX_SAFE_INTEGER. This is optional because it's not applied to CPU
// constraints (it's unlikely anyone will ever enter a number so high in that
// field.)
//
// expects object as parameter
// {
//   input: {
//     form: <string input value being validated>
//     limit: <string system limit for input value>
//     safeLimit: <number highest value allowed regardless of system limit>
//     unit: <string unit for limit>
//     errorPath: <i18n error string path for system limit error>
//   },
//   minimum: {
//     form: <minimum string input value to guard against>
//     default: <default string value for this related min input if not provided in form>
//     errorPath: <i18n error string path for comparison error w/ minimum>
//   }
// }
// returns null if validation succeeds or error string if fails>,
const validateResourceLimit = ({ input, minimum }) => {
  // common validity checks for fields w/ limits
  // if empty, then using defaults, always valid
  //
  // NOTE: this may cause multi-field errors to only show one of those (rather than both,
  // which is the typical case when you have two fields which are defining a range.).
  // This is preferable to showing an error for an admin adjusted default, where the user
  // has yet to override that value.
  if (isEmptyValue(input.form)) {
    return null;
  }

  // input value must be a number
  const inputValue = Number(input.form);
  if (isNaN(inputValue)) {
    return internalError(`validateResourceLimit: input.form=${input.form} isNaN`);
  }

  // input value must be >= 0
  if (inputValue < 0) {
    return minSystemLimitError('0');
  }

  if (input.safeLimit !== undefined && inputValue > input.safeLimit) {
    return maxSystemLimitError(input.safeLimit, input.unit);
  }

  // if limit is empty, then no system limit, but we still need to check
  // related values, so don't return yet.
  if (!isEmptyValue(input.limit)) {
    // system limit is _not_ empty, so check against it
    const limitValue = Number(input.limit);
    if (isNaN(limitValue)) {
      return internalError(`validateResourceLimit: input.limit=${input.limit} isNaN`);
    }
    // input value must <= associated system limit, taking into account that
    // limit of 0 == unlimited
    if (limitValue > 0 && (inputValue === 0 || inputValue > limitValue)) {
      return maxSystemLimitError(input.limit, input.unit);
    }
  }

  // if 0 was not disallowed by the system limit above, then it's valid.
  //
  // Example: CPU Request: 2, CPU Limit: 0
  // This would mean that I need a minimum of 2 CPUs, but I don't have an upper
  // limit. This is valid even though 0 < 2, whereas any non-0 value needs to
  // be > the corresponding request.
  if (input.form === '0') {
    return null;
  }

  // Determine if we have a minimum input value or need to use default
  let minValue = null;
  if (!isEmptyValue(minimum.form)) {
    // use input form value
    minValue = Number(minimum.form);
    if (isNaN(minValue)) {
      return internalError(`validateResourceLimit: minimum.form=${minimum.form} isNaN`);
    }
  } else {
    // use default value instead
    minValue = Number(minimum.default);
    if (isNaN(minValue)) {
      return internalError(`validateResourceLimit: minimum.default=${minimum.default} isNaN`);
    }
  }
  // now that we have a value, validate it appropriately
  if (minValue !== 0) {
    // either through input or default, we have a minimum value
    if (inputValue < minValue) {
      return vueI18n.t(minimum.errorPath);
    }
  }
  // all good!
  return null;
};

// Validate RAM/CPU requests.
//
// Compares user-entered value against system-wide max, if any, and against the
// effective limit, if any.
//
// 0, meaning no minimum requested, is always allowed.
//
// A system-wide max of 0 means there is no upper limit to what users can request.
//
// input.unit (optional) is used in error messages to label limits (e.g. 'GiB').
//
// In addition to the (admin-configured) system limit, for RAM constraints we
// have an extra always-applied safeLimit.  Because we end up converting GiB to
// bytes, we need to make sure the converted values don't exceed
// MAX_SAFE_INTEGER. This is optional because it's not applied to CPU
// constraints (it's unlikely anyone will ever enter a number so high in that
// field.)
//
// expects object as parameter
// {
//   input: {
//     form: <string input value being validated>
//     limit: <string system limit for input value>
//     safeLimit: <number highest value allowed regardless of system limit>
//     unit: <string unit for limit>
//     errorPath: <i18n error string path for system limit error>
//   },
//   maximum: {
//     form: <maximum string input value to guard against>
//     default: <default string value for this related max input if not provided in form>
//     errorPath: <i18n error string path for comparison error w/ maximum>
//   }
// }
// returns null if validation succeeds or error string if fails>,
const validateResourceRequest = ({ input, maximum }) => {
  // common validity checks for fields w/ limits
  // if empty, then using defaults, always valid
  //
  // NOTE: this may cause multi-field errors to only show one of those (rather than both,
  // which is the typical case when you have two fields which are defining a range.).
  // This is preferable to showing an error for an admin adjusted default, where the user
  // has yet to override that value.
  if (isEmptyValue(input.form)) {
    return null;
  }
  // if 0, then no limit, always valid
  if (input.form === '0') {
    return null;
  }

  // input value must be a number
  const inputValue = Number(input.form);
  if (isNaN(inputValue)) {
    return internalError(`validateResourceRequest: input.form=${input.form} isNaN`);
  }

  // input value must be >= 0
  if (inputValue < 0) {
    return minSystemLimitError('0');
  }

  if (input.safeLimit !== undefined && inputValue > input.safeLimit) {
    return maxSystemLimitError(input.safeLimit, input.unit);
  }

  // if limit is empty, then no system limit, but we still need to check
  // related values, so don't return yet.
  if (!isEmptyValue(input.limit)) {
    // system limit is _not_ empty, so check against it
    const limitValue = Number(input.limit);
    if (isNaN(limitValue)) {
      return internalError(`validateResourceRequest: input.limit=${input.limit} isNaN`);
    }
    // input value must <= associated system limit
    if (limitValue > 0 && inputValue > limitValue) {
      return maxSystemLimitError(input.limit);
    }
  }

  // Determine if we have a maximum input value or need to use default
  let maxValue = null;
  if (!isEmptyValue(maximum.form)) {
    // use input form value
    maxValue = Number(maximum.form);
    if (isNaN(maxValue)) {
      return internalError(`validateResourceRequest: maximum.form=${maximum.form} isNaN`);
    }
  } else {
    // use default value instead
    maxValue = Number(maximum.default);
    if (isNaN(maxValue)) {
      return internalError(`validateResourceRequest: maximum.default=${maximum.default} isNaN`);
    }
  }
  // now that we have a value, validate it appropriately
  if (maxValue !== 0) {
    // either through input or default, we have a restrictive value
    if (inputValue > maxValue) {
      return vueI18n.t(maximum.errorPath);
    }
  }
  // all good!
  return null;
};

// consider a value empty if it is equal to null or ''.
const isEmptyValue = valueStr => {
  return !!((valueStr === '' || valueStr === null));
};

const noUndefined = array => {
  return array.every(value => value !== undefined);
};

export {
  MaxTimeout,
  importAppData,
  exportAppData,
  importDefaultsAndLimits,
  transformNSToSec, // tested
  transformSecToNS, // tested
  transformByteToGiB, // tested
  transformGiBToByte, // tested
  errorForMinValueZero,
  errorForTimeoutSettings,
  maxSystemLimitError,
  minSystemLimitError,
  invalidNumericError,
  internalError,
  parseNumberOrNull,
  isEmptyValue,
  noUndefined,
  validateResourceLimit,
  validateResourceRequest,
  validateMinProcesses,
  validateMaxProcesses,
  maxGibibytes,
};
