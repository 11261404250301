// Copyright (C) 2022 by Posit Software, PBC.

import * as Validators from 'vuelidate/lib/validators';

export const usernameMinLength = 3;
export const usernameMaxLength = 64;

const usernameFirstLetterRegexp = /^[a-zA-Z]/;
const usernameValidCharacters = /^[a-zA-Z0-9_.]+$/;

// usernameValidator returns a set of `vuelidate` validators that validate a
// supplied username. A valid username is one that is non-empty, is not in the
// list of prohibited usernames, and may have other limitations depending on
// the authentication provider.
export function usernameValidator(serverSettings) {
  const {
    prohibitedUsernames,
    usernameValidator: usernameRules,
  } = serverSettings;

  const validators = {
    // validators that are always present
    // also covers (usernameValidator === 'permissive')
    required: Validators.required,
    prohibited: value => !prohibitedUsernames.includes(value.trim().toLowerCase()),
  };

  if (usernameRules === 'default') {
    Object.assign(validators, {
      // additional validators
      minLength: Validators.minLength(3),
      maxLength: Validators.maxLength(64),
      firstLetter: value => usernameFirstLetterRegexp.test(value),
      validCharacters: value => usernameValidCharacters.test(value),
    });
  }

  return validators;
}

// emailValidator returns a set of `vuelidate` validators that validate a
// supplied email.
export function emailValidator() {
  return {
    required: Validators.required,
    email: isValidEmail,
  };
}

// isValidEmail checks if a supplied email is valid.  A valid email is one that
// has a username and domain which are separated by an @-symbol.
export function isValidEmail(email) {
  const userAndDomain = email.split('@');
  if (userAndDomain.length !== 2) {
    // expecting ['user', 'domain']
    return false;
  }
  const [user, domain] = userAndDomain;
  if (user.trim().length === 0 || domain.trim().length === 0) {
    // expecting non-empty strings
    return false;
  }
  const hostAndTLDs = domain.split('.');
  if (hostAndTLDs.length < 2) {
    // expecting ['host', 'tld', ... ]
    return false;
  }
  const [host, ...tlds] = hostAndTLDs;
  // host must be non-empty and TLDs must be > 1 length
  return host.trim().length > 0 && tlds.every(t => t.trim().length >= 2);
}

// password validator
export function password() {
  return {
    required: Validators.required,
    minLength: Validators.minLength(6),
  };
}

// repeat password validator
export function repeatPassword(fieldName = 'password') {
  return {
    required: Validators.required,
    same: Validators.sameAs(fieldName),
  };
}

// Validates whether a git URL is valid, using a much more permissive matcher
// than the built-in Vuelidate 'url' validator. We really just want to ensure
// that it begins with http/https.
//
// See https://github.com/rstudio/connect/issues/18666 for context
export const gitUrl = Validators.helpers.regex('gitUrl', /^https?:\/\/\S+/);
