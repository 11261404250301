<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
    Server notices

    Renders the server notices view.
  -->
<template>
  <div v-if="ready">
    <LicenseExpiration
      :server="server"
    />
    <DeprecatedSettings
      :server="server"
    />
  </div>
</template>

<script>
import { keysToCamel } from '@/api/transform';

import LicenseExpiration from './LicenseExpiration.vue';
import DeprecatedSettings from './DeprecatedSettings.vue';

export default {
  name: 'ServerNotices',
  components: {
    LicenseExpiration,
    DeprecatedSettings,
  },
  props: {
    ngServer: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ready() {
      return this.server !== undefined;
    },
    // Convert the incoming Angular model (ngServer) from snake_case to
    // camelCase for our child components.
    server() {
      return keysToCamel(this.ngServer);
    },
  },
};
</script>

<style lang="scss">
  /* adjustment allowing multiple broadcast messages, each with their own X. */
  .broadcastMessage {
      position: relative;
      width: 100%;
  }
  /* Adjustment needed to make the X not be all, well, you know, "buttony". */
  .broadcastMessage button.messageClose {
      background-color: inherit;
  };
</style>
