<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="title"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <EmbeddedStatusMessage
          v-if="message"
          :show-close="false"
          :message="message"
          type="info"
          class="rsc-status"
        />
        <p>{{ description }}</p>
        <RSInputText
          ref="input"
          :show-label="false"
          :value="text"
          :label="textLabel"
          name="ctd-text"
          readonly
        />
      </div>
    </template>

    <template #controls>
      <RSButton
        id="ctd-copy"
        :label="$t('common.buttons.copy')"
        @click="copyText"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSModal from 'Shared/components/RSModal';

import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

export default {
  name: 'CopyTextDialog',
  components: { EmbeddedStatusMessage, RSButton, RSInputText, RSModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    copiedMessage: {
      type: String,
      required: true,
    },
    textLabel: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: null,
    };
  },
  created() {
    this.message = this.infoMessage;
  },
  mounted() {
    this.$el.querySelector('#ctd-copy').focus();
  },
  methods: {
    copyText() {
      const input = this.$refs.input.$el.querySelector('input');
      input.focus();
      input.setSelectionRange(0, input.value.length);
      document.execCommand('copy');
      this.message = this.copiedMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.rsc-status {
  margin-bottom: 0.9rem;
}
</style>
