<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="rsc-metrics__section"
    data-automation="metrics-process-table"
  >
    <div class="sectionTitle small">
      {{ $t('admin.metrics.processes.title') }}
    </div>
    <RSTable
      :columns="tableHeaders"
      @sort="sortChanged"
    >
      <RSTableRow
        v-for="process in processes"
        :key="process.jobKey"
        :row-id="process.jobKey"
        :row-label="getRowLabel(process.appId, process.appName)"
        :class="{ disabled: isProcessDisabled(process) }"
        class="process-list__process-row"
      >
        <RSTableCell class="hideOnMobile">
          {{ process.hostname }}
        </RSTableCell>
        <RSTableCell
          :cell-id="process.jobKey"
        >
          <component
            :is="getRowClickable(process.appId) ? 'router-link' : 'span'"
            :to="{ name: 'apps.access', params: { idOrGuid: process.appGuid } }"
            :title="process.appName"
          >
            {{ process.appName }}
          </component>
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ jobDescription(process.type) }}
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ process.appRunAs }}
        </RSTableCell>
        <RSTableCell>
          {{ process.cpuCurrent | cpu }}
        </RSTableCell>
        <RSTableCell>
          {{ process.ram | humanizeBytesBinary }}
        </RSTableCell>
        <RSTableCell class="hideOnMobile">
          {{ process.startTime | fromNow }}
        </RSTableCell>
        <RSTableCell>
          <div class="process-list__actions">
            <router-link
              v-if="getRowClickable(process.appId)"
              :to="{ name: 'apps.logs', params: { idOrGuid: process.appGuid }, query: { logKey: process.jobKey } }"
              :title="$t('admin.metrics.processes.logAction')"
              target="_blank"
            >
              <i
                aria-hidden="true"
                class="rs-icon logIcon"
              />
              <span class="sr-only">{{ $t('admin.metrics.processes.logAction') }}</span>
            </router-link>
            <button
              v-if="canKillProcess(process)"
              :title="$t('admin.metrics.processes.killAction')"
              :disabled="isProcessDisabled(process)"
              class="process-list__kill-button"
              data-automation="process-list__kill-button"
              @click="confirmKillProcess(process)"
            >
              <i
                aria-hidden="true"
                class="rs-icon killIcon"
              />
              <span class="sr-only">{{ $t('admin.metrics.processes.killAction') }}</span>
            </button>
          </div>
        </RSTableCell>
      </RSTableRow>
    </RSTable>
    <div
      v-if="!hasProcesses"
      class="rsc-metrics__process-list--empty"
    >
      {{ $t('admin.metrics.processes.noProcesses') }}
    </div>
    <ConfirmModal
      v-if="confirm.show"
      :confirmation="$t('admin.metrics.processes.killConfirm.confirmation')"
      @confirm="killProcess(confirm.process)"
      @cancel="clearConfirm"
    >
      <EmbeddedStatusMessage
        type="warning"
        class="confirm-details__warning"
        :message="$t('admin.metrics.processes.killConfirm.warning')"
        :show-close="false"
      />

      <i18n
        path="admin.metrics.processes.killConfirm.details"
        tag="p"
      >
        <code class="confirm-details__process-type">{{ jobDescription(confirm.process.type) }}</code>
        <span class="confirm-details__process-app-name">{{ confirm.process.appName }}</span>
      </i18n>
    </ConfirmModal>
  </div>
</template>

<script>
import moment from 'moment-mini';

import RSTable from 'Shared/components/RSTable';
import RSTableRow from 'Shared/components/RSTableRow';
import RSTableCell from 'Shared/components/RSTableCell';
import ConfirmModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';

import { JobTags } from '@/api/dto/job';
import { getProcesses } from '@/api/metrics';
import { killJob } from '@/api/jobs';
import { humanizeBytesBinary } from '@/utils/bytes.filter';
import { setInfoMessage, setErrorMessageFromAPI } from '@/utils/status';
import { cancelableInterval } from '@/utils/promiseInterval';

const RFC_3339 = 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z';

export default {
  name: 'ProcessList',
  components: {
    RSTable,
    RSTableCell,
    RSTableRow,
    ConfirmModal,
    EmbeddedStatusMessage,
  },
  filters: {
    humanizeBytesBinary,
    fromNow(time) {
      return moment(time, RFC_3339).fromNow();
    },
    cpu(cpuCurrent) {
      if (isNaN(parseFloat(cpuCurrent)) || !isFinite(cpuCurrent)) {
        return '-';
      }

      return cpuCurrent.toFixed(2);
    },
  },
  props: {
    // Testing flag to keep network calls from being made
    shouldInit: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      api: {
        processes: [],
      },
      fetchProcessesAborter: new AbortController(),
      processes: [],
      disabledProcesses: [],
      sortingState: {
        column: 'cpuCurrent',
        direction: 'desc',
      },
      tableHeaders: [
        {
          name: 'hostname',
          label: this.$t('admin.metrics.processes.hostname'),
          class: 'hideOnMobile',
          sortable: true
        },
        {
          name: 'appName',
          label: this.$t('admin.metrics.processes.contentName'),
          sortable: true,
        },
        {
          name: 'type',
          label: this.$t('admin.metrics.processes.appType'),
          class: 'hideOnMobile',
          sortable: true,
        },
        {
          name: 'appRunAs',
          label: this.$t('admin.metrics.processes.user'),
          class: 'hideOnMobile',
          sortable: true,
        },
        {
          name: 'cpuCurrent',
          label: this.$t('admin.metrics.processes.cpu'),
          sortable: true, direction: 'desc'
        },
        {
          name: 'ram',
          label: this.$t('admin.metrics.processes.ram'),
          sortable: true
        },
        {
          name: 'startTime',
          label: this.$t('admin.metrics.processes.startTime'),
          class: 'hideOnMobile',
          sortable: true
        },
        {
          name: 'actions',
          label: this.$t('admin.metrics.processes.actions'),
          sortable: false,
          width: '50px',
        },
      ],
      confirm: {
        show: false,
        process: null,
      }
    };
  },
  computed: {
    hasProcesses() {
      return this.processes.length !== 0;
    },
  },
  created() {
    if (this.shouldInit) {
      this.init();
    }
  },
  beforeDestroy() {
    this.fetchProcessesAborter.abort();
  },
  methods: {
    init() {
      cancelableInterval(this.fetchProcesses, this.fetchProcessesAborter.signal, 6000);
    },
    async fetchProcesses() {
      return getProcesses()
        .then(processes => {
          this.api.processes = processes;
          this.processes = this.sortAndFilter(this.api.processes);
        })
        .catch(setErrorMessageFromAPI);
    },
    sortAndFilter(processes) {
      // TODO: Filter
      const { column, direction } = this.sortingState;
      function compare(process1, process2) {
        const invert = direction === 'desc' ? -1 : 1;
        // Time fields need to be sorted using moment
        if (column === 'startTime') {
          return invert *
            (moment(process1[column], RFC_3339) - moment(process2[column], RFC_3339));
        }
        // Type fields need to be sorted by their linguistic representation
        if (column === 'type') {
          return invert * (
            JobTags.of(process1[column]).description()
              .localeCompare(JobTags.of(process2[column]).description())
          );
        }
        // Assume that everything but cpu and ram are string sorted
        if (column !== 'cpuCurrent' && column !== 'ram') {
          return invert * process1[column].localeCompare(process2[column]);
        }
        // Assume numeric sort for everything else
        return invert * (process1[column] - process2[column]);
      }
      return processes.sort(compare);
    },
    sortChanged({ index, direction }) {
      if (direction === null) {
        direction = 'desc';
      }
      this.sortingState = {
        column: this.tableHeaders[index].name,
        direction: direction,
      };
      for (const index2 in this.tableHeaders) {
        this.tableHeaders[+index2].direction = (+index === +index2) ? direction : null;
      }
      this.processes = this.sortAndFilter(this.api.processes);
    },
    // eslint-disable-next-line no-shadow
    getRowLabel(appId, name) {
      return appId === '0'
        ? ''
        : this.$t('admin.metrics.processes.navigateToApp', { name });
    },
    getRowClickable(appId) {
      return appId !== '0';
    },
    // For testing
    columnNameToIndex(columnName) {
      return this.tableHeaders.findIndex(column => column.name === columnName);
    },
    jobDescription(tag) {
      return JobTags.of(tag).description();
    },
    isProcessDisabled(process) {
      return this.disabledProcesses.includes(process.jobKey);
    },
    disableProcess(process) {
      this.disabledProcesses.push(process.jobKey);
    },
    canKillProcess(process) {
      // Can only kill processes that belong to a content item and are not
      // environment restores.
      const processJobTag = JobTags.of(process.type);

      return process.appId !== '0' &&
        ![
          JobTags.PackratRestoreTag,
          JobTags.PythonRestoreTag,
        ].includes(processJobTag);
    },
    confirmKillProcess(process) {
      this.confirm = {
        show: true,
        process: process,
      };
    },
    clearConfirm() {
      this.confirm = {
        show: false,
        process: null,
      };
    },
    killProcess(process) {
      this.clearConfirm();

      killJob(process.appGuid, process.jobKey)
        .then(() => {
          this.disableProcess(process);
          setInfoMessage(this.$t('admin.metrics.processes.killSuccess', {
            type: this.jobDescription(process.type),
          }));
        })
        .catch(setErrorMessageFromAPI);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.process-list__process-row {
  &.disabled {
    opacity: 0.5;

    .process-list__kill-button {
      cursor: not-allowed;
    }
  }
}

.process-list__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 3px;

  a, button {
    padding: 0;
    background-color: inherit;

    &:hover {
      background-color: $color-light-grey-2;
    }

    .rs-icon {
      margin-right: 0;
    }
  }
}

.confirm-details__process-type {
  display: inline-block;
  padding: 0 3px;
}

.confirm-details__process-app-name {
  font-weight: bold;
}

.confirm-details__warning {
  margin-bottom: 1.0rem;
}

</style>
