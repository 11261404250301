// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiExperimentalPath } from '@/utils/paths';

export function getFeatureUsage() {
  return axios
    .get(apiExperimentalPath('feature_usage/'))
    .then(({ data }) => data);
}

export const Features = {
  OpenSolo: 'open_solo',
};

export function markFeatureUsed(feature) {
  // failures here are acceptable and may be safely ignored
  return axios
    .post(apiExperimentalPath(`feature_usage/${encodeURIComponent(feature)}`))
    .catch(() => {});
}
