// Copyright (C) 2022 by Posit Software, PBC.

import { ActionTypes } from './app-state.constants';
import { StatusTypes } from '@/store/redux/status/message-types';

const {
  SET_STATUS_MESSAGE,
  CLEAR_STATUS_MESSAGE,
  SHOW_NAV_PANEL,
  SHOW_USER_PANEL,
  SHRINK_HEADER,
  SCROLL_POS,
  TOGGLE_PARAMS_PANEL,
  SHOW_REPORT_PROGRESS,
  RELOADING_CONTENT_FRAME,
} = ActionTypes;

/**
 * Show the spinner with a message. Each page and component (on the page) can have
 * it's own spinner. This way hiding the spinner doesn't break another component's or
 * another page's activity messages.
 * @param {String} msg.pageId - TODO this should be optional and default to the
 * current url (from routerState).
 * @param {String} msg.compId - TODO this should be renamed to the more generic
 * messageId and should also be optional. It should default to a fixed value.
 * @param {String} msg.message
 * @param {Boolean} opts.dimContent - if true, will also dim the main content
 * @param {Number} opts.timeout - will show the spinner after timemout (in ms)
 * @param {Boolean} opts.contextualized - if unset defaults to displaying globally(in header)
 * @returns {ReduxAction}
 */
export function statusActivityMessage(
  { pageId, compId, message },
  { dimContent, timeout, contextualized } = {}
) {
  return {
    type: ActionTypes.SAGA_STATUS_ACTIVITY_MESSAGE,
    payload: {
      pageId,
      compId,
      message,
      opts: { dimContent, timeout, contextualized },
    },
  };
}

export function clearStatusActivityMessage({ pageId, compId }) {
  return {
    type: ActionTypes.SAGA_CLEAR_STATUS_ACTIVITY_MESSAGE,
    payload: {
      pageId,
      compId,
    },
  };
}

/**
 * Used internally to update redux state. See statusActivityMessage
 * @param {String} msg.pageId
 * @param {String} msg.compId
 * @param {String} msg.message
 * @param {Boolean} opts.dimContent
 * @param {Boolean} opts.contextualized
 * @returns {ReduxAction}
 * @private
 */
export function _updateStatusActivityMessage(
  { pageId, compId, message },
  { dimContent, contextualized } = {}
) {
  return {
    type: ActionTypes.SET_STATUS_ACTIVITY_MESSAGE,
    payload: {
      pageId,
      compId,
      message,
      opts: { dimContent, contextualized },
    },
  };
}

export function _clearStatusActivityMessage({ pageId, compId }) {
  return {
    type: ActionTypes.CLEAR_STATUS_ACTIVITY_MESSAGE,
    payload: {
      pageId,
      compId,
    },
  };
}

export function statusErrorMessage(message) {
  return {
    type: SET_STATUS_MESSAGE,
    payload: {
      message,
      statusType: StatusTypes.ERROR_MESSAGE,
    },
  };
}

export function statusInfoMessage(message) {
  return {
    type: SET_STATUS_MESSAGE,
    payload: {
      message,
      statusType: StatusTypes.INFO_MESSAGE,
    },
  };
}

export function statusWarningMessage(message) {
  return {
    type: SET_STATUS_MESSAGE,
    payload: {
      message,
      statusType: StatusTypes.HTTP_WARNING,
    },
  };
}

/**
 * Clears any pending status error/info/warning message. But not activity!!!
 * @returns {ReduxAction}
 */
export function clearStatusMessage() {
  return {
    type: CLEAR_STATUS_MESSAGE,
  };
}

export function showNavPanel(show) {
  return {
    type: SHOW_NAV_PANEL,
    show,
  };
}

export function showUserPanel(show) {
  return {
    type: SHOW_USER_PANEL,
    show,
  };
}

export function shrinkHeader(shrink) {
  return {
    type: SHRINK_HEADER,
    shrink,
  };
}

export function setScrollPosition({ top: topScroll }) {
  return {
    type: SCROLL_POS,
    payload: { top: topScroll },
  };
}

/**
 * This is not the visibility action you're looking for. You want
 * #tryToSetPanelVisibility or #tryToTogglePanelVisibility.
 * @param {Object} args
 * @param {boolean} args.show
 * @returns {action}
 * @private
 */
export function _setParamsPanelVisibility({ show }) {
  return {
    type: TOGGLE_PARAMS_PANEL,
    payload: { show },
  };
}

/**
 * It *tries* to change which panel is visible because if the input
 * panel is open and dirty, it'll ask the user if she wants to
 * close the input panel. If the user says "No", then the action
 * gets cancelled.
 * @param {Object} args
 * @param {PanelType} args.panelType
 * @param {boolean} args.show
 * @returns {action}
 */
export function tryToSetPanelVisibility({ panelType, show }) {
  return {
    type: ActionTypes.SAGA_SET_PANEL_VISIBILITY,
    payload: { panelType, show },
  };
}

/**
 * See #tryToSetPanelVisibility.
 *
 * @param {Object} args
 * @param {PanelType} args.panelType
 * @returns {action}
 */
export function tryToTogglePanelVisibility({ panelType }) {
  return {
    type: ActionTypes.SAGA_TOGGLE_PANEL_VISIBILITY,
    payload: { panelType },
  };
}

export function showReportProgress({ appId, variantId }) {
  return {
    type: SHOW_REPORT_PROGRESS,
    payload: {
      show: true,
      appId,
      variantId,
    },
  };
}

export function hideReportProgress({ appId, variantId }) {
  return {
    type: SHOW_REPORT_PROGRESS,
    payload: {
      show: false,
      appId,
      variantId,
    },
  };
}

export function showSubscriptionsModal() {
  return {
    type: ActionTypes.SHOW_SUBSCRIPTIONS,
  };
}

export function hideSubscriptionsModal() {
  return {
    type: ActionTypes.HIDE_SUBSCRIPTIONS,
  };
}

export function setContentFrameReload(flag) {
  return {
    type: RELOADING_CONTENT_FRAME,
    payload: {
      flag,
    },
  };
}
