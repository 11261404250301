<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    data-automation="new-api-key-confirmation"
    :active="true"
    :subject="$t('apiKeys.create.newKeyTitle', { name: apiKey.name })"
    @close="$emit('close')"
  >
    <template #content>
      <i18n
        path="apiKeys.create.confirmation"
        tag="p"
      >
        <a
          href="#"
          target="_blank"
          @click.prevent="copyToClipboard"
        >
          {{ $t('apiKeys.create.copyNow') }}
        </a>
      </i18n>
      <input
        class="key-value"
        type="text"
        data-automation="new-api-key-value"
        :aria-label="$t('apiKeys.create.newKeyAria', { name: apiKey.name })"
        :value="apiKey.key"
        readonly="true"
      >
      <EmbeddedStatusMessage
        v-if="showKeyCopied"
        type="info"
        data-automation="key-copied-msg"
        :show-close="false"
        :message="$t('apiKeys.create.copiedMsg')"
      />
    </template>

    <template #controls>
      <RSButton
        data-automation="api-key-copy"
        :label="$t('apiKeys.create.copyBtnLabel')"
        @click="copyToClipboard"
      />
    </template>
  </RSModal>
</template>

<script>
import { copyToClipboard } from '@/utils/clipboard';
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';

const COPIED_MSG_TIMEOUT = 5000;

export default {
  name: 'NewKeyConfirmation',
  components: {
    RSModal,
    RSButton,
    EmbeddedStatusMessage,
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showKeyCopied: false,
      copiedTimeout: null,
    };
  },
  methods: {
    async copyToClipboard() {
      await copyToClipboard({ value: this.apiKey.key });
      this.showKeyCopied = true;
      this.handleMsgTimeout();
    },
    handleMsgTimeout() {
      // avoid timeout callbacks bleeding if btn is clicked many times
      if (this.copiedTimeout) {
        clearInterval(this.copiedTimeout);
      }
      this.copiedTimeout = setTimeout(() => {
        this.showKeyCopied = false;
      }, COPIED_MSG_TIMEOUT);
    },
  },
};
</script>

<style lang="scss" scoped>
.key-value {
  margin: 0.8rem 0;
  text-align: center;
  width: 100%;
}
</style>
