// Copyright (C) 2023 by Posit Software, PBC.
import { cloneDeep } from 'lodash';
import { keysToCamel } from '@/api/transform';

// ScheduleTypes holds singleton values and a construction function for raw
// schedule type values.
export const ScheduleTypes = {
  Minute: 'minute',
  Hour: 'hour',
  Day: 'day',
  Week: 'week',
  Weekday: 'weekday',
  DayOfWeek: 'dayofweek',
  SemiMonth: 'semimonth',
  DayOfMonth: 'dayofmonth',
  DayweekOfMonth: 'dayweekofmonth',
  Year: 'year',

  categoryOf(sched) {
    switch (sched) {
      case ScheduleTypes.Minute:
        return ScheduleTypes.Minute;
      case ScheduleTypes.Hour:
        return ScheduleTypes.Hour;
      case ScheduleTypes.Day:
      case ScheduleTypes.Weekday:
        return ScheduleTypes.Day;
      case ScheduleTypes.Week:
      case ScheduleTypes.DayOfWeek:
        return ScheduleTypes.Week;
      case ScheduleTypes.SemiMonth:
        return ScheduleTypes.SemiMonth;
      case ScheduleTypes.DayOfMonth:
      case ScheduleTypes.DayweekOfMonth:
        return ScheduleTypes.DayOfMonth;
      case ScheduleTypes.Year:
        return ScheduleTypes.Year;
    }
  }
};

// DaysOfWeek holds singleton values and a construction function for raw
// day of week type values.
export const DaysOfWeek = {
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',

  options() {
    return [
      DaysOfWeek.Sunday,
      DaysOfWeek.Monday,
      DaysOfWeek.Tuesday,
      DaysOfWeek.Wednesday,
      DaysOfWeek.Thursday,
      DaysOfWeek.Friday,
      DaysOfWeek.Saturday,
    ];
  },

  valueOf(day) {
    return DaysOfWeek.options().indexOf(day);
  },

  stringOf(day) {
    return DaysOfWeek.options()[day];
  },
};

// OrdinalMonthWeek holds singleton values and a construction function for raw
// ordinal week type values.
export const OrdinalMonthWeeks = {
  First: 'first',
  Second: 'second',
  Third: 'third',
  Fourth: 'fourth',
  Fifth: 'fifth',

  options() {
    return [
      OrdinalMonthWeeks.First,
      OrdinalMonthWeeks.Second,
      OrdinalMonthWeeks.Third,
      OrdinalMonthWeeks.Fourth,
      OrdinalMonthWeeks.Fifth,
    ];
  },

  valueOf(weekStr) {
    switch (weekStr) {
      case OrdinalMonthWeeks.First:
        return 1;
      case OrdinalMonthWeeks.Second:
        return 2;
      case OrdinalMonthWeeks.Third:
        return 3;
      case OrdinalMonthWeeks.Fourth:
        return 4;
      case OrdinalMonthWeeks.Fifth:
        return 5;
    }
  },

  stringOf(weekNum) {
    switch (weekNum) {
      case 1:
        return OrdinalMonthWeeks.First;
      case 2:
        return OrdinalMonthWeeks.Second;
      case 3:
        return OrdinalMonthWeeks.Third;
      case 4:
        return OrdinalMonthWeeks.Fourth;
      case 5:
        return OrdinalMonthWeeks.Fifth;
    }
  },
};

// SemiMonthOptions holds singleton values and a construction function for raw
// semi month option type values.
export const SemiMonthOptions = {
  First: 'first',
  Last: 'last',
};

export class Schedule {
  constructor({
    id = null,
    appId,
    variantId,
    activate = null,
    email = null,
    type = null,
    schedule = null,
    timezone = null,
    startTime = null,
    nextRun = null,
  } = {}) {
    this.id = id;
    this.appId = appId;
    this.variantId = variantId;
    this.activate = activate;
    this.email = email;
    this.type = type;
    this.schedule = schedule;
    this.timezone = timezone;
    this.startTime = new Date(startTime);
    this.nextRun = new Date(nextRun);
    this.specifics = {};
  }

  static fromRedux(reduxVariant) {
    const clone = keysToCamel(cloneDeep(reduxVariant));
    // After keys to camel, the redux moment instances have dates as xyz.d
    if (clone.startTime.isAMomentObject) {
      clone.startTime = clone.startTime.d;
    }
    if (clone.nextRun.isAMomentObject) {
      clone.nextRun = clone.nextRun.d;
    }
    return new Schedule(clone);
  }

  toJSON() {
    this.buildScheduleString();

    return {
      id: this.id,
      appId: this.appId,
      variantId: this.variantId,
      activate: this.activate,
      email: this.email,
      type: this.type,
      schedule: this.schedule,
      timezone: this.timezone,
      startTime: this.startTime,
      nextRun: this.nextRun,
    };
  }

  toString() {
    return `Schedule(${this.id})`;
  }

  setSpecifics(options = {}) {
    this.specifics = options;
  }

  buildScheduleString() {
    let schedObj = { N: this.specifics.interval };

    switch (this.type) {
      case ScheduleTypes.Weekday:
        schedObj = {};
        break;
      case ScheduleTypes.DayOfWeek:
        schedObj = { Days: this.specifics.days };
        break;
      case ScheduleTypes.SemiMonth:
        schedObj = { First: this.specifics.semimonth === SemiMonthOptions.First };
        break;
      case ScheduleTypes.DayOfMonth:
        schedObj.Day = this.specifics.day;
        break;
      case ScheduleTypes.DayweekOfMonth:
        schedObj.Day = this.specifics.day;
        schedObj.Week = this.specifics.week;
        break;
    }

    this.schedule = JSON.stringify(schedObj);
  }
}
