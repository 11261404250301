// Copyright (C) 2022 by Posit Software, PBC.

const metrics = {
  featureUsage: {
    title: 'Usage Scorecard',
    usageInfo: [
      `
        The usage scorecard indicates what top line Posit Connect features
        you have used.
      `,
      `
        To help improve Connect, you can send Posit this usage information
        from time to time by copying the scorecard and emailing us.
      `,
    ],
    dataLoadError: 'Unable to load usage scorecard data.',
    notifyCopy: {
      purpose: 'Usage Scorecard Copied',
      message: {
        email: `
          The usage scorecard data has been copied. Please paste it into an
          email and send it to {mailto}.
        `,
        thanks: `
          Thank you for making Posit Connect better!
        `,
        privacy: `
          Learn more about our privacy policy: {policy}
        `,
      },
    },
  },
};

export { metrics };
