<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Renders the metrics view
-->
<template>
  <div data-automation="metrics">
    <div class="flex">
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        {{ $t('admin.metrics.title') }}
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-if="loading"
      data-automation="loading"
      :show-close="false"
      :message="$t('admin.metrics.loading')"
      type="activity"
    />
    <div v-else>
      <div
        v-if="!isMetricsEnabled"
        data-automation="metrics-disabled"
        class="rsc-metrics__section"
      >
        <div class="sectionTitle small">
          {{ $t('admin.metrics.noMetrics') }}
        </div>
      </div>
      <div v-else>
        <div class="rsc-metrics__filter-header">
          <TimeframeSelector
            class="rsc-metrics__input"
            @change="timeframeChanged"
          />
          <div class="rsc-metrics__info">
            <div>{{ $t('admin.timeInfo', { offset }) }}</div>
            <div class="rsc-metrics__info__server-name">
              {{ $t('admin.metrics.server', { name: serverSettings.hostname }) }}
            </div>
          </div>
        </div>

        <div
          class="rsc-metrics__graphs"
          data-automation="metrics-charts"
        >
          <div
            v-for="(chart, index) in chartList"
            :key="index"
            :data-automation="`metrics-${chart.type}`"
            class="rsc-metrics__graph"
          >
            <ChartHeader
              :type="chart.type"
              :max="chart.max"
              :value="chart.value"
            />
            <MetricsChart
              :data="chart.data"
              :options="chart.options"
            />
          </div>
        </div>

        <FeatureUsage
          :version="serverSettings.version"
          class="rsc-metrics__section"
        />
      </div>

      <ProcessList />
    </div>
  </div>
</template>

<script>
import ChartHeader from './ChartHeader';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import FeatureUsage from './FeatureUsage';
import MetricsChart from './MetricsChart';
import ProcessList from './ProcessList';
import TimeframeSelector from './TimeframeSelector';
import { MetricsSocket, socketEventTransformer } from './webSocket';
import { getHistoricData } from '@/api/metrics';
import { getHistoricParams, getOptions, transformData } from './chartHelper';
import { localOffset } from '@/utils/timezone';
import { mapState } from 'vuex';
import { setErrorMessageFromAPI } from '@/utils/status';

export default {
  name: 'MetricsView',
  components: {
    EmbeddedStatusMessage,
    ChartHeader,
    FeatureUsage,
    MetricsChart,
    ProcessList,
    TimeframeSelector,
  },
  data() {
    return {
      loading: true,
      offset: localOffset(),
      // timeframe default values must match the defaults inside the TimeframeSelector component
      timeframe: { unit: 1, range: 'days' },
      charts: {
        cpu: { data: { datasets: [] }, options: {} },
        ram: { data: { datasets: [] }, options: {} },
        namedUsers: { data: { datasets: [] }, options: {} },
        shinyConnections: { data: { datasets: [] }, options: {} },
      },
      gauges: {
        cpu: { max: 1, value: 0 },
        ram: { max: 1, value: 0 },
        namedUsers: { max: 0, value: 0 },
        shinyConnections: { max: 0, value: 0 },
      },
    };
  },
  computed: {
    isMetricsEnabled() {
      return this.serverSettings.metricsRrdEnabled;
    },
    chartList() {
      return [
        {
          type: 'cpu',
          data: this.charts.cpu.data,
          options: this.charts.cpu.options,
          max: this.gauges.cpu.max,
          value: this.gauges.cpu.value,
        },
        {
          type: 'ram',
          data: this.charts.ram.data,
          options: this.charts.ram.options,
          max: this.gauges.ram.max,
          value: this.gauges.ram.value,
        },
        {
          type: 'namedUsers',
          data: this.charts.namedUsers.data,
          options: this.charts.namedUsers.options,
          max: this.gauges.namedUsers.max,
          value: this.gauges.namedUsers.value,
        },
        {
          type: 'shinyConnections',
          data: this.charts.shinyConnections.data,
          options: this.charts.shinyConnections.options,
          max: this.gauges.shinyConnections.max,
          value: this.gauges.shinyConnections.value,
        },
      ];
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    })
  },
  created() {
    this.socket = null;
    this.init();
  },
  mounted() {
    this.$refs.title.focus();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
  },
  methods: {
    async init() {
      if (this.isMetricsEnabled) {
        await this.populateCharts();
        this.socket = new MetricsSocket(this.socketHandler);
      }
      this.loading = false;
    },
    populateCharts() {
      this.chartList.forEach(({ type }) => {
        const params = getHistoricParams(type, this.timeframe);
        getHistoricData(params)
          .then(data => {
            this.charts[type].options = getOptions(type, this.timeframe);
            this.charts[type].data = transformData(type, data);
          })
          .catch(setErrorMessageFromAPI);
      });
    },
    socketHandler(evt) {
      const charts = socketEventTransformer(evt, this.timeframe, this.charts);
      charts.forEach(({ type, data, gauge }) => {
        this.gauges[type] = gauge;
        this.charts[type].data = data;
      });
    },
    timeframeChanged(newTimeframe) {
      this.timeframe = newTimeframe;
      this.populateCharts();
    },
  },
};
</script>
