// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { keysToCamel } from '@/api/transform';
import { apiExperimentalPath, apiPath, serverPath } from '@/utils/paths';

export const getFeatureUsage = () => axios.get(apiExperimentalPath('feature_usage/')).then(({ data }) => data);

export const getProcesses = () => {
  return axios
    .get(apiPath('metrics/procs'))
    .then(({ data }) => data.map(proc => keysToCamel(proc)));
};

export const getHistoricData = params => {
  // data comes over the unix domain socket from the rserver-monitor program
  // https://github.com/rstudio/rstudio-pro/blob/bc5f67fe150a806bedfddca26886dc6db6055563/src/cpp/monitor/http/Server.cpp#L226-L228
  return axios
    .get(serverPath('__metrics__/rrd_export_flot'), { params })
    .then(({ data }) => data);
};

export const getToken = () => axios.get(serverPath('__token__')).then(({ data }) => data);
