// Copyright (C) 2023 by Posit Software, PBC.

import {
  createVariantOverrides,
  getVariantOverrides,
  duplicateVariant,
  promoteVariantOverrides,
  renderVariant,
  deleteVariant,
  promoteVariant,
} from '@/api/parameterization';
import {
  PARAMETERIZATION_ADD_VARIANT,
  PARAMETERIZATION_FETCH_VARIANTS,
  PARAMETERIZATION_SET_INITIAL_VARIANT,
  PARAMETERIZATION_SELECT_VARIANT,
  PARAMETERIZATION_SELECT_DEFAULT_VARIANT,
} from '@/store/modules/parameterization';
import { setErrorMessage } from '@/utils/status';
import { taskToPromise } from '@/api/tasks';
import { VISIBILITY_ADHOC } from '@/constants/variants';

export const LEGACY_PARAMS_SET_NEW = 'LEGACY_PARAMS_SET_NEW';
export const LEGACY_PARAMS_SET_BUSY = 'LEGACY_PARAMS_SET_BUSY';
export const LEGACY_PARAMS_SET_ERROR = 'LEGACY_PARAMS_SET_ERROR';
export const LEGACY_PARAMS_SET_OVERRIDES = 'LEGACY_PARAMS_SET_OVERRIDES';
export const LEGACY_PARAMS_SET_ADHOC_RUN = 'LEGACY_PARAMS_SET_ADHOC_RUN';
export const LEGACY_PARAMS_SET_RENAMING_VARIANT = 'LEGACY_PARAMS_SET_RENAMING_VARIANT';
export const LEGACY_PARAMS_SET_SAVING_VARIANT = 'LEGACY_PARAMS_SET_SAVING_VARIANT';
export const LEGACY_PARAMS_UPDATE_FORM = 'LEGACY_PARAMS_UPDATE_FORM';
export const LEGACY_PARAMS_SET_UNSAVED_MODAL = 'LEGACY_PARAMS_SET_UNSAVED_MODAL';
export const LEGACY_PARAMS_NEW = 'LEGACY_PARAMS_NEW';
export const LEGACY_PARAMS_START = 'LEGACY_PARAMS_START';
export const LEGACY_PARAMS_CLEAR = 'LEGACY_PARAMS_CLEAR';
export const LEGACY_PARAMS_SAVE_PARAMS = 'LEGACY_PARAMS_SAVE_PARAMS';
export const LEGACY_PARAMS_RUN_REPORT = 'LEGACY_PARAMS_RUN_REPORT';
export const LEGACY_PARAMS_REVERT_CHANGES = 'LEGACY_PARAMS_REVERT_CHANGES';
export const LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES = 'LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES';
export const LEGACY_PARAMS_SAVE_VARIANT = 'LEGACY_PARAMS_SAVE_VARIANT';
export const LEGACY_PARAMS_SAVE_AS_VARIANT = 'LEGACY_PARAMS_SAVE_AS_VARIANT';
export const LEGACY_PARAMS_DELETE_VARIANT = 'LEGACY_PARAMS_DELETE_VARIANT';

export const defaultState = () => ({
  isNew: false,
  isBusy: false,
  renamingVariant: false,
  savingVariant: false,
  adHocRun: {},
  form: {
    loaded: false,
    dirty: false,
  },
  overrides: {},
  unsavedChanges: {
    showModal: false,
    onIgnore: () => {},
  },
});

export default {
  state: defaultState(),
  mutations: {
    [LEGACY_PARAMS_SET_NEW]: (state, val) => {
      state.isNew = val;
    },
    [LEGACY_PARAMS_SET_BUSY]: (state, busy) => {
      state.isBusy = busy;
    },
    [LEGACY_PARAMS_SET_OVERRIDES]: (state, overrides) => {
      state.overrides = overrides;
    },
    [LEGACY_PARAMS_SET_ADHOC_RUN]: (state, adHocRun) => {
      state.adHocRun = adHocRun;
    },
    [LEGACY_PARAMS_UPDATE_FORM]: (state, updates) => {
      state.form = {
        ...state.form,
        ...updates,
      };
    },
    [LEGACY_PARAMS_CLEAR]: state => {
      Object.assign(state, defaultState());
    },
    [LEGACY_PARAMS_SET_RENAMING_VARIANT]: (state, flag) => {
      state.renamingVariant = flag;
    },
    [LEGACY_PARAMS_SET_SAVING_VARIANT]: (state, flag) => {
      state.savingVariant = flag;
    },
    [LEGACY_PARAMS_SET_UNSAVED_MODAL]: (state, { show, ignoreCallback = () => {} }) => {
      state.unsavedChanges.showModal = show;
      state.unsavedChanges.onIgnore = ignoreCallback;
    },
  },
  actions: {
    [LEGACY_PARAMS_SET_ERROR]: (state, err) => {
      setErrorMessage(err);
    },
    [LEGACY_PARAMS_START]: ({ commit }, variantId) => {
      return createVariantOverrides(variantId)
        .then(overrides => {
          commit(LEGACY_PARAMS_SET_OVERRIDES, overrides);
          return overrides;
        });
    },
    [LEGACY_PARAMS_SAVE_PARAMS]: ({ commit, state }, variantId) => {
      const iframe = document.getElementById('parameters-iframe');

      if (!iframe) {
        return Promise.reject({ message: 'parameters UI has not loaded.' });
      }

      return new Promise((resolve, reject) => {
        const get = ({ retries, delay }) => {
          getVariantOverrides(variantId, state.overrides.id).then(overrides => {
            if (overrides.version > state.overrides.version) {
              commit(LEGACY_PARAMS_SET_OVERRIDES, overrides);
              resolve(overrides);
            } else if (retries > 0) {
              setTimeout(() => get({ retries: retries - 1, delay }), delay);
            } else {
              reject(new Error('Unable to save variant parameters'));
            }
          });
        };

        // this is the actual save command. It's done via the shiny form
        iframe.contentWindow.postMessage({ type: 'customshiny:save' }, '*');

        // We know that Shiny has issued its "disconnected" event. Fetch the
        // overrides until we see a new version, which means we have the
        // output from the shiny customization application.
        get({ retries: 20, delay: 500 });
      });
    },
    [LEGACY_PARAMS_RUN_REPORT]: async({ commit, dispatch, state }, variantId) => {
      commit(LEGACY_PARAMS_SET_BUSY, true);

      // Setting frame form as un-loaded because it'll disconnect,
      // when run finishes and frame form is ready, it'll get back as loaded.
      commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });

      if (state.form.dirty) {
        await dispatch(LEGACY_PARAMS_SAVE_PARAMS, variantId);
      }

      const newAdHocVariant = await duplicateVariant(variantId, '', VISIBILITY_ADHOC);
      await promoteVariantOverrides(newAdHocVariant.id, state.overrides.id);

      const task = await renderVariant(newAdHocVariant.id);

      return taskToPromise(task.id, data => {
        if (!data.finished) {
          return data;
        }

        if (data.code === 0) {
          // Report successfully completed
          commit(LEGACY_PARAMS_SET_ADHOC_RUN, newAdHocVariant);
        } else {
          // Report failed
          dispatch(LEGACY_PARAMS_SET_ERROR, data.error);
        }

        commit(LEGACY_PARAMS_SET_BUSY, false);
        return data;
      });
    },
    [LEGACY_PARAMS_NEW]: async({ commit, dispatch, rootState }) => {
      const { id: variantId } = rootState.parameterization.currentVariant;
      const newAdHocVariant = await duplicateVariant(variantId, 'Untitled', VISIBILITY_ADHOC);
      commit(LEGACY_PARAMS_CLEAR);
      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, newAdHocVariant.appId);
      commit(PARAMETERIZATION_ADD_VARIANT, newAdHocVariant);
      dispatch(PARAMETERIZATION_SELECT_VARIANT, newAdHocVariant.id);
      return dispatch(LEGACY_PARAMS_START, newAdHocVariant.id).then(() => {
        commit(LEGACY_PARAMS_SET_NEW, true);
      });
    },
    [LEGACY_PARAMS_REVERT_CHANGES]: async({ commit, dispatch, state, rootState }) => {
      const { isNew } = state;
      const { id: variantId } = rootState.parameterization.currentVariant;
      commit(LEGACY_PARAMS_CLEAR);
      return dispatch(LEGACY_PARAMS_START, variantId).then(() => {
        commit(LEGACY_PARAMS_SET_NEW, isNew);
      });
    },
    [LEGACY_PARAMS_CONFIRM_IGNORE_CHANGES]({ commit, dispatch, state, rootState }) {
      const { id: appId } = rootState.contentView.app;
      const { currentVariant } = rootState.parameterization;
      const callback = state.unsavedChanges.onIgnore;

      commit(LEGACY_PARAMS_CLEAR);

      // We run the original method (mutation, action or navigation)
      // attempted to run before confirming to ignore changes.
      callback();

      // We want to use existing selected variant only if:
      // - is not a new variant, new variants are ad-hoc and untitled
      if (!state.isNew) {
        commit(PARAMETERIZATION_SET_INITIAL_VARIANT, currentVariant.id);
      }
      return dispatch(PARAMETERIZATION_FETCH_VARIANTS, appId);
    },
    [LEGACY_PARAMS_SAVE_VARIANT]: async({ commit, dispatch, state, rootState }) => {
      const { id: variantId } = rootState.parameterization.currentVariant;
      const { adHocRun } = state;

      // Setting frame form as un-loaded because it'll disconnect
      commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });

      await dispatch(LEGACY_PARAMS_SAVE_PARAMS, variantId);
      await promoteVariantOverrides(variantId, state.overrides.id);

      if ('key' in adHocRun) {
        await promoteVariant(variantId, adHocRun.key);
      }

      commit(LEGACY_PARAMS_CLEAR);
      return dispatch(LEGACY_PARAMS_START, variantId);
    },
    [LEGACY_PARAMS_SAVE_AS_VARIANT]: async(
      { commit, dispatch, state, rootState },
      { name, visibility }, // eslint-disable-line no-shadow
    ) => {
      const { currentVariant } = rootState.parameterization;
      const { adHocRun } = state;

      // Setting frame form as un-loaded because it'll disconnect,
      commit(LEGACY_PARAMS_UPDATE_FORM, { loaded: false });
      await dispatch(LEGACY_PARAMS_SAVE_PARAMS, currentVariant.id);

      const newVariant = await duplicateVariant(currentVariant.id, name, visibility);

      await promoteVariantOverrides(newVariant.id, state.overrides.id);

      if ('key' in adHocRun) {
        await promoteVariant(newVariant.id, adHocRun.key);
      }

      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, newVariant.appId);
      await dispatch(PARAMETERIZATION_SELECT_VARIANT, newVariant.id);
      return newVariant;
    },
    [LEGACY_PARAMS_DELETE_VARIANT]: async({ dispatch, rootState }) => {
      const { id: variantId, appId } = rootState.parameterization.currentVariant;

      await deleteVariant(variantId);
      await dispatch(PARAMETERIZATION_FETCH_VARIANTS, appId);
      return dispatch(PARAMETERIZATION_SELECT_DEFAULT_VARIANT);
    },
  },
};
