// Copyright (C) 2022 by Posit Software, PBC.

const appModeStrings = {
  0: 'unknown',
  1: 'shiny',
  2: 'rmd-shiny',
  3: 'rmd-static',
  4: 'static',
  5: 'api',
  6: 'tensorflow-saved-model',
  7: 'jupyter-static',
  8: 'python-api',
  9: 'python-dash',
  10: 'python-streamlit',
  11: 'python-bokeh',
  12: 'python-fastapi',
  13: 'quarto-shiny',
  14: 'quarto-static',
  15: 'python-shiny',
  16: 'jupyter-voila'
};

// AppMode reflects the backend type `AppMode`.  It is used to specify the
// type of an application.
export class AppMode {
  constructor(value) {
    this._value = value;
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  toString() {
    return appModeStrings[this._value];
  }
}

// AppModes holds singleton values and a construction function for raw app mode
// values.
const AppModes = {
  Unknown: new AppMode(0),
  Shiny: new AppMode(1),
  ShinyRmd: new AppMode(2),
  StaticRmd: new AppMode(3),
  Static: new AppMode(4),
  PlumberApi: new AppMode(5),
  TensorFlowApi: new AppMode(6),
  StaticJupyter: new AppMode(7),
  PythonApi: new AppMode(8),
  PythonDash: new AppMode(9),
  PythonStreamlit: new AppMode(10),
  PythonBokeh: new AppMode(11),
  PythonFastAPI: new AppMode(12),
  ShinyQuarto: new AppMode(13),
  StaticQuarto: new AppMode(14),
  PythonShiny: new AppMode(15),
  JupyterVoila: new AppMode(16),

  // eslint-disable-next-line complexity
  of(value) {
    switch (value) {
      case 0:
      case 'unknown':
        return AppModes.Unknown;
      case 1:
      case 'shiny':
        return AppModes.Shiny;
      case 2:
      case 'rmd-shiny':
        return AppModes.ShinyRmd;
      case 3:
      case 'rmd-static':
        return AppModes.StaticRmd;
      case 4:
      case 'static':
        return AppModes.Static;
      case 5:
      case 'api':
        return AppModes.PlumberApi;
      case 6:
      case 'tensorflow-saved-model':
        return AppModes.TensorFlowApi;
      case 7:
      case 'jupyter-static':
        return AppModes.StaticJupyter;
      case 8:
      case 'python-api':
        return AppModes.PythonApi;
      case 9:
      case 'python-dash':
        return AppModes.PythonDash;
      case 10:
      case 'python-streamlit':
        return AppModes.PythonStreamlit;
      case 11:
      case 'python-bokeh':
        return AppModes.PythonBokeh;
      case 12:
      case 'python-fastapi':
        return AppModes.PythonFastAPI;
      case 13:
      case 'quarto-shiny':
        return AppModes.ShinyQuarto;
      case 14:
      case 'quarto-static':
        return AppModes.StaticQuarto;
      case 15:
      case 'python-shiny':
        return AppModes.PythonShiny;
      case 16:
      case 'jupyter-voila':
        return AppModes.JupyterVoila;
      default:
        return AppModes.Unknown;
    }
  },
};

export default AppModes;
