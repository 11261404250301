// Copyright (C) 2022 by Posit Software, PBC.

export default {
  redux: {
    status: {
      set: 'redux-status-set',
      clear: 'redux-status-clear',
    },
    activity: {
      set: 'redux-activity-set',
      clear: 'redux-activity-clear',
    },
    error: {
      set: 'redux-error-set',
    },
    variants: {
      add: 'redux-variants-add',
    },
    toggleParametersPanel: 'toggle-parameters-panel',
    refreshApp: 'refresh-app',
  },
};
