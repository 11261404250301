// Copyright (C) 2023 by Posit Software, PBC.

const apiKeys = {
  title: 'Llaves API',
  newKey: 'Nueva llave API',
  newKeyAria: 'Agregar nueva llave API',
  noKeysMsg: `No tienes ninguna llave API. Para crear una, presiona el botón 'Nueva llave API'.`,
  about: {
    title: 'Acerca de las llaves API',
    impersonateWarning: 'Cuida tu llave API como a una contraseña. Cualquier portador de tu llave API se puede hacer pasar por ti.',
    description: `Las llaves API te permiten hacer peticiones a {systemName}
      con los mismos privilegios que tienes al tener una sesión activa en un explorador.
      Por ejemplo, es posible usar estas llaves para automatizar peticiones
      a contenido para el cual necesitas autenticación.`,
    docsRef: 'Visita la {0} para mas detalles.',
    linkLabel: 'documentación sobre llaves API',
  },
  table: {
    rowLabel: 'Llave API {name}',
    deleteKeyLabel: 'Elimina la llave API {name}',
    cols: {
      name: 'Nombre',
      key: 'Llave',
      created: 'Creación',
      lastUsed: 'Úlitmo uso',
    },
  },
  create: {
    title: 'Crear llave API',
    nameInputLabel: 'Introduce un nombre para tu nueva llave API',
    createBtnLabel: 'Crear llave',
    nameIsRequired: 'El nombre es requerido',
    newKeyTitle: 'Llave API: {name}',
    confirmation: `Ésta es tu nueva llave. {0} Es la única vez que se mostrará.`,
    copyNow: '¡Cópiala ahora!',
    newKeyAria: 'Tu nueva llave API {name}',
    copiedMsg: 'Tu llave ha sido copiada.',
    copyBtnLabel: 'Copiar',
  },
  delete: {
    title: 'Eliminar llave API {name}',
    text: '¿Estás seguro de querer eliminar la llave API "{name}"?',
    btnLabel: 'Elminar llave',
    keyDeleted: 'La llave API has sido eliminada.',
  },
};

export { apiKeys };
