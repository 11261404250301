// Copyright (C) 2022 by Posit Software, PBC.

export const content = {
  titles: {
    deployContent: 'Contenido Nuevo de un Repositorio Git',
  },
  view: {
    undeployed: {
      title: 'Este {type} se encuentra incompleto.',
      msg: 'Este {type} no se a desplegado. Por favor intenta publicando nuevamente.',
      checkLogs: 'Visita la sección de {logs} en busca de errores en caso de que la publicación falle.',
    },
    unrendered: {
      title: 'El resultado no se ha reproducido.',
      checkLogs: `Visita la sección de {logs} en busca de errores en caso de que el documento
        falle en reproducirse nuevamente.
      `,
      notParam: `Puedes reproducir el contenido al darle click en el botón {refresh}
      que se encuentra a la derecha en la parte superior. Es posible programar el reporte
      para que sea ejecutado automaticamente al dar click en la pestaña de {sched} en las
      opciones del contenido a la derecha.`,
      param: `Puedes reproducir el contenido al darle click en el botón {refresh}
      que se encuentra a la derecha en la parte superior ó también abriendo la sección de {input}
      a la izquierda y presionando el botón {run}. Es posible programar el reporte
      para que sea ejecutado automaticamente al dar click en la pestaña de {sched} en las
      opciones del contenido a la derecha.`,
      redeploy: `Si la ejecución de este contenido falla continuamente, es posible que el
      código fuente presente un error ó que no sea compatible con el ambiente al cual se publicó.
      Intente publicar nuevamente después de resolver el problema.`,
    },
    blank: {
      message: '[es] There are no personal runs for this content.'
    },
    unpublished: {
      title: 'Este {type} no se ha publicado.',
      publish: `Pro favor confirma la configuración para accesar el contenido 
      y usa el botón de {publish} para publicar el contenido.`,
      adminAction: `Los administradores pueden visitar la página {unpublishedLink}
      para ver una lista de todo el contenido no publicado.`,
    },
    runReport: 'Ejecutar reporte',
    runningReport: 'Ejecutando reporte',
    reloadingContent: 'Recargando contenido',
    refreshReport: 'Recargar reporte',
    unpublishedItems: 'elementos sin publicar',
    unpublishedPage: 'página de elementos sin publicar',
    pamReauthModal: {
      loginAgain: 'Ingresa nuevamente',
      message: `La aplicación actual esta solicitando que ingreses nuevamente.
        Presiona OK para ir a la página de inicio de sesión, o cierra este mensage y dirigete
        a la vista de la aplicación.`,
    },
  },
};
