// Copyright (C) 2022 by Posit Software, PBC.

import {
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLOT,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_STREAMLIT,
  PYTHON_BOKEH,
  PYTHON_SHINY,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_R,
  QUARTO_PYTHON,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  R_VETIVER,
  SHINY_APP,
  SHINY_RMARKDOWN,
  TENSOR_FLOW,
} from '@/constants/contentTypes';

export const content = {
  types: {
    [JUPYTER_NOTEBOOK]: {
      name: 'Jupyter Notebook',
      runAction: 'TODO',
    },
    [JUPYTER_VOILA]: {
      name: 'Voilà Dashboard',
      runAction: null
    },
    [PIN]: {
      name: 'Pin',
      runAction: 'Knit',
    },
    [PLOT]: {
      name: 'Plot',
      runAction: 'TODO',
    },
    [PLUMBER_API]: {
      name: 'Plumber API',
      runAction: 'Run API',
    },
    [RMARKDOWN]: {
      name: 'R Markdown Document',
      runAction: 'Knit',
    },
    [RMARKDOWN_PARAMS]: {
      name: 'Parameterized R Markdown Document',
      runAction: 'Knit',
    },
    [SHINY_APP]: {
      name: 'Shiny App',
      runAction: 'Run App',
    },
    [SHINY_RMARKDOWN]: {
      name: 'Shiny R Markdown Document',
      runAction: 'Run Document',
    },
    [TENSOR_FLOW]: {
      name: 'TensorFlow Model',
      runAction: null,
    },
    [R_VETIVER]: {
      name: 'Vetiver API',
      runAction: 'Run Document',
    },
    [PYTHON_API]: {
      name: 'Python API',
      runAction: null,
    },
    [PYTHON_FASTAPI]: {
      name: 'Python FastAPI',
      runAction: null,
    },
    [PYTHON_SHINY]: {
      name: 'Python Shiny App',
      runAction: null,
    },
    [PYTHON_DASH]: {
      name: 'Dash App',
      runAction: null,
    },
    [PYTHON_STREAMLIT]: {
      name: 'Streamlit App',
      runAction: null,
    },
    [PYTHON_BOKEH]: {
      name: 'Bokeh App',
      runAction: null,
    },
    [PYTHON_VETIVER]: {
      name: 'Vetiver API',
      runAction: 'Run',
    },
    [QUARTO]: {
      name: 'Quarto Content',
      runAction: 'Render',
    },
    [QUARTO_R]: {
      name: 'Quarto Content',
      runAction: 'Render',
    },
    [QUARTO_PYTHON]: {
      name: 'Quarto Content',
      runAction: 'Render',
    },
    [QUARTO_WEBSITE]: {
      name: 'Quarto Content',
      runAction: 'Render',
    },
  },
  newButton: {
    label: 'Publish',
    [RMARKDOWN]: 'R Markdown Document',
    [RMARKDOWN_PARAMS]: 'Parameterized R Markdown Document',
    [SHINY_APP]: 'Shiny App',
    [PLUMBER_API]: 'Plumber API',
    [JUPYTER_NOTEBOOK]: 'Jupyter Notebook',
    [QUARTO]: 'Quarto Content',
    git: 'Import from Git',
    gitUnavailable: 'Git is not available on your installation',
    jumpStart: 'Jump Start Examples',
    publishingGuide: 'How to Publish (User Guide)',
  },
  deploymentDialog: {
    common: {
      manifestJson: 'manifest.json',
      backButtonLabel: 'Back',
      nextButtonLabel: 'Next',
      openContentLabel: 'Open Content',
      deployContentLabel: 'Deploy Content',
      fetchingBranchesActivityMessage:
        'Fetching branches from the repository...',
      fetchingDirectoriesActivityMessage:
        'Fetching list of deployable directories...',
      deployingContentActivityMessage:
        'Deploying your content.<br/>You may close this dialog at any time...',
      noManifestsFoundError: 'No deployable content was found.',
      rootDirectory: 'root directory',
    },
    title: 'New Content from Git Repository',
    enterRepository: {
      description:
        'Create a content item in {systemDisplayName} that automatically receives updates from its backing Git repository.',
      learnMore: 'Learn more about {learnMoreLink} in the Posit Connect User Guide.',
      learnMoreLinkText: 'Git-backed content',
      label: 'Git repository URL',

      details: {
        urlProvided: {
          text: 'Repository location must be a URL',
        },
        urlSimple: {
          text: 'URL must not include username, query, or fragment',
        },
        urlHTTPorHTTPS: {
          text: 'URL must use HTTP or HTTPS',
        },
        urlHTTPS: {
          text: 'HTTPS is preferred',
        },
        urlCredentials: {
          text: 'URL may be associated with credentials',
          additional: {
            username: 'Authenticated access using the "{username}" account.',
            suggested: 'Uses anonymous access. The "{username}" account is available only to {url} URLs.',
            none: 'Uses anonymous access. Git accounts have not been configured.',
            unmatched: 'Uses anonymous access. No configured Git account is associated with this URL.',
            pending: 'Waiting for a valid URL.',
          },
        },
      },
    },
    branchScanningFailed: {
      description:
        '{systemDisplayName} could not find available branches. ' +
        'Double-check your repository permissions.',
      hasError: `Scanning returned the error: {branchScanningError}`,
      timeout: `Scanning is taking too long.`,
    },
    selectBranch: {
      label: 'Select a branch:',
    },
    directoryScanningFailed: {
      description:
        'The selected branch does not contain deployable content. ' +
        'No directory with a {manifestJson} was found in that branch. ' +
        'Click "Back" to use a different branch.',
      help:
        'You can make a directory deployable by creating a {manifestJson} using ' +
        'the R function {writeManifestR} or the Python CLI {writeManifestPython}. ' +
        'See the {userGuideLink} for more information.',
      writeManifestR: 'rsconnect::writeManifest()',
      writeManifestPython: 'rsconnect write-manifest',
      userGuide: 'Posit Connect User Guide',
      scanningTimeout: 'Scanning is taking too long.',
    },
    selectDirectory: {
      label:
        'The following directories contain {manifestJson} files. Select one:',
      enterTitleLabel: 'Enter a title for the content:',
      required: 'A title is required.',
    },
    gitMisconfigured: {
      message:
        'Git support is not configured on your {systemDisplayName} server. ' +
        'Please contact your system administrator for assistance.',
    },
  },
  jumpstart: {
    purpose: 'Jump Start Examples',
    listOfExamples: 'List of examples',
    downloadExampleCodeLabel: 'Download Example Code',
    publishLabel: 'Get Started using "{example}"',
    showOnLoginLabel: 'Show these examples whenever I log in',
    dontShowExamples: {
      purpose: `Don't Show Examples`,
      noExamplesNextLogin:
        'We will not show you these examples next time you login.',
      viewAnyTime:
        'To view them any time, just pick Jump Start Examples from the Publish menu.',
    },
    examples: {
      about: 'About this example',
      descriptionsByType: {
        [JUPYTER_NOTEBOOK]: 'Jupyter',
        [JUPYTER_VOILA]: 'Voilà',
        [PIN]: 'Pins',
        [PLOT]: 'Plots',
        [PLUMBER_API]: 'Plumber API',
        [PYTHON_API]: 'Python API',
        [PYTHON_DASH]: 'Dash',
        [PYTHON_FASTAPI]: 'Python FastAPI',
        [PYTHON_STREAMLIT]: 'Streamlit',
        [PYTHON_BOKEH]: 'Bokeh',
        [PYTHON_SHINY]: 'Python Shiny',
        [PYTHON_VETIVER]: 'Vetiver Python',
        [RMARKDOWN]: 'R Markdown',
        [RMARKDOWN_PARAMS]: 'R Markdown',
        [SHINY_APP]: 'Shiny',
        [SHINY_RMARKDOWN]: 'Shiny R Markdown',
        [TENSOR_FLOW]: 'TensorFlow',
        [R_VETIVER]: 'Vetiver R',
        [QUARTO]: 'Quarto',
        [QUARTO_R]: 'Quarto',
        [QUARTO_PYTHON]: 'Quarto',
        [QUARTO_WEBSITE]: 'Quarto',
      },
      learnMore: {
        title: 'Learn more about {typeDescription}',
        linkTitlePrefix: 'Link to',
      },
      requirements: 'Requirements',
      thumbnailAlt: 'Example thumbnail',
    },
  },
  settings: {
    git: {
      titles: {
        generated: 'This content was generated from Git',
        repo: 'Repo',
        branch: 'Branch',
        directory: 'Directory',
        updateNow: 'Update Now',
        updatePeriodically: 'Check for updates periodically',
        checkingForChanges: 'Checking for changes',
        noChanges: 'No changes were found in the Git repository.',
        changes: 'Changes were found in the Git repository. Deploying ...',
      },
      errors: {
        invalidAppID: 'Invalid App ID received',
        branchDisappeared: `Failed to find branch '{branch}'.`,
      },
    },
  },
  requestPermissions: {
    modal: {
      subject: 'Request "{privilege}" Permissions',
      message:
        'Are you sure you want to request "{privilege}" Permissions on this server? This will contact the {systemDisplayName} administrator on your behalf to request these additional privileges.',
    },
    success: 'Permissions requested successfully.',
    notSharedWithYou: `It looks like this content hasn't been shared with you.`,
    notSharedWithYouShort: `This content hasn't been shared with you.`,
    adminOverrides: `Administrative overrides of content permissions will be captured in the audit logs.`,
    cannotRequestDueToEmail: `You can't request access to this content because {systemDisplayName}’s email server hasn’t been set up. Contact your admin to set up the email server.`,
    gainAccess: 'Gain Access',
    requestAccess: 'Request Access',
    requestEdit: [
      'Collaborator',
      'Edit, manage, or share this content.',
    ],
    requestView: [
      'Viewer',
      'View or print this content.',
    ],
    requestPending: 'Re-request access',
    toast: {
      unknownError: 'Something went wrong. Try again later.',
      accessRequestSent: 'Your access request has been sent.',
      selfGranted: 'Access granted. This is an auditable action.',
    },
    lastRequested: 'Last Requested Time: '
  },
  untitled: 'Untitled',
  list: {
    tooltip: {
      unknown: 'View item',
      application: 'View application',
      document: 'View document',
      site: 'View site',
      plot: 'View plot',
      pin: 'View pin',
      api: 'View API',
      tensorFlowApi: 'View TensorFlow API',
    },
    noContent: {
      viewer: `Either no content has been published, or none is visible to
        you. If you were expecting to see something here, you should
        contact the author of the content you're looking for and ask
        them to add you as a viewer.`,
      startPublishing: `You have no visible published content. You can get started with
        publishing new content by clicking the Publish button.`,
      noFilterMatch: `The combination of options you have selected does not match any
        content available. Please try clearing or changing your selected options.`,
    },
    headers: {
      name: 'Name',
      type: 'Type',
      author: 'Author',
      lastDeployedTime: 'Last Deployed',
    },
    labels: {
      searchForContent: 'Search for content',
      searchForContentName: 'Search for content...',
      visibility: 'Visibility',
      contentType: 'Content Type',
      owner: 'Owner',
      loading: 'Please wait, loading content...',
      unpublished: 'unpublished',
      linkApp: 'Open content',
      fromGit: 'from Git',
      emptyForUser: 'This user has not published any content.',
      content: 'Content',
      expandedView: 'Expanded View',
      compactView: 'Compact View',
      showOptionsPanel: 'Show Options Panel',
    },
    visibility: {
      visibleToYou: 'Visible to You',
      contentYouOwn: 'Content You Own',
      allServerContent: 'All Server Content',
      all: 'All',
      you: 'You',
    },
    contentType: {
      all: 'All',
      application: 'Applications',
      document: 'Documents & Sites',
      plot: 'Plots',
      pin: 'Pins',
      api: 'APIs',
    },
    title: {
      editor: {
        withTag: {
          withSearch: 'Results for "{search}" in your matching {contentType}',
          noSearch: 'Your matching {contentType}',
        },
        noTag: {
          withSearch: 'Results for "{search}" in your {contentType}',
          noSearch: 'Your {contentType}',
        },
      },
      nonEditor: {
        withTag: {
          withSearch: 'Results for "{search}" in matching {contentType}',
          noSearch: 'Matching {contentType}',
        },
        noTag: {
          withSearch: 'Results for "{search}" in {contentType}',
          noSearch: '{contentType}',
        },
      },
    },
    messages: {
      removedCategories: 'Could not locate one or more tag categories. ',
      removedTags:
        'One or more tags could not be located within the following categories: ',
      adjustedFilters:
        'Your tag filter selections have been adjusted accordingly.',
    },
  },
  type: {
    description: {
      unknown: 'Item',
      application: 'Application',
      document: 'Document',
      site: 'Site',
      plot: 'Plot',
      pin: 'Pin',
      api: 'API',
      tensorFlowApi: 'TensorFlow Model API',
    },
    prose: {
      unknown: 'item',
      application: 'application',
      document: 'document',
      site: 'site',
      plot: 'plot',
      pin: 'pin',
      api: 'API',
      tensorFlowApi: 'TensorFlow Model API',
    },
  },
  filters: {
    tags: {
      deselect: 'Remove {tag} from content filters',
    },
  },
  header: {
    breadcrumb: 'Content',
  },
  view: {
    undeployed: {
      title: 'This {type} is incomplete.',
      msg: 'This {type} has not been fully deployed. Please try publishing again.',
      checkLogs: 'View the {logs} for errors.',
    },
    unrendered: {
      title: 'This output has not been rendered.',
      checkLogs: 'View the {logs} for errors.',
      notParam: `You can render content by clicking the {refresh}
      button on the right of the header. You can schedule the report to be
      rendered automatically by clicking the {sched} tab
      in the settings on the right.`,
      param: `You can render content by clicking the {refresh}
      button on the right of the header or by opening the {input}
      tab on the left and clicking the {run} button. You
      can schedule the report to be rendered automatically by clicking the
      {sched} tab in the settings on the right.`,
      redeploy: `If rendering fails repeatedly, the source may contain an
      error or may not run in the deployed environment. Deploy the modified
      source after resolving the rendering issue.`,
    },
    blank: {
      message: 'There are no personal runs for this content.'
    },
    unpublished: {
      title: 'This {type} has not been published.',
      publish: `Please confirm the sharing configuration for the content
      and use the {publish} button to publish this content.`,
      adminAction: `Administrators can visit the {unpublishedLink} page
      to enumerate all unpublished content.`,
    },
    runReport: 'Run Report',
    runningReport: 'Running Report',
    reloadingContent: 'Reloading Content',
    refreshReport: 'Refresh Report',
    unpublishedItems: 'unpublished items',
    unpublishedPage: 'Unpublished Apps Page',
    pamReauthModal: {
      loginAgain: 'Log In Again',
      message: `The current application is requesting that you log in again.
        Press OK to go to the login page, or close this dialog to go back to the application view.`,
    },
    refreshReportMsg: 'Your request to regenerate this content has been submitted.',
    refreshReportComplete: 'Content generation complete.',
  },
  goToListing: 'Go to Content Listing',
  loginToSee: 'Log in to see this content.',
  browserRestrictions: 'We cannot display this piece of content {0} due to browser restrictions.',
  clickToViewInTab: 'Click the button below to view the content in a new window/tab.',
  clickToAccess: 'Click to access content',
  noOffHostExecutionImages: `
    Content will not be available until an administrator adds appropriate container images
    to the system via the "Environments" menu or the environment API.
  `,
};
