<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Dialog to rename a group, extending CreateGroupDialog as it behaves fairly similar.
-->
<template>
  <RSModalForm
    :active="true"
    :subject="$t('groups.titles.renameGroup')"
    @close="close"
    @submit="rename"
  >
    <template #content>
      <RSInputText
        ref="input"
        v-model.trim="form.groupName"
        :label="$t('groups.name.rename', { name: group.name })"
        :message="groupNameError"
        name="cgd-name"
      />
    </template>
    <template #controls>
      <RSButton
        :label="$t('groups.actions.rename.title')"
        data-automation="rename-group"
      />
    </template>
  </RSModalForm>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSModalForm from 'Shared/components/RSModalForm';
import CreateGroupDialog from '@/views/groups/CreateGroupDialog';
import { renameGroup } from '@/api/groups';

import {
  setErrorMessageFromAPI,
  setInfoMessage,
  clearStatusMessage,
} from '@/utils/status';

export default {
  name: 'RenameGroupDialog',
  components: { RSButton, RSInputText, RSModalForm },
  extends: CreateGroupDialog,
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs.input.focusElement();
  },
  methods: {
    rename() {
      // trigger validation before API submit and bail if form is not valid
      this.$v.form.groupName.$touch();
      if (this.$v.form.groupName.$invalid) {
        // return resolved promise, as throwing would bubble up to action caller
        return Promise.resolve();
      }

      this.$v.form.$reset();

      clearStatusMessage();
      return renameGroup(this.group.guid, this.form.groupName)
        .then(group => {
          this.$emit('rename', group);
          setInfoMessage(
            this.$t('groups.status.renamed', {
              name: this.form.groupName,
            })
          );
        })
        .catch(e => {
          this.close();
          setErrorMessageFromAPI(e);
        });
    },
  },
};
</script>

<style lang="scss">
label[for="cgd-name"] {
  width: 100%;
}
</style>
