<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModalForm
    :active="true"
    :subject="dialogTitle"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <template #content>
      <fieldset :disabled="processing">
        <div class="flex-column">
          <RSInputSelect
            v-model="form.type"
            :options="installOptions"
            :label="$t('executionEnvironments.addOrEditInstallationDialog.type.label')"
            data-automation="installation-type"
            name="install-type"
            :help="$t('executionEnvironments.addOrEditInstallationDialog.type.help')"
            @change="userActionDetected('type')"
          />
          <RSInputText
            v-model.trim="form.versionStr"
            :label="$t('executionEnvironments.addOrEditInstallationDialog.version.label')"
            name="version-str"
            data-automation="versionStr"
            :placeholder="$t('executionEnvironments.addOrEditInstallationDialog.version.placeholder')"
            :message="errorForVersionStr"
            :help="$t('executionEnvironments.addOrEditInstallationDialog.version.help')"
            @input="userActionDetected('versionStr')"
          />
          <RSInputText
            v-model.trim="form.path"
            :label="$t('executionEnvironments.addOrEditInstallationDialog.path.label')"
            name="path"
            data-automation="path"
            :placeholder="$t('executionEnvironments.addOrEditInstallationDialog.path.placeholder')"
            :message="errorForPath"
            :help="pathHelp"
            @input="userActionDetected('path')"
          />
        </div>
      </fieldset>
    </template>
    <template #controls>
      <RSButton
        id="iaed-submit"
        data-automation="installation-add-or-edit-dialog-submit"
        :disabled="disableOK"
        :label="dialogSubmitLabel"
      />
    </template>
  </RSModalForm>
</template>

<script>
import Vue from 'vue';

import RSButton from 'Shared/components/RSButton';
import RSModalForm from 'Shared/components/RSModalForm';
import RSInputText from 'Shared/components/RSInputText';
import RSInputSelect from 'Shared/components/RSInputSelect';

import { Installation } from '@/api/dto/environment';

import {
  isEmptyValue,
  invalidAbsoluteUnixFilepath,
  invalidSemanticVersionString,
  containsControlWhitespace,
} from '@/utils/validation';

export default {
  name: 'InstallationAddOrEditDialog',
  components: {
    RSButton,
    RSModalForm,
    RSInputText,
    RSInputSelect,
  },
  props: {
    installation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        type: 'R', // enum (R, Python, Quarto)
        versionStr: '',
        path: '',
      },
      userActivity: {
        type: false,
        versionStr: false,
        path: false,
      },
      actionDetectedFlag: false,
      processing: false,
      installOptions: [
        {
          label: this.$t('executionEnvironments.addOrEditInstallationDialog.type.options.python'),
          value: 'Python',
        },
        {
          label: this.$t('executionEnvironments.addOrEditInstallationDialog.type.options.quarto'),
          value: 'Quarto',
        },
        {
          label: this.$t('executionEnvironments.addOrEditInstallationDialog.type.options.r'),
          value: 'R',
        },
      ],
    };
  },
  computed: {
    isAddOrEdit() {
      if (
        this.installation &&
        this.installation.id !== undefined &&
        this.installation.id === -1
      ) {
        return 'ADD';
      }
      return 'EDIT';
    },
    dialogTitle() {
      return this.isAddOrEdit === 'ADD'
        ? this.$t('executionEnvironments.addOrEditInstallationDialog.title.add')
        : this.$t('executionEnvironments.addOrEditInstallationDialog.title.edit');
    },
    dialogSubmitLabel() {
      if (this.isAddOrEdit === 'ADD') {
        return this.$t('executionEnvironments.environmentAddOrEditDialog.submitLabel.add');
      }
      return this.$t('executionEnvironments.environmentAddOrEditDialog.submitLabel.edit');
    },
    disableOK() {
      return !this.actionDetectedFlag || !this.inputValid;
    },
    pathHelp() {
      if (this.form.type === 'Python') {
        return this.$t('executionEnvironments.addOrEditInstallationDialog.path.help.python');
      }
      if (this.form.type === 'Quarto') {
        return this.$t('executionEnvironments.addOrEditInstallationDialog.path.help.quarto');
      }
      return this.$t('executionEnvironments.addOrEditInstallationDialog.path.help.r');
    },
    inputValid() {
      return (
        !this.versionStrInvalid &&
        !this.pathInvalid
      );
    },
    versionStrInvalid() {
      // value is required
      // must parse into semantic version object
      return Boolean(
        isEmptyValue(this.form.versionStr) ||
        invalidSemanticVersionString(this.form.versionStr)
      );
    },
    errorForVersionStr() {
      return this.versionStrInvalid && this.userActivity.versionStr
        ? this.$t('executionEnvironments.addOrEditInstallationDialog.version.error')
        : null;
    },
    pathInvalid() {
      // value is required
      // The installation path must be an absolute Unix filepath (it must start with '/')
      return Boolean(
        isEmptyValue(this.form.path) ||
        containsControlWhitespace(this.form.path, false) ||
        invalidAbsoluteUnixFilepath(this.form.path)
      );
    },
    errorForPath() {
      return this.pathInvalid && this.userActivity.path
        ? this.$t('executionEnvironments.addOrEditInstallationDialog.path.error')
        : null;
    },
  },
  mounted() {
    this.updateForm();
    this.$el.querySelector('#install-type').focus();
  },
  methods: {
    userActionDetected(fieldVar) {
      this.actionDetectedFlag = true;
      Vue.set(this.userActivity, fieldVar, true);
    },
    updateForm() {
      if (this.installation.id !== -1) {
        this.form.path = this.installation.path;
        this.form.versionStr = `${this.installation.version.major}.${this.installation.version.minor}.${this.installation.version.patch}`;
        this.form.type = this.installation.type;
      } else {
        this.form.path = '';
        this.form.versionStr = '';
        this.form.type = 'Python';
      }
    },
    onSubmit() {
      const installation = new Installation({
        type: this.form.type,
        path: this.form.path,
        versionStr: this.form.versionStr,
        id: this.installation.id,
      });
      this.$emit('submit', installation);
    },
  },
};
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>
