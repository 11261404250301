// Copyright (C) 2022 by Posit Software, PBC.

import moment from 'moment-mini';
import { vueI18n } from '@/i18n';

export function activeTime(datetime) {
  if (!datetime) {
    return vueI18n.t('users.label.activeTime.never');
  }
  return moment(datetime).calendar(null, {
    lastDay: `[${vueI18n.t('users.label.activeTime.yesterday')}]`,
    sameDay: `[${vueI18n.t('users.label.activeTime.today')}]`,
    nextDay: `[${vueI18n.t('users.label.activeTime.tomorrow')}]`,
    nextWeek: 'YYYY-MM-DD',
    lastWeek: 'YYYY-MM-DD',
    sameElse: 'YYYY-MM-DD',
  });
}
