<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div data-automation="app-settings__schedule">
    <p
      v-if="!isDeployed"
      data-automation="app-settings__schedule-undeployed"
    >
      {{ $t('appSettings.schedule.notDeployed') }}
    </p>

    <NotSchedulableMsg v-if="notSchedulable" />

    <ScheduleForm
      v-if="canBeScheduled"
      data-automation="schedule-tab__schedulable"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';
import ScheduleForm from './ScheduleForm';
import NotSchedulableMsg from './NotSchedulableMsg';

export default {
  name: 'ScheduleSettings',
  components: {
    ScheduleForm,
    NotSchedulableMsg,
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentVariant: state => state.parameterization.currentVariant,
    }),
    canBeScheduled() {
      return (
        this.isDeployed &&
        !isEmpty(this.currentVariant) &&
        this.app.isRenderable()
      );
    },
    notSchedulable() {
      return this.isDeployed && !this.app.isRenderable();
    },
    isDeployed() {
      return this.app?.isDeployed();
    },
  },
};
</script>
