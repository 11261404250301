// Copyright (C) 2023 by Posit Software, PBC.

const parametersPanel = {
  title: 'Parámetros',
  save: '[es] Save',
  saveAs: 'Guardar Como',
  runReport: 'Ejecutar Reporte',
  clone: '[es] Clone',
  rename: 'Renombrar',
  delete: '[es] Delete',
  deleteRun: '[es] Delete Run',
  revert: 'Revertir',
  default: 'Por defecto',
  close: 'Cerrar',
  open: 'Abrir',
  modify: 'Modificar',
  runs: '[es] My Runs',
  noRuns: '[es] This Report Has Not Been Run',
  deletedRun: '<strong>[es] Run deleted.</strong>',
  revertedChanges: 'Cambios revertidos.',
  deletedVariant: '<strong>[es] Deleted Variant:</strong><br>{name}',
  renamedVariant: '[es] Renamed Variant',
  addedVariant: '[es] Added Variant',
  copyOf: '[es] Copy of',
  confirmModal: {
    title: 'Cambios de Parámetros No Guardados',
    message: 'Le gustaría {cancel} o {disca}?'
  },
  renameVariantModal: {
    createTitle: '[es] Clone Variant',
    renameTitle: 'Renonmbrar Variante',
    name: 'Nombre de Variante',
    maxLength: '[es] Max: {maxNameLength} characters',
    cancel: 'Cancelar',
    rename: 'Renonmbrar',
    create: '[es] Clone',
  },
  confirmClose: {
    discardChanges: 'Discartar Cambios',
    cancel: 'Regresar al Formulario de Parámetros',
    warning: 'Tiene cambios sin guardar que se perderán si cierra el Panel de Parámetros.',
  },
  confirmSwitchVariant: {
    discardChanges: 'Cambiar Variante',
    cancel: 'Regresar a la variante actual',
    warning: 'Tiene cambios sin guardar que se perderán si selecciona una variante diferente.',
  },
  confirmSwitchRun: {
    title: '[es] Unsaved Run Changes',
    discardChanges: '[es] Switch Run',
    cancel: '[es] Return to Current Run',
    warning: '[es] You have unsaved changes that will be lost if you select a different run.',
  },
  confirmDeleteRun: {
    delete: '[es] Delete Run',
    cancel: '[es] Keep Run',
    warning: '[es] Once deleted, this run cannot be restored.',
  },
  confirmDelete: {
    delete: '[es] Delete Variant',
    cancel: '[es] Keep Variant',
    warning: '[es] Are you certain you want to delete this variant?'
  }
};

export { parametersPanel };
