<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <p>
      {{ $t('content.deploymentDialog.branchScanningFailed.description') }}
    </p>
    <p v-if="hasBranchScanningError">
      {{ $t('content.deploymentDialog.branchScanningFailed.hasError', { branchScanningError }) }}
    </p>
    <p v-if="!hasBranchScanningError">
      {{ $t('content.deploymentDialog.branchScanningFailed.timeout') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BranchScanFailure',
  props: {
    branchScanningError: {
      type: String,
      required: true,
    }
  },
  methods: {
    hasBranchScanningError() {
      return !!this.branchScanningError;
    },
  },
};
</script>
