<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSInputSearch
    v-model="form.search"
    :data-automation="dataAutomation"
    :icon="icon"
    :label="label"
    :loading="loading"
    :name="name"
    :search-results="api.principals"
    :show-label="showLabel"
    :show-clear="showClear"
    @clear="clearSearch"
    @input="onInput"
    @select="onSelect"
  >
    <template #result="principal">
      <RSPrincipalInfo
        :is-group="isGroup(principal)"
        :initials="principal.displayInitials"
        :name="principal.displayName"
      />
    </template>
  </RSInputSearch>
</template>

<script>
import RSInputSearch from 'Shared/components/RSInputSearch';
import RSPrincipalInfo from 'Shared/components/RSPrincipalInfo';
import { User } from '@/api/dto/user';
import { Group } from '@/api/dto/group';
import { searchGroups } from '@/api/groups';
import { searchUsers } from '@/api/users';
import { debounce } from '@/utils/debounce';
import { setErrorMessageFromAPI } from '@/utils/status';

const principalTypes = ['user', 'group', 'all'];

export default {
  name: 'PrincipalSearchSelect',
  components: { RSInputSearch, RSPrincipalInfo },
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    hasClear: {
      type: Boolean,
      default: false,
    },
    remoteLookup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icon: 'search',
      loading: false,
      form: {
        search: '',
      },
      api: {
        principals: [],
      },
    };
  },
  computed: {
    showClear() {
      return Boolean(this.hasClear && this.form.search);
    },
  },
  created() {
    if (!principalTypes.includes(this.type)) {
      throw new Error(
        `:type '${this.type}' is invalid. Must be one of: ${principalTypes.join(
          ', '
        )}`
      );
    }
  },
  methods: {
    isUser(principal) {
      return (
        this.api.principals.find(p => p.guid === principal.guid) instanceof User
      );
    },
    isGroup(principal) {
      return (
        this.api.principals.find(p => p.guid === principal.guid) instanceof
        Group
      );
    },
    clearSearch() {
      this.api.principals = [];
      this.form.search = '';
    },
    onSelect(principal) {
      this.$emit('select', principal);
      this.api.principals = [];
      this.form.search = this.remoteLookup ? principal.displayName : '';
    },
    onInput() {
      if (this.form.search === '') {
        // user cleared input,
        // clear principals to prevent undesired selection at "enter"
        this.api.principals = [];
      }
      this.inputDebounce();
    },
    inputDebounce: debounce(300, function() {
      if (this.form.search !== '') {
        this.search(this.form.search);
      }
      this.$emit('input', this.form.search);
    }),
    setPrincipals(principals, prefix) {
      // verify search criteria didn't changed while performing the search
      if (prefix === this.form.search) {
        this.api.principals = principals;
      }
    },
    search(prefix) {
      this.loading = true;

      let searcher;
      switch (this.type) {
        case 'user':
          searcher = searchUsers(this.serverSettings, {
            prefix,
            includeRemote: this.remoteLookup,
          });
          break;
        case 'group':
          searcher = searchGroups(this.serverSettings, {
            prefix,
            includeRemote: this.remoteLookup,
          });
          break;
        case 'all':
          searcher = this.searchUsersAndGroups(this.serverSettings, { prefix });
          break;
      }
      return searcher
        .then(({ results }) => this.setPrincipals(results, prefix))
        .catch(setErrorMessageFromAPI)
        .finally(() => (this.loading = false));
    },
    searchUsersAndGroups(serverSettings, { prefix }) {
      // Return at most 10 users and 10 groups. (#18392)
      const searchPayload = {
        prefix,
        includeRemote: this.remoteLookup,
      };
      return Promise.all([
        searchUsers(serverSettings, searchPayload),
        searchGroups(serverSettings, searchPayload),
      ]).then(([{ results: users }, { results: groups }]) => ({
        results: users.slice(0, 10).concat(groups.slice(0, 10)),
      }));
    },
  },
};
</script>
