// Copyright (C) 2022 by Posit Software, PBC.

const language = {
  en: 'Inglés',
  es: 'Español',
  select: {
    label: 'Seleccione el idioma:',
  },
};

export { language };
