// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import { keysToCamel, keysToSnake } from './transform';

/**
 * API calls for custom URLs (vanities)
 */

/**
 * Creates a new custom URL
 * @param {number} appId The app this custom URL belongs to.
 * @param {string} pathPrefix The custom URL path.
 * @returns {Promise} A Promise that resolves with the created custom URL.
 */
export function createCustomUrl(appId, pathPrefix) {
  return axios.post(
    apiPath('vanities'),
    keysToSnake({ appId, pathPrefix }),
  ).then(response => {
    return keysToCamel(response.data);
  });
}

/**
 * Updates a custom URL
 * @param {number} id The id of the custom URL to update
 * @param {string} pathPrefix The new custom URL path.
 * @returns {Promise} A Promise that resolves with the updated custom URL.
 */
export function updateCustomUrl(id, pathPrefix) {
  return axios.put(
    apiPath(`vanities/${ encodeURIComponent(id) }`),
    keysToSnake({ pathPrefix }),
  ).then(response => {
    return keysToCamel(response.data);
  });
}

/**
 * Deletes a custom URL
 * @param {number} id The id of the custom URL to be deleted.
 * @returns {Promise} The executed Promise for the deletion.
 */
export function deleteCustomUrl(id) {
  return axios.delete(apiPath(`vanities/${ encodeURIComponent(id) }`));
}
