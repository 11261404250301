<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.pythonRsconnect.title') }}
      </StepHeading>

      <p v-html="$t('publish.pythonRsconnect.body', { primaryKind })" />

      <div class="wizard-panel__section">
        <h3 class="wizard-panel__subtitle">
          {{ $t('publish.pythonRsconnect.install') }}
        </h3>

        <div class="python-rsconnect__install">
          <pre class="python-rsconnect__install-code"><code>{{ connectCode }}</code></pre>

          <CopyButton
            class="python-rsconnect__install-copy"
            :copy-text="connectCode"
          />
        </div>
      </div>

      <ul class="wizard-panel__links">
        <li>
          <a
            href="https://pypi.org/project/rsconnect-python/"
            target="_blank"
          >{{ $t('publish.pythonRsconnect.links.package') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import {
  QUARTO,
  QUARTO_R,
  QUARTO_PYTHON,
  QUARTO_WEBSITE,
} from '@/constants/contentTypes';

export default {
  name: 'PythonRsconnect',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    primaryKind() {
      switch (this.copyFrom.type) {
        case QUARTO:
        case QUARTO_R:
        case QUARTO_PYTHON:
        case QUARTO_WEBSITE:
          return this.$t('publish.pythonRsconnect.kind.quarto');
        default:
          return this.$t('publish.pythonRsconnect.kind.python');
      }
    },
    connectCode() {
      return 'pip install rsconnect-python';
    }
  },
};
</script>

<style lang="scss" scoped>
  .python-rsconnect {
    &__install {
      display: flex;

      &-code {
        flex-grow: 1;
        margin-bottom: 0;
      }

      &-copy {
        margin-left: 0.25rem;
      }
    }
  }
</style>
