// Copyright (C) 2022 by Posit Software, PBC.

const userPanel = {
  profile: 'Profile',
  profileMenu: 'Profile Menu',
  loginMenu: 'Login Menu',
  apiKeys: 'API Keys',
  logout: 'Log Out',
  account: 'Account',
  login: 'Log In',
  signUp: 'Sign Up',
};

export { userPanel };
