<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <button
    ref="selectorButton"
    class="mode-selector__button"
    :aria-label="$t('appSettings.access.labels.modeSelector')"
    :disabled="!enabled"
    data-automation="mode-selector"
    @click.stop="toggleSelector"
    @keydown.esc="hideMenu({ keepFocus: true })"
  >
    <div
      :class="`mode-selector__button--${mode}`"
      :data-automation="`mode-selector__button--${mode}`"
    />
    <ul
      role="menu"
      tabindex="-1"
      :class="['mode-selector__menu', { 'open': isOpen }]"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.tab="hideMenu"
      @keydown.esc="hideMenu({ keepFocus: true })"
    >
      <li
        v-for="({ value, label, dataAutomation }, i) in modes"
        :key="i"
      >
        <button
          :ref="label"
          tabindex="-1"
          :data-automation="dataAutomation"
          :class="[
            'mode-selector__choice',
            `mode-selector__choice--${value}`,
            { 'mode-selector__choice--selected': isSelected(value) }
          ]"
          :aria-label="label"
          @click="onSelect(value)"
        >
          {{ label }}
        </button>
      </li>
    </ul>
  </button>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import {
  ACCESS_SETTINGS_UPDATE_MODE,
  ModeType,
} from '@/store/modules/accessSettings';

export default {
  name: 'ModeSelector',
  props: {
    modeValue: {
      type: String,
      default: '',
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentItem: 0,
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      storeMode: state => state.accessSettings.mode,
    }),
    modes() {
      return [
        {
          label: this.$t('appSettings.access.labels.viewer'),
          dataAutomation: 'viewer-mode',
          value: ModeType.VIEWER,
        },
        {
          label: this.$t('appSettings.access.labels.collaborator'),
          dataAutomation: 'collaborator-mode',
          value: ModeType.OWNER,
        },
      ];
    },
    mode() {
      return this.modeValue || this.storeMode;
    }
  },
  created() {
    window.addEventListener('click', this.handleClickAnywhere);
  },
  destroyed() {
    window.removeEventListener('click', this.handleClickAnywhere);
  },
  methods: {
    ...mapMutations({
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
    }),
    isSelected(mode) {
      return this.mode === mode;
    },
    onSelect(mode) {
      if (this.$listeners.onSelect) {
        this.$emit('onSelect', mode);
        return;
      }
      this.updateMode(mode);
    },
    toggleSelector() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.currentItem = 0;
        this.focusItem();
      }
    },
    focusItem() {
      const optionRef = this.modes[this.currentItem].label;
      this.$nextTick(() => this.$refs[optionRef][0].focus());
    },
    handleClickAnywhere() {
      this.isOpen = false;
    },
    hideMenu({ keepFocus = false }) {
      this.isOpen = false;
      if (keepFocus) { this.$nextTick(() => this.$refs.selectorButton.focus()); }
    },
    onArrowUp(e) {
      e.preventDefault();
      const currentItem = this.currentItem - 1;
      this.currentItem = currentItem < 0 ? this.modes.length - 1 : currentItem;
      this.focusItem();
    },
    onArrowDown(e) {
      e.preventDefault();
      const currentItem = this.currentItem + 1;
      this.currentItem = currentItem > this.modes.length - 1 ? 0 : currentItem;
      this.focusItem();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';
@import 'connect-elements/src/styles/shared/_variables';

.mode-selector {
  &__button {
    width: ($rs-icon-size + 17px);
    margin: 0 2px;
    padding: 0 0 0 4px;
    background-color: $color-white;
    color: $color-secondary-inverse;
    border-left: 0;
    background-position: 34px center;
    background-size: 8px 8px;
    background-repeat: no-repeat;

    &:enabled {
      background-image: url('connect-elements/src/images/popupArrow.svg');
    }

    &--viewer,
    &--owner {
      width: $rs-icon-size;
      height: $rs-icon-size;
      background-size: ($rs-icon-size + 4px) ($rs-icon-size + 4px);
      background-position: center center;
    }

    &--viewer {
      background-image: url('connect-elements/src/images/actionView.svg');
    }

    &--owner {
      background-image: url('connect-elements/src/images/actionEdit.svg');
    }
  }

  &__menu {
    border-radius: 3px;
    display: none;
    position: absolute;
    right: 0;
    margin: 1px 1px 0 0;
    z-index: 1000;
    background-color: $color-white;
    box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.5);

    li {
      margin-bottom: 0;
    }

    &.open {
      display: block;
    }
  }

  &__choice {
    display: block;
    height: ($rs-icon-size + 4px);
    width: 100%;
    padding: 0 8px 0 ($rs-icon-size + 8px);
    text-align: left;
    background-color: transparent;
    background-size: ($rs-icon-size + 4px) ($rs-icon-size + 4px);
    background-position: 2px center;
    background-repeat: no-repeat;

    &:hover {
      background-color: $color-posit-teal;
      color: $color-white;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      color: $color-white;
      background-color: $color-posit-teal;
      outline: none;
    }

    &--selected {
      background-color: $color-light-grey-2;
    }

    &--viewer {
      background-image: url('connect-elements/src/images/actionView.svg');
    }

    &--owner {
      background-image: url('connect-elements/src/images/actionEdit.svg');
    }
  }
}
</style>
