<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white pin-environment">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.pinEnvironment.title') }}
      </StepHeading>

      <p class="wizard-panel__notice pin-environment__notice">
        <strong>{{ $t('publish.pinEnvironment.warning.note') }}</strong>
        {{ $t('publish.pinEnvironment.warning.text') }}
      </p>

      <p class="pin-environment__intro">
        {{ $t('publish.pinEnvironment.intro') }}
      </p>

      <div class="wizard-panel__copy">
        <pre class="wizard-panel__copy-code"><code>{{ variables }}</code></pre>
        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="variables"
        />
      </div>

      <div class="pin-environment__learn-more">
        <h3 class="wizard-panel__subtitle">
          {{ $t('publish.pinEnvironment.links.title') }}
        </h3>

        <ul class="pin-environment__links">
          <li
            v-for="link in links"
            :key="link.title"
          >
            <a
              :href="link.target"
              target="_blank"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import CopyButton from '@/components/CopyButton';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import { serverURL, docsPath } from '@/utils/paths';

export default {
  name: 'PinEnvironment',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  data() {
    return {
      links: [
        { title: this.$t('publish.pinEnvironment.links.renviron'), target: 'https://cran.r-project.org/web/packages/startup/vignettes/startup-intro.html' },
        { title: this.$t('publish.pinEnvironment.links.vars'), target: docsPath('user/content-settings/#content-vars') },
        { title: this.$t('publish.pinEnvironment.links.api'), target: docsPath('user/api-keys/#api-keys-creating') },
      ]
    };
  },
  computed: {
    apiKey() {
      return this.$t('publish.keys.CONNECT_API_KEY');
    },
    serverAddress() {
      return serverURL('/');
    },
    variables() {
      const server = `CONNECT_SERVER="${this.serverAddress}"`;
      const api = `CONNECT_API_KEY="${this.apiKey}"`;
      return `${server}\n${api}`;
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.pin-environment {
  &__notice.wizard-panel__notice {
    margin: 1rem 0;
  }

  &__learn-more {
    margin-top: 1.5rem;
  }

  &__links {
    list-style-type: none;
    padding: 0;
    margin-top: 1.5rem;
    margin-left: 1rem;
    line-height: 1rem;
  }
}

.wizard-panel {
  &__copy {
    display: flex;

    &-code {
      flex-grow: 1;
        margin-bottom: 0;
    }

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }
}
</style>
