<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <p
      class="rsc-lead"
      data-automation="no-assigned-tags-message"
    >
      {{ locals.message }}
    </p>
    <p
      v-if="isAdmin"
      data-automation="create-tags-message"
    >
      {{ locals.whenAdmin.create }}
      <router-link
        data-automation="tag-editor-link"
        :to="{ name: 'admin.tag_editor' }"
      >
        {{ locals.tagEditor }}
      </router-link>.
      {{ locals.whenAdmin.onlyAdmins }}
    </p>
    <p v-else>
      {{ locals.notAdmin }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyTagsMessage',
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.locals = this.$t('appSettings.tags.cantAssign');
  },
};
</script>
