// Copyright (C) 2022 by Posit Software, PBC.

// NOTES: Cannot have spaces within template strings ex. {name} NOT { name }

const common = {
  product: 'Posit Connect',
  json: 'JSON',
  yaml: 'YAML',
  errors: {
    unknown: 'An error has occurred',
    withString: 'Error: {errorMsg}',
    malformedResponse: 'Malformed response',
  },
  results: {
    empty: 'No results.',
  },
  buttons: {
    ok: 'OK',
    yes: 'Yes',
    cancel: 'Cancel',
    save: 'Save',
    discardChanges: 'Discard Changes',
    request: 'Request',
    copy: 'Copy',
    copied: 'Copied',
    next: 'Next',
    back: 'Back',
    done: 'Done',
    reload: 'Reload',
    delete: 'Delete',
  },
  actions: {
    close: 'Close Modal',
    clearAllTags: 'Clear all tags',
    clearAllFilters: 'Clear all filters',
  },
  modal: {
    confirm: {
      subject: 'Are you sure?',
    }
  },
  jobTags: {
    unknown: 'unknown job',
    /* eslint-disable camelcase */
    build_report: 'Render',
    build_site: 'Render',
    build_jupyter: 'Render',
    packrat_restore: 'R snapshot restore',
    python_restore: 'Python environment restore',
    configure_report: 'Parameter configuration',
    run_app: 'Shiny application',
    run_api: 'Plumber API',
    run_python_api: 'Python API',
    run_dash_app: 'Dash application',
    run_streamlit: 'Streamlit application',
    run_bokeh_app: 'Bokeh application',
    run_fastapi_app: 'FastAPI application',
    run_pyshiny_app: 'Python Shiny application',
    run_voila_app: 'Voila interactive notebook',
    run_tensorflow: 'TensorFlow Model API',
    render_shiny: 'Interactive document render',
    testing: 'test job',
    git: 'Git updater',
    val_py_ext_pkg: 'Python external packages validation',
    val_r_ext_pkg: 'R external package validation',
    val_r_install: 'R Installation Verification',
    ctrl_extraction: 'Controls extraction',
    self_test: 'Server status report',
    /* eslint-enable camelcase */
  },
  loading: 'Loading...',
  saving: 'Saving...',
  pleaseWait: 'Please wait...',
  options: {
    title: 'Options',
    hideOptionsPanel: 'Hide options panel',
  },
  users: 'users',
  groups: 'groups',
  adminGuide: 'Admin Guide',
  signUp: 'Sign Up',
  logIn: 'Log In',
  open: 'Open',
  dontHaveAccount: `Don't have an account?`,
  contactYourAdmin: 'Contact your admin.',
  here: 'here',
  or: 'or',
  and: 'and',
  none: 'None',
  footer: 'Copyright © {year} Posit Software, PBC.',
  logs: 'Logs',
  input: 'Input',
  schedule: 'Schedule',
  publish: 'Publish',
  notLoggedIn: `You're Not Logged In`,
  notFound: 'Not found',
  notFoundMsg: `There's nothing here! Try again.`
};

export { common };
