// Copyright (C) 2022 by Posit Software, PBC.

// SelfTestRun reflects the backend struct `GetDTO` in the selftest API
export class SelfTestRun {
  constructor({
    id,
    status, // eslint-disable-line no-shadow
    startTime,
    hostname,
    passed,
    failed,
  } = {}) {
    this.id = id;
    this.status = status; // TODO: make enum? can be requested, running, done, canceled
    this.startTime = new Date(startTime);
    this.hostname = hostname;
    this.passed = passed;
    this.failed = failed;
  }

  toJSON() {
    return {
      id: this.id,
      status: this.status,
      startTime: this.startTime,
      hostname: this.hostname,
      passed: this.passed,
      failed: this.failed,
    };
  }
}

export class SelfTestResult {
  constructor({
    group,
    test, // eslint-disable-line no-shadow
    passed,
    output,
    error,
  } = {}) {
    this.group = group;
    this.test = test;
    this.passed = passed;
    this.output = output;
    this.error = error;
  }

  toJSON() {
    return {
      group: this.group,
      test: this.test,
      passed: this.passed,
      output: this.output,
      error: this.error,
    };
  }
}

export class SelfTestRunResults {
  constructor(
    run = {}, // eslint-disable-line no-shadow
    results = {},
  ) {
    this.run = new SelfTestRun(run);
    this.results = results.map(r => new SelfTestResult(r));
  }

  resultString() {
    return this.run.resultString();
  }
}
