// Copyright (C) 2022 by Posit Software, PBC.

// AccessType reflects the backend `AccessType` type.  It is used to specify
// who has access to a piece of content.
export class AccessType {
  constructor(value) {
    this._value = value;
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  toString() {
    return `AccessType(${this._value})`;
  }
}

// AccessTypes holds singleton values and a construction function for raw
// accessType values.
const AccessTypes = {
  All: new AccessType('all'),
  LoggedIn: new AccessType('logged_in'),
  Acl: new AccessType('acl'),

  of(value) {
    switch (value) {
      case 'all':
        return AccessTypes.All;
      case 'logged_in':
        return AccessTypes.LoggedIn;
      case 'acl':
        return AccessTypes.Acl;
      default:
        // default to Acl as it's the most restrictive
        return AccessTypes.Acl;
    }
  },
};

export default AccessTypes;
