// Copyright (C) 2022 by Posit Software, PBC.

const userPanel = {
  profile: 'Perfil',
  profileMenu: 'Menú de Perfil',
  loginMenu: 'Menú de Inicio de Sesión',
  apiKeys: 'Claves API',
  logout: 'Cerrar Sesión',
  account: 'Cuenta',
  login: 'Iniciar Sesión',
  signUp: 'Registrarse',
};

export { userPanel };
