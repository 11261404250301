<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__publish-jupyter wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.publishJupyter.title') }}
      </StepHeading>
      <i18n
        path="publish.publishJupyter.body.pressPublish"
        tag="p"
      >
        <template #publish>
          <span class="emphasize">{{ $t('publish.publishJupyter.body.publish') }}</span>
        </template>
      </i18n>
      <p>
        <img
          :alt="$t('publish.publishJupyter.screenshotAlt')"
          src="./screenshot.png"
        >
      </p>

      <p>{{ $t('publish.publishJupyter.body.rsconnectJupyterInstall') }}</p>

      <p class="wizard-panel__notice">
        <strong>{{ $t('publish.publishJupyter.warning.note') }}</strong>
        {{ $t('publish.publishJupyter.warning.text') }}
      </p>

      <ul class="publish-jupyter__links">
        <li class="publish-jupyter__link">
          <a
            ref="learnMoreLink"
            :href="learnMoreLink"
            target="_blank"
          >{{ $t('publish.publishJupyter.learnMore') }}</a>
        </li>
        <li class="publish-jupyter__link">
          <a
            :href="troubleshootingLink"
            target="_blank"
          >{{ $t('publish.publishJupyter.troubleshooting') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import { docsPath } from '@/utils/paths';

export default {
  name: 'PublishJupyter',
  components: { StepHeading },
  mixins: [PanelMixin],

  computed: {
    learnMoreLink() {
      return docsPath('user/jupyter-notebook');
    },
    troubleshootingLink() {
      return docsPath('user/troubleshooting');
    }
  },
};
</script>

<style lang="scss" scoped>
  .wizard-panel .publish-jupyter {
    &__links {
      margin-top: 1rem;
    }

    &__link {
      margin-bottom: 0.5rem;
    }
  }
</style>
