<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel section="rmarkdownParamsOutro">
    <template #body>
      <p>{{ $t('publish.rmarkdownParamsOutro.body.postPublish') }}</p>
      <p>{{ $t('publish.rmarkdownParamsOutro.body.sharing') }}</p>
    </template>
  </OutroPanel>
</template>

<script>
import OutroPanel from '../OutroPanel';

export default {
  name: 'RMarkdownParamsOutro',
  components: { OutroPanel }
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__outro {
    background-image: url('./background.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>
