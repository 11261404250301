<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <nav
    class="content-path path"
    aria-label="Breadcrumb"
  >
    <div
      v-if="isAuthenticated"
      id="breadcrumbs"
      class="content-path__breadcrumb"
    >
      <div
        class="rsc-breadcrumb"
        data-automation="rsc-breadcrumb"
      >
        <!-- TODO: should focus by default -->
        <router-link
          :to="{ name: 'contentList' }"
          data-automation="content-header-breadcrumb"
        >
          {{ $t('content.header.breadcrumb') }}
        </router-link>
      </div>
    </div>

    <div
      id="currentLocation"
      class="content-path__title"
      :class="{ hasVariants }"
      data-automation="app-header-title"
      aria-current="page"
    >
      {{ appTitle }}
    </div>

    <ParameterizedVariantSelect
      v-if="hasVariants"
      :is-new="false"
      :disabled="false"
      @change="handleVariantChange"
    />
  </nav>
</template>

<script>
import ParameterizedVariantSelect from '@/components/ParameterizedVariantSelect.vue';
import { mapState } from 'vuex';

export default {
  name: 'ContentPath',
  components: {
    ParameterizedVariantSelect,
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      variants: state => state.parameterization.variants,
      isAuthenticated: state => state.currentUser.isAuthenticated,
    }),
    appTitle() {
      return this.app?.displayName || this.$t('content.untitled');
    },
    hasVariants() {
      return this.variants.length > 1;
    },
  },
  methods: {
    handleVariantChange: function(variantId) {
      this.$router.push({ params: { id: variantId } });
    },
  }
};
</script>
