// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

import { SelfTestRun, SelfTestRunResults } from './dto/selfTests';
import { keysToCamel } from '@/api/transform';
import { apiV1Path } from '@/utils/paths';

export function getSelfTestRuns() {
  return axios
    .get(apiV1Path('selftests'))
    .then(({ data }) => {
      return data.map(r => new SelfTestRun(keysToCamel(r)));
    });
}

export function getSelfTestRun(id) {
  return axios
    .get(apiV1Path(`selftests/${encodeURIComponent(id)}`))
    .then(({ data }) => new SelfTestRun(keysToCamel(data)));
}

export function getSelfTestRunResults(id) {
  return axios
    .get(apiV1Path(`selftests/${encodeURIComponent(id)}/results`))
    .then(({ data }) => {
      const run = keysToCamel(data.run); // eslint-disable-line no-shadow
      const results = data.results.map(r => keysToCamel(r));
      return new SelfTestRunResults(run, results);
    });
}

export function runSelfTests(hostname) {
  const postData = {};
  if (hostname !== undefined) {
    postData.hostname = hostname;
  }

  return axios
    .post(apiV1Path(`selftests`), postData)
    .then(({ data }) => new SelfTestRun(keysToCamel(data)));
}

export function deleteSelfTestRun(id) {
  return axios
    .delete(apiV1Path(`selftests/${encodeURIComponent(id)}`));
}
