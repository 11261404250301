<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <fieldset :disabled="disableInputs">
    <div class="rs-field">
      <p>
        {{ $t('content.deploymentDialog.enterRepository.description') }}
      </p>
      <p>
        <i18n
          path="content.deploymentDialog.enterRepository.learnMore"
          tag="span"
        >
          <template #learnMoreLink>
            <a
              target="_blank"
              :href="learnMoreLink"
            >
              {{ $t('content.deploymentDialog.enterRepository.learnMoreLinkText') }}
            </a>
          </template>
        </i18n>
      </p>
    </div>
    <div class="rs-divider" />
    <GitRepositoryURLInput
      :value="repositoryUrl"
      @input="newValue => $emit('update:repositoryUrl', newValue)"
      @valid="isValid => $emit('valid', isValid)"
    />
  </fieldset>
</template>

<script>
import GitRepositoryURLInput from './GitRepositoryURLInput';

export default {
  name: 'SelectRepository',
  components: {
    GitRepositoryURLInput,
  },
  emits: ['valid', 'update:repositoryUrl'],
  props: {
    repositoryUrl: {
      type: String,
      required: true,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
    learnMoreLink: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(this.focusModalTitle);
  },
  methods: {
    focusModalTitle() {
      const title = document.querySelector('.rs-modal__title');
      if (title) { title.focus(); }
    },
  },
};

</script>
