<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModalForm
    :active="true"
    :subject="$t('executionEnvironments.deleteEnvironmentDialog.title')"
    @close="onClose"
    @submit="deleteEnvironment"
  >
    <template #content>
      <div
        class="details rsc-text"
      >
        <div class="rsc-alert warning">
          <p
            v-for="line in $t('executionEnvironments.deleteEnvironmentDialog.deleteWarning')"
            :key="line"
          >
            {{ line }}
          </p>
        </div>
        <div class="ml-20">
          <p>
            {{ $t('executionEnvironments.deleteEnvironmentDialog.confirmRemovalTitle') }}
          </p>
          <p>
            <span class="indented-emphasis">
              {{ environment.title }}
            </span>
          </p>
          <p>
            {{ $t('executionEnvironments.deleteEnvironmentDialog.confirmRemovalReference') }}
          </p>
          <p>
            <span class="indented-emphasis">
              {{ environment.name }}
            </span>
          </p>
        </div>
      </div>
    </template>
    <template #controls>
      <RSButton
        id="delete-environment-dialog-submit"
        :label="$t('common.buttons.delete')"
        data-automation="delete-environment-dialog-submit"
      />
    </template>
  </RSModalForm>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSModalForm from 'Shared/components/RSModalForm';

import {
  clearStatusMessage,
} from '@/utils/status';

export default {
  name: 'EnvironmentDeleteDialog',
  components: { RSButton, RSModalForm },
  props: {
    environment: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$el.querySelector('#delete-environment-dialog-submit').focus();
  },
  methods: {
    deleteEnvironment() {
      clearStatusMessage();
      this.$emit('delete', this.environment);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.rsc-text p {
  margin-bottom: 1rem;
}
.ml-20 {
  margin-left: 20px;
}
.indented-emphasis {
  font-weight: bold;
  padding-left: 15px;
}
</style>
