<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Renders the routable Documentation view container
-->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div class="flex">
        <h1
          ref="title"
          class="sectionTitle focusedTitle"
          tabindex="-1"
        >
          {{ $t('documentation.title') }}
        </h1>
      </div>
      <div class="section">
        {{ $t('connect.longDescription') }}
      </div>

      <DocumentationViewItems />

      <ConnectLogoAnchor />

      <About />
    </div>
  </div>
</template>

<script>
import ConnectLogoAnchor from '@/components/ConnectLogoAnchor.vue';
import DocumentationViewItems from '@/views/documentation/DocumentationViewItems';
import About from '@/views/documentation/About';

export default {
  name: 'DocumentationView',
  components: {
    DocumentationViewItems,
    ConnectLogoAnchor,
    About,
  },
  mounted() {
    this.$refs.title.focus();
  }
};
</script>
