<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  This component responsibility is to show
  to non publisher users the blurb about not having permissions,
  and handle the request permissions modal for that case.
-->

<template>
  <div
    data-automation="cannot-activate-token"
  >
    <h2 class="rsc-lead">
      {{ $t('ideToken.cannotPublish.title') }}
    </h2>
    <i18n
      tag="p"
      path="ideToken.cannotPublish.details"
    >
      <template #username>
        <span class="emphasize">{{ username }}</span>
      </template>
      <template #permissionsLink>
        <a
          href="#"
          data-automation="activate-token-req-perm"
          @click.prevent="togglePermissionsModal"
        >
          {{ $t('ideToken.cannotPublish.linkLabel') }}
        </a>
      </template>
    </i18n>
    <RequestPermissionsModal
      privilege="publisher"
      :show-modal="showPermissionsModal"
      @close="togglePermissionsModal"
    />
  </div>
</template>

<script>
import RequestPermissionsModal from '@/views/content/RequestPermissionsModal';

export default {
  name: 'TokenCannotPublish',
  components: {
    RequestPermissionsModal,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPermissionsModal: false,
    };
  },
  methods: {
    togglePermissionsModal() {
      this.showPermissionsModal = !this.showPermissionsModal;
    },
  },
};
</script>
