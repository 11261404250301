/* istanbul ignore file */

// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Ignoring coverage for this file as it serves as an entry point
 */

// Initialize Vue.js runtime environment
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';

// Initialize Axios for our Vue.js app
import '@/api/axiosSetup';

// Redux and angular router plugins
import VueRedux from '@/plugins/redux';
import VueIframeBus from '@/plugins/iframeBus';

// needed for vueClipboard to work in modals
VueClipboard.config.autoSetContainer = true;

Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueRedux);
Vue.use(VueIframeBus);

import App from '@/App';

// Legacy Redux store and globalEventBus listeners setup
import { reduxStore } from '@/store/redux';
import { setupGlobalEventBusListeners } from '@/globalEventBus';
setupGlobalEventBusListeners(reduxStore);

// Vuex store
import { store } from '@/store';

// Vue router
import router from '@/router';

// Initialize / Configure vue-i18n
import { vueI18n as i18n } from '@/i18n/index';

(() => new Vue({
  el: '#connectui',
  name: 'ConnectRoot',
  i18n,
  store,
  router,
  redux: reduxStore,
  render: h => h(App),
}))();
