// Copyright (C) 2022 by Posit Software, PBC.

const language = {
  en: 'English',
  es: 'Spanish',
  select: {
    label: 'Select Language:',
  },
};

export { language };
