<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="formSection infoSection"
    data-automation="schedule-tab__not-schedulable"
  >
    <i18n
      v-if="isWorker"
      tag="p"
      path="appSettings.schedule.notOfType"
      data-automation="schedule-tab__not-schedulable-worker"
    >
      <template #mode>
        <span>{{ noSchedulableAppDesc }}</span>
      </template>
    </i18n>
    <p
      v-if="isStatic"
      data-automation="schedule-tab__not-schedulable-static"
    >
      {{ $t('appSettings.schedule.notPublished') }}
    </p>
    <p
      v-if="isUnknown"
      data-automation="schedule-tab__not-schedulable-unknown"
    >
      {{ $t('appSettings.schedule.cannotRefresh') }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppModes from '@/api/dto/appMode';

const contentDescLocaleKey = appmode => {
  switch (appmode) {
    case AppModes.Shiny:
      return 'shiny';
    case AppModes.ShinyRmd:
    case AppModes.ShinyQuarto:
      return 'interactiveShiny';
    case AppModes.StaticRmd:
      return 'staticRmd';
    case AppModes.Static:
      return 'static';
    case AppModes.PlumberAPI:
    case AppModes.PythonAPI:
      return 'api';
    case AppModes.PythonDash:
      return 'dash';
    case AppModes.PythonStreamlit:
      return 'streamlit';
    case AppModes.PythonBokeh:
      return 'bokeh';
    case AppModes.PythonFastAPI:
      return 'fastapi';
    case AppModes.PythonShiny:
      return 'shinyPython';
    case AppModes.JupyterVoila:
      return 'voila';
    case AppModes.TensorFlow:
      return 'tensorflow';
    case AppModes.StaticJupyter:
      return 'jupyter';
    case AppModes.StaticQuarto:
      return 'staticQuarto';
    default:
      return 'unknown';
  }
};

export default {
  name: 'NotSchedulableMsg',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
    isWorker() {
      return this.app.hasWorker();
    },
    isStatic() {
      return this.app.isStatic();
    },
    isUnknown() {
      return this.app.appMode === AppModes.Unknown;
    },
    noSchedulableAppDesc() {
      const modeKey = contentDescLocaleKey(this.app.appMode);
      return this.$t(`appSettings.schedule.contentDesc.${modeKey}`);
    },
  },
};
</script>
