<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    :aria-label="$t('contentHeader.actions.history.label')"
    :title="$t('contentHeader.actions.history.label')"
    class="action outputHistory content-actions__history-button"
    data-automation="menuitem-history--dropdown"
    @click="onToggleHistory"
  >
    <span class="actionTitle">{{ $t('contentHeader.actions.history.label') }}</span>
  </button>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { TOGGLE_BUNDLES_HISTORY } from '@/store/modules/bundles';
import { TOGGLE_RENDERINGS_HISTORY } from '@/store/modules/contentView';

export default {
  name: 'HistoryButton',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
  },
  methods: {
    ...mapMutations({
      toggleBundlesHistory: TOGGLE_BUNDLES_HISTORY,
      toggleRenderingsHistory: TOGGLE_RENDERINGS_HISTORY,
    }),
    onToggleHistory() {
      if (this.app.isStatic()) {
        this.toggleBundlesHistory();
      } else {
        this.toggleRenderingsHistory();
      }
    },
  },
};
</script>
