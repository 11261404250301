// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiExperimentalPath } from '@/utils/paths';
import { keysToCamel } from './transform';

export const getExamples = () => axios.get(apiExperimentalPath('examples')).then(({ data }) => {
  const examples = keysToCamel(data);
  examples.forEach(example => {
    example.downloadUrl = apiExperimentalPath(['examples', example.name, 'zip'].join('/'));
  });
  return examples;
});
