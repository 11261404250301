<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__jupyter-open wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.jupyterNotebookOpen.title') }}
      </StepHeading>
      <i18n
        path="publish.jupyterNotebookOpen.body"
        tag="p"
      >
        <template #dir>
          <code>{{ dir }}</code>
        </template>
        <template #source>
          <code>{{ source }}</code>
        </template>
        <template #zip>
          <code>{{ zip }}</code>
        </template>
      </i18n>
      <p>
        <a
          href="https://jupyter.org/"
          target="_blank"
        >{{ $t('publish.jupyterNotebookOpen.learnMore') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'JupyterNotebookOpen',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__jupyter-open {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>
