// Copyright (C) 2022 by Posit Software, PBC.

const password = {
  required: 'Este campo es requerido.',
  minLength: 'La contraseña es muy corta. Debería ser de al menos 6 carateres.',
  same: 'Las contraseñas deben coincidir.',
  sameAsPassword: 'Las contraseñas deben ser exactamente iguales.',
};

const authentication = {
  userCompletion: {
    title: 'Configuración inicial de la cuenta',
    description:
      'Necesitamos un poco mas de información para terminar de configurar tu cuenta.',
    updating: {
      message: 'Actualizando tu perfil...',
    },
  },
  setPassword: {
    title: 'Elige una nueva contraseña',
    help: [
      'Intenta usando algunas palabras, evita frases comunes.',
      `No te bases en nombres asociados a este usuario.`,
      `No te bases en nombres comunes.`,
      `No uses contraseñas faciles de adivinar como "contraseña".`,
      `No uses fechas o numeros consecutivos.`,
      'Letras o sucesiones de teclas en el teclado son faciles de adivinar.',
      'Variedad de caracteres (mayúsculas, minúsculas, numeros y símbolos) no garantizan una contraseña fuerte.',
      `"c0ntr453ña" no es necesariamente una contraseña difícil de adivinar.`,
    ],
    status: {
      success: 'Tu nueva contraseña se ha guardado.',
      missingToken: `Falta el token en el link para restablecer la contraseña.
      Por favor intenta nuevamente.`,
      changed: 'Contraseña actualizada con éxito.',
    },
  },
  label: {
    username: 'Usuario',
    email: 'Email',
    password: 'Contraseña',
    changePassword: 'Cambiar contraseña',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    password2: 'Contraseña (nuevamente)',
    repeatNewPassword: 'Nueva contraseña (nuevamente)',
    setPassword: 'Definir contraseña',
    forgotPassword: '¿Olvidaste tu contraseña?',
    selfRegistration: '¿No tienes cuenta?',
    alreadyHaveAccount: '¿Ya tienes cuenta?',
    confirmPassword: 'Confirmar contraseña',
    challengeResponse: 'Código de verificación',
    challengeImage: 'Imagen de verificación',
    challengeVerification: 'Código de verificación *',
    challengeLoading: 'Cargando código de verificación...',
    audioUnsupported: 'Verificación por audio no esta disponible en este explorador.',
    confirmingPassword: 'Confirmando contraseña...',
  },
  validation: {
    username: {
      required: 'El usuario es requerido.',
      blank: password.required,
      prohibited: 'Este usuario esta prohibido por políticas.',
      minLength: 'Este usuario es demasiado corto. Debe contener un mínimo de 3 caracteres.',
      maxLength: 'Este usuario es demasiado largo. Debe contener un máximo de 64 caracteres.',
      firstLetter: 'El primer caracter debe ser una letra.',
      validCharacters:
        'Caracter invalido en uso. Solamente se pueden usar letras, numeros, puntos y guion bajo (_).',
    },
    email: {
      required: 'Email es requerido.',
      email: 'No es un email válido.',
    },
    password,
    currentPassword: password,
    newPassword: password,
    repeatNewPassword: password,
  },
  websudo: {
    message:
      'Por cuestiones de seguridad, pro favor ingresa la contraseña asociada con tu cuenta {authentication}.',
    unrecoverable: `Ha ocurrido un problema. Por favor recarga la página he intenta nuevamente. Error: {error}`,
  },
  login: {
    title: 'Iniciar sesión',
    blurb: 'Iniciar sesión con tus credenciales {auth}',
    warning: `El ingreso esta tomando mas tiempo de lo esperado.
      Por favor notifica el problema a tu administrador {systemDisplayName}.
      Pueden existir logs que ayuden a identificar el problema.`,
    unableToSignIn: `No es posible ingresar. Por favor intenta nuevamente o
      notifica el problema a tu administrador {systemDisplayName}.`,
    confirm: {
      enterPassword: `Tu cuenta ya fue confirmada. Ingresa tu contraseña para continuar.`,
      enterNewPassword: `Tu constraseña ya fue establecida previamente. Ingresa tu contraseña para continuar.`,
    },
    illegalRedirect: 'Re-dirección insegura a un sitio desconocido. Por favor notifica el problema a tu administrador. {errUrl}',
    malformedRedirect: 'Re-dirección insegura a un sitio desconocido. No es posible interpretar la dirección. {errUrl}',
  },
};

export { authentication };
