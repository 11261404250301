// Copyright (C) 2022 by Posit Software, PBC.

/**
 * delay returns a promise that resolves after `ms` milliseconds
 * @param {Number} ms milliseconds
 * @returns {Promise<any>}
 */
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
