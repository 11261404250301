// Copyright (C) 2023 by Posit Software, PBC.

import moment from 'moment-mini';

export class ApiKey {
  constructor({
    id,
    key,
    name: keyName,
    userId,
    created,
    lastUsedTime,
  }) {
    this.id = id;
    this.key = key;
    this.name = keyName;
    this.userId = userId;
    this.created = moment(created).calendar();
    this.lastUsed = moment(lastUsedTime).calendar();
  }
}
