<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <a
    :aria-label="$t('navigation.goToRStudioWebsite')"
    href="https://posit.co"
    target="_blank"
  >
    <img
      src="/images/logoPosit.svg"
      alt="Posit"
      class="orbLogo"
    >
  </a>
</template>

<script>
export default {
  name: 'ConnectLogoAnchor',
};
</script>

<style scoped lang="scss">
.orbLogo {
    width: 146px;
    height: 51px;
    margin-bottom: 15px;
}
</style>
