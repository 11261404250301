<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="tags-toggle-info toggleableInfo"
  >
    <ul>
      <li
        v-for="(helpBullet, index) in $t('admin.tags.gettingStarted.shortOverview')"
        :key="'hi-' + index"
      >
        {{ helpBullet }}
      </li>
    </ul>
    <h4 class="tags-toggle-info__description-title">
      {{ $t('admin.tags.gettingStarted.how.title') }}
    </h4>
    <p>
      {{ $t('admin.tags.gettingStarted.how.shortDescription') }}
      <span
        v-if="exampleCategories.length > 1"
      >
        {{ $t('admin.tags.gettingStarted.catgoriesExamples', { one: exampleCategories[0], two: exampleCategories[1] }) }}
      </span>
      <span
        v-else
      >
        {{ $t('admin.tags.gettingStarted.categoryExample', { category: exampleCategories[0] }) }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'TagsHelpInfo',
  props: {
    exampleCategories: {
      type: Array,
      required: true,
    },
  },
};
</script>
