// Copyright (C) 2022 by Posit Software, PBC.

import { admin } from './admin';
import { appSettings } from './appSettings';
import { authentication } from './authentication';
import { apiKeys } from './apiKeys';
import { common } from './common';
import { confirmUser } from './confirmUser';
import { connect } from './connect';
import { content } from './content';
import { contentHeader } from './contentHeader';
import { documentation } from './documentation';
import { email } from './email';
import { executionEnvironments } from './executionEnvironments';
import { groups } from './groups';
import { historyNavigation } from './history';
import { language } from './language';
import { landingPage } from './landingPage';
import { metrics } from './metrics';
import { navPanel } from './navPanel';
import { navigation } from './navigation';
import { notices } from './notices';
import { parametersPanel } from './parametersPanel';
import { publish } from './publish';
import { ideToken } from './ideToken';
import { renderSender } from './renderSender';
import { resetPassword } from './resetPassword';
import { sourceVersions } from './sourceVersions';
import { subscriptions } from './subscriptions';
import { userPanel } from './userPanel';
import { users } from './users';
import { variants } from './variants';
import { versions } from './versions';
import { welcome } from './welcome';

const en = {
  admin,
  appSettings,
  authentication,
  apiKeys,
  common,
  confirmUser,
  connect,
  content,
  contentHeader,
  documentation,
  email,
  executionEnvironments,
  groups,
  historyNavigation,
  language,
  landingPage,
  metrics,
  navPanel,
  navigation,
  notices,
  parametersPanel,
  publish,
  ideToken,
  renderSender,
  resetPassword,
  sourceVersions,
  subscriptions,
  userPanel,
  users,
  variants,
  versions,
  welcome,
};

export { en };
