<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  ServerStatusView
  Renders the routable Server Status View view container
-->
<template>
  <div>
    <div class="rsc-serverstatus__header">
      <div
        class="flex"
        data-automation="status-report"
      >
        <h1
          ref="title"
          class="sectionTitle focusedTitle"
          tabindex="-1"
        >
          {{ $t('admin.serverStatus.title') }}
        </h1>
      </div>
      <RunNewReportButton
        @new-report="getReports"
      />
    </div>
    <div>{{ $t('admin.timeInfo', { offset }) }}</div>
    <div
      v-if="displayTable"
      class="serverStatusTable"
    >
      <RSTable
        class="contentListing"
        :columns="colNames"
      >
        <RSTableRow
          v-for="(report, i) in reports"
          :key="i"
          :row-id="report.id"
          :row-label="`Report Generated ${report.startTime.toLocaleString()}`"
          :report="report"
          :class="[{highlighted: (i == selectionIndex)}, 'clickable']"
          clickable
          @click="selectReport(i)"
        >
          <RSTableCell>
            {{ report.startTime.toLocaleString() }}
          </RSTableCell>
          <RSTableCell>
            {{ report.hostname }}
          </RSTableCell>
          <RSTableCell>
            {{ resultString(report) }}
          </RSTableCell>
        </RSTableRow>
      </RSTable>
    </div>
    <div
      v-if="displayTable"
    >
      <div
        v-if="reports[selectionIndex]"
      >
        <div class="statusReportToolbar">
          <RSButton
            :label="$t('admin.serverStatus.downloadReport')"
            type="secondary"
            :class="{ disabled: !displaySelectedReport }"
            @click="downloadReport"
          />
          <RSButton
            :label="displaySelectedReport ? $t('admin.serverStatus.deleteReport') : $t('admin.serverStatus.cancelReport')"
            type="secondary"
            @click="deleteSelectedReport"
          />
        </div>
        <hr>
        <ServerStatusReportDocument
          v-if="displaySelectedReport"
          ref="reportDocument"
          :report="reports[selectionIndex]"
        />
        <div
          v-if="!displaySelectedReport"
          class="report-message"
        >
          <h3>{{ $t('admin.serverStatus.reportStillRunning') }}</h3>
        </div>
      </div>
      <div
        v-if="!reports[selectionIndex]"
        class="report-message"
      >
        <hr>
        <h3>{{ $t('admin.serverStatus.selectReport') }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelfTestRuns, deleteSelfTestRun } from '@/api/selfTests';
import { setErrorMessage } from '@/utils/status';
import { localOffset } from '@/utils/timezone';
import { safeAPIErrorMessage } from '@/api/error';
import RSButton from 'Shared/components/RSButton';
import ServerStatusReportDocument from './serverStatus/ServerStatusReportDocument';
import RunNewReportButton from './serverStatus/RunNewReportButton';
import { makeReportDocumentHTML } from './serverStatus/utils';
import RSTable from 'Shared/components/RSTable';
import RSTableRow from 'Shared/components/RSTableRow';
import RSTableCell from 'Shared/components/RSTableCell';

export default {
  name: 'ServerStatusView',
  components: {
    RSButton,
    ServerStatusReportDocument,
    RSTable,
    RSTableRow,
    RSTableCell,
    RunNewReportButton,
  },
  data() {
    return {
      reports: [],
      offset: localOffset(),
      selectionIndex: null,
    };
  },
  computed: {
    displayTable() {
      return this.reports.length > 0;
    },
    displaySelectedReport() {
      return ['done', 'canceled'].includes(this.reports[this.selectionIndex].status);
    }
  },
  created() {
    this.getReports();
    this.interval = setInterval(() => {
      this.getReports();
    }, 5000);
    this.colNames = ['startTime', 'hostname', 'status'].map(col => {
      return {
        label: this.$t(`admin.serverStatus.table.colNames.${col}`),
        width: '33.33%',
      };
    });
  },
  mounted() {
    this.$refs.title.focus();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getReports() {
      return getSelfTestRuns()
        .then(res => {
          res.sort(function(a, b) {
            const keyA = new Date(a.startTime),
              keyB = new Date(b.startTime);
            // Compare the 2 dates
            if (keyA < keyB) { return 1; }
            if (keyA > keyB) { return -1; }
            return 0;
          });
          this.reports = res || [];
        })
        .catch(err => {
          setErrorMessage(safeAPIErrorMessage(err));
          this.reports = [];
        });
    },
    async deleteSelectedReport() {
      try {
        await deleteSelfTestRun(this.reports[this.selectionIndex].id);
        this.getReports();
      } catch (error) {
        setErrorMessage(safeAPIErrorMessage(error));
      }
    },
    selectReport(i) {
      this.selectionIndex = i;
    },
    downloadReport() {
      const doc = makeReportDocumentHTML(this.$refs.reportDocument.$el.outerHTML);
      const report = new Blob([doc], {
        type: 'application/html'
      });
      const filedate = this.reports[this.selectionIndex].startTime.toJSON().slice(0, 19)
        .replace(/[/\\?%*:|"<>]/g, '.');
      const filename = `rsc-server-status-report-${this.reports[this.selectionIndex].hostname}-${filedate}.html`;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(report);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    resultString(report) {
      const passedSymbol = '✅';
      const failedSymbol = '⚠️';
      let resultString = '';
      switch (report.status) {
        case 'requested':
          resultString = this.$t('admin.serverStatus.statusRequested');
          break;
        case 'canceled':
          resultString = this.$t('admin.serverStatus.statusCanceled');
          break;
        case 'running':
          resultString = this.$t('admin.serverStatus.statusRunning');
        // eslint-disable-next-line no-fallthrough
        case 'done':
          if (report.passed > 0) {
            resultString = resultString.concat(`${passedSymbol} ${report.passed} ${this.$t('admin.serverStatus.passedStatus')}`);
          }
          if (report.passed > 0 && report.failed > 0) {
            resultString = resultString.concat(', ');
          }
          if (report.failed > 0) {
            resultString = resultString.concat(`${failedSymbol} ${report.failed} ${this.$tc('admin.serverStatus.failedStatus', report.failed)}`);
          }
      }
      return resultString;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.rsc-serverstatus__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.serverStatusTable {
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 1rem;
}

.contentListing {
  table.rs-table {
    width: 100%;
    border-bottom: 1px solid #f1f0f0;

    thead, tbody, tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tbody {
      display: block;
      overflow: auto;
      table-layout: fixed;
      max-height: 275px;
    }
  }
}

.clickable {
  @include clickable-table-cell($color-light-grey, 250ms);
}

.clickable.highlighted {
    background-color: $color-light-blue;
}

.statusReportToolbar {
  margin: 14px 0px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  column-gap: 10px;
}

.rs-button {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.report-message {
  margin-top: 20px;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500;
  }
}

</style>
