<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <RSInputCheckbox
      v-model="sendEmail"
      name="send-enabled"
      data-automation="schedule__email"
      :label="$t('appSettings.schedule.email.checkboxLabel')"
      :disabled="emailDisabled"
      @change="emitChange"
    />

    <div
      v-if="sendEmail"
      data-automation="schedule__email-full-controls"
    >
      <p
        v-if="canEditVariant"
        class="owners-opt-out"
      >
        {{ $t('appSettings.schedule.email.ownersNotified') }}
        <i18n
          v-if="isAppOwner"
          tag="span"
          path="appSettings.schedule.email.unlessOptout"
        >
          <template #optoutLink>
            <router-link
              class="link-like-button"
              data-automation="schedule__email-opt-out"
              :to="{ name: 'apps.subscriptions', params: { idOrGuid: app.guid } }"
            >
              {{ $t('appSettings.schedule.email.optout') }}
            </router-link>
          </template>
        </i18n>
        .
      </p>

      <RSInputCheckbox
        v-model="sendToCollabs"
        name="send-collaborators"
        class="send-collaborators vertical-space"
        :class="{ 'disabled-control': !canEditVariant }"
        data-automation="schedule__email-collabs"
        :label="$t('appSettings.schedule.email.sendCollab')"
        :disabled="!canEditVariant"
        @change="emitChange"
      />

      <RSInputCheckbox
        v-model="sendToViewers"
        name="send-viewers"
        :class="{ 'showing-mail-all': mailAllEnabled, 'disabled-control': !canEditVariant }"
        data-automation="schedule__email-viewers"
        :label="sendViewersLabel"
        :disabled="!canEditVariant"
        @change="emitChange"
      />

      <!-- When mail all is configured -->
      <RSRadioGroup
        v-if="mailAllEnabled"
        name="mail-all-viewers"
        class="mail-all-viewers"
        :class="{ 'disabled-control': !canEditVariant }"
        data-automation="schedule__email-all"
        :options="mailAllOptions"
        :value="mailAllValue"
        @change="onMailAllChange"
      />

      <div
        class="rs-field__help-label"
      >
        {{ $t('appSettings.schedule.email.additional') }}
      </div>
      <PrincipalSearchSelect
        v-if="canEditVariant"
        name="send-additional"
        data-automation="schedule__email-additional-search"
        type="user"
        :label="$t('appSettings.schedule.email.additional')"
        :server-settings="{ authentication: { externalUserSearch: false } }"
        @select="addRecipient"
      />

      <div
        class="additional-recipients-list"
        data-automation="schedule__email-additional-list"
      >
        <div
          v-for="(recp, index) in additionalRecipients"
          :key="recp.displayName"
          :data-automation="`schedule__email-additional-${index}`"
          class="additional-recipient"
        >
          <RSPrincipalInfo
            :initials="recp.displayInitials"
            :name="recp.displayName"
          />
          <button
            v-if="canEditVariant"
            class="additional-recipient__remove"
            :data-automation="`schedule__email-remove-recipient-${index}`"
            :aria-label="$t('appSettings.schedule.email.removeAdditional', { name: recp.displayName })"
            @click="() => removeRecipient(index)"
          />
        </div>
      </div>

      <p
        v-if="viewersRestriction"
        data-automation="schedule__email-viewer-restriction"
      >
        {{ $t('appSettings.schedule.email.additionalPrivacy') }}
      </p>
    </div>
  </div>
</template>

<script>
import { showSubscriptionsModal } from '@/store/redux/app-state/app-state.actions';
import { setInfoMessage, setErrorMessage } from '@/utils/status';
import AccessTypes from '@/api/dto/accessType';
import RSRadioGroup from 'Shared/components/RSRadioGroup';
import RSInputCheckbox from 'Shared/components/RSInputCheckbox';
import RSPrincipalInfo from 'Shared/components/RSPrincipalInfo';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';

const ALL = AccessTypes.All.toJSON();
const ACL = AccessTypes.Acl.toJSON();

export default {
  name: 'ScheduleEmail',
  components: {
    RSRadioGroup,
    RSInputCheckbox,
    RSPrincipalInfo,
    PrincipalSearchSelect,
  },
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    app: {
      type: Object,
      required: true,
    },
    accessList: {
      type: Array,
      required: true,
    },
    accessGroups: {
      type: Array,
      required: true,
    },
    collabs: {
      type: Boolean,
      required: true,
    },
    viewers: {
      type: Boolean,
      required: true,
    },
    all: {
      type: Boolean,
      required: true,
    },
    subscribers: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const canEditVariant = this.currentUser.isAppEditor(this.app);
    return {
      canEditVariant,
      sendEmail: this.enabled,
      sendToCollabs: this.collabs,
      sendToViewers: this.viewers || this.all,
      additionalRecipients: [...this.subscribers],
      mailAllValue: this.all ? ALL : ACL,
      mailAllOptions: [
        {
          value: ACL,
          label: this.$t('appSettings.schedule.email.viewersAcl'),
          disabled: !canEditVariant,
        },
        {
          value: ALL,
          label: this.$t('appSettings.schedule.email.usersInSystem'),
          disabled: !canEditVariant,
        },
      ],
    };
  },
  mapDispatchToMethods(dispatch) {
    return {
      showSubscriptionsModal: () => dispatch(showSubscriptionsModal()),
    };
  },
  computed: {
    emailDisabled() {
      return !this.canEditVariant || !this.serverSettings.mailConfigured;
    },
    sendViewersLabel() {
      return `${this.$t('appSettings.schedule.email.sendViewers')}${this.mailAllEnabled ? ':' : ''}`;
    },
    isAppOwner() {
      return this.currentUser.isAppOwner(this.app);
    },
    mailAllEnabled() {
      return this.serverSettings.mailAll && this.app.accessType !== AccessTypes.Acl;
    },
    viewersRestriction() {
      return (
        this.serverSettings.viewersCanOnlySeeThemselves &&
        !this.currentUser.canPublish()
      );
    },
  },
  methods: {
    onMailAllChange(val) {
      this.mailAllValue = val;
      this.emitChange();
    },
    userInList(user, list) {
      return !!list.find(u => u.guid === user.guid);
    },
    subscriberHasAccess(rec) {
      if (this.app.accessType !== AccessTypes.Acl) {
        return true;
      }
      const userListed = this.userInList(rec, this.accessList);
      const userInGroups = !!this.accessGroups.find(g => {
        return this.userInList(rec, g.members || []);
      });
      return userListed || userInGroups;
    },
    addRecipient(rec) {
      if (rec.guid === this.app.ownerGuid) {
        setInfoMessage(
          this.$t('appSettings.schedule.email.noNeedOwner'),
          true,
        );
        return;
      }

      if (!this.subscriberHasAccess(rec)) {
        setErrorMessage(this.$t('appSettings.schedule.email.accessRequired'));
        return;
      }

      this.additionalRecipients.push(rec);
      this.emitChange();
    },
    removeRecipient(pos) {
      this.additionalRecipients.splice(pos, 1);
      this.emitChange();
    },
    emitChange() {
      this.$emit('change', {
        sendEmail: this.sendEmail,
        collaborators: this.sendToCollabs,
        viewers: this.sendToViewers,
        mailAll: this.mailAllValue === ALL,
        additionalRecipients: this.additionalRecipients,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_variables';
@import 'connect-elements/src/styles/shared/_colors';

.rs-field.disabled-control,
.rs-radiogroup.disabled-control {
  color: $color-dark-grey;
}

.owners-opt-out {
  font-size: 0.74rem;
}

.rs-field.send-collaborators,
.rs-field.showing-mail-all {
  margin-bottom: 0;
}

.mail-all-viewers {
  padding-left: 1rem;
}

.additional-recipient {
  border-bottom: 1px solid $color-light-grey-3;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;

  &__remove {
    width: $rs-icon-size;
    height: $rs-icon-size;
    margin: 0;
    background-color: transparent;
    color: $color-secondary-inverse;
    background-position: center;
    background-size: $rs-icon-size $rs-icon-size;
    background-repeat: no-repeat;
    background-image: url('connect-elements/src/images/close.svg');
  }
}
</style>
