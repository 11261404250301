// Copyright (C) 2023 by Posit Software, PBC.

import pairsToQueryParams from '@/store/redux/utils/pairsToQueryParams';
import axios from 'axios';
import { apiPath } from '@/utils/paths';

// Issues an API request to send this variant+rendering.
export function sendEmail({
  variantId,
  renderingId,
  email,
}) {
  const queryPairs = [
    ['email', email],
    // renderingId is sometimes undefined (when not viewing history),
    // which causes this argument to be discarded by pairsToQueryParams.
    ['rendering_id', renderingId]
  ];

  const url = `variants/${encodeURIComponent(variantId)}/sender?${
    pairsToQueryParams(...queryPairs)}`;

  return axios.post(apiPath(url)).then(res => res.data);
}
