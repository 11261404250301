<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="nothingEmbedded"
    data-automation="no-content-frame-msg"
  >
    <div
      v-if="!isDeployed"
      data-automation="undeployed-content-msg"
    >
      <h1 class="undeployed">
        <div class="typeUnpublished" />
        {{ $t('content.view.undeployed.title', { type: appProse }) }}
      </h1>
      <p class="undeployed">
        {{ $t('content.view.undeployed.msg', { type: appType }) }}
      </p>
      <i18n
        v-if="canViewLogs"
        tag="p"
        path="content.view.undeployed.checkLogs"
        class="unrendered"
      >
        <template #logs>
          <a
            data-automation="content-log-link"
            href="_"
            @click="showLogs"
            @keypress.enter="showLogs"
          >
            {{ $t('common.logs') }}
            <span class="msg-logs-icon" />
          </a>
        </template>
      </i18n>
    </div>

    <div
      v-if="isUnrendered"
      data-automation="unrendered-variant-msg"
    >
      <h1 class="unrendered">
        <div class="typeDoc" />
        {{ $t('content.view.unrendered.title') }}
      </h1>
      <div class="wrapText">
        <i18n
          v-if="canViewLogs"
          tag="p"
          path="content.view.unrendered.checkLogs"
          class="unrendered"
        >
          <template #logs>
            <a
              data-automation="content-log-link"
              href="_"
              @click="showLogs"
              @keypress.enter="showLogs"
            >
              {{ $t('common.logs') }}
              <span class="msg-logs-icon" />
            </a>
          </template>
        </i18n>
        <i18n
          v-if="hasParams"
          tag="p"
          path="content.view.unrendered.param"
          class="unrendered"
          data-automation="unrendered-variant-param-msg"
        >
          <template #refresh>
            <span class="emphasize">{{ $t('content.view.refreshReport') }}</span>
          </template>
          <template #run>
            <span class="emphasize">{{ $t('content.view.runReport') }}</span>
          </template>
          <template #input>
            <span class="emphasize">{{ $t('common.input') }}</span>
          </template>
          <template #sched>
            <span class="emphasize">{{ $t('common.schedule') }}</span>
          </template>
        </i18n>
        <i18n
          v-else
          tag="p"
          path="content.view.unrendered.notParam"
          class="unrendered"
          data-automation="unrendered-variant-nonparam-msg"
        >
          <template #refresh>
            <span class="emphasize">{{ $t('content.view.refreshReport') }}</span>
          </template>
          <template #sched>
            <span class="emphasize">{{ $t('common.schedule') }}</span>
          </template>
        </i18n>
        <p class="unrendered">
          {{ $t('content.view.unrendered.redeploy') }}
        </p>
      </div>
    </div>

    <div
      v-if="isDeployed && !isPublished"
      data-automation="unpublished-content-msg"
    >
      <h1 class="unpublished">
        <div class="typeUnpublished" />
        {{ $t('content.view.unpublished.title', { type: appProse }) }}
      </h1>
      <i18n
        tag="p"
        path="content.view.unpublished.publish"
        class="unpublished"
      >
        <template #publish>
          <span class="emphasize">{{ $t('common.publish') }}</span>
        </template>
      </i18n>
      <i18n
        v-if="userIsAdmin"
        tag="p"
        path="content.view.unpublished.publish"
        class="unpublished"
        data-automation="admin-unpublished-msg"
      >
        <template #unpublishedLink>
          <a
            :title="unpublishedLink.title"
            :href="unpublishedLink.link"
          >
            {{ $t('content.view.unpublishedItems') }}
          </a>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import { unpublishedAppsPath } from '@/utils/paths';

export default {
  name: 'RenderMessage',
  props: {
    contentType: {
      type: String,
      required: true,
    },
    isDeployed: {
      type: Boolean,
      required: true,
    },
    isPublished: {
      type: Boolean,
      required: true,
    },
    isUnrendered: {
      type: Boolean,
      required: true,
    },
    hasParams: {
      type: Boolean,
      required: true,
    },
    userIsAdmin: {
      type: Boolean,
      required: true,
    },
    canViewLogs: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    appType() {
      return this.$t(`content.type.description.${this.contentType}`);
    },
    appProse() {
      return this.$t(`content.type.prose.${this.contentType}`);
    },
    unpublishedLink() {
      return {
        href: unpublishedAppsPath(),
        title: this.$t('content.view.unpublishedPage'),
      };
    },
  },
  methods: {
    showLogs(ev) {
      ev.preventDefault();
      this.$emit('show-logs');
    },
  },
};
</script>

<style scoped>
.msg-logs-icon {
  background-image: url(connect-elements/src/images/actionLogs.svg);
  background-repeat: no-repeat;
  background-size: 1.5rem;
  display: inline-block;
  height: 1.5rem;
  vertical-align: bottom;
  width: 1.5rem;
}
</style>
