<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <!-- NOTE: `mousedown` is used instead of `click` on both options and the close
       icon because any mouse clicks (or touches) outside of the input will
       cause a `blur` event on the input before the `click` event can be
       registered. Using `mousedown` in these instances is a fairly common
       workaround, but obviously not ideal. -->

  <div
    role="listbox"
    :class="{active}"
    :aria-label="label"
    class="search-select"
  >
    <input
      ref="input"
      v-model="text"
      :aria-label="label"
      :title="title"
      :disabled="disabled"
      type="text"
      class="search-select__input"
      :class="{ flashFade }"
      @focus="activate"
      @blur="deactivate"
      @keydown.esc="blur"
      @keydown.up.prevent="moveHighlight('up')"
      @keydown.down.prevent="moveHighlight('down')"
      @keydown.enter="selectHighlighted"
    >

    <button
      v-show="active"
      class="search-select__close"
      :aria-label="$t('parametersPanel.close')"
      @mousedown.prevent="blur"
    />

    <div
      v-show="active"
      class="search-select__options"
      @mousedown.prevent=""
    >
      <template v-for="group in filteredOptionsByGroup">
        <div
          v-if="group.name"
          :key="group.name"
          :data-testid="`${group.name}-group`"
          class="search-select__group"
          @mousedown.prevent=""
        >
          {{ group.name }}
        </div>

        <div
          v-for="option in group.options"
          :key="option.value"
          role="option"
          tabindex="-1"
          :data-value="option.value"
          :class="optionClasses(option, group)"
          :aria-selected="isSelected(option)"
          :title="option.text"
          class="search-select__option"
          @mouseover="setHighlighted(option)"
          @focus="setHighlighted(option)"
          @click.prevent="setSelected(option)"
          @keypress.enter="setSelected(option)"
        >
          <span>{{ option.text }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';

export default {
  name: 'SearchSelect',
  props: {
    label: {
      type: String,
      required: true,
    },
    // Array object shape: { value, text, group (optional), class (optional) }
    options: {
      type: Array,
      required: true,
    },
    // Object shape: { value, text, group (optional), class (optional) }
    selected: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    defaultValue: {
      type: [String, Number, Boolean, Date, Symbol],
      default: null
    },
    flashFade: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      active: false,
      text: '',
      highlighted: null,
    };
  },
  computed: {
    filteredOptions() {
      return this.filteredOptionsByGroup.reduce((acc, group) => acc.concat(group.options), []);
    },
    filteredOptionsByGroup() {
      const DEFAULT_GROUP_NAME = '__ungrouped__';
      const groupsByName = {};
      const matcher = new RegExp(this.text, 'ig');

      for (const option of this.options) {
        if (this.text === '' || option.text.match(matcher)) {
          const groupName = option.group || DEFAULT_GROUP_NAME;

          if (!groupsByName.hasOwnProperty(groupName)) {
            groupsByName[groupName] = [];
          }

          groupsByName[groupName].push(option);
        }
      }

      const groups = [];

      for (const [groupName, options] of Object.entries(groupsByName)) {
        if (groupName === DEFAULT_GROUP_NAME) {
          groups.unshift({ name: null, options });
        } else {
          groups.push({ name: groupName, options });
        }
      }

      return groups;
    }
  },
  watch: {
    selected: {
      handler: function() {
        this.text = this.selected && this.selected.text ? this.selected.text.replace(/\n/g, ' ') : '';
      },
      immediate: true
    },
    text: function() {
      // Select first element as highlighted when active and text changes
      if (this.active) {
        const options = this.filteredOptions;

        if (options.length > 0 && this.text !== '') {
          this.setHighlighted(options[0]);
        } else {
          this.clearHighlighted();
        }
      }
    }
  },
  methods: {
    setSelected(option) {
      this.blur();
      this.$emit('select', option.value);
    },
    setHighlighted(option) {
      this.highlighted = option;
    },
    clearHighlighted() {
      this.highlighted = null;
    },
    selectHighlighted() {
      if (this.highlighted) {
        this.setSelected(this.highlighted);
      }
    },
    activate() {
      this.active = true;
      this.text = '';
    },
    deactivate() {
      this.text = this.selected ? this.selected.text.replace(/\n/g, ' ') : '';
      this.highlighted = null;
      this.active = false;
    },
    blur() {
      // NOTE: `blur` and a $ref isn't ideal here, but it keeps the implementation much
      // simpler (which seems worth the tradeoff).
      this.$refs.input.blur();
    },
    moveHighlight(direction) {
      const options = this.filteredOptions;
      let index = this.highlighted ? options.indexOf(this.highlighted) : -1;

      switch (direction) {
        case 'up':
          index--;
          if (index >= 0) {
            this.setHighlighted(options[index]);
          }
          break;
        case 'down':
          index++;
          if (index < options.length) {
            this.setHighlighted(options[index]);
          }
          break;
      }
    },
    isHighlighted(option) {
      return (
        this.highlighted !== null &&
        this.highlighted !== undefined &&
        this.highlighted.value === option.value
      );
    },
    isSelected(option) {
      return (
        this.selected !== null &&
        this.selected !== undefined &&
        this.selected.value === option.value
      );
    },
    isDefault(option) {
      return (
        !isNil(this.defaultValue) &&
        this.defaultValue === option.value
      );
    },
    isDefaultGroup(group) {
      return group.name !== null;
    },
    optionClasses(option, group) {
      return {
        highlighted: this.isHighlighted(option),
        default: this.isDefault(option),
        grouped: !this.isDefaultGroup(group),
        selected: this.isSelected(option),
        [option.class]: !!option.class,
      };
    }
  },
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';

$search-select-text-color: $color-dark-grey-3;
$search-select-background-color: $color-white;
$search-select-background-color-hover: $color-light-grey;
$search-select-border-color: $color-medium-grey;
$search-select-disabled-text-color: $color-dark-grey;
$search-select-disabled-background-color: $color-light-grey;
$search-select-disabled-border-color: $color-light-grey-2;
$search-select-close-button-padding: 6px;
$search-select-input-padding: 6px;
$search-select-input-font-size: 0.8em;
$search-select-option-padding: 6px;
$search-select-option-text-color: $color-dark-grey-3;
$search-select-option-font-size: 0.8em;
$search-select-option-highlighted-color: $color-light-grey-2;
$search-select-options-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
$search-select-group-text-color: $color-dark-grey-2;
$search-select-group-font-size: 0.7em;
$search-select-group-font-weight: inherit;
$search-select-icon-size: 20px;
$search-select-options-max-height: 400px;

.search-select {
  position: relative;
  display: inline-block;
  margin: auto;

  &.active {
    .search-select__input {
      cursor: text;
    }
  }

  &:not(.active) {
    .search-select__input {
      background-image: url(connect-elements/src/images/iconDownArrow.svg);
      background-repeat: no-repeat;
      background-position: center right $search-select-input-padding;
      background-size: $search-select-icon-size $search-select-icon-size;

      &:hover {
        background-color: $search-select-background-color-hover;
      }

      &:disabled,
      &:disabled:hover {
        cursor: not-allowed;
        color: $search-select-disabled-text-color;
        background-color: $search-select-disabled-background-color;
        border-color: $search-select-disabled-border-color;
      }
    }
  }
}

.search-select__input {
  cursor: default;
  background-color: $search-select-background-color;
  border: 1px solid $search-select-border-color;
  color: $color-dark-grey-3;
  display: block;
  font-size: $search-select-input-font-size;
  padding: $search-select-input-padding;
  padding-right: $search-select-input-padding*2 + $search-select-icon-size;

  &::-ms-clear {
    display: none;
  }

  &.flashFade {
    animation: fadeOut 2s ease-out;
  }

  @keyframes fadeOut {
    0% {
      background-color: $color-alert-info;
    }

    100% {
      background-color: transparent;
    }
  }
}

.search-select__close {
  display: block;
  position: absolute;
  top: $search-select-input-padding;
  right: $search-select-input-padding;
  height: $search-select-icon-size;
  width: $search-select-icon-size;
  padding: $search-select-close-button-padding;

  background-color: $search-select-background-color;
  background-image: url(connect-elements/src/images/close.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $search-select-icon-size $search-select-icon-size;

  &:hover {
    background-color: $search-select-background-color-hover;
  }
}

.search-select__options {
  position: absolute;
  background-color: $search-select-background-color;
  max-height: $search-select-options-max-height;
  border: 1px solid $search-select-border-color;
  box-shadow: $search-select-options-shadow;
  overflow: auto;
  width: 100%;
  z-index: 1;
}

.search-select__group {
  display: block;
  color: $search-select-group-text-color;
  font-size: $search-select-group-font-size;
  font-weight: $search-select-group-font-weight;
  line-height: 1em;
  padding: $search-select-option-padding;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-select__option {
  display: block;
  cursor: pointer;
  color: $search-select-option-text-color;
  font-size: $search-select-option-font-size;
  line-height: 1.25em;
  padding: $search-select-option-padding $search-select-option-padding $search-select-option-padding $search-select-option-padding*2+$search-select-icon-size;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &.highlighted {
    background-color: $search-select-option-highlighted-color;
  }

  &.selected {
    background-image: url(connect-elements/src/images/check.svg);
    background-repeat: no-repeat;
    background-position: center left $search-select-option-padding;
    background-size: $search-select-icon-size $search-select-icon-size;
  }

  &.default {
    span {
      font-weight: 600;
    }
  }
}
</style>
