// Copyright (C) 2022 by Posit Software, PBC.

import { app } from '@/store/redux/apps/apps.selectors';

/**
 * Returns the given pageId's latest activity message. Never returns null.
 * `pageId`s (aka urls) are unique up to query params.
 *
 * @param {ReduxState} state
 * @param {String} pageId - ignores query params
 * @return {Activity} activity messages. This is never null.
 */
export function latestActivity(state, pageId) {
  pageId = removeQueryParams(pageId);
  const activities = state.appState.activityMessages[pageId] || [];
  return activities[0] || {};
}

export function activityDimmerCount(state, pageId) {
  pageId = removeQueryParams(pageId);
  return state.appState.activityDimmerCounts[pageId] || 0;
}

export function removeQueryParams(pageId) {
  if (pageId) {
    return pageId.replace(/^(.*)\?.*/, '$1');
  }
  return pageId;
}

/**
 * These two functions are awkwardly named (for lack of better names).
 *
 * - #isCurrentAppSettingsPanelVisibility is used to track visibility settings for apps
 * where the current user has read-only perms. Tracking is per app.
 * - #isGlobalAppSettingsPanelVisibility is used to track visibility settings for apps
 * where the current user can edit the app. One flag for all editable apps.
 *
 * Not sure what the requirement here was but the individual tracking vs mass tracking
 * is somewhat confusing. It's possibly not needed?
 * @param {ReduxState} state
 * @returns {boolean}
 */
export function isCurrentAppSettingsPanelVisible(state) {
  const _app = app(state) || {};
  return !!state.appState.settingsPanelMap[_app.id];
}

/**
 * These two functions are awkwardly named (for lack of better names).
 *
 * - #isCurrentAppSettingsPanelVisibility is used to track visibility settings for apps
 * where the current user has read-only perms. Tracking is per app.
 * - #isGlobalAppSettingsPanelVisibility is used to track visibility settings for apps
 * where the current user can edit the app. One flag for all editable apps.
 *
 * Not sure what the requirement here was but the individual tracking vs mass tracking
 * is somewhat confusing. It's possibly not needed?
 * @param {ReduxState} state
 * @returns {boolean}
 */
export function isGlobalAppSettingsPanelVisibile(state) {
  return state.appState.showSettingsPanel;
}

export function isParamsPanelOpen(state) {
  return state.appState.showParamsPanel;
}

export function lastKnownLoginTime(state) {
  return state.appState.lastKnownLoginUnix;
}

export function appStateReportProgress(state) {
  const { show = false } = state.appState.showReportProgress || {};
  return show;
}
