<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <ul
    v-if="applications.length"
    data-automation="content-list__blog"
  >
    <li
      v-for="app in applications"
      :key="app.guid"
      class="listItem content-blog-item"
    >
      <router-link
        :to="{ name: 'apps.access', params: { idOrGuid: app.guid } }"
        :title="$t(`content.list.tooltip.${app.contentType()}`)"
        class="listingImageWrapper"
        tabindex="-1"
      >
        <img
          class="content-blog-item__image"
          :alt="app.displayName"
          :src="appImagePath(app.guid)"
          data-automation="blog-item-image"
          @error="evt => appImageFallback(evt, app)"
        >
      </router-link>
      <div class="itemDetails">
        <div class="itemHeader">
          <router-link
            :to="{ name: 'apps.access', params: { idOrGuid: app.guid } }"
            :title="$t(`content.list.tooltip.${app.contentType()}`)"
            class="itemTitle content-blog-item__title"
            data-automation="blog-item-title"
          >
            <span
              :class="{ 'rsc-content-list__unpublished': !app.isDeployed() }"
            >
              {{ app.contentDisplayName() || $t('content.untitled') }}
            </span>
          </router-link>
        </div>

        <div class="itemMeta">
          <div
            v-if="hasOwner(app)"
            class="author"
            data-automation="blog-owner"
          >
            <router-link
              :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
              :title="$t('users.action.navigateOwner')"
            >
              <!-- TODO: word-wrap the displayOwnerName rather than truncate it,
                   once word-wrapping is fixed for RSPrincipalInfo component -->
              <RSPrincipalInfo
                :initials="displayOwnerInitials(app)"
                :name="displayOwnerName(app) | truncate(18)"
              />
            </router-link>
          </div>
          <div
            class="itemDescription"
            data-automation="blog-description"
          >
            <span>{{ app.description }}</span>
          </div>
          <div class="itemInfo">
            <div
              class="content-blog-item__info-chunk"
              data-automation="blog-type"
            >
              {{ $t('content.list.headers.type') }}: {{ $t(`content.type.description.${app.contentType()}`) }}
            </div>
            <div
              class="content-blog-item__info-chunk"
              data-automation="blog-deployed"
            >
              <span v-if="app.isDeployed()">
                {{ $t('content.list.headers.lastDeployedTime') }}: {{ app.lastDeployedTime | activeTime }}{{ fromGit(app.git) }}
              </span>
              <span
                v-else
                class="rsc-content-list__unpublished"
              >
                {{ $t('content.list.labels.unpublished') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import RSPrincipalInfo from 'Shared/components/RSPrincipalInfo';
import { activeTime } from '@/utils/activeTime.filter';
import { truncate } from '@/utils/truncate.filter';
import {
  hasOwner,
  fromGit,
  displayOwnerInitials,
  displayOwnerName,
} from '@/utils/contentList';
import { apiPath } from '@/utils/paths';

export default {
  name: 'ContentBlog',
  components: {
    RSPrincipalInfo,
  },
  filters: {
    activeTime,
    truncate,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasOwner,
    fromGit,
    displayOwnerInitials,
    displayOwnerName,
    appImagePath(appGuid) {
      return apiPath(`applications/${appGuid}/image`);
    },
    appImageFallback(evt, app) {
      const imgType = app.thumbnailType();
      evt.target.src = `images/contenttype/${imgType}.svg`;
      evt.target.classList.add('content-blog-item__image--default');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';
.listItem {
  @include flex-space-after();
  padding-top: 6px;
  padding-bottom: 20px;
  position: relative;

  .listingImageWrapper {
    display: block;
    flex-shrink: 0;

    width: 150px;
    margin: 0 15px 15px 0;
    height: fit-content;

    img {
      width: 150px;

      &:after {
        content: " ";
        display: block;
        background-color: $color-light-grey;
        width: 150px;
        height: 150px;
      }
    }
  }

  .itemDetails {
    width: 100%;
    font-size: 13px;
    line-height: 20px;

    .itemHeader {
      @include flex-space-between();
      align-items: flex-start;
      line-height: 30px;
      margin-bottom: 3px;

      .itemTitle {
        font-size: 20px;
        display: block;
      }
    }

    .itemMeta {
      .itemDescription {
        margin-bottom: 5px;
        white-space: pre-line;
      }

      .itemDate, .itemInfo {
        font-size: 12px;
        color: $color-dark-grey;
      }
    }
  }
}

@media screen and (max-width: 500px){
  .listItem {
    display: block;
    clear: both;

    &:after {
      @include really-clear-float();
    }
  }
}
.author {
  display: flex;
}
</style>
