// Copyright (C) 2022 by Posit Software, PBC.

const groups = {
  titles: {
    createGroup: 'Add Group',
    importGroup: 'Import Group',
    renameGroup: 'Rename Group',
    list: {
      filtered: 'Matching groups',
      all: 'Groups',
    },
    members: 'Members',
    noMembers: 'This group has no members',
    providerManaged:
      'Remote group owned by your authentication provider',
    owner: 'owner',
    addMember: 'Add Member',
    isLocal:
      'Connect is using local membership information.',
    isRemote:
      'Connect is using membership information from your remote {authProviderName} provider.',
    showAuthentication: 'Show Authentication Source',
    hideAuthentication: 'Hide Authentication Source',
  },
  search: {
    label: 'Search Groups',
    desc: 'Search for the Group to be imported to {systemDisplayName}.',
  },
  actions: {
    add: 'Add Group',
    create: 'Create Group',
    import: 'Import Group',
    delete: {
      title: 'Delete Group',
      label: 'Delete Group {name}',
      implications: 'Removing this group will disable access to all content related to this group, please consider that users that belong to {name} will stop seeing that content (if any).',
      desc: 'Are you sure you want to remove the group named: {name}?',
    },
    intervalNotice: 'Group details are updated in the background throughout the day. Learn more about the sync interval in the Posit Connect {0}',
    rename: {
      title: 'Rename Group',
      label: 'Rename Group {name}',
    },
    removeGroupMember: 'Remove {user} from group {group}',
    navigateGroup: `Group {name}'s details`,
    showOptions: 'Options',
  },
  status: {
    added: 'Group {name} added.',
    deleted: 'Group {name} successfully deleted.',
    renamed: 'Group successfully renamed as {name}.',
    loading: 'Getting groups...',
    loadingMembers: 'Getting members...',
    importingMemberships: 'Importing group memberships...',
  },
  name: {
    short: 'Group',
    long: 'Group Name',
    rename: 'Enter the new name for {name}.',
    errors: {
      required: 'Title is required and must be less than 4096 characters long.',
    },
  },
  options: {
    title: 'Options',
    actions: {
      close: 'close',
      clearSearch: 'clear search',
    },
    search: {
      filter: 'Search',
      description: 'Search for a group...',
    },
    info: {
      title: 'Info',
      exactTerm: 'exact',
      search: 'Use the Search field to search for groups by name.',
      searchWithUniqueID: 'Use the Search field to search groups by name or by their {0} remote identity (UniqueID).',
      addGroup: 'You can add a new group with the Add Group button.',
      importGroup: 'You can import a group with the Import Group button.',
    },
  },
};

export { groups };
