// Copyright (C) 2022 by Posit Software, PBC.

const executionEnvironments = {
  actionMessages: {
    added: 'Execution Environment "{title}" successfully added',
    updated: 'Execution Environment "{title}" successfully updated',
    deleted: 'Execution Environment "{title}" successfully deleted',
  },
  viewHeader: {
    addButton: 'Add Environment',
    search: {
      label: 'Search for environments',
      placeholder: 'Search for environments...',
    },
    title: 'Execution Environments',
  },
  table: {
    titleCol: 'Title',
    pythonCol: 'Python',
    quartoCol: 'Quarto',
    rCol: 'R',

  },
  summary: {
    actions: {
      edit: {
        altText: 'edit environment',
        title: 'Edit environment',
      },
      delete: {
        altText: 'delete environment',
        title: 'Delete environment',
      },
    },
    tooltips: {
      title: '{title} uses the container image {name}.',
      pythonInstalls: {
        one: 'Configured Python version: {version}',
        multiple: 'Configured Python versions: {version}',
        none: 'No Python versions configured',
      },
      quartoInstalls: {
        one: 'Configured Quarto version: {version}',
        multiple: 'Configured Quarto versions: {version}',
        none: 'No Quarto versions configured',
      },
      rInstalls: {
        one: 'Configured R version: {version}',
        multiple: 'Configured R versions: {version}',
        none: 'No R versions configured',
      },
      warnings: {
        // extra space and line returns are visible within tooltips!
        incomplete: `This environment will not be used by the system
until an administrator has defined installations.`,
      },
    },
    warnings: {
      incomplete: 'Incomplete',
    },
  },
  detailed: {
    titles: {
      description: 'Description',
      installations: 'Installations',
      advanced: 'Advanced Settings',
      attributes: 'Attributes',
    },
    labels: {
      matching: 'Matching strategy:',
      supervisor: 'Supervisor script:',
      updated: 'Last updated:',
      guid: 'API GUID:',
      imageName: 'Image name:',
    },
    actions: {
      copyGuid: { // get rid of Execution.. and lowercase environment
        title: 'Copy GUID to the clipboard',
        altText: 'Copy environment GUID',
        successMessage: 'Execution Environment GUID has been copied to the clipboard',
      },
      copyName: {
        title: 'Copy image name to Clipboard.',
        altText: 'Copy image name',
        successMessage: 'Execution Environment Image Name has been copied to the clipboard',
      },
    },
    empty: {
      description: 'No description provided for this environment.',
      installations: 'No installations defined for this environment.',
    },
    tooltips: {
      // extra space and line returns are visible within tooltips!
      incomplete: `This environment will not be used by the system
until an administrator has defined installations.`,
    },
  },
  deleteEnvironmentDialog: {
    title: 'Delete Execution Environment',
    deleteWarning: [
      `Removing an environment can prevent the execution of content 
       currently assigned to use that environment.`,
      `Proceed only if you are comfortable with this potential effect on published content.`,
    ],
    confirmRemovalTitle: 'Are you sure you want to remove the environment titled:',
    confirmRemovalReference: 'which references the image:',
  },
  emptyEnvironmentsList: {
    noneYetAdded: {
      everyone: [
        `No environments have been added yet.`,
        `Content will not be available until an administrator has added 
         appropriate environments to the system.`,
      ],
    },
    searchActive: {
      desc: [
        `The search string you have entered was not found
        in any of the known environments.`,
        `Please update or clear your search string.`,
      ],
      action: 'Clear search string'
    },
  },
  environmentAddOrEditDialog: {
    dialogTitle: {
      add: 'Add Environment',
      edit: 'Edit Environment',
    },
    submitLabel: {
      add: 'Add',
      edit: 'Update',
    },
    title: {
      label: 'Title',
      help: `Provide a title for this environment.`,
      // replace Value with attribute name
      error: `Title must be provided, be no longer than 64 characters in length,
        and cannot contain the characters: tab, newline, backspace, 
        form-feed or return.`,
    },
    description: {
      label: 'Description',
      help: `Provide a description which will elaborate on 
        the use and purpose of this environment.`,
      error: `Description is optional but if provided, 
        can be no longer than 4096 characters in length,
        and cannot contain the characters: tab, backspace, 
        or form-feed (returns are allowed).`,
    },
    name: {
      label: 'Image name (in repository)',
      help: `The location of your image within a container repository.
      Image name must be unique across the defined environments and cannot be adjusted later.
      For example: ghcr.io/rstudio/content-base:r4.1.0-py3.9.2-jammy`,
      error: `Image name must be provided, cannot contain the characters: space, tab, newline, backspace,
      form-feed or return and must be unique across Execution Environments.`,
    },
    matching: {
      label: 'Matching strategy',
      help: `The matching strategy influences how the server 
        will decide to use this Execution Environment when matching it to content 
        bundles. This is an advanced option and is normally 
        not required.`,
    },
    supervisor: {
      label: 'Supervisor script path',
      help: `The absolute path to the supervisor script within the image. Supervisors are
        used during the initialization of the Execution Environment. 
        This is an advanced option and is normally not required.`,
      error: `Supervisor script path is optional but if provided, must start with
        a slash (/) but not end with a slash (/) and cannot contain 
        the characters: tab, newline, backspace, form-feed or return.`,
    },
    installationList: {
      title: 'Installations Available',
      add: 'Add Installation',
      headers: {
        type: 'Type',
        version: 'Version',
        path: 'Path',
      },
      emptyList: `Environments are not used by Connect until an 
        installation has been added.`,
    },
    actionItems: {
      editAltText: 'edit environment',
      deleteAltText: 'remove environment',
    },
    accordionLabel: 'Advanced',
  },
  addOrEditInstallationDialog: {
    title: {
      add: 'Add Installation',
      edit: 'Edit Installation',
    },
    type: {
      label: 'Type',
      help: `The type (Python, Quarto or R) of the installation being 
        defined within this environment.`,
      options: {
        python: 'Python',
        quarto: 'Quarto',
        r: 'R',
      }
    },
    version: {
      label: 'Version',
      placeholder: 'major.minor.patch',
      help: `The full version string (major.minor.patch) of this 
        installation. For example: '3.8.13'`,
      error: `Version must be provided, be in the form of a semantic version
        string (major.minor.patch), and cannot contain the characters: 
        tab, newline, backspace, form-feed or return.`,
    },
    path: {
      label: 'Path',
      placeholder: 'absolute path to executable',
      help: {
        python: `The full path to the Python executable file. 
          For example: '/opt/python/3.8.1/bin/python'`,
        quarto: `The full path to the Quarto executable file. 
          For example: '/opt/quarto/bin/quarto'`,
        r: `The full path to the R executable file. 
          For example: '/opt/R/4.0.5/bin/R'`,
      },
      error: `Path must be provided, must start with a slash (/), 
      must not end with a slash (/), and cannot contain the characters: 
      tab, newline, backspace, form-feed or return.`
    },
  },
};

export { executionEnvironments };
