// Copyright (C) 2022 by Posit Software, PBC.

const variants = {
  select: {
    label: 'Variant',
    title: {
      default: 'Select a variant',
      disabled:
        'Disabled while editing settings - save or discard your changes',
    },
  },
  visibility: {
    personal: 'Personal',
    shared: 'Shared',
    runs: 'Runs',
  },
};

export { variants };
