// Copyright (C) 2022 by Posit Software, PBC.

const connect = {
  longDescription: `
    Posit Connect is a publishing platform for the work your teams create in
    R and Python. Share Shiny applications, R Markdown reports, Plumber and
    Flask APIs, Dash and Streamlit applications, Quarto projects, dashboards,
    plots, Jupyter Notebooks, and more in one convenient place. Use
    push-button publishing from the RStudio IDE, scheduled execution of
    reports, and flexible security policies to bring the power of data science
    to your entire enterprise.`,
};

export { connect };
