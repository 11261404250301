// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath } from '@/utils/paths';
import { delay } from '@/store/redux/utils/delay';

/**
 * * @typedef Task
 * * @property {string} id
 * * @property {number} user_id
 * * @property {any} status
 * * @property {any} result
 * * @property {boolean} finished
 * * @property {any} code
 * * @property {string} error
 * * @property {number} last_status
 */

/**
 * getTask returns the status of a server-side task
 * @param {string} taskId unique id of the task
 * @param {number} firstStatus offset of the first status message to return
 * @return {AxiosPromise<any>} result of the call
 */
export function getTask(taskId, firstStatus = 0) {
  return axios.get(
    apiPath(
      `tasks/${encodeURIComponent(taskId)}?first_status=${encodeURIComponent(
        firstStatus
      )}`
    )
  );
}

/**
 * taskToPromise converts a taskId into a promise
 * and additionally fires off a callback every time
 * the task is polled.
 * Finally, the `last_status` is followed, meaning only
 * new status lines are fetched on each poll.
 * @param {string} taskId unique id of the task
 * @param {function} onPoll function that accepts a task object.
 * Has no return value.
 * @returns {Promise<Task>} the finished task object
 * @throws when any of the underlying task calls fail
 * but not if the task itself fails.
 */
export async function taskToPromise(taskId, onPoll = () => {}) {
  let task = await getTask(taskId);
  onPoll(task.data);
  while (!task.data.finished) {
    await delay(400);
    task = await getTask(taskId, task.data.last_status);
    onPoll(task.data);
  }
  return task.data;
}
