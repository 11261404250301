<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    width="wide"
    :closeable="!isDeploying"
    :subject="logTitle"
    data-automation="bundle-logs-modal"
    @close="$emit('close')"
  >
    <template #content>
      <LogViewer
        v-if="isDeploying || finishedDeploying"
        :entries="deployLogs"
        :embedded="false"
        class="output"
      />
      <LogOutput
        v-else-if="jobs.length > 0"
        :app="app"
        :job="currentJob"
        class="output"
      />
    </template>
    <template #controls>
      <div class="actions flex">
        <div class="actionBar showTitles alignWithRegularButtons">
          <button
            v-if="previousVisible"
            class="action back"
            :disabled="!previousEnabled"
            data-automation="previous-log"
            @click="prevJob"
          >
            {{ $t('sourceVersions.bundleLog.previous') }}
          </button>
          <button
            v-if="nextVisible"
            class="action forward"
            :disabled="!nextEnabled"
            data-automation="next-log"
            @click="nextJob"
          >
            {{ $t('sourceVersions.bundleLog.next') }}
          </button>
        </div>
        <a
          v-if="downloadVisible"
          class="button default"
          :href="downloadUrl"
          :class="{ disabled: !downloadEnabled }"
          data-automation="log-download"
        >{{ $t('sourceVersions.bundleLog.download') }}</a>
      </div>
    </template>
  </RSModal>
</template>

<script>
import RSModal from 'Shared/components/RSModal';
import LogOutput from '@/views/content/LogsOverlay/LogOutput';
import LogViewer from '@/components/LogViewer';
import { downloadJobLogPath, getJobLog } from '@/api/jobs';
import {
  LOAD_BUNDLE_JOBS,
  SET_BUNDLE_LOG_POSITION,
} from '@/store/modules/bundles';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'BundleLog',
  components: { RSModal, LogOutput, LogViewer },
  props: {
    selectedBundleId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      jobs: state => state.bundles.selected.jobs,
      isFetchingBundles: state => state.bundles.isFetchingBundles,
      isFetchingBundleJobs: state => state.bundles.isFetchingBundleJobs,
      currentJobKey: state => state.bundles.selected.currentJobKey,
      deployLogs: state => state.bundles.selected.deployLogs,
      isDeploying: state => state.bundles.isDeploying,
    }),
    jobIndex() {
      return this.jobs.findIndex(j => j.key === this.currentJobKey);
    },
    currentJob() {
      return this.jobs[this.jobIndex];
    },
    previousVisible() {
      return !this.isDeploying && this.jobs.length > 1;
    },
    previousEnabled() {
      return this.jobIndex < this.jobs.length - 1;
    },
    nextVisible() {
      return !this.isDeploying && this.jobs.length > 1;
    },
    nextEnabled() {
      return this.jobIndex > 0;
    },
    downloadVisible() {
      return !this.isDeploying && !this.finishedDeploying && this.jobs.length > 0;
    },
    downloadEnabled() {
      return this.jobs.length > 0;
    },
    downloadUrl() {
      return this.app.guid && this.currentJobKey ? downloadJobLogPath(this.app.guid, this.currentJobKey) : '';
    },
    isFetchingData() {
      return this.isFetchingBundleJobs || this.isFetchingBundles;
    },
    finishedDeploying() {
      return !this.isDeploying && this.deployLogs.length;
    },
    logTitle() {
      if (this.isFetchingData) {
        return this.$t('sourceVersions.bundleLog.title.fetching');
      }
      if (this.currentJobKey === false) {
        return this.$t('sourceVersions.bundleLog.title.noLog');
      }

      if (this.jobs.length === 0 || this.jobIndex === -1) {
        return this.$t('sourceVersions.bundleLog.title.notFound');
      }

      if (this.isDeploying) {
        return this.$t('sourceVersions.bundleLog.title.deploying');
      }

      if (this.finishedDeploying) {
        return this.$t('sourceVersions.bundleLog.title.completed');
      }

      return this.$t('sourceVersions.bundleLog.title.deployed', {
        bundleId: this.currentJob.bundleId,
        jobKey: this.currentJob.key,
      });
    },
  },
  created() {
    const jobTag = this.app.jobTag().toJSON();
    const appId = this.app.id;
    if (!this.isDeploying) {
      this.fetchBundleJobs({ bundleId: this.selectedBundleId, appId, jobTag });
    }
  },
  methods: {
    ...mapMutations({
      setBundleLogPosition: SET_BUNDLE_LOG_POSITION,
    }),
    ...mapActions({
      fetchBundleJobs: LOAD_BUNDLE_JOBS,
    }),
    nextJob() {
      const { key } = this.jobs[this.jobIndex - 1];
      this.setBundleLogPosition(key);
      getJobLog(this.app.guid, key);
    },
    prevJob() {
      const { key } = this.jobs[this.jobIndex + 1];
      this.setBundleLogPosition(key);
      getJobLog(this.app.guid, key);
    },
  }
};
</script>

<style lang="scss" scoped>
.output {
  height: 60vh;
}
</style>
