<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div class="band">
    <AboutLocal
      v-if="initialized && !hasOffHostEnv"
      :about="about"
      :build="build"
      :version="version"
      :environments="environments"
      :user-profile="userProfile"
    />
    <AboutOffHost
      v-if="initialized && hasOffHostEnv"
      :about="about"
      :build="build"
      :version="version"
      :environments="environments"
      :user-profile="userProfile"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import {
  getServerSettings,
  getRInstallations,
  getPythonInstallations,
  getQuartoInstallations,
  ExecutionTypeK8S,
} from '@/api/serverSettings';
import { mapState } from 'vuex';
import { setErrorMessageFromAPI } from '@/utils/status';

import AboutLocal from '@/views/documentation/AboutLocal';
import AboutOffHost from '@/views/documentation/AboutOffHost';
import UserRoles from '@/api/dto/userRole';

export default {
  name: 'About',
  components: {
    AboutLocal,
    AboutOffHost,
  },
  data() {
    return {
      about: '',
      build: '',
      version: '',
      userProfile: {},
      rInstalls: [],
      pythonInstalls: [],
      quartoInstalls: [],
      environments: {},
      initialized: false,
      hasOffHostEnv: false,
      enableImageManagement: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const serverSettings = await getServerSettings();
        const { about, build, version, executionType, enableImageManagement } = serverSettings;
        this.about = about;
        this.build = build;
        this.version = version;
        this.hasOffHostEnv = executionType === ExecutionTypeK8S;
        this.enableImageManagement = enableImageManagement && this.hasOffHostEnv; // only if k8s

        if (this.currentUser?.userRole >= UserRoles.Publisher &&
            !this.enableImageManagement) {
          await this.getInstallations();
        }
        this.initialized = true;
      } catch (e) {
        setErrorMessageFromAPI(e);
      }
    },
    // This will update this.environments
    // versionType: 'rVersions', 'pythonVersions', or 'quartoVersions'
    combineVersionsWithInstalls(installs, versionType) {
      if (versionType !== 'rVersions' && versionType !== 'pythonVersions' && versionType !== 'quartoVersions') {
        return;
      }
      installs.forEach(install => {
        const key = `${install.cluster_name}:${install.image_name}`;
        if (this.environments[key] === undefined) {
          Vue.set(this.environments, key, {
            clusterName: install.cluster_name,
            imageName: install.image_name,
            rVersions: [],
            pythonVersions: [],
            quartoVersions: [],
          });
        }
        // Duplicates may come through, so we need to suppress them
        if (!this.environments[key][versionType].includes(install.version)) {
          this.environments[key][versionType].push(install.version);
        }
      });
    },
    async getInstallations() {
      try {
        this.environments = {};

        const rInstalls = await getRInstallations();
        this.combineVersionsWithInstalls(rInstalls, 'rVersions');

        const pythonInstalls = await getPythonInstallations();
        this.combineVersionsWithInstalls(pythonInstalls, 'pythonVersions');

        const quartoInstalls = await getQuartoInstallations();
        this.combineVersionsWithInstalls(quartoInstalls, 'quartoVersions');

        // In the (rare) situation where R, Python, and Quarto are all
        // disabled, build a fake (Local:Local) environment having no
        // installations.
        //
        // This lets us report (none) data to publishers.
        if (Object.keys(this.environments).length === 0) {
          Vue.set(this.environments, 'Local:Local', {
            clusterName: 'Local',
            imageName: 'Local',
            rVersions: [],
            pythonVersions: [],
            quartoVersions: [],
          });
        }
      } catch (e) {
        setErrorMessageFromAPI(e);
      }
    },
  },
};
</script>
