// Copyright (C) 2022 by Posit Software, PBC.

const resetPassword = {
  titles: {
    resetPassword: 'Restablecer contraseña',
    resetPasswordEmailSent: 'Correo Electrónico de Restablecimiento de Contraseña Enviado',
  },
  instructions: {
    checkEmail: 'Compruebe su correo electrónico para obtener instrucciones sobre cómo restablecer su contraseña. Póngase en contacto con su administrador de {systemDisplayName} para obtener ayuda si su nombre de usuario es correcto pero no ha recibido un correo electrónico después de unos minutos.',
    enterUsername: 'Introduzca su nombre de usuario y seleccione Restablecer Contraseña. Se enviará un correo electrónico a la dirección registrada en su cuenta con más instrucciones.',
    askYourAdmin: 'Pida a su administrador {systemDisplayName} que obtenga un {resetLink} de su perfil de usuario para ti. Al visitar este enlace podrá establecer una nueva contraseña.',
    resetLink: 'enlace de restablecimiento de contraseña',
  },
  validationMessage: 'Este campo no puede quedar en blanco.',
};

export { resetPassword };
