// Copyright (C) 2022 by Posit Software, PBC.

const versions = {
  connect: {
    label: 'Construir',
  },
  python: {
    label: 'Versión de Python disponible | Versiones de Python disponibles',
  },
  r: {
    label: 'Versión R Disponible | Versiones R disponibles',
  },
};

export { versions };
