// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { apiPath, apiV1Path, apiExperimentalPath } from '@/utils/paths';
import { App } from './dto/app';
import { taskToPromise } from './tasks';
import { keysToCamel, keysToSnake } from './transform';

/**
 * Get an app
 *
 * @deprecated use #getAppBy instead
 * @param {string | number} appId - ID of the app
 * @returns {Promise<App>}
 */
export function getApp(appId) {
  return axios
    .get(apiPath(`applications/${encodeURIComponent(appId)}`))
    .then(({ data }) => new App(keysToCamel(data)));
}

/**
 * Get an app
 *
 * @param {string} guid - GUID of the app
 * @returns {Promise<App>}
 */
export function getAppBy(guid) {
  return axios
    .get(apiV1Path(`content/${encodeURIComponent(guid)}`))
    .then(({ data }) => {
      return App.fromV1(keysToCamel(data));
    });
}

/**
 * Updates an app's settings.
 *
 * @deprecated use #updateContent instead
 * @param {string | number} appId - ID of the app
 * @param {object} updatePayload - values that need to be updated.
 * @returns {Promise<App>}
 */
export function updateApp(appId, updatePayload) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}`),
      keysToSnake(updatePayload)
    )
    .then(({ data }) => new App(keysToCamel(data)));
}

/**
 * Updates a content item settings.
 *
 * @param {string} contentGuid - GUID of the content
 * @param {object} updatePayload - values that need to be updated.
 * @returns {Promise<App>}
 */
export function updateContent(contentGuid, updatePayload) {
  return axios
    .patch(
      apiV1Path(`content/${encodeURIComponent(contentGuid)}`),
      keysToSnake(updatePayload)
    )
    .then(({ data }) => new App(keysToCamel(data)));
}

export function updateAppImage(appGuid, updatePayload) {
  return axios.post(
    apiPath(`applications/${encodeURIComponent(appGuid)}/image`),
    updatePayload
  )
    .then(({ data }) => data);
}

export function deleteAppImage(appGuid) {
  return axios.delete(
    apiPath(`applications/${encodeURIComponent(appGuid)}/image`)
  ).then(({ data }) => data);
}

/**
 * Get an app tags
 * @param {string|number} appId The id of the app to retrieve the related tags
 * @returns {Promise} A Promise that resolves with the app's tags
 */
export function getAppTags(appId) {
  const url = apiPath(`applications/${appId}/tags`);
  return axios.get(url).then(response => {
    return keysToCamel(response.data);
  });
}

/**
 * Associate an app to a tag
 * @param {string|number} appId The id of the app
 * @param {string|number} tagId The id of the tag to be set
 * @returns {Promise} A Promise that resolves when the tag is added to the app
 */
export function addTagToApp(appId, tagId) {
  const url = apiPath(`applications/${appId}/tags`);
  const payload = { id: tagId };
  return axios.post(url, payload);
}

/**
 * Remove an associated tag from an app
 * @param {string|number} appId The id of the app
 * @param {string|number} tagId The id of the tag to be removed from the app
 * @returns {Promise} A Promise that resolves when the tag is removed from the app
 */
export function removeTagFromApp(appId, tagId) {
  const url = apiPath(`applications/${appId}/tags/${tagId}`);
  return axios.delete(url);
}

/**
 * Get an app's environment vars
 * @param {string|number} appId The id of the app to retrieve the vars
 * @returns {Promise} A Promise that resolves with the app's vars
 */
export function getAppVars(appId) {
  const url = apiPath(`applications/${appId}/environment`);
  return axios.get(url).then(response => keysToCamel(response.data, [], false));
}

/**
 * Get an app's image
 * @param {string} appGuid
 * @returns {Promise<AxiosResponse<Blob>>}
 */
export function getAppImage(appGuid) {
  const url = apiPath(`applications/${appGuid}/image`);
  return axios.get(url, { responseType: 'blob' })
    .then(({ data }) => data);
}

/**
 * Create/Update/Remove an app's environment var
 * @param {string|number} appId The id of the app to create/update/remove the var
 * @param {string|number} version The version number to create/update/remove the new var
 * @param {string|number} values The values obj to create/update/remove the new var
 * @returns {Promise} A Promise that resolves
 */
export function updateAppVars(appId, version, values) {
  const url = apiPath(`applications/${appId}/environment`);
  return axios.post(url, { version, values });
}

export function setApplicationRepository(appId, repoUrl, branch, subdirectory) {
  const data = {
    repository: repoUrl.trim(),
    branch: branch.trim(),
    subdirectory: subdirectory.trim(),
  };
  return axios.post(
    apiPath(`applications/${encodeURIComponent(appId)}/repo`),
    data
  );
}

/**
 * createApplication creates a new application record but does not deploy it
 * @param {string} appName application `name` field, must be a slug of some kind
 * @param {string} appTitle title field, free form text
 * @returns {AxiosPromise<any>}
 */
export function createApplication(appName, appTitle = undefined) {
  const data = { name: appName };
  if (appTitle !== undefined) {
    data.title = appTitle;
  }
  return axios.post(apiPath(`applications`), data);
}

/**
 * deployApplication deploys an application. An appId is required.
 * A bundleId is required when the application is not git-backed.
 * @param {number} appId the application ID
 * @param {number?} bundleId (optional) the bundle ID
 * @returns {AxiosPromise<any>}
 */
export function deployApplication(appId, bundleId = undefined) {
  let data = {};
  if (bundleId !== undefined) {
    data = { bundleId };
  }
  return axios.post(
    apiExperimentalPath(`content/${encodeURIComponent(appId)}/deploy`),
    data
  );
}

export function deployApplicationResult(appId, onPoll = () => {}) {
  return deployApplication(appId)
    .then(task => taskToPromise(task.data.task_id, onPoll));
}

export function terminateApplication(appId) {
  return axios
    .post(apiPath(`applications/${encodeURIComponent(appId)}/terminate`));
}

/**
 * Updates an app's runAs settings.
 *
 * @param {string | number} appId - ID of the app
 * @param {object} updatePayload - new runAs settings (runAs, runAsCurrentUser)
 * @returns {Promise<App>}
 */
export function updateRunAs(appId, updatePayload) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}/runas`),
      keysToSnake(updatePayload)
    )
    .then(({ data }) => new App(keysToCamel(data)));
}

/**
 * Add a user principal to an app's ACL. (Also used to update the user's role.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} guid - GUID of the user to add/update
 * @param {string} appRole - the access role the user should have for the app
 * @returns {Promise} an empty Promise that revolves when the user is added
 */
export function addUser(appId, guid, appRole) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}/users`),
      keysToSnake({ guid, appRole }),
    );
}

/**
 * Add a group principal to an app's ACL. (Also used to update the group's role.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} guid - GUID of the group to add/update
 * @param {string} appRole - the access role the group should have for the app
 * @returns {Promise} an empty Promise that revolves when the group is added
 */
export function addGroup(appId, guid, appRole) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}/groups`),
      keysToSnake({ guid, appRole }),
    );
}

/**
 * Remove a user's access to an app (remove them from the ACL.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} userGuid - GUID of the user to remove
 * @returns {Promise} an empty Promise that resolves when the user is removed
 */
export function removeUser(appId, userGuid) {
  return axios
    .delete(apiPath(`applications/${encodeURIComponent(appId)}/users/${encodeURIComponent(userGuid)}`));
}

/**
 * Remove a group's access to an app (remove them from the ACL.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} groupGuid - GUID of the group to remove
 * @returns {Promise} an empty Promise that revolves when the group is removed
 */
export function removeGroup(appId, groupGuid) {
  return axios
    .delete(apiPath(`applications/${encodeURIComponent(appId)}/groups/${encodeURIComponent(groupGuid)}`));
}
