<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="contentContainer"
    data-automation="content-frame-container"
    :class="wrapperClass"
  >
    <div
      v-if="variantHasNoRuns"
      data-automation="no-content-page"
      class="blank-container"
    >
      <p class="blank-content-message">
        {{ $t('content.view.blank.message') }}
      </p>
    </div>

    <div v-else>
      <iframe
        v-if="showAppFrame"
        id="contentIFrame"
        data-automation="content-frame"
        width="100%"
        height="100%"
        :class="frameClass"
        :src="appFrameUrl"
        :title="systemDisplayName"
      />
    </div>

    <div
      v-if="variantIsBusy"
      class="contentMessageContainer"
      data-automation="report-in-progress"
    >
      <div class="genMessage">
        <div class="genMessageSpinnerContainer">
          <Spinner />
        </div>
        {{ $t('content.view.runningReport') }}
      </div>
    </div>

    <RenderMessage
      v-if="showRenderMessage"
      :content-type="app.contentType()"
      :is-deployed="appIsReady"
      :is-published="isPublished"
      :is-unrendered="isUnrederedVariant"
      :has-params="isParameterized"
      :user-is-admin="currentUser.isAdmin()"
      :can-view-logs="userCanViewLogs"
      @show-logs="seeAppLogs"
    />

    <RSModal
      v-if="pamReauthRequested"
      data-automation="app-reauth-request-modal"
      :active="true"
      :subject="$t('content.view.pamReauthModal.loginAgain')"
      @close="cancelPamReauth"
    >
      <template #content>
        <p>
          {{ $t('content.view.pamReauthModal.message') }}
        </p>
      </template>
      <template #controls>
        <RSButton
          data-automation="confirm-pam-reauth"
          :label="$t('common.buttons.ok')"
          @click="redirectToLogin"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import { serverURL } from '@/utils/paths';
import {
  SET_LOGS_PANEL_VISIBILITY,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import { mapState, mapMutations } from 'vuex';
import { isEmpty } from 'lodash';

import Spinner from '@/components/Spinner';
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';
import RenderMessage from './RenderMessage';

export const SHOW_SETTINGS_CLASS = 'showSettingsPanel';
export const HINT_PARAMS_CLASS = 'hintParamsPanel';
export const SHOW_PARAMS_CLASS = 'showParamsPanel';
export const IFRAME_REAUTH_REQ = 'loginAgain';

export default {
  name: 'EmbedApp',
  components: {
    Spinner,
    RSModal,
    RSButton,
    RenderMessage,
  },
  data() {
    return {
      pamReauthRequested: false,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      appError: state => state.contentView.appError,
      showLogs: state => state.contentView.showLogs,
      showSettingsPanel: state => state.contentView.showSettingsPanel,
      showParametersPanel: state => state.contentView.showParametersPanel,
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      serverSettings: state => state.server.settings,
      runs: state => state.parameterization.runs,
      variants: state => state.parameterization.variants,
      currentVariant: state => state.parameterization.currentVariant,
      isUnauthorized: state => state.contentView.isUnauthorized,
      renderingHistory: state => state.contentView.renderingHistory,
      reloadingContentFrame: state => state.contentView.reloadingContentFrame,
      appHistory: state => state.bundles,

      variantIsBusy: state => state.legacyParams.isBusy || state.parameterization.isBusy,
      legacyAdHocRun: state => state.legacyParams.adHocRun,
      newLegacyVariant: state => state.legacyParams.isNew,
    }),
    appIdOrGuidParam() {
      return this.$route.params.idOrGuid;
    },
    systemDisplayName() {
      return this.serverSettings.systemDisplayName;
    },
    permissionRequestEnabled() {
      return this.serverSettings.permissionRequest;
    },
    useWindowLocation() {
      return this.serverSettings.useWindowLocation;
    },
    newParameterizationEnabled() {
      return this.serverSettings.newParameterizationEnabled;
    },
    wrapperClass() {
      const wClass = [];
      const handleParamDocClasses = (
        !!this.app &&
        this.isParameterized &&
        this.isAuthenticated &&
        this.currentUser.canViewApp(this.app));

      if (this.useShowSettingsClass) {
        wClass.push(SHOW_SETTINGS_CLASS);
      }

      if (handleParamDocClasses) {
        if (this.showParametersPanel) {
          wClass.push(SHOW_PARAMS_CLASS);
        } else {
          wClass.push(HINT_PARAMS_CLASS);
        }
      }

      return wClass;
    },
    useShowSettingsClass() {
      // If app didn't load and user is unauthorized, settings not showing
      // (a non-admin user without app permissions)
      if (!this.app && this.isUnauthorized) {
        return false;
      }

      return this.showSettingsPanel || (
        !this.appIsReady ||
        this.currentUser.isAnonymous()
      );
    },
    frameClass() {
      const frameClass = ['appFrame'];
      if (this.variantIsBusy) {
        frameClass.push('outOfDate');
      }
      return frameClass;
    },
    userCanViewLogs() {
      return this.currentUser.canViewAppSettings(this.app);
    },
    variantHasNoRuns() {
      return this.newParameterizationEnabled &&
        this.isParameterized &&
        !isEmpty(this.variants) &&
        isEmpty(this.runs) &&
        isEmpty(this.renderingHistory.items);
    },
    appIsReady() {
      return !this.appError && this.app?.isDeployed();
    },
    appFrameUrl() {
      let appUrl = '';

      if (this.showUnauthorizedView || this.currentUser.isAnonymous()) {
        return this.anonUrl;
      }

      if (!this.appIsReady || this.isUnrederedVariant) {
        return '';
      }

      // NOTE: This used to come from `app.url`, but was changed to support
      // customer environments struggling with proxy configuration.
      if (this.useWindowLocation) {
        appUrl = serverURL(`content/${ this.appIdOrGuidParam }/`);
      } else {
        appUrl = this.app.url;
      }

      // Showing a variant
      if (!isEmpty(this.currentVariant)) {
        const variantKey = this.legacyAdHocRun.key || this.currentVariant.key;
        appUrl += `v${variantKey}/`;
        if (this.showHistoricalVariant) {
          appUrl += `_rev${this.renderingHistory.displayedId}/`;
        }
      }

      // Showing a previous render
      if (this.showPreviousBundle) {
        appUrl += `_rev${this.appHistory.displayedId}/`;
      }

      return appUrl;
    },
    showHistoricalVariant() {
      return (
        this.isPublished &&
        !this.newLegacyVariant &&
        isEmpty(this.legacyAdHocRun) &&
        this.currentVariant.renderingId !== this.renderingHistory.displayedId
      );
    },
    showPreviousBundle() {
      return (
        this.app.isStatic() &&
        this.currentUser.isAppEditor(this.app) &&
        this.app.bundleId !== this.appHistory.displayedId
      );
    },
    isUnrederedVariant() {
      return !isEmpty(this.currentVariant) && !this.currentVariant.renderDuration;
    },
    isParameterized() {
      return this.app?.hasParameters;
    },
    isPublished() {
      return !isEmpty(this.currentVariant);
    },
    anonUrl() {
      if (this.currentVariant.renderTime) {
        return serverURL(`content/${ this.appIdOrGuidParam }/v${ this.currentVariant.key }/#__rscembedded__`);
      }
      return serverURL(`content/${ this.appIdOrGuidParam }/#__rscembedded__`);
    },
    showUnauthorizedView() {
      return this.isUnauthorized && this.permissionRequestEnabled;
    },
    showAppFrame() {
      return (
        Boolean(this.appFrameUrl) &&
        !this.reloadingContentFrame &&
        !this.variantIsBusy
      );
    },
    showRenderMessage() {
      return this.app && !this.showAppFrame;
    },
  },
  watch: {
    reloadingContentFrame(reloading) {
      // Content iframe will be removed when "reloading" state is set.
      // Dispatch state reloading to false for the iframe to show up again on next tick.
      if (reloading) {
        this.$nextTick(() => this.setFrameReloadState(false));
      }
    },
  },
  created() {
    window.addEventListener('message', this.pamReauthHandler);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.pamReauthHandler);
  },
  methods: {
    ...mapMutations({
      setFrameReloadState: SET_CONTENT_FRAME_RELOADING,
      setLogsVisibility: SET_LOGS_PANEL_VISIBILITY,
    }),
    pamReauthHandler({ data, origin: o }) {
      const expectedOrigin = `${location.protocol}//${location.host}`;

      if (!data || data !== IFRAME_REAUTH_REQ || o !== expectedOrigin) {
        // Deny action,
        // - if message not comming from the same origin
        // - if message does not have the expected data
        return;
      }

      this.pamReauthRequested = true;
    },
    cancelPamReauth() {
      this.pamReauthRequested = false;
    },
    redirectToLogin() {
      this.$router.push({ name: 'login_view' });
    },
    seeAppLogs() {
      this.$router.push({ name: 'apps.logs', replace: true });
      this.setLogsVisibility(true);
    },
  },
};
</script>

<style lang="scss" scoped>
  .blank-container {
    display: flex;
    justify-content: center;
    padding-top: 3.5rem;
  }

  .blank-content-message {
    font-size: 1.25rem;
  }
</style>
