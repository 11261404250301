<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <EmbeddedStatusMessage
      v-if="loading"
      :message="$t('appSettings.info.status.loading')"
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <div
      v-if="!loading"
      class="formSection itemDate"
    >
      <div
        class="created"
        data-automation="settings-info-created"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.created') }}</span> {{ created }}
      </div>
      <div
        class="updated"
        data-automation="settings-info-last-deployed"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.lastDeployed') }}</span> {{ lastDeployed }}
      </div>
      <div
        class="type"
        data-automation="settings-info-type"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.contentType') }}</span> {{ typeDescription }}
      </div>
      <div
        class="guid"
        data-automation="settings-info-app-guid"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.GUID') }}</span> {{ appGuid }}
        <img
          src="/images/copy.svg"
          class="copy-guid"
          :alt="$t('appSettings.info.contentInfo.copyGUID')"
          tabindex="0"
          role="button"
          @click="copyGUID"
          @keydown.space="copyGUID"
        >
      </div>
      <div
        class="app-id"
        data-automation="settings-info-app-id"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.contentID') }}</span> {{ appId }}
      </div>
      <div
        v-show="rVersion"
        class="rversion"
        data-automation="settings-info-rversion"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.rVersion') }}</span> {{ rVersion }}
      </div>
      <div
        v-show="pyVersion"
        class="pyversion"
        data-automation="settings-info-pyversion"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.pythonVersion') }}</span> {{ pyVersion }}
      </div>
      <div
        v-show="quartoVersion"
        class="quartoversion"
        data-automation="settings-info-quartoversion"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.quartoVersion') }}</span> {{ quartoVersion }}
      </div>
      <div
        v-show="imageName && clusterName"
        class="imageNameWithCluster"
        data-automation="settings-info-imageNameWithCluster"
      >
        <span class="emphasize">{{ $t('appSettings.info.contentInfo.lastExecution') }}</span>
        {{ $t('appSettings.info.contentInfo.usedImageWithinCluster', { imageName, clusterName }) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-mini';
import { mapState } from 'vuex';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { copyToClipboard } from '@/utils/clipboard';

export default {
  name: 'ContentInfo',
  components: {
    EmbeddedStatusMessage
  },
  computed: {
    ...mapState({
      created: ({ infoSettings: { app: { createdTime } } }) => moment(createdTime)
        .format('MMM D, YYYY h:mma ([GMT]Z)'),
      lastDeployed: ({ infoSettings: { app: { lastDeployedTime } } }) => moment(lastDeployedTime)
        .format('MMM D, YYYY h:mma ([GMT]Z)'),
      typeDescription: state => state.infoSettings.app.typeDescription,
      appGuid: state => state.infoSettings.app.guid,
      appId: state => state.infoSettings.app.id,
      rVersion: state => state.infoSettings.app.rVersion,
      pyVersion: state => state.infoSettings.app.pyVersion,
      quartoVersion: state => state.infoSettings.app.quartoVersion,
      imageName: state => state.infoSettings.app.imageName,
      clusterName: state => state.infoSettings.app.clusterName,
    }),
    loading() {
      return this.created === null;
    }
  },
  methods: {
    async copyGUID() {
      await copyToClipboard({ value: this.appGuid });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_mixins';
@import 'connect-elements/src/styles/shared/_colors';

.copy-guid {
  @include control-visible-focus;
  padding: 4px;
  margin: 0 0 -4px 1px;
  &:hover {
    background: $color-light-grey-3;
  }
}
</style>
