// Copyright (C) 2022 by Posit Software, PBC.

// UserRole reflects the backend `AuthRole` type. It is used to specify the kind
// of user, i.e. administrator, publisher, viewer - this is used to determine
// the operations that are valid for the user to undertake on the dashboard.
// For the purpose of the dashboard, users that do not have a user role
// specified are given an anonymous role.
export class UserRole {
  constructor(value) {
    this._value = value;

    // other calculated values
    this.displayName = value
      ? value[0].toUpperCase() + value.slice(1)
      : 'Anonymous';
  }

  // Ensures serialization via `JSON.stringify` returns the raw value.
  toJSON() {
    return this._value;
  }

  toString() {
    const roleDescription =
      this === UserRoles.Anonymous ? 'anonymous' : this._value;
    return `UserRole(${roleDescription})`;
  }

  // Allows comparison operators (>, >=, ==, <=, <) to work with this object.
  valueOf() {
    switch (this) {
      case UserRoles.Admin:
        return 3;
      case UserRoles.Publisher:
        return 2;
      case UserRoles.Viewer:
        return 1;
      case UserRoles.Anonymous:
        return 0;
      default:
        return 0;
    }
  }
}

// UserRoles holds singleton values and a construction function for raw user role
// values.
const UserRoles = {
  Admin: new UserRole('administrator'),
  Publisher: new UserRole('publisher'),
  Viewer: new UserRole('viewer'),
  Anonymous: new UserRole(''),

  of(value) {
    switch (value) {
      case 'administrator':
        return UserRoles.Admin;
      case 'publisher':
        return UserRoles.Publisher;
      case 'viewer':
        return UserRoles.Viewer;
      default:
        // all other values are defaulted to anonymous
        return UserRoles.Anonymous;
    }
  },
};

export default UserRoles;
