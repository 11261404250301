// Copyright (C) 2022 by Posit Software, PBC.

import { vueI18n } from '@/i18n/index';
import moment from 'moment-mini';

export const MaxJobCount = 40;

class JobTag {
  constructor(value) {
    this._value = value;
  }

  description() {
    return vueI18n.t(`common.jobTags.${this._value}`);
  }

  toJSON() {
    return this._value;
  }

  toString() {
    return `JobTag(${this._value})`;
  }

  valueOf() {
    return this._value;
  }
}

export const JobTags = {
  UnknownTag: new JobTag('unknown'),
  BuildReportTag: new JobTag('build_report'),
  BuildSiteTag: new JobTag('build_site'),
  BuildJupyterTag: new JobTag('build_jupyter'),
  PackratRestoreTag: new JobTag('packrat_restore'),
  PythonRestoreTag: new JobTag('python_restore'),
  ConfigureReportTag: new JobTag('configure_report'),
  RunAppTag: new JobTag('run_app'),
  RunAPITag: new JobTag('run_api'),
  RunPythonAPITag: new JobTag('run_python_api'),
  RunPythonDashAppTag: new JobTag('run_dash_app'),
  RunPythonStreamlitAppTag: new JobTag('run_streamlit'),
  RunPythonBokehAppTag: new JobTag('run_bokeh_app'),
  RunPythonFastAPIAppTag: new JobTag('run_fastapi_app'),
  RunPythonShinyAppTag: new JobTag('run_pyshiny_app'),
  RunJupyterVoilaAppTag: new JobTag('run_voila_app'),
  RunTensorFlowTag: new JobTag('run_tensorflow'),
  RenderShinyTag: new JobTag('render_shiny'),
  TestTag: new JobTag('testing'),
  GitTag: new JobTag('git'),
  ValidatePyExtPkgTag: new JobTag('val_py_ext_pkg'),
  ValidateRExtPkgTag: new JobTag('val_r_ext_pkg'),
  ValidateRInstallTag: new JobTag('val_r_install'),
  ControlExtractionTag: new JobTag('ctrl_extraction'),
  SelfTestTag: new JobTag('self_test'),
  // eslint-disable-next-line complexity
  of(value) {
    switch (value) {
      case 'build_report':
        return JobTags.BuildReportTag;
      case 'build_site':
        return JobTags.BuildSiteTag;
      case 'build_jupyter':
        return JobTags.BuildJupyterTag;
      case 'packrat_restore':
        return JobTags.PackratRestoreTag;
      case 'python_restore':
        return JobTags.PythonRestoreTag;
      case 'configure_report':
        return JobTags.ConfigureReportTag;
      case 'run_app':
        return JobTags.RunAppTag;
      case 'run_api':
        return JobTags.RunAPITag;
      case 'run_python_api':
        return JobTags.RunPythonAPITag;
      case 'run_dash_app':
        return JobTags.RunPythonDashAppTag;
      case 'run_streamlit':
        return JobTags.RunPythonStreamlitAppTag;
      case 'run_bokeh_app':
        return JobTags.RunPythonBokehAppTag;
      case 'run_fastapi_app':
        return JobTags.RunPythonFastAPIAppTag;
      case 'run_pyshiny_app':
        return JobTags.RunPythonShinyAppTag;
      case 'run_voila_app':
        return JobTags.RunJupyterVoilaAppTag;
      case 'run_tensorflow':
        return JobTags.RunTensorFlowTag;
      case 'render_shiny':
        return JobTags.RenderShinyTag;
      case 'testing':
        return JobTags.TestTag;
      case 'git':
        return JobTags.GitTag;
      case 'val_py_ext_pkg':
        return JobTags.ValidatePyExtPkgTag;
      case 'val_r_ext_pkg':
        return JobTags.ValidateRExtPkgTag;
      case 'val_r_install':
        return JobTags.ValidateRInstallTag;
      case 'ctrl_extraction':
        return JobTags.ControlExtractionTag;
      case 'self_test':
        return JobTags.SelfTestTag;
      case 'unknown':
      default:
        return JobTags.UnknownTag;
    }
  },
};

class JobState {
  constructor(value) {
    this._value = value;
  }

  toJSON() {
    return this._value;
  }

  toString() {
    return `JobState(${this._value})`;
  }
}

export const JobStates = {
  Running: new JobState('running'),
  Success: new JobState('success'),
  Gone: new JobState('gone'),
  Error: new JobState('error'),

  of(value) {
    switch (value) {
      case 'running':
        return JobStates.Running;
      case 'success':
        return JobStates.Success;
      case 'gone':
        return JobStates.Gone;
      case 'error':
        return JobStates.Error;
      default:
        throw new Error(`Unknown JobState "${value}"`);
    }
  },
};

const processInterruptedByConnect = 128 + 2; // SIGINT
const processKilledByConnect = 128 + 9; // SIGKILL
const processTerminatedByConnect = 128 + 15; // SIGTERM

export class JobLogLine {
  constructor({ line, isError }) {
    this.isError = isError;
    const timestampRegex = /\d+\/\d+\/\d+ \d{2}:\d{2}:\d+\.\d+/;
    const lineMatch = line.match(timestampRegex);
    if (lineMatch === null) {
      // TODO: Warning?
      this.timestamp = '';
    } else {
      this.timestamp = lineMatch[0];
    }
    this.localTimestamp = moment.utc(this.timestamp, 'YYYY/MM/DD HH:mm:ss.SSSSSSSSS').local()
      .format('YYYY/MM/DD h:mm:ss A');
    this.line = line.slice(this.timestamp.length);
  }

  getTimestamp() {
    return this.timestamp;
  }

  getMomentTimestamp() {
    return moment.utc(this.timestamp, 'YYYY/MM/DD HH:mm:ss.SSSSSSSSS').toDate();
  }

  getLine() {
    return this.line;
  }

  Error() {
    return this.isError;
  }

  toString() {
    return [this.timestamp, this.line].join('');
  }
}

// Job mirrors the server-side job.JobDetails struct.
export class Job {
  constructor({
    id,
    ppid,
    pid,
    key,
    remoteId,
    appId,
    appGuid,
    variantId,
    bundleId,
    tag,
    finalized,
    // eslint-disable-next-line no-shadow
    origin,
    stdout,
    stderr,
    loggedError,
    startTime,
    endTime,
    lastHeartbeatTime,
    // eslint-disable-next-line no-shadow
    status,
    exitCode,
    hostname,
    cluster,
    image,
    runAs,
  }) {
    this.id = id;
    this.ppid = ppid;
    this.pid = pid;
    this.key = key;
    this.remoteId = remoteId;
    this.appId = appId;
    this.appGuid = appGuid;
    this.variantId = variantId;
    this.bundleId = bundleId;
    this.tag = JobTags.of(tag);
    this.finalized = finalized;
    this.hostname = hostname;
    this.origin = origin;
    this.stdout = Job.toLines(stdout, false);
    this.stderr = Job.toLines(stderr, true);
    this.loggedError = loggedError;
    this.startTime = startTime;
    this.endTime = endTime;
    this.lastHeartbeatTime = lastHeartbeatTime;
    this.status = status;
    this.exitCode = exitCode;
    this.cluster = cluster;
    this.image = image;
    this.runAs = runAs;

    if (this.finalized === null || this.finalized === undefined) {
      this.finalized = this.status !== 0;
    }

    this.title = this.title();
    this.subtitle = this.subtitle();
  }

  static toLines(textBlob, isError) {
    return textBlob ?
      textBlob.split('\n').map(line => new JobLogLine({
        line: line,
        isError: isError,
      })) :
      [];
  }

  toJSON() {
    return {
      pid: this.pid,
      key: this.key,
      appId: this.appId,
      appGuid: this.appGuid,
      variantId: this.variantId,
      bundleId: this.bundleId,
      tag: this.tag,
      finalized: this.finalized,
      hostname: this.hostname,
      // eslint-disable-next-line no-shadow
      origin: this.origin,
      stdout: this.stdout.join('\n'),
      stderr: this.stderr.join('\n'),
      loggedError: this.loggedError,
      startTime: this.startTime,
      endTime: this.endTime,
      exitCode: this.exitCode,
    };
  }

  duration() {
    const startMoment = moment(this.startTime);
    const endMoment = moment(this.endTime);
    const dur = moment.duration(endMoment.diff(startMoment));
    return dur.humanize();
  }

  title() {
    const parsedTime = moment(this.startTime).fromNow();
    if (this.state() === JobStates.Running) {
      return vueI18n.t('appSettings.logs.runningLogTitle', { jobDescription: this.tag.description(), startTime: parsedTime });
    }
    return vueI18n.t('appSettings.logs.completedLogTitle', { jobDescription: this.tag.description(), startTime: parsedTime });
  }

  subtitle() {
    if (this.state() === JobStates.Running) {
      return vueI18n.t('appSettings.logs.runningLogSubtitle', { jobKey: this.key, hostname: this.hostname });
    } else if (this.state() === JobStates.Gone) {
      return vueI18n.t('appSettings.logs.goneLogSubtitle', { jobKey: this.key, hostname: this.hostname });
    }
    return vueI18n.t('appSettings.logs.completedLogSubtitle', { jobKey: this.key, hostname: this.hostname, duration: this.duration() });
  }

  toCollatedLines() {
    function compare(a, b) {
      if (a.getTimestamp() < b.getTimestamp()) {
        return -1;
      } else if (a.getTimestamp() > b.getTimestamp()) {
        return 1;
      }
      return 0;
    }
    return this.stdout.concat(this.stderr).sort(compare)
      .filter(line => line.timestamp !== '' && line.line !== ' ');
  }

  // eslint-disable-next-line complexity
  state() {
    // For interactive content (including the Shiny parameter configuration
    // application), exit codes 128+2==130 (SIGINT), 128+9==137 (SIGKILL) and
    // 128+15==143 (SIGTERM) indicate that Connect killed the process and are
    // expected.
    switch (this.tag) {
      case JobTags.RunAppTag:
      case JobTags.ConfigureReportTag:
      case JobTags.RunAPITag:
      case JobTags.RunPythonAPITag:
      case JobTags.RunPythonDashAppTag:
      case JobTags.RunPythonStreamlitAppTag:
      case JobTags.RunPythonBokehAppTag:
      case JobTags.RunPythonFastAPIAppTag:
      case JobTags.RunPythonShinyAppTag:
      case JobTags.RunJupyterVoilaAppTag:
      case JobTags.RunTensorFlowTag:
        if (this.exitCode === processInterruptedByConnect) {
          return JobStates.Success;
        }
        if (this.exitCode === processKilledByConnect) {
          return JobStates.Success;
        }
        if (this.exitCode === processTerminatedByConnect) {
          return JobStates.Success;
        }
        break;
    }

    switch (this.exitCode) {
      case 0:
        return JobStates.Success;
      case -1:
      case null:
        // -1 indicates that the app is still running;
        // However, if the job has been finalized without an end time, it was lost
        if (this.endTime === null && this.finalized) {
          return JobStates.Gone;
        } else if (!this.finalized) {
          return JobStates.Running;
        }
        break;
    }
    return JobStates.Error;
  }

  isDone() {
    return this.isError() || this.isSuccess();
  }

  isRunning() {
    return this.state() === JobStates.Running;
  }

  isSuccess() {
    return this.state() === JobStates.Success;
  }

  isGone() {
    return this.state() === JobStates.Gone;
  }

  isError() {
    return this.state() === JobStates.Error;
  }

  errorCode() {
    if (this.loggedError) {
      return this.loggedError.code;
    }
    return null;
  }
}

export class JobKillOrder {
  constructor({
    guid,
    appId,
    appGuid,
    jobId,
    jobKey,
    result,
  }) {
    this.guid = guid;
    this.appId = appId;
    this.appGuid = appGuid;
    this.jobId = jobId;
    this.jobKey = jobKey;
    this.result = result;
  }
}
