// Copyright (C) 2022 by Posit Software, PBC.

import { ActionTypes } from './app-state.constants';
import { removeQueryParams } from './app-state.selectors';

const {
  SET_STATUS_MESSAGE,
  CLEAR_STATUS_MESSAGE,
  SET_STATUS_ACTIVITY_MESSAGE,
  CLEAR_STATUS_ACTIVITY_MESSAGE,
  SCROLL_POS,
  SET_INIT_WINDOW_SIZE,
  SHOW_CONTENT_OPTIONS_PANEL,
  SHOW_NAV_PANEL,
  SHOW_REPORT_PROGRESS,
  SHOW_USER_PANEL,
  SHRINK_HEADER,
  TOGGLE_PARAMS_PANEL,
  TOGGLE_SHOW_SETTINGS_PANEL,
} = ActionTypes;

export const initialStateFn = () => ({
  showLogs: false,
  showSubscriptionsModal: false,
  showNavPanel: false,
  showUserPanel: false,
  shrinkHeader: true,
  scroll: {
    top: 0,
  },
  expandHeader: false,
  showParamsPanel: false,
  showReportProgress: {
    show: false,
  },
  showSettingsPanel: true, // default to showing the settings panel when appropriate
  settingsPanelMap: {}, // a map (boolean keyed by app ID) that tracks panel state for apps
  showContentOptionsPanel: false,
  statusMessage: {},
  /**
   * Keeps a list of all the activity messages by pageId
   */
  activityMessages: {},
  /**
   * Counts the number of activities that requested to dim the content, indexed by
   * pageId.
   */
  activityDimmerCounts: {},
  /**
   * Last time (in Unix time) the user was known to have been logged in.
   */
  lastKnownLoginUnix: null,
  /**
   * Flag in redux state to remove and re-insert the content iframe when needed.
   * Avoid using the appState angular module from app-state.service.js
   * since we want to remove Angular from the codebase.
   */
  reloadingContentFrame: false,
});

/**
 * Safe to be called twice in a row. Returns updated activityMessages and
 * activityDimmerCounts.
 * @param {ReduxState} state
 * @param {String} pageId - aka URLs are unique up to query params
 * @param {String} compId
 * @param {String} message
 * @param {Object} opts
 * @returns {ReduxState}
 */
function addActivity(state, { pageId, compId, message, opts = {} }) {
  pageId = removeQueryParams(pageId);
  const activities = state.activityMessages[pageId] || [];
  const alreadyAdded = !!activities.find(x => x.compId === compId);
  if (alreadyAdded) {
    return {};
  }
  const newActivity = { compId, message, opts };
  const activityMessages = {
    ...state.activityMessages,
    [pageId]: [
      // latest message is first
      newActivity,
      ...activities,
    ],
  };
  // then do the dimmer counts. If opts.dimCount = true, then increment count.
  const curActivityDimmerCount = state.activityDimmerCounts[pageId] || 0;
  const activityDimmerCounts = {
    ...state.activityDimmerCounts,
    [pageId]: opts.dimContent
      ? curActivityDimmerCount + 1
      : curActivityDimmerCount,
  };
  return {
    activityMessages,
    activityDimmerCounts,
  };
}

function removeActivity(state, { pageId, compId }) {
  pageId = removeQueryParams(pageId);
  const activities = state.activityMessages[pageId] || [];
  const activity = activities.find(x => x.compId === compId);
  if (!activity) {
    return {};
  }
  const activityMessages = {
    ...state.activityMessages,
    [pageId]: activities.filter(x => x.compId !== compId),
  };
  // then decrement the dimmer counts only if we previously requested to dimContents
  const curActivityDimmerCount = state.activityDimmerCounts[pageId] || 0;
  const activityDimmerCounts = {
    ...state.activityDimmerCounts,
    [pageId]: (activity.opts || {}).dimContent
      ? curActivityDimmerCount - 1
      : curActivityDimmerCount,
  };
  return {
    activityMessages,
    activityDimmerCounts,
  };
}

// eslint-disable-next-line complexity
export default function appState(state = initialStateFn(), action) {
  switch (action.type) {
    case CLEAR_STATUS_MESSAGE: {
      return { ...state, statusMessage: {} };
    }
    case SET_STATUS_MESSAGE: {
      const { message, statusType, opts = {} } = action.payload;
      return { ...state, statusMessage: {
        message,
        statusType,
        ...opts,
      } };
    }
    case SET_STATUS_ACTIVITY_MESSAGE: {
      const { pageId, compId, message, opts = {} } = action.payload;
      return {
        ...state,
        ...addActivity(state, { pageId, compId, message, opts }),
      };
    }
    case CLEAR_STATUS_ACTIVITY_MESSAGE: {
      const { pageId, compId } = action.payload;
      return {
        ...state,
        ...removeActivity(state, { pageId, compId }),
      };
    }
    case SET_INIT_WINDOW_SIZE:
      return { ...state, showContentOptionsPanel: action.size.width >= 1024 }; // Hide by default for narrower views
    case SHOW_NAV_PANEL:
      return { ...state, showNavPanel: action.show };
    case SHOW_USER_PANEL:
      return { ...state, showUserPanel: action.show };
    case SHRINK_HEADER: {
      const { top: _top } = state.scroll;
      const shrinkHeader = action.shrink;
      return { ...state, shrinkHeader,
        expandHeader: !shrinkHeader && _top < 50 };
    }
    case SCROLL_POS: {
      const { top: _top } = action.payload;
      const { shrinkHeader } = state;
      return { ...state, scroll: {
        top: _top,
      },
      expandHeader: !shrinkHeader && _top < 50 };
    }
    case TOGGLE_SHOW_SETTINGS_PANEL: {
      const { appId, show } = action.payload;

      // Original settings
      let { settingsPanelMap, showSettingsPanel } = state;

      // If there's an app id passed, track state in our map
      if (appId) {
        settingsPanelMap = {
          ...state.settingsPanelMap,
          [+appId]: show,
        };
      } else {
        // Otherwise just track state globally
        showSettingsPanel = show;
      }
      return {
        ...state,
        showSettingsPanel,
        settingsPanelMap,
      };
    }
    case TOGGLE_PARAMS_PANEL: {
      const { show } = action.payload;

      return {
        ...state,
        showParamsPanel: show,
      };
    }
    case SHOW_CONTENT_OPTIONS_PANEL: {
      return {
        ...state,
        showContentOptionsPanel: !state.showContentOptionsPanel,
      };
    }
    case SHOW_REPORT_PROGRESS: {
      const { appId, variantId, show } = action.payload;

      // Short circuit if we're trying to hide report progress for the wrong report
      if (
        !show &&
        !(
          appId === state.showReportProgress.appId &&
          variantId === state.showReportProgress.variantId
        )
      ) {
        return state;
      }

      return {
        ...state,
        showReportProgress: {
          show,
          appId,
          variantId,
        },
      };
    }
    case ActionTypes.TRACK_LAST_LOGIN: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case ActionTypes.SHOW_LOGS:
      return { ...state, showLogs: true };
    case ActionTypes.HIDE_LOGS:
      return { ...state, showLogs: false };
    case ActionTypes.SHOW_SUBSCRIPTIONS:
      return { ...state, showSubscriptionsModal: true };
    case ActionTypes.HIDE_SUBSCRIPTIONS:
      return { ...state, showSubscriptionsModal: false };
    case ActionTypes.RELOADING_CONTENT_FRAME: {
      const { flag } = action.payload;
      return { ...state, reloadingContentFrame: flag };
    }
    default:
      return state;
  }
}
