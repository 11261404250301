// Copyright (C) 2022 by Posit Software, PBC.

import { auditLogs } from './auditLogs';
import { apiKeys } from './apiKeys';
import { authentication } from './authentication';
import { common } from './common';
import { confirmUser } from './confirmUser';
import { connect } from './connect';
import { content } from './content';
import { documentation } from './documentation';
import { historyNavigation } from './history';
import { language } from './language';
import { landingPage } from './landingPage';
import { navigation } from './navigation';
import { parametersPanel } from './parametersPanel';
import { resetPassword } from './resetPassword';
import { userPanel } from './userPanel';
import { ideToken } from './ideToken';
import { versions } from './versions';
import { welcome } from './welcome';

const es = {
  auditLogs,
  apiKeys,
  authentication,
  common,
  confirmUser,
  connect,
  content,
  documentation,
  historyNavigation,
  language,
  landingPage,
  navigation,
  parametersPanel,
  resetPassword,
  userPanel,
  ideToken,
  versions,
  welcome,
};

export { es };
