<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <SearchSelect
      :label="$t('parametersPanel.runs')"
      :flash-fade="highlighted"
      :selected="selectedOption"
      :options="options"
      :disabled="disabled"
      :title="$t('parametersPanel.runs')"
      class="run-select__select"
      @select="onSelect"
    />

    <ConfirmModal
      v-if="confirmSwitchRun"
      :subject="$t('parametersPanel.confirmSwitchRun.title')"
      :discard-label="$t('parametersPanel.confirmSwitchRun.discardChanges')"
      :cancel-label="$t('parametersPanel.confirmSwitchRun.cancel')"
      :warning="$t('parametersPanel.confirmSwitchRun.warning')"
      @discard="onConfirmSwitch"
      @cancel="confirmSwitchRun = null"
    />
  </div>
</template>

<script>
import ConfirmModal from '@/components/ParametersPanel/ConfirmModal';
import SearchSelect from './SearchSelect';
import { mapActions, mapState } from 'vuex';
import { PARAMETERIZATION_SELECT_RUN } from '@/store/modules/parameterization';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'VariantRunSelect',
  components: {
    ConfirmModal,
    SearchSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: () => ({
    confirmSwitchRun: null,
  }),
  computed: {
    ...mapState({
      currentVariant: state => state.parameterization.currentVariant,
      currentRun: state => state.parameterization.currentRun,
      isRunDirty: state => state.parameterization.isRunDirty,
      isVariantDirty: state => state.parameterization.isVariantDirty,
      runs: state => state.parameterization.runs,
    }),
    defaultRunOption() {
      return { value: -1, text: this.$t('parametersPanel.noRuns') };
    },
    selectedOption() {
      if (isEmpty(this.filteredRuns)) {
        return this.defaultRunOption;
      }

      return this.toOption(this.currentRun);
    },
    filteredRuns() {
      if (isEmpty(this.runs)) {
        return [];
      }

      return this.runs
        .filter(runItem => runItem.variantId === this.currentVariant.id);
    },
    options() {
      if (isEmpty(this.filteredRuns)) {
        return [this.defaultRunOption];
      }

      return this.filteredRuns.map(r => this.toOption(r));
    },
  },
  methods: {
    toOption(r) {
      if (isNil(r)) {
        return {};
      }

      return { value: r.id, text: this.runLabelName(r) };
    },
    runLabelName(r) {
      if (isNil(r)) {
        throw new Error('Run object is required');
      }

      return `${r.renderTime.toLocaleString(navigator.language, { dateStyle: 'short' })} - ${r.renderTime.toLocaleString(navigator.language, { timeStyle: 'medium' })}`;
    },
    onConfirmSwitch() {
      this.switchRun(this.confirmSwitchRun);
      this.confirmSwitchRun = null;
    },
    onSelect(id) {
      const selectedRun = this.runs.find(r => r.id === id);

      if (!this.isVariantDirty || !this.isRunDirty) {
        this.switchRun(selectedRun);
        return;
      }

      this.confirmSwitchRun = selectedRun;
    },
    switchRun(r) {
      this.$emit('change', r);
      this.selectRun(r);
    },
    ...mapActions({
      selectRun: PARAMETERIZATION_SELECT_RUN,
    }),
  },
};
</script>

<style lang="scss">
.search-select.run-select__select {
  width: 100%;

  .search-select__input {
    font-size: 0.825rem;
    width: 100%;
  }
}
</style>
