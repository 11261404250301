<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    ref="button"
    :aria-label="label"
    :class="[{
      'button--left': isLeftExpanding,
      'button--right': !isLeftExpanding
    }, icon]"
    class="button"
    v-bind="$attrs"
    @click="$emit('click')"
  >
    <span
      class="button__icon"
    />
    <span class="button__text">{{ label }}</span>
  </button>
</template>

<script>
export const Direction = {
  left: 'left',
  right: 'right',
};

export default {
  name: 'ExpandingButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    expandDirection: {
      type: String,
      validator: value => Object.values(Direction).includes(value),
      default: Direction.left,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data: () => ({ Direction }),
  computed: {
    isLeftExpanding() {
      return this.expandDirection === Direction.left;
    }
  },
  methods: {
    focus() {
      this.$refs.button.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_mixins';
@import 'connect-elements/src/styles/shared/_colors';

.button {
  @include control-visible-focus;

  align-items: center;
  background-color: $color-posit-orange;
  display: flex;
  font-size: 1.3em;
  padding: 0.3em;
  position: absolute;
  top: 15px;
  white-space: nowrap;
  z-index: 10;

  .button__icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 1em;
    width: 1em;
  }

  .button__text {
    color: $color-white;
    display: inline-block;
    font-weight: 600;
    max-width: 0;
    overflow: hidden;
    transition-duration: 0.4s;
    transition-property: max-width;
  }

  &:hover,
  &:focus-visible {
    background-color: $color-alternate-dark;
    .button__text {
      max-width: 300px;
    }
  }

  &:active {
    transition-property: unset;
  }

  &--right {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 7px;
    border-top-left-radius: 0;
    border-top-right-radius: 7px;
    flex-direction: row;
    left: 0;

    &:hover,
    &:focus-visible {
      box-shadow: 3px 3px 8px 0px $color-dark-grey;
    }
    .button__icon {
      margin-right: 0.3em;
    }
  }

  &--left {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 0;
    flex-direction: row-reverse;
    right: 0;

    &:hover,
    &:focus-visible {
      box-shadow: -3px 3px 8px 0px $color-dark-grey;
    }

    .button__icon {
      margin-left: 0.3em;
      background-color: transparent;
    }
  }

  // icons
  &.parameters {
    .button__icon {
      background-image: url('connect-elements/src/images/iconDocParams.svg');
    }
  }
  &.close {
    .button__icon {
      background-image: url('connect-elements/src/images/closeButton.svg');
    }
  }
}
</style>
