<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Dialog to create a group, it recieves a show prop to display itself,
  it emits a "close" and "created" events to the parent.
-->
<template>
  <RSModalForm
    :active="true"
    :subject="$t('groups.titles.createGroup')"
    @close="close"
    @submit="create"
  >
    <template #content>
      <RSInputText
        ref="input"
        v-model.trim="form.groupName"
        :label="$t('groups.name.long')"
        :message="groupNameError"
        name="cgd-name"
        data-automation="create-group__name"
      />
    </template>
    <template #controls>
      <RSButton
        :label="$t('groups.actions.create')"
        data-automation="new-group-create"
      />
    </template>
  </RSModalForm>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSModalForm from 'Shared/components/RSModalForm';
import { createGroup } from '@/api/groups';
import { getFirstErrorMsg } from '@/utils/errorSelector';

import {
  setErrorMessageFromAPI,
  setInfoMessage,
  clearStatusMessage,
} from '@/utils/status';

const errors = [
  {
    name: 'required',
    message: 'groups.name.errors.required',
  },
];

export default {
  name: 'CreateGroupDialog',
  components: { RSButton, RSInputText, RSModalForm },
  data() {
    return {
      form: { groupName: '' },
    };
  },
  computed: {
    groupNameError() {
      return this.$v.form.groupName.$error
        ? getFirstErrorMsg(errors, this.form.groupName)
        : null;
    },
  },
  mounted() {
    this.$el.querySelector('#cgd-name').focus();
  },
  methods: {
    close() {
      this.$emit('close');
      this.form.groupName = '';
      this.$v.form.$reset();
    },
    create() {
      // trigger validation before API submit and bail if form is not valid
      this.$v.form.groupName.$touch();
      if (this.$v.form.groupName.$invalid) {
        this.$nextTick(() => this.$refs.input.focusElement());
        // return resolved promise, as throwing would bubble up to action caller
        return Promise.resolve();
      }

      this.$v.form.$reset();

      clearStatusMessage();
      return createGroup(this.form.groupName)
        .then(group => {
          this.$emit('created', group);
          setInfoMessage(
            this.$t('groups.status.added', {
              name: this.form.groupName,
            })
          );
        })
        .catch(e => {
          this.close();
          setErrorMessageFromAPI(e);
        });
    },
  },
  validations() {
    return {
      form: {
        groupName: {
          required: v => !!v,
          max: v => v.length <= 4096,
        },
      },
    };
  },
};
</script>
