// Copyright (C) 2023 by Posit Software, PBC.

const apiKeys = {
  title: 'API Keys',
  newKey: 'New API Key',
  newKeyAria: 'Add new API key',
  noKeysMsg: `You don't have any API keys. To create one, click the 'New API Key' button above.`,
  about: {
    title: 'About API Keys',
    impersonateWarning: 'Treat your API Key like you treat your password. Anyone with your API Key can impersonate you.',
    description: `{warning} API keys enable you to send requests to {systemDisplayName} that have
      all the same privileges that you have when logged in in a web
      browser. For example, you could use these keys to automate
      requests to hosted content that require authentication.`,
    docsRef: 'See the {0} for more details.',
    linkLabel: 'API Keys documentation',
  },
  table: {
    rowLabel: 'API Key {name}',
    deleteKeyLabel: 'Delete the {name} API Key',
    cols: {
      name: 'Name',
      key: 'Key',
      created: 'Created',
      lastUsed: 'Last Used',
    },
  },
  create: {
    title: 'Create API Key',
    nameInputLabel: 'Enter a name for the new API Key',
    createBtnLabel: 'Create Key',
    nameIsRequired: 'The name is required',
    newKeyTitle: 'API Key: {name}',
    confirmation: `Here's your new key. {0} This is the only time we'll show it to you.`,
    copyNow: 'Copy it now!',
    newKeyAria: 'Your new {name} API key value',
    copiedMsg: 'Your key has been copied.',
    copyBtnLabel: 'Copy',
  },
  delete: {
    title: 'Delete {name} API Key',
    text: 'Are you sure you want to delete "{name}" API Key?',
    btnLabel: 'Delete Key',
    keyDeleted: 'API Key Deleted.',
  },
};

export { apiKeys };
