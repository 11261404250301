// Copyright (C) 2022 by Posit Software, PBC.

import Vue from 'vue';
import globalEventBusEvents from './constants/globalEventBusEvents';
import {
  statusInfoMessage,
  statusErrorMessage,
  clearStatusMessage,
  statusActivityMessage,
  clearStatusActivityMessage,
  tryToTogglePanelVisibility,
} from '@/store/redux/app-state/app-state.actions';
import { PanelTypes } from '@/store/redux/app-state/app-state.constants';
import { addVariant } from '@/store/redux/variants/variant.actions';
import { keysToSnake } from '@/api/transform';

export const globalEventBus = new Vue();

/*
 * These globalEventBus listeners are legacy code ported over
 * from an angular service to maintain some required funcitonality.
 * These need to be migrated and handled by Vuex. GH issue #24837.
 * Please, do not add more events here, use Vuex instead.
 */
export const setupGlobalEventBusListeners = reduxStore => {
  globalEventBus.$on(globalEventBusEvents.redux.status.set, function({
    message,
    noAutoHide,
  }) {
    reduxStore.dispatch(statusInfoMessage(message));
    if (noAutoHide) {
      return;
    }
    setTimeout(() => {
      reduxStore.dispatch(clearStatusMessage());
    }, 2000);
  });

  globalEventBus.$on(globalEventBusEvents.redux.status.clear, function() {
    reduxStore.dispatch(clearStatusMessage());
  });

  globalEventBus.$on(globalEventBusEvents.redux.activity.set, function({
    compId,
    pageId,
    message,
    options,
  }) {
    reduxStore.dispatch(
      statusActivityMessage({ compId, pageId, message }, options)
    );
  });

  globalEventBus.$on(globalEventBusEvents.redux.activity.clear, function({
    compId,
    pageId,
  }) {
    reduxStore.dispatch(clearStatusActivityMessage({ compId, pageId }));
  });

  globalEventBus.$on(globalEventBusEvents.redux.error.set, function(msg) {
    reduxStore.dispatch(statusErrorMessage(msg));
  });

  globalEventBus.$on(globalEventBusEvents.redux.toggleParametersPanel, () => {
    reduxStore.dispatch(tryToTogglePanelVisibility({ panelType: PanelTypes.INPUT_PANEL }));
  });

  globalEventBus.$on(globalEventBusEvents.redux.variants.add, ({ variant }) => {
    reduxStore.dispatch(addVariant(keysToSnake(variant)));
  });
};

