<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div
    v-if="!!app"
    class="parameters-panel"
    :class="{ open: isOpen }"
  >
    <ExpandingButton
      v-if="isOpen"
      :label="$t('parametersPanel.close')"
      :expand-direction="Direction.left"
      data-automation="close-parameters-button"
      icon="close"
      @click="onClosePanel"
    />
    <div
      v-if="isBusy"
      class="spinner-container"
    >
      <Spinner />
    </div>
    <div class="params-container">
      <div
        v-if="isOpen"
        class="header"
      >
        <div class="titles">
          <h2
            ref="parametersHeading"
            tabindex="-1"
          >
            {{ $t("parametersPanel.title") }}
          </h2>
          <h3>
            {{ currentVariant.name }}
          </h3>
        </div>

        <div
          class="variantRuns"
          data-testid="variant-runs-selector"
        >
          <label
            class="runsTitle"
            for="variant-run-selector"
          >
            {{ $t('parametersPanel.runs') }}
          </label>

          <div class="variant-run-actions">
            <VariantRunSelect
              id="variant-run-selector"
              class="variant-run-selector"
              :disabled="!hasVariantRuns"
              :highlighted="flashRunsHighlight"
            />
            <button
              v-if="hasVariantRuns"
              class="delete-button"
              :aria-label="$t('parametersPanel.deleteRun')"
              :title="$t('parametersPanel.deleteRun')"
              data-testid="delete-run"
              :disabled="isBusy"
              @click="onDeleteRun"
            />
          </div>
        </div>

        <EmbeddedStatusMessage
          :fade-out="true"
          :message="message"
          :show-close="false"
          :truncate="true"
          size="small"
          type="info"
        />
      </div>

      <form
        v-if="hasParameters && isOpen"
        class="params-form"
        @submit.prevent="onRunReport"
      >
        <div class="form-body">
          <ul class="param-list">
            <li
              v-for="(param) in parameters"
              :key="param.name"
            >
              <ParameterComponent
                :parameter="param"
                :value="valueForParameter(param.name)"
                class="form-component"
                @value-updated="onValueUpdated"
              />
            </li>
          </ul>
        </div>
      </form>

      <div class="footer">
        <div class="top-buttons">
          <RSButton
            :disabled="isBusy"
            :label="$t('parametersPanel.runReport')"
            data-automation="run-report-button"
            type="primary"
            @click="onRunReport"
          />

          <RSButton
            v-if="isModifiable && isCollaborator"
            type="secondary"
            :label="$t('parametersPanel.save')"
            :disabled="!isVariantDirty"
            @click="onSave"
          />

          <RSButton
            v-if="!isModifiable && isCollaborator"
            type="secondary"
            :label="$t('parametersPanel.saveAs')"
            :disabled="!isVariantDirty"
            @click="onSaveAs"
          />
        </div>

        <hr>

        <div class="bottom-buttons">
          <RSButton
            v-if="!isVariantDirty && isCollaborator"
            class="rename-button"
            type="secondary"
            :label="$t('parametersPanel.rename')"
            @click="variantNameMode = VariantNameMode.rename"
          />
          <RSButton
            v-if="isVariantDirty || !isCollaborator"
            class="revert-button"
            type="secondary"
            :disabled="!isVariantDirty"
            :label="$t('parametersPanel.revert')"
            @click="onRevertValues"
          />

          <RSButton
            v-if="isModifiable && isCollaborator"
            class="delete-button"
            type="secondary"
            :label="$t('parametersPanel.delete')"
            :disabled="isVariantDirty"
            @click="onDeleteVariant"
          />

          <RSButton
            v-if="isCollaborator"
            class="clone-button"
            type="secondary"
            data-automation="params-clone-variant-btn"
            :label="$t('parametersPanel.clone')"
            @click="onCloneVariant"
          />
        </div>

        <hr>
      </div>
    </div>

    <div
      :class="{ open: isOpen }"
      class="hint-panel"
    >
      <ExpandingButton
        v-if="!isOpen"
        ref="openButton"
        :label="$t('parametersPanel.title')"
        :expand-direction="Direction.right"
        icon="parameters"
        data-automation="open-parameters-button"
        @click="togglePanel"
      />
    </div>

    <ConfirmModal
      v-if="showConfirmModal"
      :subject="confirmLabels.subject"
      :warning="confirmLabels.warning"
      :discard-label="confirmLabels.confirm"
      :cancel-label="confirmLabels.cancel"
      @discard="onConfirm"
      @cancel="onCancelConfirm"
    />

    <VariantNameModal
      v-if="variantNameMode !== VariantNameMode.hidden"
      :variant-name="variantNameForModal"
      :mode="variantNameMode"
      @close="variantNameMode = VariantNameMode.hidden"
      @create="onCreateVariant"
      @rename="onRenameVariant"
    />
  </div>
</template>

<script>
import ConfirmModal from './ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import VariantRunSelect from '@/components/VariantRunSelect.vue';
import ExpandingButton, { Direction } from './ExpandingButton';
import ParameterComponent from './ParameterComponent';
import RSButton from 'Shared/components/RSButton';
import VariantNameModal, { VariantNameMode } from './VariantNameModal';
import Spinner from '@/components/Spinner';
import globalEventBusEvents from '@/constants/globalEventBusEvents';
import isEmpty from 'lodash/isEmpty';
import {
  PARAMETERIZATION_RUN_REPORT,
  PARAMETERIZATION_CREATE_VARIANT,
  PARAMETERIZATION_DELETE_RUN,
  PARAMETERIZATION_DELETE_VARIANT,
  PARAMETERIZATION_FETCH_PARAMETERS,
  PARAMETERIZATION_RENAME_VARIANT,
  PARAMETERIZATION_REVERT_VALUES,
  PARAMETERIZATION_SAVE_AS,
  PARAMETERIZATION_SAVE_VARIANT_VALUES,
  PARAMETERIZATION_SET_VALUE,
  PARAMETERIZATION_SET_BUSY,
} from '@/store/modules/parameterization';
import { globalEventBus } from '@/globalEventBus';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ParametersPanel',
  components: {
    ConfirmModal,
    EmbeddedStatusMessage,
    VariantRunSelect,
    ExpandingButton,
    ParameterComponent,
    VariantNameModal,
    RSButton,
    Spinner,
  },
  data: () => ({
    clonedVariantName: '',
    confirmClose: false,
    confirmDelete: false,
    confirmDeleteRun: false,
    handler: null,
    flashRunsHighlight: false,
    isOpen: false,
    message: null,
    renameVariant: false,
    Direction,
    variantNameMode: VariantNameMode.hidden,
    VariantNameMode,
  }),
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentRun: state => state.parameterization.currentRun,
      currentVariant: state => state.parameterization.currentVariant,
      isBusy: state => state.parameterization.isBusy,
      isRunDirty: state => state.parameterization.isRunDirty,
      isVariantDirty: state => state.parameterization.isVariantDirty,
      parameters: state => state.parameterization.parameters,
      values: state => state.parameterization.values,
      variants: state => state.parameterization.variants,
      runs: state => state.parameterization.runs,
    }),
    isModifiable() {
      // eslint-disable-next-line camelcase
      return this.currentVariant && !this.currentVariant.isDefault;
    },
    hasParameters() {
      return !isEmpty(this.parameters);
    },
    hasVariantRuns() {
      const currentVariantRuns = this.runs.filter(r => r.variantId === this.currentVariant.id);
      return !isEmpty(currentVariantRuns);
    },
    isCollaborator() {
      return this.app.appRole.isCollaborator();
    },
    showConfirmModal() {
      return this.confirmClose ||
        this.confirmDelete ||
        this.confirmDeleteRun;
    },
    confirmLabels() {
      if (this.confirmClose) {
        return {
          subject: this.$t('parametersPanel.confirmModal.title'),
          warning: this.$t('parametersPanel.confirmClose.warning'),
          confirm: this.$t('parametersPanel.confirmClose.discardChanges'),
          cancel: this.$t('parametersPanel.confirmClose.cancel'),
        };
      }
      if (this.confirmDeleteRun) {
        return {
          subject: this.$t('parametersPanel.confirmDeleteRun.delete'),
          warning: this.$t('parametersPanel.confirmDeleteRun.warning'),
          confirm: this.$t('parametersPanel.confirmDeleteRun.delete'),
          cancel: this.$t('parametersPanel.confirmDeleteRun.cancel'),
        };
      }
      return {
        subject: this.$t('parametersPanel.confirmDelete.delete'),
        warning: this.$t('parametersPanel.confirmDelete.warning'),
        confirm: this.$t('parametersPanel.confirmDelete.delete'),
        cancel: this.$t('parametersPanel.confirmDelete.cancel'),
      };
    },
    variantNameForModal() {
      return this.variantNameMode === VariantNameMode.rename
        ? this.currentVariant.name
        : this.clonedVariantName;
    },
    hasDirtyParameters() {
      return this.isRunDirty || this.isVariantDirty;
    },
  },
  created() {
    this.fetchParameters();

    this.$watch('hasDirtyParameters', hasChanges => {
      if (hasChanges) {
        this.handler = e => {
          e.preventDefault();
          // Chrome requires returnValue to be a string
          e.returnValue = '';
        };
        window.addEventListener('beforeunload', this.handler);
      } else {
        window.removeEventListener('beforeunload', this.handler);
        this.handler = null;
      }
    });
  },
  methods: {
    valueForParameter(paramName) {
      if (!isEmpty(this.values)) {
        return this.values[paramName];
      }
      return null;
    },
    onValueUpdated({ parameter, value }) {
      this.setValue({ parameter, value });
    },
    onCloneVariant() {
      const newName = `${this.$t('parametersPanel.copyOf')} ${
        this.currentVariant.name
      }`;

      this.clonedVariantName = newName;
      this.variantNameMode = VariantNameMode.create;
    },
    onCreateVariant({ newName }) {
      this.createVariant({
        fromVariantId: this.currentVariant.id,
        newName,
      }).then(() => this.setMessage(this.$t('parametersPanel.addedVariant')));
      this.variantNameMode = VariantNameMode.hidden;
    },
    onRenameVariant({ newName }) {
      this.renameCurrentVariant(newName).then(() => this.setMessage(this.$t('parametersPanel.renamedVariant')));
      this.variantNameMode = VariantNameMode.hidden;
    },
    onDeleteVariant() {
      this.confirmDelete = true;
    },
    onRunReport() {
      this.runReport({ id: this.currentVariant.id, values: this.values })
        .then(err => {
          if (err) {
            return;
          }
          // TODO: remove the timeout once the Run Report API is implemented.
          setTimeout(() => {
            this.setBusy(false);
            this.flashRunsHighlight = true;
            setTimeout(() => { this.flashRunsHighlight = false; }, 2_000);
          }, 1_000);
        });
    },
    onDeleteRun() {
      this.confirmDeleteRun = true;
    },
    onSave() {
      this.saveVariantValues({ values: this.values });
    },
    onSaveAs() {
      this.saveAs({ values: this.values });
    },
    onRevertValues() {
      this.revertValues();
      this.setMessage(this.$t('parametersPanel.revertedChanges'));
    },
    setMessage(message) {
      this.message = message;
      setTimeout(() => { this.message = null; }, 3_000);
    },
    onConfirmedDeleteVariant() {
      const variantName = this.currentVariant.name;
      this.deleteVariant({
        appId: this.app.id,
        variantId: this.currentVariant.id,
      }).then(() => {
        this.setMessage(this.$t('parametersPanel.deletedVariant', {
          name: variantName,
        }));
      });
    },
    onConfirmedDeleteRun() {
      this.deleteRun(this.currentRun.id)
        .then(() => this.setMessage(this.$t('parametersPanel.deletedRun')))
        .catch(error => console.debug(`DELETE RUN ERROR: [${error.response.status}]`));
    },
    onConfirm() {
      if (this.confirmClose) {
        this.confirmClose = false;
        this.togglePanel();
      }

      if (this.confirmDeleteRun) {
        this.confirmDeleteRun = false;
        this.onConfirmedDeleteRun();
      }

      if (this.confirmDelete) {
        this.confirmDelete = false;
        this.onConfirmedDeleteVariant();
      }
    },
    onCancelConfirm() {
      this.confirmClose = false;
      this.confirmDelete = false;
      this.confirmDeleteRun = false;
    },
    onClosePanel() {
      if (this.isVariantDirty) {
        this.confirmClose = true;
        return;
      }
      this.setBusy(false);
      this.togglePanel();
    },
    togglePanel() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$nextTick(() => this.$refs.parametersHeading.focus());
      } else {
        this.revertValues();
        this.$nextTick(() => this.$refs.openButton.focus());
      }
      globalEventBus.$emit(globalEventBusEvents.redux.toggleParametersPanel);
    },
    ...mapMutations({
      revertValues: PARAMETERIZATION_REVERT_VALUES,
      setValue: PARAMETERIZATION_SET_VALUE,
      setBusy: PARAMETERIZATION_SET_BUSY,
    }),
    ...mapActions({
      createVariant: PARAMETERIZATION_CREATE_VARIANT,
      deleteVariant: PARAMETERIZATION_DELETE_VARIANT,
      deleteRun: PARAMETERIZATION_DELETE_RUN,
      fetchParameters: PARAMETERIZATION_FETCH_PARAMETERS,
      renameCurrentVariant: PARAMETERIZATION_RENAME_VARIANT,
      runReport: PARAMETERIZATION_RUN_REPORT,
      saveAs: PARAMETERIZATION_SAVE_AS,
      saveVariantValues: PARAMETERIZATION_SAVE_VARIANT_VALUES,
    }),
  },
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_mixins';
@import 'connect-elements/src/styles/shared/_colors';

.parameters-panel {
  position: absolute;
  top: 0;
  left: -320px;
  bottom: 0px;
  width: 350px;
  z-index: 20;

  background-color: $color-white;
  display: grid;
  grid-template-columns: auto 30px;
  transition-duration: 0.25s;
  transition-property: left;

  &.open {
    box-shadow: 9px 0 7px -6px $color-medium-grey;
    cursor: default;
    grid-template-columns: auto;
    left: 0;
  }

  .form-body {
    min-height: 5em;
  }

  .param-list {
    list-style: none;
    margin: 0 5px;
  }

  .params-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 1em;

    .params-form {
      position: relative;
      flex: 1 1 100%;
      height: 0;
      min-height: 100px;
      overflow-y: auto;
    }

    .header,
    .footer {
      flex: 0 0 auto;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin: 1em 0;

    .titles {
      display: flex;
      flex-direction: column;
      padding-bottom: 1em;
      margin-bottom: .5em;

      h2 {
        @include control-visible-focus;
        flex-grow: 2;
        font-weight: bold;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0.25em;
      }

      h3 {
        color: $color-dark-grey;
        font-size: 18px;
        font-weight: 400;
      }

      .runsTitle {
        display: inline-block;
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 0.25em;
      }
    }

    .statusMessage {
      min-height: 1em;
      padding-bottom: .5em;
      border-bottom: 1px solid $color-light-grey-4;
    }
  }

  .variantRuns {
    margin-bottom: 0.5rem;
    .runsTitle {
      font-weight: bold;
    }

    .variant-run-actions {
      display: flex;
      margin-top: 0.5rem;
    }

    .variant-run-selector {
      flex-grow: 1;
    }

    .delete-button {
      background: url('connect-elements/src/images/actionDelete.svg')
        no-repeat left center;
      background-size: 30px;
      box-shadow: none;
      margin-left: 0.5rem;
      min-width: unset;
    }
  }

  .footer {
    border-top: 1px solid $color-light-grey-4;
    margin-top: .5em;
    padding-top: 1em;

    .top-buttons,
    .bottom-buttons {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 1em 0;

      button {
        min-width: unset;
      }
    }

    .top-buttons {
      margin-bottom: 35px;
    }

    .bottom-buttons {
      .clone-button {
        background: url('connect-elements/src/images/actionNew.svg') no-repeat
          left center;
        background-size: 30px;
      }

      .rename-button {
        background: url('connect-elements/src/images/actionRename.svg')
          no-repeat left center;
        background-size: 30px;
      }

      .revert-button {
        background: url('connect-elements/src/images/actionReset.svg')
          no-repeat left center;
        background-size: 30px;
      }

      .delete-button {
        background: url('connect-elements/src/images/actionDelete.svg')
          no-repeat left center;
        background-size: 30px;
      }

      button {
        border: none;
        box-shadow: none;
        height: 1.4rem;
        text-align: left;
        padding: 0;
        padding-left: 2em;
      }
    }
  }

  .hint-panel {
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    justify-content: center;
    position: relative;

    &.open {
      width: 0;
    }
  }

  .spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(250,250,250, 0.75);
    z-index: 5;

    .spinner {
      width: 7rem;
      height: 7rem;
    }
  }
}
</style>
