// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Utility to interface with status mechanism
 *
 * These functions wrap the implementation for displaying status allowing us
 * the ability to change out that implementation.
 *
 * Currently, the implementation utilizes the angularJS / ngRedux status mechanism
 * by emitting an event over the global event bus, which is received by the
 * angular-vue-bridge service. This service maps the event received into the
 * appropriate ngRedux action and dispatches it.
 *
 * In the future, the event will be serviced by something else.. thus the reason
 * we are wrapping it (as well as for some convenience of the caller).
 */

import { globalEventBus } from '@/globalEventBus';
import globalEventBusEvents from '@/constants/globalEventBusEvents';
import { vueI18n } from '@/i18n/index';

export const setInfoMessage = (message, noAutoHide) => {
  globalEventBus.$emit(globalEventBusEvents.redux.status.set, {
    message,
    noAutoHide,
  });
};
export const setErrorMessage = msg => {
  globalEventBus.$emit(globalEventBusEvents.redux.error.set, msg);
};
export const setErrorMessageFromAPI = e => {
  let message;
  if (e && e.response && e.response.data && e.response.data.error) {
    message = vueI18n.t('common.errors.withString', {
      errorMsg: e.response.data.error,
    });
  } else {
    console && console.error && console.error(e);
    message = vueI18n.t('common.errors.unknown');
  }
  setErrorMessage(message);
};
export const clearStatusMessage = () => {
  globalEventBus.$emit(globalEventBusEvents.redux.status.clear);
};

/**
 * Register an Activity Message
 * @param {Object} activityMessage
 * @param {string} activityMessage.pageId       - The page related to consume/display the message
 * @param {string} activityMessage.componentId  - The component identifier that registers the message
 * @param {string} activityMessage.message      - The message to be displayed
 * @param {number} activityMessage.options.timeout
 * @param {boolean} activityMessage.options.dimContent
 * @param {boolean} activityMessage.options.contextualized
 */
export const setActivityMessage = ({
  pageId,
  componentId,
  message,
  options,
}) => {
  globalEventBus.$emit(globalEventBusEvents.redux.activity.set, {
    compId: componentId,
    pageId: pageId,
    message,
    options,
  });
};

/**
 * Clear an Activity Message
 * @param {Object} messageProps
 * @param {string} messageProps.pageId       - The page related to the message (Must be the same value passed to setActivityMessage)
 * @param {string} messageProps.componentId  - The component identifier that registered the message
 */
export const clearActivityMessage = ({ pageId, componentId }) => {
  globalEventBus.$emit(globalEventBusEvents.redux.activity.clear, {
    compId: componentId,
    pageId: pageId,
  });
};
