<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <OutroPanel section="pythonBokehOutro" />
</template>

<script>
import OutroPanel from '../OutroPanel';

export default {
  name: 'PythonBokehOutro',
  components: { OutroPanel }
};
</script>
