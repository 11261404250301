<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="formSection">
    <div
      class="rsc-row"
      :class="{ 'hasError' : invalid && !pristine }"
    >
      <div
        v-if="readOnly"
        class="itemDescription sectionBlurb respectLineBreaks"
        data-automation="settings-info-description"
      >
        {{ description }}
      </div>
      <RSInputText
        v-if="!readOnly"
        v-model="newDescription"
        name="itemDescription"
        class="itemDescription"
        :lines="6"
        :show-label="false"
        :placeholder="$t('appSettings.info.description.placeholder')"
        label="Content Description"
        :message="invalid"
        data-automation="settings-info-description"
        @input="onDescriptionChange"
      />
    </div>
  </div>
</template>

<script>

import RSInputText from 'Shared/components/RSInputText';

const MAX_DESCRIPTION_LENGTH = 4096;

export default {
  name: 'ItemDescription',
  components: { RSInputText },
  props: {
    description: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      newDescription: this.description,
      pristine: true,
    };
  },
  computed: {
    invalid() {
      if (this.description.length > MAX_DESCRIPTION_LENGTH && !this.pristine) {
        return this.$t('appSettings.info.description.error');
      }
      return null;
    }
  },
  watch: {
    description(newValue) {
      if (newValue !== this.newDescription) {
        this.newDescription = newValue;
        this.pristine = true;
      }
    }
  },
  methods: {
    onDescriptionChange(value) {
      this.pristine = false;
      this.$emit('input', value);
    }
  }
};
</script>
