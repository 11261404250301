<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div
        v-if="serverSettings.mailConfigured"
        data-automation="confirm-user-mail-configured"
      >
        <h1>{{ $t("confirmUser.awaitingConfirmationTitle") }}</h1>

        <h2 class="rsc-lead">
          {{ $t("confirmUser.leadMessage") }} {{ $t("confirmUser.leadMessageInstructions") }}
        </h2>

        <p>{{ $t("confirmUser.timeEmailArrivalMessage") }}</p>

        <RSButton
          :disabled="isSendingConfirmationEmail"
          :label="$t('confirmUser.resendConfirmationEmail')"
          data-automation="confirm-user__submit-button"
          @click="resendConfirmationEmail"
        />
      </div>
      <div
        v-else
        data-automation="confirm-user-no-mail-configured"
      >
        <h1> {{ $t("confirmUser.requiresConfirmationTitle") }}</h1>

        <h2 class="rsc-lead">
          {{ $t("confirmUser.leadMessage") }}
        </h2>

        <p>
          <span v-html="askYourAdminMessagePart1" />
          <a
            :href="userProfile"
            data-automation="ask-your-admin-link"
            @click.prevent="copyLink"
          >{{ $t('confirmUser.copyLink') }}</a>
          {{ $t("confirmUser.askYourAdminMessagePart2") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import { copyToClipboard } from '@/utils/clipboard';
import { getCurrentUser } from '@/api/users';
import { mapState } from 'vuex';
import { sendOrGetAccountConfirmationLink } from '@/api/authentication';
import { setErrorMessageFromAPI, setInfoMessage, clearStatusMessage } from '@/utils/status';
import { userPath, groupPath, getHashQueryParameter } from '@/utils/paths';
import { vueI18n } from '@/i18n/index';

const NOTIFICATION_TIMEOUT = 5000;
const CONFIRMATION_CHECK_INTERVAL = 3000;

export default {
  name: 'ConfirmUser',
  components: {
    RSButton,
  },
  data() {
    return {
      intervalId: null,
      currentUser: {},
      isSendingConfirmationEmail: false,
    };
  },
  computed: {
    userProfile() {
      if (!this.currentUser.guid) {
        return '';
      }

      if (this.currentUser.type && this.currentUser.type === 'group') {
        return groupPath(this.currentUser.guid);
      }

      return userPath(this.currentUser.guid);
    },
    askYourAdminMessagePart1() {
      return vueI18n.t('confirmUser.askYourAdminMessagePart1', {
        systemDisplayName: this.serverSettings.systemDisplayName
      });
    },
    ...mapState({
      serverSettings: state => state.server.settings
    })
  },
  created() {
    this.getUser().then(this.redirectIfConfirmed);
  },
  beforeDestroy() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  },
  methods: {
    getUser() {
      return new Promise((resolve, reject) => {
        getCurrentUser()
          .then(userInfo => {
            this.currentUser = userInfo;
            resolve(userInfo);
          })
          .catch(err => {
            setErrorMessageFromAPI(err);
            reject(err);
          });
      });
    },
    redirectIfConfirmed() {
      if (this.currentUser.confirmed) {
        // We are already confirmed. Why are we even here?
        this.handleRedirect();
      } else {
        // We poll to check if the confirmation has happened in another tab/window
        // and then proceed to their original destination. This polling happens
        // when we first land on this page because the email has likely been sent
        // and we are just waiting for the user to open/click.
        this.intervalId = setInterval(() => {
          this.getUser().then(newUser => {
            if (newUser.confirmed) {
              this.handleRedirect();
            }
          });
        }, CONFIRMATION_CHECK_INTERVAL);
      }
    },
    handleRedirect() {
      const [url] = getHashQueryParameter('url') || [];
      const [target] = getHashQueryParameter('target') || [];
      if (url) {
        // Someone gave us an absolute URL.
        window.location.href = url;
      } else if (target) {
        window.location.hash = `#${target}`;
      } else {
        // Visit a dashboard page
        window.location.href = '/';
      }
    },
    resendConfirmationEmail() {
      this.isSendingConfirmationEmail = true;
      sendOrGetAccountConfirmationLink(this.currentUser.guid)
        .then(() => {
          setInfoMessage(vueI18n.t('confirmUser.confirmationEmailSent'), true);
          setTimeout(clearStatusMessage, NOTIFICATION_TIMEOUT);
        })
        .catch(setErrorMessageFromAPI)
        .finally(() => {
          this.isSendingConfirmationEmail = false;
        });
    },
    async copyLink() {
      await copyToClipboard({ value: this.userProfile });
      setInfoMessage(vueI18n.t('confirmUser.copyLinkMessage'), true);
      setTimeout(clearStatusMessage, NOTIFICATION_TIMEOUT);
    }
  }
};
</script>
