// Copyright (C) 2023 by Posit Software, PBC.

export const contentHeader = {
  actions: {
    refresh: {
      label: 'Refresh Report',
    },
    emailReport: {
      label: 'Email Report',
    },
    openSolo: {
      label: 'Open Solo',
    },
    openSoloCustom: {
      label: 'Open Solo (Custom URL)',
    },
    logs: {
      label: 'Logs',
    },
    settings: {
      label: 'Settings',
    },
    more: {
      label: 'More',
    },
    print: {
      label: 'Print',
    },
    history: {
      label: 'History',
    },
    sourceVersions: {
      label: 'Source Versions',
    },
    delete: {
      label: 'Delete',
      confirmation: {
        purpose: 'Remove {category}',
        details: 'Are you sure you want to remove this {category}? This operation cannot be undone.',
      },
      success: 'Deleted {category} \'{title}\'.',
      failure: 'Unable to delete {category} \'{title}\'.',
    },
  },
};

