// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Utility to select an error or multiple errors from a list of errors
 */

import { vueI18n } from '@/i18n/index';

/**
 * Gets the first occuring error from the error list for the field passed in
 * and returns the error message using i18n.
 *
 * @param {[{ name: String, message: String, params: {key: value} }]} errors - error list
 * @param {Object} field - form field with error that matches the name from the error list
 * @param {Object} i18n - reference to Vue's i18n instance
 *
 * @returns {*}
 */
const getFirstErrorMsg = (errors, field) => {
  const error = errors.find(err => !field[err.name]);
  return error ? vueI18n.t(error.message, error.params || {}) : '';
};

export { getFirstErrorMsg };
