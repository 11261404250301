<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="admin-queue">
    <div class="flex">
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        {{ $t('admin.queue.queue') }}
      </h1>
    </div>
    <div
      v-if="hasRecords"
    >
      <RSTable
        :columns="tableHeaders"
      >
        <RSTableRow
          v-for="(record, rowIndex) in queueRecords"
          :key="record.id"
          :row-id="record.id"
          :deletable="true"
          @delete="deleteQueueHandler(record.id, rowIndex)"
        >
          <RSTableCell
            v-for="recordKey in queueRecordOrderedKeys"
            :key="`${record.id}-${recordKey}`"
          >
            <pre
              v-if="recordKey === 'payload'"
              class="queue-payload"
            >{{ record[recordKey] }}</pre>
            <span v-else>
              {{ record[recordKey] }}
            </span>
          </RSTableCell>
        </RSTableRow>
      </RSTable>
    </div>
    <div
      v-else
      class="emptyListMessage"
    >
      {{ $t('common.results.empty') }}
    </div>
  </div>
</template>

<script>
import { getQueue, deleteQueueProcess } from '@/api/queue';
import { setErrorMessageFromAPI } from '@/utils/status';
import RSTable from 'Shared/components/RSTable';
import RSTableRow from 'Shared/components/RSTableRow';
import RSTableCell from 'Shared/components/RSTableCell';

// Keys used to loop columns in order
const queueRecordOrderedKeys = [
  'id',
  'queue',
  'priority',
  'type',
  'active',
  'payload',
  'permit',
  'heartbeat',
  'hostname',
  'createdTime',
];

export default {
  name: 'AdminQueueView',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  data() {
    return {
      queueRecords: [],
      fetchInterval: null,
    };
  },
  computed: {
    hasRecords() {
      return Boolean(this.queueRecords.length);
    },
  },
  created() {
    this.tableHeaders = queueRecordOrderedKeys.map(columnkey => {
      const label = this.$t(`admin.queue.${columnkey}`);
      if (columnkey === 'createdTime') {
        return {
          label,
          size: 2,
        };
      }
      return label;
    });
    this.queueRecordOrderedKeys = queueRecordOrderedKeys;
    this.init();
  },
  mounted() {
    this.$refs.title.focus();
  },
  beforeDestroy() {
    clearInterval(this.fetchInterval);
  },
  methods: {
    init() {
      const fetchCaller = this.fetchQueue.bind(this);
      this.fetchQueue();
      this.fetchInterval = setInterval(fetchCaller, 5000);
    },
    fetchQueue() {
      return getQueue()
        .then(records => {
          this.queueRecords = records;
        })
        .catch(setErrorMessageFromAPI);
    },
    deleteQueueHandler(processId, rowIndex) {
      return deleteQueueProcess(processId)
        .then(() => {
          this.queueRecords.splice(rowIndex, 1);
        })
        .catch(setErrorMessageFromAPI);
    },
  },
};
</script>
