<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <a
    v-if="clickable"
    class="user"
    tabindex="0"
    role="link"
    @click.prevent="onClicked"
    @keydown.space.prevent="onClicked"
  >
    <div
      class="userIcon"
      data-automation="user-icon"
    >
      <UserIcon
        :username="username"
        :first-name="firstName"
        :last-name="lastName"
      />
    </div>
    <div
      class="userName"
      data-automation="user-name"
    >
      {{ username }}
    </div>
  </a>
  <div
    v-else
    class="user"
  >
    <div class="userIcon">
      <UserIcon
        :username="username"
        :first-name="firstName"
        :last-name="lastName"
      />
    </div>
    <div class="userName">
      {{ username }}
    </div>
  </div>
</template>

<script>
import UserIcon from './UserIcon';

export default {
  name: 'UserBadge',
  components: { UserIcon },
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    onClicked() {
      this.$emit('clicked');
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';

.user {
  display: inline-block;
  line-height: 30px;

  .userIcon {
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    overflow: hidden;
    background-color: $color-secondary;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  .userName {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}
  a.user {
    text-decoration: none;
  }
  .userName {
    overflow-wrap: anywhere;
  }
</style>
