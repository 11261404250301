// Copyright (C) 2023 by Posit Software, PBC.

// Vue plugin to make the redux store available to all components.  While you
// can directly use the redux store as `this.$redux`, it's encouraged to use the
// `mapStateToData` and `mapDispatchToMethods` options to map the redux state to
// local data and dispatch actions via local methods.`
//
// Define mapStateToData in a component to map the redux state to local,
// reactive data.  The component will automatically subscribe and unsubscribe
// from the redux store in order to keep $data up-to-date using your
// mapStateToData function.  mapStateToData should be a function that takes a
// single argument, the current redux state, and returns an object that will be
// merged with the component $data.
//
// Define mapDispatchToMethods in a component to map any redux dispatch actions
// to component methods. mapDispatchToActions should be a function that takes a
// single argument (dispatch from the redux store) and returns an object with
// the method names as the property name and the method implmentation as the
// property value.

const unsubscribePropertyName = Symbol('vueReduxUnsubscribe');

const VueRedux = {
  install(app) {
    app.mixin({
      beforeCreate() {
        const options = this.$options;

        if (options.redux) {
          this.$redux = options.redux;
        } else if (options.parent && options.parent.$redux) {
          this.$redux = options.parent.$redux;
        }
      },

      data() {
        const { mapStateToData } = this.$options;
        if (mapStateToData) {
          return mapStateToData(this.$redux.getState());
        }
        return {};
      },

      created() {
        const { mapStateToData, mapDispatchToMethods } = this.$options;

        if (mapStateToData) {
          this[unsubscribePropertyName] = this.$redux.subscribe(() => {
            Object.assign(this.$data, mapStateToData(this.$redux.getState()));
          });
        }

        if (mapDispatchToMethods) {
          Object.assign(this, mapDispatchToMethods(this.$redux.dispatch));
        }
      },

      beforeDestroy() {
        if (this.hasOwnProperty(unsubscribePropertyName)) {
          this[unsubscribePropertyName]();
        }
      }
    });
  }
};

export default VueRedux;
