<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModalForm
    :active="true"
    :subject="$t('contentHeader.actions.delete.confirmation.purpose', {
      category: category,
      title: appTitle
    })"
    @close="$emit('deleteModal')"
    @submit="onConfirm"
  >
    <template #content>
      <p class="details">
        {{ $t('contentHeader.actions.delete.confirmation.details', {
          category: category
        }) }}
      </p>
    </template>
    <template #controls>
      <RSButton
        type="primary"
        :label="$t('common.buttons.ok')"
        :title="$t('common.buttons.ok')"
        data-automation="confirm-dialog-submit"
      />
    </template>
  </RSModalForm>
</template>

<script>
import { terminateApplication } from '@/api/app';
import { setErrorMessage, setInfoMessage } from '@/utils/status';
import RSModalForm from 'Shared/components/RSModalForm';
import RSButton from 'Shared/components/RSButton';
import { mapState } from 'vuex';

export default {
  name: 'DeleteModal',
  components: { RSModalForm, RSButton },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
    }),
    category() {
      return this.$t(`content.type.prose.${this.app.contentType()}`);
    },
    appTitle() {
      return this.app.displayName;
    },
  },
  methods: {
    onConfirm() {
      return terminateApplication(this.app.id)
        .then(() => {
          setInfoMessage(this.$t('contentHeader.actions.delete.success', {
            category: this.category,
            title: this.appTitle,
          }), true);

          return this.$router.push({ name: 'contentList' });
        })
        .catch(() => {
          setErrorMessage(this.$t('contentHeader.actions.delete.failure', {
            category: this.category,
            title: this.appTitle,
          }), true);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.details {
  white-space: normal;
  text-align: left;
}
</style>
