<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioFooter"
    class="band"
  >
    <div class="bandContent">
      <div
        id="copyright"
        data-automation="footer"
      >
        {{ $t('common.footer', {year: new Date().getFullYear()}) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';
@import 'connect-elements/src/styles/shared/_mixins';

#rStudioFooter {
  position: relative;
  box-sizing: border-box;
  height: $footer-height;

  &.band {
    background-color: $color-primary;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;

    font-weight: normal;
    font-size: 12px;

    line-height: 25px;
  }

  .bandContent {
    @include flex-space-between();
    align-items: center;
    line-height: 20px;

    a {
      color: #fff;
    }

    #copyright {
     flex-shrink: 5;
    }
  }
}
</style>
