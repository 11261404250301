// Copyright (C) 2022 by Posit Software, PBC.

// Initialize Vue-i18n library
// with configuration and language resource objects

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { en } from './en/index';
import { es } from './es/index';

const languageConfig = {
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    es: es,
  },
};

Vue.use(VueI18n);

const vueI18n = new VueI18n(languageConfig);
vueI18n.constants = {
  systemDisplayName: 'Posit Connect',
};

/**
 * This is a factory function that proxies the original t() and i() methods of vuei18n.
 * Such methods are the main used methods for translation through the codebase.
 * We are doing this to provide constants such as systemDisplayName,
 * that come from the server, won't change, and can be used in
 * locale files without the need to add them on every component.
 * (can ve overriden when needed if a value is passed to it).
 * @param {Vuei18n} target The original Vuei18n instance
 * @param {String} method The method to be proxied.
 * @returns {Function} A function that returns the original function plus the extended constant values.
 */
const extTranslateFunc = (target, method) => ((labelPath, locale, messages, host, values) => {
  let extendedValues = values;
  if (!Array.isArray(values)) {
    extendedValues = { ...vueI18n.constants, ...values };
  }
  return target[method](
    labelPath,
    locale,
    messages,
    host,
    extendedValues
  );
});

/**
 * Using a proxy to extend vuei18n to handle constants in messages,
 * this helps on situations where we can define messaging terms
 * that come from the server, won't change, and can be used in
 * locale files without the need to add them on every component.
 * E.g: Branding display name.
 */
const i18nExtended = new Proxy(vueI18n, {
  get: function(target, prop) {
    if (prop === '_t') {
      return extTranslateFunc(target, prop);
    }
    if (prop === '_i') {
      return extTranslateFunc(target, prop);
    }
    return Reflect.get(...arguments);
  },
});

// TODO: Handle these constants in a new way,
// within the Vue world.
const initI18nConstants = constants => {
  vueI18n.constants = { ...vueI18n.constants, ...constants };
  return i18nExtended;
};

export { i18nExtended as vueI18n, initI18nConstants };
