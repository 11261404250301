<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="reportResults"
    class="rsc-serverstatus-report"
    tabindex="0"
  >
    <h2 ref="top">
      {{ $t('admin.serverStatus.reportTitle') }} <code>{{ reportResults.run.hostname }}</code>
    </h2>
    {{ reportResults.run.startTime.toLocaleString() }}
    <div>
      <div
        role="button"
        tabindex="0"
        class="back-to-top"
        @click="backToTop"
        @keyup.enter="backToTop"
        @keyup.space="backToTop"
      >
        <span
          role="img"
          :aria-label="$t('admin.serverStatus.backToTop')"
        >
          ↑
        </span>
        <span class="result-link">
          {{ $t('admin.serverStatus.backToTop') }}
        </span>
      </div>
      <h3>
        {{ $t('admin.serverStatus.summary') }}
      </h3>
      <p class="passed">
        <span>✅</span> {{ reportResults.run.passed }} {{ $t('admin.serverStatus.passedStatus') }}
      </p>
      <p class="failed">
        <span>⚠️</span> {{ reportResults.run.failed }} {{ $tc('admin.serverStatus.failedStatus', reportResults.run.failed) }}
      </p>
      <p
        v-if="report.status === 'canceled'"
        class="failed"
      >
        {{ $t('admin.serverStatus.reportWasCanceled') }}
      </p>
      <div
        v-for="(group, name, i) in groupedResults"
        :key="i"
      >
        <h4>
          <span
            class="result-link"
            :onclick="'document.getElementById(\'' + 'group' + i + '\').scrollIntoView()'"
          >
            {{ group.localizedName }}
            <span class="args">
              {{ group.args ? " (" + group.args + ")" : "" }}
            </span>
          </span>
        </h4>
        <ul>
          <li
            v-for="(result, j) in group.results"
            :key="j"
          >
            <span
              class="result-link"
              :class="result.passed ? 'passed' : 'failed'"
              :onclick="'document.getElementById(\'' + 'group' + i + 'test' + j + '\').scrollIntoView()'"
            >
              <span
                role="img"
                :aria-label="result.passed ? 'passed' : 'failed'"
              >{{ result.passed ? "✅ " : "⚠️ " }}</span> {{ result.test.localizedName }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <!-- Begin groups iteration -->
      <div
        v-for="(group, name, i) in groupedResults"
        :key="i"
      >
        <div class="result-header">
          <h3
            :id="'group' + i"
          >
            {{ $t('admin.serverStatus.group') }}: {{ group.localizedName }}
          </h3>
          <div class="args">
            {{ group.args ? " (" + group.args + ")" : "" }}
          </div>
        </div>
        <div
          v-for="(result, j) in group.results"
          :key="j"
        >
          <div class="result-header">
            <h4
              :id="'group' + i + 'test' + j"
            >
              {{ $t('admin.serverStatus.test') }}: {{ result.test.localizedName }}
            </h4>
            <div class="args">
              {{ result.test.args ? " (" + result.test.args + ")" : "" }}
            </div>
            <div :class="result.passed ? 'passed' : 'failed'">
              {{ result.passed ? `✅ ${$t('admin.serverStatus.passedStatus').toUpperCase()}` : `⚠️ ${$tc('admin.serverStatus.failedStatus').toUpperCase()}` }}
            </div>
          </div>
          <p class="result-description">
            {{ result.test.description }}
          </p>
          <p
            v-if="!result.passed"
            class="result-error-text"
          >
            {{ result.test.errorText }}
          </p>
          <div v-if="result.output">
            <h5>{{ $t('admin.serverStatus.stdOut') }}</h5>
            <pre tabindex="0">{{ result.output }}</pre>
          </div>
          <div v-if="result.error">
            <h5>{{ $t('admin.serverStatus.stdErr') }}</h5>
            <pre tabindex="0">{{ result.error }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelfTestRunResults } from '@/api/selfTests';
import { setErrorMessage } from '@/utils/status';
import { localOffset } from '@/utils/timezone';
import { safeAPIErrorMessage, isAPIErrorMessage } from '@/api/error';
import { toCamelCase } from './utils';

export default {
  name: 'ServerStatusReportDocument',
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reportResults: null,
      offset: localOffset(),
    };
  },
  computed: {
    groupedResults: function() {
      // This function should just get ALL the data into the format I want.
      const groupedResults = this.reportResults.results.reduce((groups, result) => {
        const group = (groups[result.group.name + result.group.args] || {
          // Default empty group
          args: result.group.args,
          passed: 0,
          failed: 0,
          results: [],
          localizedName: this.$t(`admin.serverStatus.groupNames.${toCamelCase(result.group.name)}`)
        });
        if (result.passed === true) {
          group.passed++;
        } else {
          group.failed++;
        }
        // Gather strings
        result.test.localizedName = this.$t(`admin.serverStatus.tests.${toCamelCase(result.test.name)}.name`);
        const descriptionKey = `admin.serverStatus.tests.${toCamelCase(result.test.name)}.description`;
        result.test.description = this.$te(descriptionKey) ? this.$t(descriptionKey) : '';
        const errorKey = `admin.serverStatus.tests.${toCamelCase(result.test.name)}.error`;
        result.test.errorText = this.$te(errorKey) ? this.$t(errorKey) : '';
        group.results.push(result);
        groups[result.group.name + result.group.args] = group;
        return groups;
      }, {});
      return groupedResults;
    },
  },
  watch: {
    report: function() {
      this.pollReportResults();
    }
  },
  created() {
    this.pollReportResults();
  },
  methods: {
    async pollReportResults() {
      if (!this.report) {
        return;
      }

      const previousReportResults = this.reportResults;
      try {
        this.reportResults = await getSelfTestRunResults(this.report.id);
        if (['requested', 'running'].includes(this.reportResults.run.status)) {
          setTimeout(this.pollReportResults, 3000);
        }
      } catch (error) {
        if (isAPIErrorMessage(error)) {
          setErrorMessage(safeAPIErrorMessage(error));
          this.reportResults = previousReportResults;
        } else {
          throw error;
        }
      }
    },
    backToTop() {
      this.$refs.top.scrollIntoView();
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  scroll-margin-top: 60px;
}

.rsc-serverstatus-report {
  margin-top: 20px;

  /* Here through "End replicated styles" should be present in
  makeReportDocumentHTML() and ServerStatusReportDocument.vue */
  ul {
    padding-left: 20px;
  }

  li {
    margin: 0px !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 500;
  }

  pre {
    line-height: 1.5rem;
  }

  .passed {
    color: hsl(115, 73%, 31%);
    font-weight: 700;
  }

  .failed {
    color: hsl(40, 79%, 34%);
    font-weight: 700;
  }

  .result-header {
    display: flex;
    align-items: baseline;
    justify-content: start;
    gap: 1rem;
    margin-top: 20px;
  }

  .result-link {
    cursor: pointer;
  }

  .result-link:hover {
      text-decoration: underline;
  }

  .result-error-text {
    font-weight: 700;
  }

  .back-to-top {
    position: fixed;
    right: 32px;
    bottom: 20px;
    background: white;
    border-radius:13px;
    height: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    padding-right: 8px;
  }

  .back-to-top:hover {
    cursor: pointer;
  }

  .back-to-top [role=img] {
    font-weight: 700;
    background:#333333;
    color: white;
    border-radius:50%;
    height: 26px;
    width: 26px;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    margin-right: 0px;
  }

  .args {
    font-size: 0.9rem;
    color: #555;
  }
  /* End replicated styles */

  &__input {
    margin-bottom: 0.9rem;
  }

  &__section {
    margin-bottom: 3rem;
  }

    &-header {
      margin-bottom: 0.6rem;
      padding: 0.6rem 0;
      align-items: flex-end;
      justify-content: space-between;
      display: flex;
    }
}

</style>
