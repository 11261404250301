<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    :aria-label="$t('contentHeader.actions.delete.label')"
    :title="$t('contentHeader.actions.delete.label')"
    class="action delete content-actions__delete-button"
    data-automation="menuitem-delete--dropdown"
    @click="$emit('deleteModal')"
  >
    <span class="actionTitle">{{ $t('contentHeader.actions.delete.label') }}</span>
  </button>
</template>

<script>

export default {
  name: 'DeleteButton',
};
</script>
