<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioHeader"
    class="connect-header"
  >
    <ServerNotices
      v-if="initialized"
      :ng-server="serverSettings"
    />
    <div class="band">
      <div class="innards bandContent">
        <div class="connect-header__left-side">
          <button
            v-if="isAuthenticated"
            id="menuToggler"
            class="connect-header__menuToggler"
            aria-label="Navigation Menu"
            aria-controls="navPanel"
            data-automation="menu-toggler"
            @click="toggleNavPanel"
          />
          <router-link
            :to="{ name: 'root' }"
            aria-label="Home"
            data-automation="header-menu-logo"
          >
            <img
              class="connect-header__logo"
              :src="'__logo__'"
              alt=""
            >
          </router-link>
        </div>
        <div
          v-if="initialized"
          class="menu main"
        >
          <span
            v-if="isAuthenticated"
            data-automation="main-menu"
            class="menuItems"
          >
            <router-link
              :to="{ name: 'contentList' }"
              class="menuItem"
              :class="{ active: isActiveTab('contentList') }"
              data-automation="header-menu-content"
            >
              {{ $t('navPanel.content') }}
            </router-link>
            <router-link
              :to="{ name: 'people' }"
              class="menuItem"
              :class="{ active: isActiveTab('people') }"
              data-automation="header-menu-people"
            >
              {{ $t('navPanel.people.title') }}
            </router-link>
            <router-link
              v-if="shouldShowEnvironmentsMenu"
              :to="{ name: 'environments' }"
              class="menuItem"
              :class="{ active: isActiveTab('environments') }"
              data-automation="header-menu-environments"
            >
              {{ $t('navPanel.environments') }}
            </router-link>
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin' }"
              class="menuItem"
              :class="{ active: isActiveTab('admin.') }"
              data-automation="header-menu-admin"
            >
              {{ $t('navPanel.admin.title') }}
            </router-link>
            <router-link
              v-if="!viewerHideDocs"
              :to="{ name: 'help.docs' }"
              class="menuItem"
              :class="{ active: isActiveTab('help.') }"
              data-automation="header-menu-documentation"
            >
              {{ $t('navPanel.documentation.title') }}
            </router-link>
          </span>
        </div>
        <CurrentUser
          v-if="initialized"
          id="currentUser"
          :server-settings="serverSettings"
        />
      </div>
    </div>
    <StatusMessage />
  </div>
</template>

<script>
import ServerNotices from '@/views/ServerNotices';
import CurrentUser from '@/components/CurrentUser';
import StatusMessage from '@/components/StatusMessage';
import { ExecutionTypeK8S } from '@/api/serverSettings';
import { showNavPanel } from '@/store/redux/app-state/app-state.actions';
import { mapState } from 'vuex';

export default {
  name: 'AppHeader',
  components: {
    ServerNotices,
    CurrentUser,
    StatusMessage,
  },
  mapStateToData(state) {
    return {
      showNavPanel: state.appState.showNavPanel,
    };
  },
  computed: {
    initialized() {
      return !!this.serverSettings;
    },
    isAdmin() {
      return this.currentUser?.isAdmin();
    },
    isPublisher() {
      return this.currentUser?.isPublisher();
    },
    isPublisherOrAdmin() {
      return this.isAdmin || this.isPublisher;
    },
    viewerHideDocs() {
      return !this.isPublisherOrAdmin && this.serverSettings.hideViewerDocumentation;
    },
    shouldShowEnvironmentsMenu() {
      return Boolean(
        this.serverSettings.executionType === ExecutionTypeK8S &&
        this.isPublisherOrAdmin &&
        this.serverSettings.enableImageManagement
      );
    },
    isActiveTab() {
      const currentPath = this.$route.name;
      return prefix => currentPath.startsWith(prefix);
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      serverSettings: state => state.server.settings,
    })
  },
  methods: {
    toggleNavPanel() {
      if (this.isAuthenticated) {
        this.$redux.dispatch(showNavPanel(!this.showNavPanel));
      } else {
        this.$router.push({ name: 'welcome' });
      }
      this.$nextTick(() => {
        const title = document.querySelector('.menuSectionTitle a');
        title?.focus();
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';
@import 'connect-elements/src/styles/shared/_mixins';

#rStudioHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  z-index: 100;

  background-color: rgba(255, 255, 255, .98);

  @include drop-shadow(0, 3px, 15px, 0px, rgba(0,0,0,0.1));
  @include transition-property(left, top);
  @include normal-transition-duration();

  #menuToggler {
    background-color: $color-white;
    padding: 0;
    display: inline-block;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    vertical-align: bottom;
    @include control-visible-focus;
  }

  #menuToggler {
    background-image: url(connect-elements/src/images/menuToggler.svg);
    cursor: pointer;
  }

  .menuItems {
    display: inline-block;
  }

  .innards {
    padding: 40px 0;
    @include flex-space-between();
    align-items: center;

    @include transition-property(padding);
    @include slow-transition-duration();
  }

  #currentUser {
    display: inline-block;
    height:30px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1023px){
  #rStudioHeader {
    .menu.main {
      display: none;
    }

    .innards {
      padding: 9px 0;
    }
  }
}
</style>
