<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    v-if="active"
    :subject="modalSubject"
    :close-button-label="$t('common.actions.close')"
    :active="active"
    :aria-label="modalSubject"
    dialog-class="publish-wizard"
    role="dialog"
    data-automation="publish-wizard__modal"
    @close="close"
  >
    <template #content>
      <!-- Despite this component clearly having a bound "is", this check complains.
           Possibly relevant: https://github.com/vuejs/eslint-plugin-vue/issues/869 -->
      <!-- eslint-disable vue/require-component-is -->
      <component
        :is="currentPanel"
        :step="stepNum + 1"
        class="publish-wizard__panel"
      />
      <!-- eslint-enable vue/require-component-is -->
    </template>
    <template #controls>
      <RSButton
        :label="$t('common.buttons.back')"
        :class="[
          'publish-wizard__button',
          'publish-wizard__back-button',
          { 'publish-wizard__back-button--hidden': !showBackButton }
        ]"
        type="secondary"
        @click="back"
      />
      <progress
        class="publish-wizard__progress"
        :aria-label="`Step ${stepNum + 1} of ${numSteps}`"
        :max="numSteps"
        :value="stepNum + 1"
      />
      <RSButton
        v-show="showNextButton"
        :label="$t('common.buttons.next')"
        class="publish-wizard__button publish-wizard__next-button"
        @click="next"
      />
      <RSButton
        v-show="showDoneButton"
        :label="$t('common.buttons.done')"
        class="publish-wizard__button publish-wizard__done-button"
        @click="close"
      />
    </template>
  </RSModal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  PUBLISH_WIZARD_CLOSE,
  PUBLISH_WIZARD_NEXT,
  PUBLISH_WIZARD_BACK,
  PUBLISH_WIZARD_SET_STEP,
} from '@/store/modules/publish';

import {
  JUPYTER_NOTEBOOK,
  JUPYTER_VOILA,
  PIN,
  PLUMBER_API,
  PYTHON_API,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_STREAMLIT,
  PYTHON_BOKEH,
  PYTHON_SHINY,
  PYTHON_VETIVER,
  QUARTO,
  QUARTO_R,
  QUARTO_PYTHON,
  QUARTO_WEBSITE,
  RMARKDOWN,
  RMARKDOWN_PARAMS,
  SHINY_APP,
  SHINY_RMARKDOWN,
  R_VETIVER,
} from '@/constants/contentTypes';
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';

import JupyterSteps from './JupyterSteps';
import JupyterVoilaSteps from './JupyterVoilaSteps';
import PinSteps from './PinSteps';
import PlumberAPISteps from './PlumberAPISteps';
import PythonAPISteps from './PythonAPISteps';
import PythonFastAPISteps from './PythonFastAPISteps';
import PythonDashSteps from './PythonDashSteps';
import PythonStreamlitSteps from './PythonStreamlitSteps';
import PythonBokehSteps from './PythonBokehSteps';
import PythonShinySteps from './PythonShinySteps';
import PythonVetiverSteps from './PythonVetiverSteps';
import QuartoSteps from './QuartoSteps';
import QuartoRSteps from './QuartoRSteps';
import QuartoPythonSteps from './QuartoPythonSteps';
import QuartoWebsiteSteps from './QuartoWebsiteSteps';
import RMarkdownParamsSteps from './RMarkdownParamsSteps';
import RMarkdownSteps from './RMarkdownSteps';
import ShinyAppSteps from './ShinyAppSteps';
import RVetiverSteps from './RVetiverSteps';

const stepsByType = {
  [JUPYTER_NOTEBOOK]: JupyterSteps,
  [JUPYTER_VOILA]: JupyterVoilaSteps,
  [PLUMBER_API]: PlumberAPISteps,
  [RMARKDOWN]: RMarkdownSteps,
  [RMARKDOWN_PARAMS]: RMarkdownParamsSteps,
  [SHINY_APP]: ShinyAppSteps,
  [SHINY_RMARKDOWN]: ShinyAppSteps,
  [PIN]: PinSteps,
  [PYTHON_API]: PythonAPISteps,
  [PYTHON_DASH]: PythonDashSteps,
  [PYTHON_FASTAPI]: PythonFastAPISteps,
  [PYTHON_STREAMLIT]: PythonStreamlitSteps,
  [PYTHON_BOKEH]: PythonBokehSteps,
  [PYTHON_SHINY]: PythonShinySteps,
  [PYTHON_VETIVER]: PythonVetiverSteps,
  [QUARTO]: QuartoSteps,
  [QUARTO_R]: QuartoRSteps,
  [QUARTO_PYTHON]: QuartoPythonSteps,
  [QUARTO_WEBSITE]: QuartoWebsiteSteps,
  [R_VETIVER]: RVetiverSteps,
};

export default {
  name: 'PublishWizard',
  components: {
    RSModal,
    RSButton,
  },
  computed: {
    ...mapState({
      active: state => state.publish.active,
      stepNum: state => state.publish.stepNum,
      contentType: state => state.publish.contentType,
      copyFrom: state => state.publish.copyFrom,
    }),
    modalSubject() {
      return this.copyFrom
        ? `${this.$t('publish.exampleSubject')} ${this.copyFrom.title}`
        : `${this.$t(
          `publish.subject.${this.contentType}`
        )}`;
    },
    steps() {
      return this.copyFrom
        ? stepsByType[this.contentType].copyFrom
        : stepsByType[this.contentType].bare;
    },
    numSteps() {
      return this.steps.length;
    },
    isLastStep() {
      return this.stepNum === this.numSteps - 1;
    },
    currentPanel() {
      return this.steps[this.stepNum];
    },
    showBackButton() {
      if (this.stepNum !== 0) {
        return true;
      }
      return (this.copyFrom !== null && this.copyFrom !== undefined);
    },
    showNextButton() {
      return !this.isLastStep;
    },
    showDoneButton() {
      return this.isLastStep;
    },
  },
  methods: {
    ...mapMutations({
      close: PUBLISH_WIZARD_CLOSE,
      next: PUBLISH_WIZARD_NEXT,
      setStep: PUBLISH_WIZARD_SET_STEP,
    }),
    ...mapActions({
      back: PUBLISH_WIZARD_BACK,
    }),
  },
};
</script>

<style lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.publish-wizard {
  &__panel {
    margin: 0 -2rem 15px -2rem;
    border-color: $color-light-grey-3;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;

    min-height: 400px;
    background-repeat: no-repeat;
  }

  &__back-button--hidden {
    visibility: hidden;
  }

  > .rs-modal__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: none;

    button:first-child {
      margin-left: 0;
    }
  }

  .publish-wizard__progress {
    appearance: none;
    border-radius: 3px;
    background-color: $color-light-grey-3;

    &::-moz-progress-bar {
      background-color: $color-posit-teal;
    }
    &::-webkit-progress-bar {
      background-color: $color-light-grey-3;
      border-radius: 3px;
    }
    &::-webkit-progress-value {
      background-color: $color-posit-teal;
      border-radius: 3px;
    }
  }
}

.wizard-panel {
  p {
    margin-bottom: 1em;
  }

  &__notice {
    color: #202020;
    border: 1px solid $color-primary;
    background-color: $color-light-blue;
    margin: 1.5rem 0 0 0;
    padding: 1rem;
  }

  &--blue {
    background-color: $color-primary-dark;
    color: $color-white;
    * {
      background-color: $color-primary-dark;
    }
  }

  &--white {
    background-color: $color-white;
    color: $color-dark-grey-3;
    * {
      background-color: $color-white;
    }
  }

  &__content {
    background-color: transparent;
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;
    min-height: 522px;

    &--no-title {
      margin-top: 30px;
    }
  }

  &__section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: 30px;
    color: $color-primary-dark;

    &--step {
      &:before {
        color: $color-white;
        display: inline-block;
        height: 3rem;
        width: 3rem;
        line-height: 3rem;
        text-align: center;
        border-radius: 3rem;
        background-color: $color-primary-dark;
        font-weight: 400;
        margin-right: 1rem;
      }

      @for $step from 1 through 9 {
        &-#{$step}:before {
          content: '#{$step}';
        }
      }
    }

    &--done {
      color: $color-white;
      padding-left: 3rem;
      background-repeat: no-repeat;
      background-image: url('connect-elements/src/images/checkWhite.svg');
      background-size: 3rem 3rem;
      background-position: left center;
    }
  }

  &__intro {
    margin-top: 1.5rem;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 1.0rem;
  }

  &__copy {
    position: relative;
    padding-bottom: 2rem;
  }

  &__copy-code {
    margin-bottom: 0;

    > code {
      display: inline-block;
    }
  }

  &__copy-button--multiline {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 10px !important;
    font-size: 11px !important;
    min-width: 0 !important;
  }

  &__links {
    li {
      margin-bottom: 0.5rem;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  &__outro-link {
    text-decoration: underline;
  }
}

@include for-small-screens() {
  .publish-wizard {
    &__button {
      min-width: auto !important;
    }
  }
}
</style>
