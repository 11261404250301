// Copyright (C) 2022 by Posit Software, PBC.

import { vueI18n } from '@/i18n/index';

// safeAPIErrorMessage attempts to return the error message contained in the response.
// If it fails to find the error message, it returns a generic message.
export function safeAPIErrorMessage(err) {
  return err && err.response && err.response.data && err.response.data.error
    ? vueI18n.t('common.errors.withString', {
      errorMsg: err.response.data.error,
    })
    : vueI18n.t('common.errors.unknown');
}

// safeAPIErrorCode attempts to return the error code contained in the response.
export function safeAPIErrorCode(err) {
  return err && err.response && err.response.data
    ? err.response.data.code
    : null;
}

// isAPIErrorMessage checks whether the response meets the criteria for
// safeAPIErrorMessage. If that function would return a generic error message,
// this function returns `false`, so you can do something else with that response
// and avoid a generic error message.
export function isAPIErrorMessage(err) {
  return !!(err && err.response && err.response.data && err.response.data.error);
}
