// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';
import { keysToCamel, keysToSnake } from '@/api/transform';
import { vueI18n } from '@/i18n';
import { apiV1Path, apiPath } from '@/utils/paths';

const ExecutionTypeK8S = 'launcher-kubernetes';
const ExecutionTypeNative = 'native';

function installationMapper({ data: { installations } }) {
  const versions = [...new Set(installations.map(({ version }) => version).reverse())];
  if (!versions.length) {
    versions.push(vueI18n.t('documentation.versions.none'));
  }
  return versions;
}

const getRVersions = () => axios.get(apiV1Path('server_settings/r')).then(installationMapper);

const getPythonVersions = () => axios.get(apiV1Path('server_settings/python')).then(installationMapper);

const getQuartoVersions = () => axios.get(apiV1Path('server_settings/quarto')).then(installationMapper);

const getRInstallations = () => axios.get(apiV1Path('server_settings/r')).then(response => {
  return response.data.installations;
});

const getPythonInstallations = () => axios.get(apiV1Path('server_settings/python')).then(response => {
  return response.data.installations;
});

const getQuartoInstallations = () => axios.get(apiV1Path('server_settings/quarto')).then(response => {
  return response.data.installations;
});

const getMailSettings = () => axios
  .get(apiPath('server_settings/mail'))
  .then(({ data }) => keysToCamel(data));

const updateMailSettings = data => axios.put(apiPath('server_settings/mail'), keysToSnake(data));

const testMailSettings = () => axios.get(apiPath('server_settings/mail/test'));

const getRuntimeDefaultsAndLimits = appMode => axios
  .get(apiPath(`server_settings/scheduler/${encodeURIComponent(appMode)}`))
  .then(({ data }) => keysToCamel(data));

const getServerSettings = () => axios.get(apiPath('server_settings')).then(({ data }) => keysToCamel(data));

const getApplicationsSettings = () => axios.get(apiPath('server_settings/applications')).then(({ data }) => keysToCamel(data));

export {
  ExecutionTypeK8S,
  ExecutionTypeNative,
  getRVersions,
  getPythonVersions,
  getQuartoVersions,
  getRInstallations,
  getPythonInstallations,
  getQuartoInstallations,
  getMailSettings,
  testMailSettings,
  updateMailSettings,
  getRuntimeDefaultsAndLimits,
  getServerSettings,
  getApplicationsSettings,
};
