<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <AppContainer>
    <h1 class="sectionTitle">
      {{ $t('authentication.unauthorized.title') }}
    </h1>

    <p>
      {{ $t('authentication.unauthorized.message') }}
    </p>
  </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';

export default {
  name: 'UnauthorizedView',
  components: {
    AppContainer,
  }
};
</script>
