<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__jupyter-file wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.jupyterNotebookFile.title') }}
      </StepHeading>
      <p>{{ $t('publish.jupyterNotebookFile.body') }}</p>
      <p>
        <a
          href="https://jupyter.org/"
          target="_blank"
        >{{ $t('publish.jupyterNotebookFile.learnMore') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';

export default {
  name: 'JupyterNotebookFile',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__jupyter-file {
    background-image: url('./background.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
</style>
