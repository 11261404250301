<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <RSPrincipalInfo
      :initials="userProfile.displayInitials"
      :title="userProfile.username"
      data-automation="user-principal-info"
    />
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
      @authenticated="refreshAfterSudo"
    >
      <div class="rsc-user-info">
        <div data-automation="ui-name-status">
          {{ userProfile.displayName }}
          <span
            v-if="userProfile.displayStatuses"
            class="rsc-user-info__status"
          >
            ({{ userProfile.displayStatuses }})
          </span>
        </div>

        <div data-automation="ui-role">
          {{ userProfile.userRole.displayName }}
        </div>

        <div
          v-if="userProfile.email"
          data-automation="ui-email"
        >
          <a :href="`mailto:${userProfile.email}`">{{ userProfile.email }}</a>
        </div>

        <div data-automation="ui-joined">
          {{ $t('users.title.joined') }}: {{ userProfile.createdTime | activeTime }}
        </div>
        <RSInputCheckbox
          v-if="canLockOrUnlockUser"
          :checked="accountLocked"
          :label="$t('users.title.accountLocked')"
          :help="$t('users.help.locked')"
          name="ui-locked"
          data-automation="ui-locked"
          @change="toggleAccountLocked(executeRestrictedApi)"
        />
      </div>
      <div class="rs-divider" />
      <div class="rs-actions pull--right">
        <ResetPassword
          v-if="currentUser.canResetPassword(serverSettings, userProfile)"
          :can-email="canEmailAuthenticationLinks"
          :user-profile="userProfile"
        />
        <ConfirmAccount
          v-if="currentUser.canConfirmAccount(serverSettings, userProfile)"
          :can-email="canEmailAuthenticationLinks"
          :user-profile="userProfile"
        />
        <ChangePassword
          v-if="currentUser.canChangePassword(serverSettings, userProfile)"
          @changed-password="refreshUserProfile"
        />
        <EditProfile
          v-if="currentUser.canEditUser(userProfile)"
          :execute-restricted-api="executeRestrictedApi"
          :current-user="currentUser"
          :profile-field-permissions="profileFieldPermissions"
          :server-settings="serverSettings"
          :user-profile="userProfile"
          @profile-updated="refreshUserProfile"
        />
      </div>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import RSInputCheckbox from 'Shared/components/RSInputCheckbox';
import RSPrincipalInfo from 'Shared/components/RSPrincipalInfo';

import { lockUser, unlockUser } from '@/api/users';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import { activeTime } from '@/utils/activeTime.filter';
import { setErrorMessageFromAPI } from '@/utils/status';

import ChangePassword from './ChangePassword';
import ConfirmAccount from './ConfirmAccount';
import EditProfile from './EditProfile';
import ResetPassword from './ResetPassword';

export default {
  name: 'UserInformation',
  components: {
    ChangePassword,
    ConfirmAccount,
    EditProfile,
    ResetPassword,
    RestrictedAccessWrapper,
    RSInputCheckbox,
    RSPrincipalInfo,
  },
  filters: {
    activeTime,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    profileFieldPermissions: {
      type: Object,
      required: true,
    },
    userProfile: {
      type: Object,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      retryUserLock: false,
    };
  },
  computed: {
    canLockOrUnlockUser() {
      return this.currentUser.canLockOrUnlockUser(this.userProfile);
    },
    accountLocked() {
      return this.userProfile.locked;
    },
    canEmailAuthenticationLinks() {
      // if mail is configured and we are able to modify accounts
      // note: external user data is not subject to account modification
      return (
        this.serverSettings.mailConfigured &&
        !this.serverSettings.externalUserData
      );
    },
  },
  methods: {
    toggleAccountLocked(executeRestrictedApi) {
      const api = this.userProfile.locked ? unlockUser : lockUser;
      executeRestrictedApi(api(this.userProfile.guid))
        .then(() => this.refreshUserProfile())
        .catch(e => {
          if (!(e instanceof ReauthenticationInProgressError)) {
            setErrorMessageFromAPI(e);
          } else {
            this.retryUserLock = true;
          }
        });
    },
    refreshAfterSudo() {
      if (this.retryUserLock) {
        const api = this.userProfile.locked ? unlockUser : lockUser;
        api(this.userProfile.guid);
        this.refreshUserProfile();
        this.retryUserLock = false;
      }
    },
    refreshUserProfile() {
      return this.$emit('refresh-user-profile');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';
.rsc-user-info {
  margin-top: 1rem;
  line-height: 2;
  word-wrap: break-word;
  word-break: break-word;

  &__status {
    color: $color-dark-grey;
    font-size: $rs-font-size-smallest;
  }
}

.pull--right {
  justify-content: flex-end;
}
</style>
