// Copyright (C) 2023 by Posit Software, PBC.

const parametersPanel = {
  title: 'Parameters',
  processing: 'Processing...',
  save: 'Save',
  saveAs: 'Save As',
  runReport: 'Run Report',
  clone: 'Clone',
  rename: 'Rename',
  delete: 'Delete',
  deleteRun: 'Delete Run',
  revert: 'Revert',
  default: 'Default',
  close: 'Close',
  open: 'Open',
  modify: 'Modify',
  runs: 'My Runs',
  noRuns: 'This Report Has Not Been Run',
  deletedRun: '<strong>Run deleted.</strong>',
  revertedChanges: 'Reverted changes.',
  deletedVariant: '<strong>Deleted Variant:</strong><br>{name}.',
  renamedVariant: 'Renamed Variant.',
  addedVariant: 'Added Variant.',
  copyOf: 'Copy of',
  confirmModal: {
    title: 'Unsaved Parameter Changes',
    message: 'Would you like to {cancel} or {discard}?'
  },
  renameVariantModal: {
    createTitle: 'Clone Variant',
    renameTitle: 'Rename Variant',
    name: 'Variant Name',
    maxLength: 'Max: {maxNameLength} characters',
    cancel: 'Cancel',
    rename: 'Rename',
    create: 'Clone',
  },
  confirmClose: {
    discardChanges: 'Discard Changes',
    cancel: 'Return to Parameters Form',
    warning: 'You have unsaved changes that will be lost if you close the Parameters Panel.',
  },
  confirmSwitchVariant: {
    discardChanges: 'Switch Variant',
    cancel: 'Return to Current Variant',
    warning: 'You have unsaved changes that will be lost if you select a different variant.',
  },
  confirmSwitchRun: {
    title: 'Unsaved Run Changes',
    discardChanges: 'Switch Run',
    cancel: 'Return to Current Run',
    warning: 'You have unsaved changes that will be lost if you select a different run.',
  },
  confirmDeleteRun: {
    delete: 'Delete Run',
    cancel: 'Keep Run',
    warning: 'Once deleted, this run cannot be restored.',
  },
  confirmDelete: {
    delete: 'Delete Variant',
    cancel: 'Keep Variant',
    warning: 'Once deleted, this variant cannot be restored.',
  },
  legacy: {
    title: 'Parameters for {name}',
    lastRendered: 'Output last rendered on {time}.',
    notRendered: 'Output using the current parameters not generated.',
    notEditable: 'You do not have privileges to customize parameters for this report. The configured parameter values are displayed below.',
    noCustomParams: 'No custom parameters',
    saveSuccess: 'Parameters have been saved.',
    saveError: 'Unable to save parameters.',
    deleteSuccess: 'Report has been deleted.',
    deleteError: 'Unable to delete report.',
    actions: {
      save: 'Save',
      saveAs: 'Save As',
      run: 'Run Report',
      new: 'New',
      rename: 'Rename',
      delete: 'Delete',
      revert: 'Revert',
    },
    renameModal: {
      title: 'Rename report',
      label: 'Enter a name for this report',
      button: 'Save',
    },
    saveAsModal: {
      title: 'Save report',
      button: 'Save',
      name: {
        title: 'Enter a name for this report',
      },
      visibility: {
        title: 'Who can see this report',
        private: 'Only you',
        public: 'Everyone with access to the document',
      }
    },
    confirmModal: {
      subject: 'Confirm Action',
      details: 'You have unsaved changes that will be lost if you close the input panel. Are you sure you wish to continue?',
      browserPrompt: 'You have unsaved changes.',
    },
    deleteConfirmModal: {
      subject: 'Delete Report',
      details: 'Are you sure that you want to delete the {0} report?',
      warning: 'This action cannot be undone and will also delete all output generated by the report.',
      confirmation: 'Delete',
    },
  },
};

export { parametersPanel };
