<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="schedule-monthly"
    data-automation="schedule-monthly"
  >
    <IntervalScheduleInput
      v-model.number="schedInterval"
      :term="intervalTerm"
      :disabled="disabled"
      @input="emitChange"
    />

    <div class="schedule-monthly__day-options">
      <div class="rs-field">
        <label
          for="schedule-monthly-day"
          class="rs-radio__label"
        >
          <input
            id="schedule-monthly-day"
            v-model="scheduleType"
            type="radio"
            class="rs-radio__input interval-check"
            name="schedule-month-day"
            data-automation="schedule-monthly__by-n-day"
            :disabled="disabled"
            :value="DAYOFMONTH"
          >
          <i18n
            path="appSettings.schedule.inputLabels.onMonthDay"
            tag="span"
          >
            <template #input>
              <input
                v-model="monthdayValue"
                type="number"
                class="schedule-monthly__nday-input"
                min="1"
                name="monthday"
                data-automation="schedule-monthly__nday-input"
                :aria-label="$t('appSettings.schedule.inputLabels.onMonthDay', { input: monthdayValue })"
                :disabled="disabled"
                @input="emitChange"
              >
              {{ daySuffix }}
            </template>
          </i18n>
        </label>
      </div>

      <div class="rs-field">
        <label
          for="schedule-monthly-nth-day"
          class="rs-radio__label"
        >
          <input
            id="schedule-monthly-nth-day"
            v-model="scheduleType"
            type="radio"
            class="rs-radio__input interval-check"
            name="schedule-month-day"
            data-automation="schedule-monthly__by-nth-weekday"
            :disabled="disabled"
            :value="DAYWEEKOFMONTH"
          >
          <i18n
            path="appSettings.schedule.inputLabels.onNthMonthDay"
            tag="span"
          >
            <template #input>
              <RSInputSelect
                v-model="nthweekValue"
                :label="$t('appSettings.schedule.inputLabels.weekPosition')"
                :options="nthweekOptions"
                data-automation="schedule-monthly__nth-day-input"
                name="schedule-monthly-nthday"
                class="schedule-monthly__week-frequency"
                :disabled="disabled"
                @change="emitChange"
              />
              <RSInputSelect
                v-model="weekdayValue"
                :label="$t('appSettings.schedule.inputLabels.weekDay')"
                :options="weekdayOptions"
                data-automation="schedule-monthly__weekday-input"
                name="schedule-monthly-weekday"
                class="schedule-monthly__week-frequency"
                :disabled="disabled"
                @change="emitChange"
              />
            </template>
          </i18n>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { ScheduleTypes, DaysOfWeek, OrdinalMonthWeeks } from '@/api/dto/schedule';
import RSInputSelect from 'Shared/components/RSInputSelect';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';

export default {
  name: 'MonthlySchedule',
  components: {
    RSInputSelect,
    IntervalScheduleInput,
  },
  props: {
    type: {
      type: String,
      default: ScheduleTypes.DayOfMonth,
    },
    interval: {
      type: Number,
      default: 1,
    },
    monthday: {
      type: Number,
      default: 1,
    },
    nthweek: {
      type: Number,
      default: 1,
    },
    weekday: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const weekday = day => this.$t(`appSettings.schedule.inputLabels.${day}`);
    return {
      scheduleType: this.type,
      schedInterval: this.interval,
      monthdayValue: this.monthday,
      nthweekValue: OrdinalMonthWeeks.stringOf(this.nthweek),
      weekdayValue: DaysOfWeek.stringOf(this.weekday),
      nthweekOptions: OrdinalMonthWeeks.options().map(d => ({ label: d, value: d })),
      weekdayOptions: DaysOfWeek.options().map(d => ({ label: weekday(d), value: d })),
    };
  },
  computed: {
    intervalTerm() {
      if (this.schedInterval > 1) {
        return this.$t(`appSettings.schedule.inputLabels.plural.dayofmonth`);
      }
      return this.$t(`appSettings.schedule.inputLabels.singular.dayofmonth`);
    },
    daySuffix() {
      const day = this.monthdayValue;
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const relevantDigits = day < 30 ? day % 20 : day % 30;
      return relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
    },
  },
  watch: {
    scheduleType(){
      this.emitChange();
    },
  },
  created() {
    this.DAYOFMONTH = ScheduleTypes.DayOfMonth;
    this.DAYWEEKOFMONTH = ScheduleTypes.DayweekOfMonth;
  },
  methods: {
    emitChange() {
      this.$emit('change', {
        type: this.scheduleType,
        interval: Number(this.schedInterval),
        nday: Number(this.monthdayValue),
        nthweek: this.nthweekValue,
        weekday: this.weekdayValue,
      });
    },
  }
};
</script>

<style lang="scss">
.schedule-monthly__week-frequency {
  display: inline-block;

  label {
    display: none;
  }

  select.rs-input {
    font-size: 0.8rem;
  }
}
</style>

<style lang="scss" scoped>
input.interval-check[type="radio"] {
  vertical-align: middle;
}

.schedule-monthly__day-options {
  padding-left: 1rem;
}

.schedule-monthly__nday-input {
  padding-right: 0.2rem;
  width: 3.8rem;
}
</style>
